<template>
    <div class="clients__page--view mb--35" :class="{ 'relative disabled': pageLoading }">
        <template v-if="fetchInvoiceLoading">
            <PageSpinner />
        </template>

        <template v-else-if="fetchInvoiceErr">
            <EmptyPage state="error" :handle-submit="handleFetchInvoice" />
        </template>

        <template v-else>
            <div class="main__content">
                <div class="pb--5">
                    <div class="row hidden-xs mb--10 header">  
                        <div class="row__left">
                            <div class="row__item pb--0">
                                <div class="text--bold text--color-gray-200">
                                    <template v-if="invoice.invoiceNo">
                                        INVOICE #{{ invoice.invoiceNo }}
                                    </template>
                                    <template v-else>
                                        Create Invoice
                                    </template>
                                </div>
                            </div>
                        </div>

                        <div class="row__right pr--0 pl--0 flex align-items-end">
                            <BaseButton
                                label="Preview Invoice" size="sm"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRight"
                                aria-controls="offcanvasRight"
                                variant="outline" inner-style="primary" class="mr--5"
                            />

                            <div class="relative flex align-items-center">
                                <div class="dropdown">
                                    <div
                                        id="invoiceDetailsDropDown" class="pointer" data-bs-toggle="dropdown" aria-expanded="false"
                                        :class="{ 'disabled': isSaveBtnDisabled || pageLoading }"
                                    >
                                        <div class="invoice__details__menu">
                                            <IconSvg name="dots-horizontal-rounded" fill="#667085" width="16px" />
                                        </div>
                                    </div>
    
                                    <ul class="dropdown-menu" aria-labelledby="invoiceDetailsDropDown">
                                        <li class="dropdown-item flex align-items-center pointer" @click="handleSubmitAction('save')">
                                            <IconSvg name="save" fill="#667085" width="16px" />
                                            <span class="ml--10 text--sm text--color-gray-500">Save as Draft</span>
                                        </li>

                                        <li class="dropdown-item flex align-items-center pointer" @click="handleSubmitAction('send')">
                                            <IconSvg name="edit" fill="#667085" width="16px" />
                                            <span class="ml--10 text--sm text--color-gray-500">Send Invoice</span>
                                        </li>

                                        <template v-if="invoice.status !== 'draft'">
                                            <li class="dropdown-item flex align-items-center pointer">
                                                <IconSvg name="duplicate" fill="#667085" width="16px" />
                                                <span class="ml--10 text--sm text--color-gray-500">Duplicate Invoice</span>
                                            </li>
                                            
                                            <li><hr class="dropdown-divider"></li>
                                            <li class="dropdown-item flex align-items-center pointer">
                                                <IconSvg name="delete" fill="#e04060" width="16px" />
                                                <span class="ml--10 text--sm text--color-warning">Delete invoice</span>
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt--40 pb--35">
                        <div class="invoice__details__row">
                            <div class="invoice__details__row__left flex-2 pl--0">
                                <div class="flex col-12">
                                    <div class="flex-1 mr--15 m-w-5">
                                        <div>
                                            <div class="col-12 flex align-items-center justify-content-between mb--5">
                                                <FormLabel title="Client information" class="mb--0" label-type="menu" info-type="required" />

                                                <div v-if="showClientInfoView" class="pointer flex align-items-center mr--10" @click="toggleClientView()">
                                                    <IconSvg name="show" fill="#289CA9" width="12px" height="12px" />
                                                    <span class="text--medium text--xs text--color-primary ml--5 ">Switch view</span>
                                                </div>
                                                
                                                <span v-else>
                                                    <BaseButton 
                                                        class="text--xs" label="+ New client" :show-spinner="false"
                                                        :disabled="checkUpgradeLoading && checkUpgradeFields.clients" medium
                                                        size="sm" variant="ghost" inner-style="primary" @submit="toggleModalActionType('client', 'add')" 
                                                    />
                                                </span>
                                            </div>
                
                                            <div class="col-12">
                                                <div class="invoice__row invoice__item">
                                                    <div class="invoice__details--item m-w-100">
                                                        <div class="flex align-items-center w--100">
                                                            <div class="flex-2">
                                                                <template v-if="!showClientInfoView">
                                                                    <CustomDropdown 
                                                                        id="clientDropdown" v-model="selectedClient" :options="clientList"
                                                                        list-type="clients" index-label="name" :loading="searchClientsLoading"
                                                                        :disabled="invoice.status !== 'draft'" @search="fetchClients" @select="v => setClient(v, true)" 
                                                                    >
                                                                        <template #prefix>
                                                                            <div class="mr--10">
                                                                                <IconSvg name="user" fill="#64748B" />
                                                                            </div>
                                                                        </template>
                                                                        <template #suffix="{ item }">
                                                                            <div v-if="item.name === client?.name" @click="isShowClientInfoView = true">
                                                                                <IconSvg name="show" fill="#289CA9" width="12px" height="12px" />
                                                                            </div>
                                                                        </template>
                                                                    </CustomDropdown>
                                                                </template>
                                                                <template v-else>
                                                                    <div class="w--100 mr--35 mt--20">
                                                                        <div class="invoice__client__text">
                                                                            Name: {{ client.name || 'N/A' }}
                                                                        </div>
                                                                        <div class="invoice__client__text">
                                                                            Email address: {{ client.email || 'N/A' }}
                                                                        </div>
                                                                        <div class="invoice__client__text">
                                                                            Phone number: {{ client.phoneNumber || 'N/A' }}
                                                                        </div>
                                                                        <div class="invoice__client__text">
                                                                            Status: {{ client.status || 'N/A' }}
                                                                        </div>
                                                                        <div class="invoice__client__text">
                                                                            Industry: {{ client.industry || 'N/A' }}
                                                                        </div>                                                    
                                                                    </div>
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </div>
                    
                                                    <div class="mt--30">
                                                        <div class="flex justify-content-between align-items-center mb--5" style="min-width: 400px;">
                                                            <FormLabel title="Billing emails" class="mb--0" label-type="menu" />
                                                            <BaseButton 
                                                                v-if="!showCCEmails" medium
                                                                variant="ghost" inner-style="primary" size="sm"
                                                                label="+ Multiple CC emails" @submit="openCCEmails('add')"
                                                            />
                                                            <BaseButton 
                                                                v-else
                                                                variant="ghost" inner-style="primary" size="sm"
                                                                label="- Manage email addresses" @submit="openCCEmails('manage')"
                                                            />
                                                        </div>
                                                        <div class="email__wrap mt--15">
                                                            <span v-if="!invoice.meta.ccEmails.length" class="text--sm text--color-gray-400">
                                                                No billing emails added.
                                                            </span>
                                                            
                                                            <div v-else class="">
                                                                <p v-for="email in invoice.meta.ccEmails" :key="email" class="sub__tag text--sm text--color-gray-400 mb--5">
                                                                    {{ email || '-' }}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex-1 m-w-5">
                                        <div class="col-12 flex align-items-center justify-content-between mb--5">
                                            <FormLabel title="Project information" class="mb--0" label-type="menu" info-type="required" />

                                            <div v-if="showProjectInfoView" class="pointer flex align-items-center mr--10" @click="toggleProjectView()">
                                                <IconSvg name="show" fill="#289CA9" width="12px" height="12px" />
                                                <span class="text--medium text--xs text--color-primary ml--5 ">Switch view</span>
                                            </div>
                                            <span v-else>
                                                <BaseButton 
                                                    class="text--xs" label="+ New project" :show-spinner="false"
                                                    :disabled="checkUpgradeLoading && checkUpgradeFields.projects" medium
                                                    size="sm" variant="ghost" inner-style="primary" @submit="toggleModalActionType('project', 'add')" 
                                                />
                                            </span>
                                        </div>
            
                                        <div class="col-12 flex align-items-center">
                                            <div class="invoice__row invoice__item">
                                                <div class="invoice__details--item">
                                                    <div class="flex align-items-center w--100">
                                                        <div class="flex-2">
                                                            <template v-if="!showProjectInfoView">
                                                                <CustomDropdown 
                                                                    id="projectDropdown" v-model="selectedProject" :options="projectList"
                                                                    list-type="projects" index-label="title" :loading="fetchProjectsLoading"
                                                                    :disabled="invoice.status !== 'draft'" @search="fetchProjects" @select="setProject" 
                                                                >
                                                                    <template #prefix>
                                                                        <div class="mr--5">
                                                                            <IconSvg name="folder" fill="#848789" width="12px" height="12px" />
                                                                        </div>
                                                                    </template>
                                                                    <template #suffix="{ item }">
                                                                        <div v-if="item.title === project?.title" @click="isShowClientInfoView = true">
                                                                            <IconSvg name="show" fill="#289CA9" width="12px" height="12px" />
                                                                        </div>
                                                                    </template>
                                                                </CustomDropdown>
                                                            </template>
                                                            <template v-else>
                                                                <div class="w--100 mr--35 mt--20">
                                                                    <p class="invoice__client__text">
                                                                        Title: <span class="text--color-normal text--capitalize">{{ project.title || '-' }}</span>
                                                                    </p>
                                                                    <p class="invoice__client__text">
                                                                        Status: <span class="text--color-normal text--capitalize">{{ project.status || '-' }}</span>
                                                                    </p>
                                                                    <p class="invoice__client__text">
                                                                        Deadline: <span class="text--color-normal">{{ $format_date(project.deadline) || '-' }}</span>
                                                                    </p>
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
            
                                <div class="row invoice__row block">
                                    <div class="invoice__calculate--wrap invoice__header ml--0 pr--0">
                                        <div class="w--100">
                                            <div class="form__row__left flex justify-content-between mr--0">
                                                <FormLabel title="Items" class="mb--0" label-type="title" />
                                                <BaseButton 
                                                    v-if="showAddItemBtn"
                                                    label="+ Add new item" size="sm" inner-style="primary" 
                                                    variant="ghost" @submit="addAnotherInvoiceItem" 
                                                />
                                            </div>

                                            <div class="mt--30 invoice__calculate--row form__row__right ml--0 w--100">
                                                <div class="form__row__left">
                                                    <FormLabel title="Name" class="mb--0" label-type="menu" info-type="required" />
                                                </div>
                                                <div class="form__row__right invoice__calculate--row">
                                                    <div class="flex-1 invoice__calculate--wrap" style="min-width: 120px;">
                                                        <FormLabel title="Quantity" class="mb--0" label-type="menu" info-type="required" />
                                                    </div>
                                                    <div class="flex-1" style="min-width: 120px;">
                                                        <FormLabel title="Price" class="mb--0" label-type="menu" info-type="required" />
                                                    </div>
                                                    <div class="flex-1" style="min-width: 150px;">
                                                        <FormLabel title="Amount" class="mb--0" label-type="menu" info-type="required" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="mt--5">
                                                <div v-for="(item, i) in invoice.meta.items" :key="i">
                                                    <div class="invoice__calculate--row form__row__right w--100 relative ml--0" :class="{ 'mt--20' :i > 0 }">
                                                        <div class="form__row__left">
                                                            <input v-model="item.itemName" type="text" class="form-control form-control-sm">
                                                        </div>
                                
                                                        <div class="form__row__right invoice__calculate--row">
                                                            <div class="invoice__calculate--wrap flex-1" style="min-width: 120px;">
                                                                <money
                                                                    v-model="item.itemQuantity" v-bind="config.number"
                                                                    class="form-control form-control-sm"
                                                                    style="width: 80%" spellcheck="false"
                                                                    @keydown.native="preventKeys"
                                                                />
                                                            </div>

                                                            <div class="flex-1" style="min-width: 120px;">
                                                                <money
                                                                    v-model="item.itemPrice" v-bind="moneyConfig"
                                                                    class="form-control form-control-sm"
                                                                    style="width: 80%" spellcheck="false"
                                                                    @keydown.native="preventKeys" @keyup.enter="addAnotherInvoiceItem"
                                                                />                                                                    
                                                            </div>

                                                            <div class="flex-1" style="min-width: 150px;">
                                                                <div class="text--upper text--xs">
                                                                    {{ $country.getSymbol(invoice.currency, 'currency') }}{{ $format_amount(itemAmount(i)) }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div v-show="i > 0" class="invoice__calculate__close" @click="removeInvoiceItem(i)">
                                                            <svg
                                                                width="16" height="16"
                                                                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <rect width="24" height="24" rx="12" fill="#B9B9B9" />
                                                                <path
                                                                    d="M11.0492 12.0001L6.92 7.87094C6.66346 7.61462 6.66346 7.19873 6.92 6.94241C7.17654 6.68586 7.59199 6.68586 7.84853 6.94241L11.9777 11.0716L16.1071 6.94241C16.3636 6.68586 16.7791 6.68586 17.0356 6.94241C17.2922 7.19873 17.2922 7.61462 17.0356 7.87094L12.9062 12.0001L17.0356 16.1293C17.2922 16.3856 17.2922 16.8015 17.0356 17.0578C16.9074 17.1859 16.7392 17.25 16.5714 17.25C16.4035 17.25 16.2354 17.1859 16.1071 17.0576L11.9777 12.9284L7.84853 17.0576C7.72026 17.1859 7.55215 17.25 7.38426 17.25C7.21637 17.25 7.04827 17.1859 6.92 17.0576C6.66346 16.8013 6.66346 16.3854 6.92 16.1291L11.0492 12.0001Z"
                                                                    fill="white"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </div>
                        
                                                    <span v-if="isInvoiceItemFilled(i)" class="form__errors flex align-items-center">
                                                        <icon-svg name="alert" width="16px" fill="#e04060" />
                                                        <span class="ml--5 text--color-warning text--xs">
                                                            Please fill the invoice item, quantity and unit price
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
            
                                <div class="row invoice__section--item">
                                    <div class="invoice__header">
                                        <div class="">
                                            <div class="invoice__compile--row">
                                                <div class="invoice__compile--label">
                                                    Subtotal
                                                </div>
                                                <div class="invoice__compile--value ml--10 text--upper">
                                                    {{ $country.getSymbol(invoice.currency, 'currency') }} {{ $format_amount(computedSubTotal) }}
                                                </div>
                                            </div>
        
                                            <div class="invoice__compile--row">
                                                <BaseButton 
                                                    class="invoice__compile--btn pl--0" label="+ Add Tax" size="sm"
                                                    variant="ghost" @submit="showAddTaxModal = true" 
                                                />
                                                <div class="invoice__compile--value text--upper">
                                                    {{ $country.getSymbol(invoice.currency, 'currency') }}{{ $format_number(computedTaxes) }}
                                                </div>
                                            </div>
        
                                            <div class="invoice__compile--row invoice__row__total">
                                                <div class="text--sm text--bold">
                                                    Total
                                                </div>
                                                <div class="text--bold text--upper text--sm">
                                                    {{ $country.getSymbol(invoice.currency, 'currency') }}{{ computedInvoiceTotal }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
            
                                <div class="row invoice__section--item">
                                    <div class="col-12 invoice__header flex align-items-center">
                                        <FormLabel title="Memo" class="mb--0 mr--5" label-type="menu" />
                                        <span class="text--xs text-label-info">(Max 300 words)</span>
                                    </div>
                                    <div class="col-6">
                                        <div class="invoice__row invoice__item">
                                            <div class="invoice__details--item">
                                                <textarea
                                                    id="exampleFormControlTextarea1" v-model="invoice.memo" name="memo" class="form-control form-control-sm"
                                                    rows="3" maxlength="300"
                                                    @change="handleFormFieldsChange"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="invoice__details__row__right flex-1">
                                <div class="row invoice__section--item">
                                    <div class="col-12">
                                        <FormLabel title="Payment information" class="mb--0" label-type="title" info-type="required" />
                                    </div>
            
                                    <div class="col-12 mt--20">
                                        <div class="row invoice__section--item">
                                            <div class="flex align-items-center mr--10 mb--10">
                                                <IconSvg name="money" width="1rem" fill="#64748B" />
                                                <FormLabel title="Currency" class="ml--5 mb--0" label-type="menu" info-type="required" />
                                            </div>
                                            <div class="col-12">
                                                <div class="invoice__details--item">
                                                    <CustomDropdown 
                                                        id="currencyDropdown" v-model="invoice.currency" size="sm"
                                                        class="w--100 text--upper" :options="currencies"
                                                        list-type="currencies" @search="searchCurrencies" @select="setCurrency" 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="flex align-items-center justify-content-between invoice__header">
                                            <div class="flex align-items-center mr--10">
                                                <IconSvg name="bank" width="1rem" fill="#64748B" />
                                                <FormLabel title="Billing method" class="mb--0 ml--5" label-type="menu" info-type="required" />
                                            </div>
                                            <BaseButton 
                                                class="p--0"
                                                :label="selectedBillingMethod.id ? '- Edit billing method' : '+ Choose a billing method'" variant="ghost" inner-style="primary"
                                                size="sm" medium @submit="showPaymentInfoModal = true"
                                            />
                                        </div>
            
                                        <template v-if="invoice.billingMethod === 'personal_account'">
                                            <div class="row invoice__section--item mt--15">
                                                <FormLabel title="Type" class="mb--0" label-type="sm_mono" />

                                                <p class="text--sm">
                                                    {{ selectedBillingMethod.name }}
                                                </p>
                                            </div>

                                            <div v-if="invoice.accountDetails.accountNumber" class="mt--20">
                                                <FormLabel title="Account details" class="mb--0" label-type="sm_mono" />

                                                <p class="invoice__client__text">
                                                    Bank Name: {{ invoice.accountDetails.bankName }}
                                                </p>
                                                <p class="invoice__client__text">
                                                    Account Name: {{ invoice.accountDetails.accountName }}
                                                </p>
                                                <p class="invoice__client__text">
                                                    Account Number:  {{ invoice.accountDetails.accountNumber }}
                                                </p>
                                                <p class="invoice__client__text">
                                                    Sort Code: {{ invoice.accountDetails.sortCode || 'N/A' }}
                                                </p>
                                                <p class="invoice__client__text">
                                                    IBAN: {{ invoice.accountDetails.iban || 'N/A' }}
                                                </p>
                                            </div>
                                        </template>
            
                                        <template v-else-if="invoice.billingMethod === 'external'">
                                            <div class="mb--20 col-12 mt--20">
                                                <FormLabel title="Third-party payment provider" class="mb--0" label-type="sm_mono" />
                                                <p class="invoice__client__text">
                                                    {{ invoice.providerData.name || '-' }}
                                                </p>
                                            </div>
                                        </template>
                                    </div>
                                </div>

                                <div class="row invoice__section--item">
                                    <div class="col-12 invoice__header">
                                        <div class="flex align-items-center mr--10">
                                            <IconSvg name="alarm" width="1rem" fill="#64748B" />
                                            <FormLabel title="Due date" class="mb--0 ml--5" label-type="menu" info-type="required" />
                                        </div>
                                    </div>
            
                                    <div class="col-12">
                                        <div class="invoice__row invoice__item">
                                            <div class="invoice__details--item">
                                                <div class="w--100">
                                                    <v-date-picker
                                                        v-model="invoice.dueDate"
                                                        mode="dateTime" color="blue" name="dueDate" nav-visibility="click"
                                                        @change="handleFormFieldsChange"
                                                    >
                                                        <template #default="{ inputValue, togglePopover }">
                                                            <input class="px-2 py-1 rounded text--sm form-control" :value="inputValue" @click="togglePopover">
                                                        </template>
                                                    </v-date-picker>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row invoice__section--item">
                                    <div class="col-12 invoice__header mt--0">
                                        <div class="flex align-items-center justify-content-between">
                                            <div class="flex align-items-center mr--10">
                                                <IconSvg name="bell" width="1rem" fill="#64748B" />
                                                <FormLabel title="Reminders" class="mb--0 ml--5" label-type="menu" />
                                            </div>

                                            <BaseButton 
                                                v-if="invoice.dueDate"
                                                variant="ghost" inner-style="primary" label="+ Add reminders" 
                                                size="sm" medium
                                                class="p--0" @submit="showRemindersModal = true"
                                            />
                                        </div>
                                    </div>

                                    <div v-if="invoice.meta.reminders && invoice.meta.reminders.length" class="col-12">
                                        <span 
                                            v-for="reminder in invoice.meta.reminders" :key="reminder" 
                                            class="block project__item__desc text--xs mb--5"
                                        >
                                            {{ mapReminders[reminder] }}
                                        </span>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <CreateClientModal 
            :show-modal="createModalType === 'clients'" :action-type="modalActionType" 
            :client-data="client" :show-billing-input="false"
            @cancel="closeCreateModal" @complete="onClientComplete"
        />

        <CreateProjectModal 
            :show-modal="createModalType === 'projects'" 
            :list="clients" :show-client-list="false"
            @complete="onProjectComplete"
            @close="closeCreateModal" 
        />

        <PreviewInvoice :items="invoice.meta.items" :invoice-data="previewInvoiceData" />

        <PageOverlayLoader v-if="pageLoading" />

        <MainModal 
            :show-modal="showAddTaxModal" 
            size="sm" title="Manage Tax"
            @close="showAddTaxModal = false"
        >
            <template #body>
                <div>
                    <NonLinkedTabs :list="taxHeaderList" type="pill" position="end" @select="v => selectedTaxHeader = v.name">
                        <template #nonLinkedTab="tab">
                            <NonLinkedTab :is-active="tab.name === 'Add new tax'">
                                <div class="mt--10 invoice__manage__tax">
                                    <div class="w--100">
                                        <FormLabel title="Tax Name" font-size="text--sm" />
                                        <select v-model="invoiceTaxType.name" class="form-select form-select-sm w--100 text--capitalize">
                                            <option v-for="taxName in taxTypes" :key="taxName.id" :value="taxName.id">
                                                {{ taxName.name }}
                                            </option>
                                        </select>
                                    </div>
        
                                    <div class="w--100 mt--10">
                                        <FormLabel title="Tax Type" font-size="text--sm" />
                                        <select v-model="invoiceTaxType.type" class="form-select form-select-sm w--100 text--capitalize">
                                            <option v-for="tax in ['percentage', 'flat']" :key="tax" :value="tax">
                                                {{ tax }}
                                            </option>
                                        </select>
                                    </div>
                
                                    <div class="mt--10 w--100">
                                        <FormLabel title="Tax Value" font-size="text--sm" />
                                        <div class="w--100">
                                            <money 
                                                v-if="invoiceTaxType.type === 'flat'"
                                                v-model="invoiceTaxType.value" 
                                                v-bind="moneyConfig" 
                                                class="form-control form-control-sm w--100" 
                                                spellcheck="false" 
                                                @keydown.native="preventKeys"
                                            />
                                            <input-money 
                                                v-else
                                                v-model="invoiceTaxType.value" 
                                                class="form__input form-control-sm w--100" 
                                                prefix="%"
                                                precision="2" 
                                            /> 
                                        </div>
                                    </div>
                                </div>
                            </NonLinkedTab>

                            <NonLinkedTab :is-active="tab.name === 'All added taxes'">
                                <div class="invoice__manage__tax">
                                    <template v-if="!invoice.meta.tax.length">
                                        <EmptyPage 
                                            state="empty_custom" title="No added taxes yet." 
                                            icon-name="earnings" subtitle="Click on the Add new tax tab to add tax to your invoice"
                                        />
                                    </template>
                                    <template v-else>
                                        <ul class="mt--30 invoice__manage__tax__list">
                                            <li v-for="(item, index) in invoice.meta.tax" :key="item._id" class="flex align-items-center mb--5 w--100">
                                                <div class="flex align-items-center justify-content-between w--100">
                                                    <div class="flex align-items-center">
                                                        <span class="box-icon block" :class="[item.taxType]" />
                                                        <span class="text--sm ml--5">{{ mapTaxTypes[item.taxName] }}, {{ item.taxType }}, {{ computeTaxValue(item) }}</span>
                                                    </div>
                                                    <span class="pointer inline-block ml--5" @click.stop="removeTaxItem(index)">
                                                        <IconSvg name="close" width="16px" fill="#212529" />
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </template>
                                </div>
                            </NonLinkedTab>
                        </template>
                    </NonLinkedTabs>
                </div>
            </template>

            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <template v-if="selectedTaxHeader === 'Add new tax'">
                        <BaseButton
                            label="Cancel" size="sm"
                            variant="outline" class=" mr--5" @submit="showAddTaxModal = false"  
                        />
                        <BaseButton 
                            label="Add" size="sm"
                            :disabled="addTaxDisabled" @submit="addTaxToInvoice" 
                        />
                    </template>
                    <template v-else>
                        <BaseButton 
                            label="Close" size="sm"
                            @submit="showAddTaxModal = false"  
                        />
                    </template>
                </div>
            </template>
        </MainModal>

        <ManageRemindersModal 
            :initial-reminders="invoice.meta.reminders" :show-modal="showRemindersModal"
            @cancel="cancelReminders" @complete="manageReminders"
        />

        <MainModal 
            :show-modal="showPaymentInfoModal" 
            size="sm" title="Manage payment route"
            @close="showPaymentInfoModal = false"
        >
            <template #body>
                <form class="mb--5">
                    <div class="client__modal__body mb--10">
                        <div class="form--control mb--15">
                            <FormLabel title="Billing method" />
                            <CustomDropdown 
                                id="billingMethods"
                                v-model="selectedBillingMethod" 
                                class="w--100" 
                                size="sm"
                                list-type="billing methods" 
                                index-label="name" 
                                :options="billingMethods"
                                @select="v => selectedBillingMethod = v"
                            />
                        </div>

                        <template v-if="selectedBillingMethod.id === 'personal_account'">
                            <FormInput 
                                v-if="accountInfoByCurrency['bankName']"
                                id="bankName" v-model="invoice.accountDetails.bankName"
                                size="sm" info-type="required" label="Bank Name"
                            />

                            <FormInput 
                                v-if="accountInfoByCurrency['accountName']"
                                id="accountName" v-model="invoice.accountDetails.accountName"
                                size="sm" info-type="required" label="Account Name" 
                            />
                            <FormInput 
                                v-if="accountInfoByCurrency['accountNumber']"
                                id="accountNumber" v-model="invoice.accountDetails.accountNumber"
                                size="sm" info-type="required" label="Account Number"
                            />

                            <FormInput 
                                v-if="accountInfoByCurrency['iban']"
                                id="iban" v-model="invoice.accountDetails.iban"
                                size="sm" info-type="required" label="IBAN"
                            />

                            <FormInput 
                                v-if="accountInfoByCurrency['sortCode']"
                                id="sortCode" v-model="invoice.accountDetails.sortCode" class-names="mt--10"
                                size="sm" info-type="required" label="Sort Code"
                            />
                        </template>

                        <template v-if="selectedBillingMethod.id === 'external'">
                            <div class="form--control mb--15">
                                <FormLabel classes="text--color-gray-200" title="Select a provider" />
                                <CustomDropdown 
                                    id="billingMethods" v-model="invoice.providerData" index-label="name"
                                    btn-classes="text--capitalize" class="w--100 text--capitalize" :options="paymentProviders"
                                    list-type="providers" @select="setProvider"
                                />
                            </div>
                        </template>
                    </div>
                </form>
            </template>
            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <BaseButton
                        class="mr--5" label="Cancel"
                        size="sm" variant="outline" @submit="showPaymentInfoModal = false"
                    />
                    <BaseButton label="Update" :disabled="billingMethodDisabled" size="sm" @submit="updateAccountDetails" />
                </div>
            </template>
        </MainModal>

        <UpgradeAccountModal :show-modal="showUpgradeModal" :type="createModalType" @cancel="closeUpgradeModal" />

        <MainModal
            :show-modal="addCCEmails" 
            size="sm" title="Add corresponding emails"
            @close="closeCCEmails"
        >
            <template #body>
                <form @submit.prevent="">
                    <div class="form-check mb--15">
                        <input 
                            id="flexCheckDefault" v-model="addClientsEmails" 
                            class="form-check-input" type="checkbox" 
                            @change="toggleBatchClientEmails"
                        >
                        <label class="form-check-label text--xs" for="addClientsEmails">
                            Add CC emails from currently selected client?
                        </label>
                    </div>
                    <MultipleBillingEmails
                        :is-flex="false" :items="innerTempEmails" classes="w--100"
                        @add="addEmail" @delete="removeEmail"
                    />
                </form>
            </template>
            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <Base-Button
                        class="mr--5" label="Cancel"
                        size="sm" variant="outline" @submit="closeCCEmails"
                    />
                    <Base-Button label="Update" size="sm" @submit="updateCCEmails" />
                </div>
            </template>
        </MainModal>
    </div>
</template>

<script>
import PreviewInvoice from "@/components/invoices/PreviewInvoice.vue";
import {
    IconSvg, CreateClientModal,
    CreateProjectModal, BaseButton,
    FormLabel, InputMoney, MultipleBillingEmails,
    PageOverlayLoader, NonLinkedTabs, NonLinkedTab,
    PageSpinner, EmptyPage, ManageRemindersModal,
    MainModal, FormInput, CustomDropdown, UpgradeAccountModal,
} from "@/components/ui/index";
import { assembleQueryList, formatAmount, convertStringAmountToInt } from "@/utils/helpers";
import { invoiceReminders, billingMethods, paymentProviders, taxTypes, mapReminders, mapTaxTypes } from "@/utils/dummy";
import { paymentSupportedCurrencies } from "@/plugins/countries";

export default {
    name: "CreateEditInvoice",

    components: {
        FormInput, MultipleBillingEmails,
        PreviewInvoice, CreateClientModal, IconSvg, CreateProjectModal, BaseButton,
        FormLabel, InputMoney, PageOverlayLoader, PageSpinner,
        EmptyPage, MainModal, ManageRemindersModal, CustomDropdown, UpgradeAccountModal,
        NonLinkedTabs, NonLinkedTab,
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (to.name === "edit-invoice-view") { 
                if (!vm.$route.params.id) {
                    return false;
                }
                vm.handleFetchInvoice(vm.$route.params.id);
                vm.invoiceType = "edit";
            } else {
                const accountPlan = vm.accountPlan;

                if (accountPlan === "free" && vm.invoices?.length >= 5) {
                    vm.$router.push("/dashboard/invoices");
                } else {
                    vm.invoiceType = "new";
                }
            };
        });
    },

    beforeRouteLeave(to, from, next) {
        // clear stored ongoing invoice if invoice is new        
        [
            "z_invoice_ongoing", 
            "z_invoice_client", 
            "z_invoice_project",
        ].forEach((item) => localStorage.removeItem(item));
        this.resetInvoiceInfo();
        
        next();
    },

    data() {
        return {
            showRemindersModal: false,
            invoiceReminders, 
            billingMethods, 
            paymentProviders, 
            taxTypes,
            mapReminders,
            mapTaxTypes,
            invoiceType: "",
            selectedBillingMethod: {
                id: "",
            },
            showAddTaxModal: false,
            invoice: {
                invoiceNo: "",
                currency: "",
                amountPaid: null,
                createdAt: null,
                dueDate: null,
                _id: undefined,
                memo: "",
                billingMethod: "",
                meta: {
                    ccEmails: [],
                    // tax: [{ taxName: "vat", taxType: "flat", taxValue: 0 }],
                    tax: [],
                    reminders: [],
                    items: [{ itemName: "", itemPrice: 0.00, itemQuantity: 0, itemUnit: 0.00 }],
                },
                status: "draft",
                title: "Invoice for",
                accountDetails: {
                    accountName: "",
                    bankName: "",
                    accountNumber: "",
                    iban: "",
                    sortCode: "",
                },
                providerData: {
                    id: "",
                    name: "",
                },
            },
            client: {
                isEmpty: true,
                _id: "",
                name: "",
                phoneNumber: "",
                email: "",
                country: "",
                address: "",
                industry: "",
                ccEmails: [],
            },
            project: {
                isEmpty: true,
                _id: "",
                title: "",
                status: "",
                isFavourite: false,
                deadline: undefined,
            },
            otherclientsSearched: [],
            currencies: paymentSupportedCurrencies,
            config: {
                number: {
                    decimal: ".",
                    thousands: ",",          // The currency name or symbol followed by a space.
                    prefix: "",
                    suffix: "",
                    precision: 0,
                    masked: false,
                },
                percentage: {
                    decimal: ".",
                    thousands: ",",          // The currency name or symbol followed by a space.
                    prefix: "%",
                    suffix: "",
                    precision: 2,
                    masked: false,
                },
            },
            addCCEmails: false,
            pageLoading: false,
            invoiceTaxType: { /** * @type{"percentage" | "flat"} */
                type: "",
                value: 0,
                name: "",
            },
            isInvoiceItemEmpty: false,
            isShowClientInfoView: false,
            modalActionType: "add",
            firstItemIsEmpty: false,
            isShowProjectInfoView: false,
            fetchProjectsLoading: false,
            fetchInvoiceLoading: false,
            searchClientsLoading: false,
            itemErrorsObj: {},
            fetchInvoiceErr: false,
            updateClientmailsLoading: false,
            submitAction: "", // "send" | "save" | "",
            clientList: [],
            projectList: [],
            showPaymentInfoModal: false,
            invalidValues: {},
            addClientsEmails: false,
            innerTempEmails: [],
            createModalType: "",
            deleteBillingMethodLoading: false,
            taxHeaderList: [
                { name: "Add new tax", iconFill: "#212529", iconName: "alarm" },
                { name: "All added taxes", iconFill: "#212529", iconName: "report" },
            ],
            selectedTaxHeader: "Add new tax",
        };
    },

    computed: {
        accountInfoByCurrency() {
            if (!this.selectedBillingMethod?.id === "personal_account") {
                return {};
            }
            switch (this.invoice.currency.toLowerCase()) {
            case "ngn":
            case "ghs":
                return {
                    accountName: true,
                    bankName: true,
                    accountNumber: true,
                };
            
            default:
                return {};
            }
        },

        addTaxDisabled() {
            return !this.invoiceTaxType.name || !this.invoiceTaxType.type || !parseFloat(this.invoiceTaxType.value);
        },

        billingMethodDisabled() {
            if (!this.selectedBillingMethod?.name) {
                return true;
            }

            if (this.selectedBillingMethod?.id === "personal_account") {
                const formFields = Object.keys(this.accountInfoByCurrency);
                let invalidValueCount = 0;

                for (let i = 0; i < formFields.length; i++) {
                    if (!this.invoice.accountDetails[formFields[i]]) {
                        invalidValueCount++;
                    }
                }

                if (invalidValueCount > 0) {
                    return true;
                }
            }

            return false;
        },

        user() {
            return this.$store.getters["auth/profile"];
        },

        accountPlan() {
            return this.user?.accountPlan;
        },

        userCurrency() {
            return this.$store.getters["app/userSettings"]?.general?.currency;
        },

        projects() {
            return this.$store.getters["project/projects"];
        },

        invoices() {
            return this.$store.getters["invoice/invoices"];
        },

        clients() {
            return this.$store.getters["clients/clients"];
        },
        
        selectedClient: {
            get: function() {
                return this.client;
            },

            set: function(newVal) {
                this.client = newVal?.client;
            },
        },

        selectedProject: {
            get: function() {
                return {
                    title: this.project?.title,
                    project: this.project,
                };
            },

            set: function(newVal) {
                this.project.title = newVal.title;
                this.project = newVal.project;
                return; 
            },
        },
    
        moneyConfig() {
            return {
                decimal: ".",
                thousands: ",",
                // prefix: this.invoice.currency + " ",
                prefix: " ",
                suffix: "",
                precision: 2,
                masked: false,
            };
        },

        showClientInfoView() {            
            if (this.client?.name && this.isShowClientInfoView) {
                return true;
            } else {
                return false;
            }
        },

        showProjectInfoView() {
            if (this.project?.title && this.isShowProjectInfoView) {
                return true;
            } else {
                return false;
            }
        },

        showAddItemBtn() {
            const firstItem = this.invoice.meta.items[0];

            if (firstItem.itemName && firstItem.itemPrice && firstItem.itemQuantity && firstItem.itemUnit) {
                return true;
            } else {
                return false;
            }
        },

        computedSubTotal() {
            if (this.invoice.meta.items.length > 0) {
                const sumItemsArr = this.invoice.meta.items
                    .map(item => item.itemPrice * item.itemQuantity)
                    .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
                return sumItemsArr;
            } else {
                return 0;
            }
        },

        computedInvoiceTotal() {
            return this.formatMoney(this.computedTaxes + this.computedSubTotal);
        },

        computedTaxes() {
            let final = 0;
            let taxCount = 0;
            let percentageTotal = 0;

            if (this.invoice?.meta.tax.length) {                
                for (let i = 0; i < this.invoice?.meta.tax.length; i++) {
                    const item = this.invoice?.meta.tax[i];

                    if (item.taxType === "percentage") {
                        percentageTotal = item.taxValue + percentageTotal;
                    } else {
                        taxCount = parseFloat(item.taxValue) + taxCount;
                    }
                }
            }

            if (percentageTotal > 0) {
                taxCount = taxCount + ((percentageTotal / 100) * this.computedSubTotal);
            }

            return taxCount;
        },

        getCCEmailsCount() {
            return 3 - (this.client?.ccEmails.length);
        },
    
        isSaveBtnDisabled() {
            if (!this.invoice.currency || !this.client?._id || !this.project?._id || !this.invoice.meta.items.length) {
                return true;
            } 

            if (!this.invoice.billingMethod) {
                return true;
            }
            
            return false;
        },

        previewInvoiceData() {
            return {
                invoice: {
                    invoiceNo: this.invoice.invoiceNo,
                    currency: this.invoice.currency,
                    createdAt: this.invoice.createdAt || new Date(),
                    dueDate: this.invoice.dueDate,
                    currency: this.invoice.currency,
                    tax: this.computedTaxes,
                    subtotal: this.computedSubTotal,
                    total: this.computedInvoiceTotal,
                },
                client: {
                    name: this.client?.name,
                    phoneNumber: this.client?.phoneNumber,
                    email: this.client?.email,
                    country: this.client?.country,
                    address: this.client?.name,
                },
                user: {
                    fullName: this.user?.fullName,
                    email: this.user?.email,
                    phoneNumber: this.user?.phoneNumber,
                },
            };
        },

        currentProject() {
            return this.$store.getters["invoice/selectedProject"];
        },

        currentClient() {
            return this.$store.getters["invoice/selectedClient"];
        },

        showUpgradeModal() {
            return this.$store.getters["app/showUpgradeModal"];
        },

        checkUpgradeLoading() {
            return this.$store.getters["app/checkUpgradeLoading"];
        },

        checkUpgradeFields() {
            return this.$store.getters["app/checkUpgradeFields"];
        },

        showCCEmails() {
            return this.addCCEmails || this.invoice.meta.ccEmails.length;
        },
    },

    watch: {
        "invoice.dueDate"(newVal) {
            if (newVal) {
                this.saveInvoiceDataOnLS();
            }
        },
    },

    async mounted() {        
        let currentProject;
        let currentClient;
        let currentInvoice;

        if (this.currentClient ?.name) {
            currentClient = this.currentClient;
        } else if (localStorage.getItem("z_invoice_client") && localStorage.getItem("z_invoice_client") !== "undefined") {
            currentClient = JSON.parse(localStorage.getItem("z_invoice_client"));
        } else {
            currentClient = {};
        }

        if (this.currentProject ?.title) {
            currentProject = this.currentProject;
        } else if (localStorage.getItem("z_invoice_project") && localStorage.getItem("z_invoice_project") !== "undefined") {
            currentProject = JSON.parse(localStorage.getItem("z_invoice_project"));
        } else {
            currentProject = {};
        }

        this.setClient({ ...currentClient });
        this.setProject({ ...currentProject });
        this.invoice = {
            ...this.invoice,
            ...(localStorage.getItem("z_invoice_ongoing") ? JSON.parse(localStorage.getItem("z_invoice_ongoing")) : {}),
        };
        
        if (this.userCurrency && !this.invoice.currency) {
            this.invoice.currency = currentInvoice?.currency || this.userCurrency.toUpperCase();
        }

        this.selectedBillingMethod = this.billingMethods.find(x => x.id === this.invoice.billingMethod) || {};

        if (this.clients.length) {
            this.clientList = this.clients;
        } else {
            await this.fetchClients();
        }

        if (this.projects.length) {
            this.projectList = this.projects;
        } else {
            await this.fetchProjects();
        }
    },

    methods: {
        convertStringAmountToInt,

        computeTaxValue(tax) {
            if (tax.taxType === "percentage") {
                return `${tax.taxValue}%`;
            }
            return `${this.$country.getSymbol(this.invoice.currency, "currency")}${this.$format_amount(tax.taxValue)}`;
        },

        searchCurrencies(query) {            
            this.currencies = this.currencies.filter(c => c.toLowerCase().includes(query?.toLowerCase()));
        },

        setCurrency(val) {
            this.invoice.currency = val;
            this.saveInvoiceDataOnLS();
        },

        setProvider(val) {            
            this.invoice.providerData = val;
            this.saveInvoiceDataOnLS();
        },

        openCCEmails(val) {
            this.addCCEmails = true;

            if (this.invoice.meta.ccEmails.length && val === "manage") {
                this.innerTempEmails = this.invoice.meta.ccEmails;
            }
        },

        closeCCEmails() {
            this.innerTempEmails = [];
            this.addClientsEmails = false;
            this.addCCEmails = false;
        },

        updateCCEmails() {
            this.invoice.meta.ccEmails = this.innerTempEmails;
            this.innerTempEmails = [];
            this.addClientsEmails = false;
            this.saveInvoiceDataOnLS();
            this.addCCEmails = false;
        },

        removeEmail(email) {
            this.innerTempEmails = this.innerTempEmails.filter(item => item !== email);
        },

        addEmail(value) {
            this.innerTempEmails = [ ...this.innerTempEmails, value];
        },

        toggleBatchClientEmails(event) {
            const checked = event.target.checked;
            const splitClientCCEmails = this.client?.ccEmails ? this.client.ccEmails.split(",") : [];
            
            if (checked) {
                this.innerTempEmails = [ ...this.innerTempEmails, ...splitClientCCEmails ];
            } else {
                this.innerTempEmails = this.innerTempEmails.filter(x => !splitClientCCEmails.includes(x));
            }
        },

        resetInvoiceInfo() {
            this.invoice = {
                currency: "",
                amountPaid: null,
                createdAt: null,
                dueDate: null,
                _id: undefined,
                memo: "",
                billingMethod: "",
                meta: {
                    ccEmails: [],
                    tax: [],
                    reminders: [],
                    items: [{ itemName: "", itemPrice: 0.00, itemQuantity: 0, itemUnit: 0.00 }],
                },
                status: "draft",
                title: "Invoice for",
                accountDetails: {
                    accountName: "",
                    bankName: "",
                    accountNumber: "",
                    iban: "",
                    sortCode: "",
                },
                providerData: {
                    id: "",
                    name: "",
                },
            };
            this.client = {
                isEmpty: true,
                _id: "",
                name: "",
                phoneNumber: "",
                email: "",
                country: "",
                address: "",
                industry: "",
                ccEmails: [],
            };
            this.project = {
                isEmpty: true,
                _id: "",
                title: "",
                status: "",
                isFavourite: false,
                deadline: undefined,
            };
            this.isShowClientInfoView = false;
            this.isShowProjectInfoView = false;
        },

        handleFormFieldsChange(event) {
            const value = event.target.value;
            const name = event.target.name;

            if (!name) return;
            
            this.invoice = {
                ...this.invoice,
                [name]: value,
            };

            this.saveInvoiceDataOnLS();
        },

        saveInvoiceDataOnLS() {
            localStorage.setItem("z_invoice_ongoing", JSON.stringify({
                ...this.invoice,
                client: this.client,
                project: this.project,
            }));
        },

        getStoredInvoiceData() {
            const res = localStorage.getItem("z_invoice_ongoing");
            const data = res ? JSON.parse(res) : null;

            if (data) {
                this.invoice = {
                    ...this.invoice,
                    ...data,
                };
                
                if (data.client) {
                    this.client = data?.client;
                    this.isShowClientInfoView = true;
                }
                if (data.project) {
                    this.project = data?.project;
                    this.isShowProjectInfoView = true;
                }

                if (data.meta.reminders.length) {
                    this.invoice.meta.reminders = data.meta.reminders.map(item => item.remindersName);
                }
            }
        },

        formatMoney: function(x) {
            return formatAmount(x);
        },

        itemAmount(i) {
            const item = this.invoice.meta.items[ i ];
            const quantity = item.itemQuantity;
            const unit = item.itemPrice;
            const price = parseFloat(quantity) * parseFloat(unit);
            
            this.invoice.meta.items[i].itemUnit = price;
            
            return typeof price === "number" ? price.toFixed(3) : 0;
        },
    
        toggleClientView() {
            this.isShowClientInfoView = !this.isShowClientInfoView;
        },

        toggleProjectView() {
            this.isShowProjectInfoView = !this.isShowProjectInfoView;
        },

        async toggleModalActionType(type, action) {
            try {
                await this.$store.dispatch("app/checkUpgradeStatus", `${type}s`);
                
                this.modalActionType = action;

                if (type === "client") {
                    this.createModalType = "clients";
                }
    
                if (type === "project") {
                    this.createModalType = "projects";
                }
    
            } catch (error) {
                // console.log({ error });
            }
        },

        closeUpgradeModal() {
            this.$store.dispatch("app/closeUpgradeModal", "invoices");
        },

        addAnotherInvoiceItem() {            
            const isEmpty = this.checkIfInvoiceItemIsEmpty();

            if (isEmpty || this.firstItemIsEmpty) {
                return;
            }  

            this.invoice.meta.items.push({
                itemName: "",
                itemPrice: 0,
                itemQuantity: 1,
                itemUnit: 0,
            });
            this.saveInvoiceDataOnLS();
        },

        checkIfInvoiceItemIsEmpty() {
            let emptyCount = 0;
            const items = this.invoice.meta.items;

            // eslint-disable-next-line indent
            items.map((item, i) => {
                const emptyItemFields = (!item.itemName 
                || parseFloat(item.itemPrice) <= 0 
                || item.itemQuantity < 1 
                || item.itemUnit <= 0
                );

                if (emptyItemFields) {
                    emptyCount++;
                    this.itemErrorsObj[i] = true;
                } else {
                    delete this.itemErrorsObj[i];
                }
            });

            return emptyCount > 0;
        },

        isInvoiceItemFilled(index) {
            if (this.itemErrorsObj[index]) {
                return true;
            } else {
                return false;
            }
        },

        removeInvoiceItem(index = this.invoice.meta.items.length - 1) {
            /**
               * This will remove an invoice item from the list of items based on the index.
               * if none is defined, it removes the last item.
            */
            // this.$delete( this.invoice.meta.items, index );
            this.invoice.meta.items = this.invoice.meta.items.filter((elem, idx) => idx !== index);
        },

        closeCreateModal() {
            this.createModalType = ""; 
        },

        onClientComplete(data) {
            this.client = {
                isEmpty: false,
                _id: data?._id || "",
                name: data?.name || "",
                phoneNumber: data?.phoneNumber || "",
                email: data?.email || "",
                country: data?.country || "",
                address: data?.address || "",
                industry: data?.industry || "",
                status: data?.status || [],
            };
            this.closeCreateModal();
            this.isShowClientInfoView = true;
        },

        onProjectComplete(data) {            
            this.project = {
                isEmpty: true,
                _id: data?.resp?._id || "",
                title: data?.resp?.title || "",
                status: data?.resp?.status || "",
                deadline: data?.resp?.deadline || "",
                isFavourite: data?.resp?.isFavourite || "",
                tags: data?.resp?.tags || [],
            };

            this.closeCreateModal();
            this.isShowProjectInfoView = true;
        },

        validateInvoiceItems() {
            delete this.invalidValues.client;
            delete this.invalidValues.project;
            delete this.invalidValues.billingMethod;
            delete this.invalidValues.computedInvoiceTotal;
            delete this.invalidValues.items;

            if (!this.client?._id) {
                this.invalidValues.client = true;
            }

            if (!this.project?.title || !this.project?._id) {
                this.invalidValues.project = true;
            } 

            if (!this.invoice.billingMethod) {
                this.invalidValues.billingMethod = true;
            } 

            if (this.computedInvoiceTotal <= 0) {
                this.invalidValues.computedInvoiceTotal = true;
            }

            const checkIfItemsAreEmpty = this.checkIfInvoiceItemIsEmpty();
            
            if (checkIfItemsAreEmpty) {
                this.invalidValues.items = true;
            }

            if (this.invalidValues["accountDetails"]) {
                this.$toast.error("Fill in account details");
            }

            if (this.invalidValues["billingMethod"]) {
                this.$toast.error("Select a billing method");
            }

            if (this.invalidValues["project"]) {
                this.$toast.error("Select a project");
            }

            if (this.invalidValues["client"]) {
                this.$toast.error("Select or create a new client");
            }

            if (this.invalidValues["items"]) {
                this.$toast.error("Fill in invoice items to continue");
            }

            const isInvalid = !!Object.keys(this.invalidValues).length > 0;
            return isInvalid;
        },

        createInvoicePayload() {
            const payload = {
                invoiceNo: this.invoice.invoiceNo,
                currency: this.invoice.currency.toLowerCase(),
                clientId: this.client?._id,
                dueDate: this.invoice.dueDate,
                amountPaid: this.invoice.amountPaid,
                memo: this.invoice.memo,
                meta: {
                    ...this.invoice.meta,
                    ccEmails: this.invoice.meta.ccEmails?.join(","),
                },
                projectId: this.project?._id,
                status: this.invoice.status,
                totalAmount: this.convertStringAmountToInt(this.computedInvoiceTotal || ""),
                accountDetails: {
                    ...this.invoice.accountDetails,
                    accountNumber: String(this.invoice.accountDetails.accountNumber) || "",
                },
                billingMethod: this.invoice.billingMethod,
                providerData: this.invoice.providerData,
            };

            return payload;
        },

        async saveExistingInvoice(payload) {
            try {
                this.pageLoading = true;

                await this.$store.dispatch("invoice/updateInvoice", payload);
                this.$toast.success("Invoice saved");
                this.$router.push({ path: "/dashboard/invoices" });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.pageLoading = false;
            }
        },

        async saveNewInvoice(payload) {
            try {
                this.pageLoading = true;
                await this.$store.dispatch("invoice/saveInvoiceDraft", payload);
                
                this.$toast.success("New draft invoice created");
                this.$router.push({ path: "/dashboard/invoices" });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.pageLoading = false;
            }
        },
        
        // As draft (new or existing)
        async sendNewInvoice(payload) {
            try {
                this.pageLoading = true;

                await this.$store.dispatch("invoice/sendNewInvoice", payload);
                
                this.$toast.success("Invoice created and sent");
                this.$router.push({ path: "/dashboard/invoices" });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.pageLoading = false;
            }
        },

        async sendExistingInvoice(payload) {
            try {
                this.pageLoading = true;

                await this.$store.dispatch("invoice/sendExistingInvoice", payload);
                
                this.$toast.success("Invoice sent");
                this.$router.push("/dashboard/invoices");
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.pageLoading = false;
            }
        },

        async handleSaveInvoice(payload) {
            payload.status = "draft";
            
            if (this.invoiceType === "new") {
                await this.saveNewInvoice(payload);
            } else {    
                await this.saveExistingInvoice({
                    ...payload,
                    _id: this.invoice._id,
                });
            };
        },

        async handleSendInvoice(payload) {
            if (this.invoiceType === "new") {
                await this.sendNewInvoice({
                    ...payload,
                    status: "issued",
                });
            } else {
                await this.sendExistingInvoice({
                    ...payload,
                    _id: this.invoice._id,
                });
            }
        },

        async handleSubmitAction(val) {
            const payload = this.createInvoicePayload();
            const isInvalid = this.validateInvoiceItems();
            
            if (isInvalid) {
                return;
            };

            if (val === "send") {
                await this.handleSendInvoice(payload);
            } 

            if (val === "save") {
                await this.handleSaveInvoice(payload);
            } 
        },

        async deleteInvoice() {
            try {
                this.pageLoading = true;

                const id = this.$route.params.id;

                await this.$store.dispatch("auth/deleteInvoice", id);

                this.$toast.success("Invoice deleted successfully");
                this.$router.push({ path: "/dashboard/invoices" });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.pageLoading = false;
            }
        },

        async handleFetchInvoice(id) { 
            try {
                this.fetchInvoiceLoading = true;
                this.fetchInvoiceErr = false;
                
                const invoiceId = id || this.$route.params.id;
                const res = await this.$store.dispatch("invoice/getInvoice", { id: invoiceId });
                let ccEmails = res?.meta.ccEmails;
                                
                if (Array.isArray(ccEmails)) {
                    ccEmails = res?.meta.ccEmails;
                } else if (typeof ccEmails === "string") {
                    ccEmails = res?.meta.ccEmails.split(",");
                } else {
                    ccEmails = [];
                }

                console.log({ ccEmails, "res.meta": res.meta }, typeof ccEmails);     

                this.invoice = {
                    ...this.invoice,
                    ...res,
                    currency: res.currency?.toUpperCase(),
                    meta: {
                        ...this.invoice.meta,
                        ...res.meta,
                        ccEmails: ccEmails,
                    },
                };

                this.selectedBillingMethod = this.billingMethods.find(x => x.id === res.billingMethod);

                console.log({ "this.invoice": this.invoice });
                
                localStorage.setItem("z_invoice_ongoing", JSON.stringify({
                    ...this.invoice,
                    client: this.client,
                    project: this.project,
                }));
                
                if (!this.client?._id) {
                    this.setClient(res.client, true);

                    if (res.client) {
                        this.isShowClientInfoView = true;
                    }
                }

                if (!this.project?._id) {
                    this.setProject(res.project);

                    if (res.project) {
                        this.isShowProjectInfoView = true;
                    }
                }
            } catch (error) {                
                this.fetchInvoiceErr = true;
            } finally {
                this.fetchInvoiceLoading = false;
            }
        },

        async fetchClients(query) {
            try {
                this.searchClientsLoading = true;

                const pageQueryObj = {
                    page: 1,
                    type: "all",
                    name: query,
                };
    
                const buildResult = assembleQueryList(pageQueryObj);
                const result = await this.$store.dispatch("clients/fetchClients", { params: buildResult, external: true, refresh: true });
                
                this.clientList = result;
            } catch(error) {
                // console.log(error);
            } finally {
                this.searchClientsLoading = false;
            }
        },

        async fetchProjects(query) {
            try {
                this.fetchProjectsLoading = true;
    
                let pageQueryObj = {
                    page: 1,
                    type: "all",
                    title: query,
                };
    
                const buildResult = assembleQueryList(pageQueryObj);
    
                const result = await this.$store.dispatch("project/fetchProjects", { params: buildResult, refresh: true, external: true });
                this.projectList = result.data;
            } catch (error) {
                // console.log(error);
            } finally {
                this.fetchProjectsLoading = false;
            }
        },

        /**
         * Returns the sum of all the elements fields in an array of elements
        */
        sumElements( elems, field ) {
            if (elems.length === 0) return 0;

            let sum = parseFloat(elems[ 0 ][ field ]);
            
            if (elems.length === 1 ) return sum;
            
            else {
                for( let i = 1; i < elems.length; i++ ) {
                    sum += parseFloat( elems[ i ][ field ] );
                }
                return sum;
            }
        },

        preventKeys(event) {
            if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 190 || event.keyCode == 37 || event.keyCode == 39) {
            } else {
                return event.preventDefault();
            }
        },

        setReminders(event, item) {
            const checked = event.target.checked;

            if (checked) {
                this.invoice.meta.reminders.push({
                    remindersName: item.id,
                    reminderDate: new Date(),
                });
            } else {
                this.invoice.meta.reminders = this.invoice.meta.reminders.filter(x => {
                    return x.remindersName !== item.id;
                });
            } 

            this.saveInvoiceDataOnLS();
        },

        cancelReminders() {
            this.showRemindersModal = false;
        },

        setClient(data, storeOnLS = false) {            
            this.client = data;
            
            if (data?.ccEmails) {
                this.invoice.meta.ccEmails = data?.ccEmails;
            }

            if (storeOnLS) {
                localStorage.setItem("z_invoice_client", JSON.stringify(data));
            }

            localStorage.setItem("z_invoice_ongoing", JSON.stringify({
                ...this.invoice,
                client: data,
            }));

            if (data) {
                this.isShowClientInfoView = true;
            }
        },

        setProject(data) {
            localStorage.setItem("z_invoice_project", JSON.stringify(data));
            localStorage.setItem("z_invoice_ongoing", JSON.stringify({
                ...this.invoice,
                project: data,
            }));

            this.project = data;
            this.isShowProjectInfoView = true;
        },

        addTaxToInvoice() {
            const payload = {
                taxName: this.invoiceTaxType.name,
                taxType: this.invoiceTaxType.type,
                taxValue: typeof this.invoiceTaxType.value === "string" ? parseFloat(this.invoiceTaxType.value) : this.invoiceTaxType.value,
            };
            
            this.invoice.meta.tax = [ ...this.invoice.meta.tax, payload ];
            this.invoiceTaxType = {
                type: "",
                value: 0,
                name: "",
            };
            this.showAddTaxModal = false;
        },

        updateAccountDetails() {
            this.invoice.billingMethod = this.selectedBillingMethod.id;
            this.showPaymentInfoModal = false;
            this.saveInvoiceDataOnLS();
        },

        manageReminders(data) {
            this.invoice.meta.reminders = data;
            this.saveInvoiceDataOnLS();
            this.showRemindersModal = false;
        },

        async deleteAccountDetails() {
            try {
                this.deleteBillingMethodLoading = true;

                const payload = {
                    _id: this.$route.params.id, 
                    billingMethod: "",                     
                    accountDetails: {
                        accountName: "",
                        bankName: "",
                        accountNumber: "",
                        iban: "",
                        sortCode: "",
                    },
                };

                const res = await this.$store.dispatch("invoice/updateInvoiceBillingData", payload);
                this.$toast.success("Account details deleted");

                this.invoice.accountDetails = res.accountDetails;
                this.saveInvoiceDataOnLS();
                this.deleteBillingMethodLoading = false;
            } catch (error) {
                this.deleteBillingMethodLoading = false;

                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            }
        },

        removeTaxItem(index = this.invoice.meta.tax.length - 1) {
            this.invoice.meta.tax = this.invoice.meta.tax.filter((elem, idx) => idx !== index);
        },

    },
};
</script>

<style lang="scss" scoped>
.row  {
  &.header {
    justify-content: space-between;
  }

  .row__left, .row__right {
    width: fit-content;
    max-width: fit-content;
  }
}

</style>