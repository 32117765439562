<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <div>
        <div class="revenue__page">
            <div>
                <div class="flex align-items-center justify-content-end">
                    <div class="dropdown mr--5" :class="{ 'disabled': statsLoading }">
                        <BaseButton
                            data-bs-toggle="dropdown" aria-expanded="false" is-suffix-icon
                            :label="String(currentYear)" size="sm" variant="outline" inner-style="secondary"
                            class="dropdown-toggle" icon-name="chevron-down" icon-width="12px" 
                        />
                        <ul class="dropdown-menu">
                            <li 
                                v-for="year in ['2024', '2025']" 
                                :key="year" 
                                class="pointer dropdown-item"
                                :class="{ 'selected': currentYear === year }" 
                                @click="handleSetYear(year)"
                            >
                                <span class="text--xs">{{ year }}</span>
                            </li>
                        </ul>
                    </div>

                    <div :class="{ 'disabled': statsLoading }">
                        <div class="dropdown">
                            <BaseButton
                                data-bs-toggle="dropdown" aria-expanded="false" is-suffix-icon
                                :label="currency" size="sm" variant="outline" inner-style="secondary"
                                class="dropdown-toggle text--upper" icon-name="chevron-down" icon-width="12px" 
                            />
                            <ul class="dropdown-menu currency">
                                <li 
                                    v-for="item in settlementAccountCurrencies" 
                                    :key="item" 
                                    class="dropdown-item pointer"
                                    :class="{ 'selected': currency === item }" 
                                    @click="setCurrency(item)"
                                >
                                    <span class="text--xs text--upper">{{ item }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="revenue__stats__wrap">
                    <div>
                        <p class="text--semi--bold text--base text--color-gray-700">
                            Stats
                        </p>
                        <p class="text--normal text--sm text--color-gray-100 flex">
                            Understand how revenue gotten from completed projects and paid invoices typically progresses. Learn about 
                            <a class="ml--5 text--normal text--sm text--color-primary text--medium flex align-items-center" href="https://heythere.substack.com/publish/post/142082967">
                                earning stats 
                                <IconSvg name="arrow-right" class="icon-rotate-45" width="12px" height="12px" fill="#289CA9" />
                            </a>
                            <!-- <CustomLink /> -->
                        </p>
                    </div>
                    
                    <div class="revenue__stats__list">
                        <template v-if="statsLoading">
                            <StatsSkeleton v-for="item in 4" :key="item" />
                        </template>
    
                        <template v-else-if="fetchStatsErr">
                            <EmptyPage state="error" type="revenue" :handle-submit="handleFetchStats" />
                        </template>   

                        <template v-else>
                            <div class="revenue__stats__list">
                                <div class="revenue__stats__list__item">
                                    <Stats
                                        title="Current Revenue"
                                        :value="transformedStats.currentMonthRevenue"
                                        desc="for this month"
                                    />
                                </div>
                                <div class="revenue__stats__list__item">
                                    <Stats
                                        title="Average Revenue"
                                        :value="transformedStats.averageMonthlyRevenue"
                                        desc="per month"
                                    />
                                </div>
                                <div class="revenue__stats__list__item">
                                    <Stats
                                        title="Average Revenue"
                                        :value="transformedStats.averageQuarterlyRevenue"
                                        :percentage="getComparison(transformedStats.lastQuarterRevenue, transformedStats.averageQuarterlyRevenue)"
                                        desc="per quarter"
                                    />
                                </div>
                                <div class="revenue__stats__list__item">
                                    <Stats
                                        title="Total Revenue"
                                        :value="transformedStats.yearlyTotalRevenue"
                                        desc="per year"
                                    />
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
    
            <div class="revenue__goals__wrap">
                <div class="revenue__goals__header">
                    <div>
                        <p class="text--semi--bold text--base text--color-gray-700">
                            Set revenue target
                        </p>
                        <p class="text--normal text--sm text--color-gray-100">
                            Maximize and measure your revenue potential by setting attainable goals.
                        </p>
                    </div>

                    <base-button 
                        label="+ Add new goal" variant="ghost" inner-style="primary"
                        size="sm" @submit="openNewGoalModal"
                    />
                </div>

                <div class="revenue__goals__tasks">
                    <BaseCard>
                        <template #card-body>
                            <PageSpinner v-if="goalLoading" />

                            <EmptyPage v-else-if="goalLoadingErr" state="error" />

                            <div v-else>
                                <div v-if="goals.length">
                                    <div class="mb--15">
                                        <p class="text--semi--bold text--base text--color-gray-700">
                                            Goals
                                        </p>
                                        <p class="text--normal text--sm text--color-gray-100 mt--5">
                                            {{ completedGoals }} of {{ goals.length }} {{ goals.length === 1 ? 'goal' : 'goals' }} completed
                                        </p>
                                    </div>
    
                                    <div v-for="goal in goals" :key="goal._id" class="revenue__goals__tasks__item">
                                        <div class="form-check flex-1">
                                            <input 
                                                :id="goal.name" 
                                                :value="goal.isCompleted"
                                                class="form-check-input" 
                                                type="checkbox" 
                                                :checked="goal.isCompleted"
                                                @change="e => handleMarkGoalComplete(e, goal)"
                                            >
                                            <label 
                                                class="form-check-label text--color-gray-600" 
                                                :class="{ 'text-line-through' : goal.isCompleted }" 
                                                for="flexCheckDefault"
                                                @click=" setCurrentGoalForEdit(goal)"
                                            >
                                                {{ goal.name }}
                                            </label>
                                        </div>
                                        <div class="pointer" @click="deleteGoal(goal._id)">
                                            <icon-svg name="close" fill="#64748B" width="16px" />
                                        </div>
                                    </div>
                                </div>

                                <EmptyPage v-else class="mt--10 mb--10" state="empty" icon-name="notes" width="50px" height="60px" type="goal" />
                            </div>
                        </template>
                    </BaseCard>
                    
                    <PageSpinner v-if="showScrollLoader" />
                </div>
            </div>
        </div>

        <MainModal 
            :show-modal="showGoalModal"  
            size="sm" :title="formActionType === 'add' ? 'Add a new goal' : 'Edit goal details'" 
            @close="resetCloseGoalModal"
        >
            <template #body>
                <form>
                    <div>
                        <FormInput 
                            id="amount"
                            v-model="form.name" no-margin-bottom
                            size="sm" label="Name"
                        />
                    </div>
                </form>
            </template>

            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <Base-Button
                        class="mr--5" label="Cancel"
                        size="sm" variant="outline" :disabled="addGoalLoading"
                        @submit="resetCloseGoalModal"
                    />
                    <Base-Button 
                        :label="formActionType === 'add' ? 'Add' : 'Save'"
                        size="sm" :loading="addGoalLoading" :disabled="!form.name || addGoalLoading"
                        type="submit" @submit="handleGoalSubmit"
                    />
                </div>
            </template>
        </MainModal>
    </div>
</template>

<script>
import { IconSvg, EmptyPage, PageSpinner, BaseButton, BaseCard, MainModal, FormInput } from "@/components/ui/index";
import Stats from "@/components/revenue/Stats.vue";
import StatsSkeleton from "@/components/revenue/StatsSkeleton.vue";
import { settlementAccountCurrencies } from "@/plugins/countries";

export default {
    name: "RevenueOverview",

    components: {
        IconSvg,
        EmptyPage,
        PageSpinner,
        BaseButton,
        Stats,
        BaseCard,
        MainModal,
        FormInput,
        StatsSkeleton,
    },

    data() {
        return {
            settlementAccountCurrencies,
            statsLoading: false,
            fetchStatsErr: false,
            fetchStatsErrMsg: "",
            form: {
                name: "",
                isCompleted: false,
            },
            formActionType: "add",
            addGoalLoading: false,
            addGoalErr: false,
            addGoalErrMsg: "",
            showGoalModal: false,
            deleteLoading: false,
            goalLoading: false,
            goalLoadingErr: false,
            showScrollLoader: false,
            previousQuarterAmt: 0,
        };
    },

    computed: {
        goals() {
            return this.$store.getters["revenue/goals"]?.list;
        },

        stats() {
            return this.$store.getters["revenue/stats"];
        },

        earnings() {
            return this.$store.getters["revenue/earnings"];
        },

        completedGoals() {
            return this.goals.filter(x => x.isCompleted).length || 0;
        },
        
        pageData() {
            return this.$store.getters["revenue/goals"].pageData;
        },

        currency() {
            return this.$store.getters["revenue/currency"];
        },

        transformedStats() {
            const statsData = {
                currentMonthRevenue: 0,
                averageMonthlyRevenue: 0,
                averageQuarterlyRevenue: 0,
                lastQuarterRevenue: 0,
                yearlyTotalRevenue: 0,
            };

            if (!Array.isArray(this.stats) || !this.stats.length) return statsData;

            // const statsObj = Object.entries({
            //     "totalRevenueCurrentMonth": [],
            //     "totalRevenuePerMonth": [
            //         {
            //             "_id": "averageMonthly",
            //             "totalAmount": 758000,
            //         },
            //     ],
            //     "totalRevenueQuartely": [
            //         {
            //             "_id": "previousQuarter",
            //             "totalAmount": 758000,
            //         },
            //     ],
            //     "totalRevenueYear": [
            //         {
            //             "_id": "yearlyTotal",
            //             "totalAmount": 758000,
            //         },
            //     ],
            // });

            // Map data
            
            const statsObj = Object.entries(this.stats[0]);
            
            statsObj.forEach(([key, values]) => {
                if (key === "totalRevenueCurrentMonth" && values.length) {
                    statsData.currentMonthRevenue = values[0]?.totalAmount || 0;
                }
                if (key === "totalRevenuePerMonth" && values.length) {
                    statsData.averageMonthlyRevenue = values.find((v) => v._id === "averageMonthly")?.totalAmount || 0;;
                }

                if (key === "totalRevenueQuartely" && values.length) {
                    const lastQuarterRevenue = values.find((v) => v._id === "previousQuarter")?.totalAmount || 0;
                    const yearlyTotalRevenue =  statsData?.averageMonthlyRevenue * 3 || 0; // Assuming average quarter = 3 months

                    statsData.lastQuarterRevenue = lastQuarterRevenue;
                    statsData.averageQuarterlyRevenue = yearlyTotalRevenue;
                }

                if (key === "totalRevenueYear" && values.length) {
                    statsData.yearlyTotalRevenue = values.find((v) => v._id === "yearlyTotal")?.totalAmount || 0;;
                }
            });

            return statsData;
        },

        currentYear() {
            return this.$store.getters["revenue/currentYear"];
        },

        monthlyTarget() {
            return this.$store.getters["app/userSettings"]?.general?.monthlyTarget;
        },
    },

    watch: {
        "currency": {
            async handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    await this.handleFetchStats({
                        currency: newVal,
                    });
                }
            },
        },

        "currentYear": {
            async handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    await this.handleFetchStats({
                        year: newVal,
                    });
                }
            },
        },
    },
    
    async mounted() {
        await this.handleFetchStats();
        await this.fetchGoals();

        window.addEventListener("scroll", this.handleScroll);
    },

    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },

    methods: {  
        getComparison(previous, current) {
            if (previous === 0 && current === 0) return 0;
            
            const difference = current - previous;
            const percentage = ((difference / previous) * 100).toFixed(2);
            
            return percentage;
        },

        handleSetYear(year) {
            this.$store.dispatch("revenue/setYear", year);
        },

        setCurrency(val) {
            this.$store.dispatch("revenue/setCurrency", val);
        },

        openNewGoalModal() {
            this.showGoalModal = true;
        },

        resetCloseGoalModal() {
            this.showGoalModal = false;

            this.form._id = "";
            this.form.name = "";
            this.form.isCompleted = "";
        },

        async handleScroll() {
            if ((window.innerHeight + window.scrollY + 100) >= document.body.offsetHeight) {
                await this.loadMoreGoals();
            }
        },

        async loadMoreGoals() {            
            if (this.showScrollLoader || this.pageData.totalPages === 1 || this.pageData.currentPage === this.pageData.totalPages) return;

            this.showScrollLoader = true;

            await this.fetchGoals({
                pageNumber: this.pageData.currentPage + 1,
            });
            this.showScrollLoader = false;
        },

        async handleFetchStats(params) {
            try {
                this.fetchStatsErr = false;
                this.statsLoading = true;

                const payload = {
                    currency: params?.currency?.toLowerCase() || this.currency,
                    year: params?.year || this.currentYear,
                };
                
                await this.$store.dispatch("revenue/fetchStats", payload);
            } catch (error) {
                this.fetchStatsErr = true;
            } finally {
                this.statsLoading = false;
            }
        },

        async fetchGoals(params) {
            try {                
                this.goalLoadingErr = "";
                this.goalLoading = true;

                const payload = {
                    pageNumber: params?.pageNumber || 1,
                };
                
                await this.$store.dispatch("revenue/fetchGoals", payload);
            } catch (error) {
                this.goalLoadingErr = true;
                
            } finally {
                this.goalLoading = false;
            }
        },

        async handleAddNewGoal() {
            try {
                this.addGoalErr = false;
                this.addGoalErrMsg = "";
                this.addGoalLoading = true;

                const payload = {
                    name: this.form.name,
                    isCompleted: this.form.isCompleted === "yes" ? true : false,
                };
                
                await this.$store.dispatch("revenue/addGoal", payload);
                this.$toast.success("New goal created");
                this.resetCloseGoalModal();
            } catch (error) {
                const errMsg = error?.response?.data?.message || error.message;
                this.addGoalErr = true;
                this.$toast.error(errMsg);
            } finally {
                this.addGoalLoading = false;
            }
        },

        setCurrentGoalForEdit(goal) {
            if (!goal._id) return;

            this.form.name = goal.name;
            this.form.isCompleted = goal.isCompleted;
            this.form._id = goal._id;

            this.formActionType = "edit";

            this.showGoalModal = true;
        },

        async handleMarkGoalComplete(event, goal) {
            if (!event) return;

            const value = event?.target?.checked;

            this.form.name = goal.name;
            this.form._id = goal._id;
            this.form.isCompleted = value;

            await this.updateGoal();
        },

        async updateGoal() {
            try {
                this.addGoalErr = false;
                this.addGoalLoading = true;
    
                const payload = {
                    _id: this.form._id || "",
                    name: this.form.name,
                    isCompleted: this.form.isCompleted,
                };

                if (!payload._id) return;
    
                await this.$store.dispatch("revenue/updateGoal", payload);

                this.$toast.success("Goal updated");

                this.resetCloseGoalModal();
            } catch (error) {
                const errMsg = error?.response?.data?.message || error.message;
                this.addGoalErr = true;
                this.$toast.error(errMsg);
            } finally {
                this.addGoalLoading = false;
            }
        }, 

        async handleGoalSubmit() {
            if (this.formActionType === "add") {
                this.handleAddNewGoal();
            } else {
                this.updateGoal();
            }
        },

        async deleteGoal(id) {
            try {
                this.deleteLoading = true;
    
                await this.$store.dispatch("revenue/deleteGoal", id);

                this.$toast.success("Goal deleted");
            } catch (error) {
                const errMsg = error?.response?.message || error.message;  
                this.$toast.error(errMsg);
            } finally {
                this.deleteLoading = false;
            }
        },
    },
};
</script>

<style lang="scss">
    .icon-rotate-45 {
        transform: rotate(-20deg);
        fill: #289CA9;
        width: 16px;
        height: 16px;
    }
</style>