<template>
    <div class="sidebar__section__group mt--20">
        <div class="home--content__wrap mt--0">
            <router-link
                v-for="link in settingsRoutesList" 
                :key="link" 
                :to="`/dashboard/settings/${modifyLink(link.name)}`"
                class="sidebar__section__item"
                :class="{ 'active': activeName === link.name }"
            >
                <icon-svg class="sidebar__icon mr--5" :name="link.iconName" :width="'16px'" />   
                <span class="sidebar__section__group__title">{{ modifyName(link.name) }}</span>
            </router-link>
        </div>
    </div>
</template>

<script>
import IconSvg from "../icons/Icon-Svg.vue";
import { settingsRoutesList } from "@/utils/dummy";

export default {
    name: "SettingsNavigation",

    components: { IconSvg },

    props: {
        activeName: {
            default: "",
            type: String,
        },
    },

    data() {
        return {
            settingsRoutesList,
        };
    },

    methods: {
        modifyName(name) {
            switch (name) {
            case "account":
                return "Account settings";
            
            default:
                return name;
            }
        },

        modifyLink(link) {
            if (link.includes("&")) {
                const replacedPath = link.replace(" & ", "-and-");
                return replacedPath;
            } else if (link.includes(" ")) {
                const replacedPath = link.replace(" ", "-");
                return replacedPath;
            } else {
                return link;
            };
        },
    },
};
</script>