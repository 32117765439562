<template>
    <BaseCard>
        <template #card-body>
            <div>
                <span class="text--sm text--color-gray-700">{{ title || "-" }}</span>
            </div>
            <div class="mt--10 mb--10">
                <span class="text--md text--semi--bold">{{ $map_symbol(currency.toLowerCase()) || "" }}{{ $format_amount(value) || "0.00" }}</span>
            </div>
            <div class="flex align-items-center">
                <div class="stats__rate mr--5" :class="[percentage > 0 ? 'green' : 'blue']">
                    <IconSvg :name="percentage > 0 ? 'up-arrow-alt' : 'down-arrow-alt'" />
                    <span class="stats__rate__value">{{ percentage }}%</span>
                </div>
                <p v-if="desc" class="text--color-gray-200 text--xs">
                    {{ desc }}
                </p>
            </div>
        </template>
    </BaseCard>
</template>

<script>
import { BaseCard, IconSvg } from "@/components/ui/index";
import { getMonth } from "@/utils/helpers";

export default {
    name: "RevenueStats",

    components: {
        BaseCard,
        IconSvg,
    },

    props: {
        percentage: {
            type: [Number, String],
            default: 0,
        },
        title: {
            type: String,
            default: "",
        },
        value: {
            type: Number,
            default: 0,
        },
        desc: {
            type: String,
            default: "",
        },
    },

    computed: {
        currency() {
            return this.$store.getters["revenue/currency"];
        },
    },
    
    methods: {
        getMonth,

        mapStatTitle(act) {
            switch (act) {
            case "current_total_month": return `Current revenue for ${this.getMonth()}`;
            case "average_month": return "Average revenue per month";
            case "average_quartely": return "Average quarterly revenue";
            case "current_total_year": return `Current total revenue for ${this.currentYear}`;
            default: return "";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.progress__line {
    width: 100%;
    background-color: #E6E6E6;

    &__inner {
        height:3px;
    }
}
</style>