<template>
    <div>
        <template v-if="view === 'form'">
            <AuthForm 
                auth-type="initiateReset" :loading="loading" 
                :error="error" @form-submit="handleReset"
            />
        </template>

        <template v-else>
            <div class="auth__form__wrap">
                <div class="follow__up__preview">
                    <p class="text--semi--bold text--color-gray-400 mb--10 text--md">
                        Email sent!
                    </p>

                    <p class="text--sm mb--10 text--color-gray-400">
                        We've sent an email to you with a reset link. If you have an account with us, you should get an email with us in the next couple of minutes. Check your spam folder if you are yet to receive an email.
                    </p>

                    <div class="mt--15 flex align-items-center">
                        <BaseButton 
                            variant="ghost" inner-style="primary" label="Enter a different email address" 
                            class="text--sm p--0 text--semi--bold" 
                            @submit="view = 'form'"  
                        />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import AuthForm from "@/components/auth/AuthForm.vue";
import { BaseButton } from "@/components/ui/index";

export default {
    name: "InitiateResetPage",
    components: {
        AuthForm, BaseButton,
    },

    data() {
        return {
            error: {
                show: false,
                value: "",
            },
            loading: false,
            view: "form",
        };
    },

    computed: {
        isBtnDisabled() {
            if (!this.email) {
                return true;
            } else if (this.loading) {
                return true;
            } else {
                return false;
            }
        },
    },

    methods: {
        async handleReset(email) {
            try {
                this.error.show = false;
                this.error.value = "";
                this.loading = true;

                await this.$store.dispatch("auth/initiatePasswordRequest", email);

                this.view = "success_message";
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                
                this.error.value = errMsg;
                this.error.show = true;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.auth--form__wrap {
    padding: 20px 40px 35px;
}
</style>