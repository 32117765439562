<template>
    <div class="segmented__bar">
        <div
            v-for="(segment, index) in total"
            :key="index"
            class="segmented__bar__item"
            :class="[index < filled ? 'active' : '', itemClass, clickable ? 'pointer' : '']"
            @click="$emit('segment-click', index)"
        />
    </div>
</template>

<script>
export default {
    name: "SegmentedProgressBar",
    props: {
        total: {
            type: Number,
            default: 4,
        },
        filled: {
            type: Number,
            default: 0,
        },
        itemClass: {
            type: String,
            default: "",
        },
        clickable: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["segment-click"],
};
</script>

<style scoped lang="scss">
.segmented__bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 6px;

    &__item {
        flex: 1;
        height: 6px;
        background-color: #e5e7eb;
        margin-right: 4px;
        border-radius: 4px;
        
        &.active {
            background-color: #fab316;
            background-color: #228E9D;
        }
        
        /* Remove margin from the last segment */
        &:last-child {
            margin-right: 0;
        }
    }
}
</style>