<template>
    <div>
        <main-modal
            :show-modal="showModal" :footer="false"
            :title="view === 'rank' ? 'Project completion goals' : 'Share accomplishments'"
            size="md" @close="$emit('cancel')"
        >
            <template #body>
                <div class="pb--15">
                    <template v-if="view === 'rank'">
                        <div class="rank__wrap">
                            <div class="rank__group__text">
                                <h5 class="rank__group__text__title">
                                    Status
                                </h5>
                                <p class="rank__group__text__desc">
                                    {{ computeRankDesc }} Completing a project can build up your skills, improve future risk assessments and help with feelings of confidence. 
                                    Remember, consistency is the modus operandi to achieve any great success!
                                </p>
                            </div>
                            <div class="rank__img">
                                <div v-for="(item, index) in rankingList" :key="item.name" class="rank__img__item" :class="{ 'active': item.name === currentRank }">
                                    <div class="rank__img__item__symbol">
                                        {{ item.symbol }}
                                    </div>

                                    <div class="rank__img__item__title">
                                        {{ item.name }}
                                    </div>

                                    <!-- <div v-if="item.name === currentRank || index === 0" class="rank__img__item__award">
                                        <img src="@/assets/img/Interface-Light-1.svg">
                                    </div> -->
                                </div>
                            </div>
                            <div class="rank__desc">
                                <div class="rank__desc__text">
                                    Shoshinsha = <strong>Beginner</strong> level. Only <strong>five (5)</strong> projects completed.
                                </div>
                                <div class="rank__desc__text">
                                    Chukyu = <strong>Intermediate</strong> level. At least <strong>ten (10)</strong> projects completed.
                                </div>
                                <div class="rank__desc__text">
                                    Sensei = <strong>Senior</strong> level. At least <strong>twenty-five 25</strong> projects completed.
                                </div>
                                <div class="rank__desc__text mb--0">
                                    Shishou = <strong>Expert</strong> level. More than <strong>fifty (50)</strong> projects completed.
                                </div>
                            </div>

                            <div class="rank__generate">
                                <h5 class="rank__group__text__title">
                                    Generate a work achievement list
                                </h5>
                                <p class="rank__group__text__desc">
                                    Share a complied list of all your successful project with potential clients, friends or the public.
                                </p>
                                <BaseButton 
                                    label="Generate" class="rank__generate__btn" size="sm"
                                    :loading="loading" :disabled="completedProjectCount < 5"
                                    @submit="startProjectGeneration"
                                />
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <div class="w--100">
                            <div>
                                <div class="rank__share__text">
                                    <p class="title">
                                        {{ currentShareOption.title }}
                                    </p>
                                    <p class="desc">
                                        {{ currentShareOption.desc }}
                                    </p>
                                </div>

                                <div class="rank__share__icon__wrap">
                                    <div 
                                        v-for="item in rankShareIcons" 
                                        :key="item"
                                        class="rank__share__icon text--sm" 
                                        :class="{ 'selected': item === currentShareOption.id }"
                                        @click="setCurrentShareOption(item)"
                                    >
                                        <IconSvg :name="item" width="42px" height="42px" />
                                        <p>{{ rankShareObj[item].name || "" }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="rank__share__action">
                                <div v-if="currentShareOption.id === 'link_circle'" class="rank__share__link">
                                    <div class="rank__share__link__text">
                                        {{ currentShareOption.link }}
                                    </div>
                                    <CopyButton variant="outline" is-icon :value="currentShareOption.link" />
                                </div>

                                <div v-if="currentShareOption.id === 'linkedin_circle'">
                                    <base-button
                                        label="Share" class="w--100" @submit="handleShareOnline('linkedin_circle')"
                                    />
                                </div>

                                <!-- <div v-if="currentShareOption.id === 'email_circle'">
                                    <form @submit.prevent="">
                                        <div class="mb--15">
                                            <p class="text--sm text--color-gray-600">
                                                To:
                                            </p>
                                            <input-multiple-emails
                                                placeholder="Enter one or more emails to send to"
                                            />
                                        </div>

                                        <div class="mb--15">
                                            <p class="text--sm text--color-gray-600">
                                                Subject
                                            </p>
                                            <FormInput v-model="form.subject" />
                                        </div>

                                        <div class="mb--15">
                                            <p class="text--sm text--color-gray-600">
                                                Message
                                            </p>
                                            <textarea id="messageShare" v-model="form.msg" class="form-control form-control-sm" rows="3" />
                                        </div>
                                        
                                        <div class="mb--15">
                                            <base-button label="Send" type="submit" class="w--100" @submit="handleShareOnline('linkedin_circle')" />
                                        </div>
                                    </form>
                                </div> -->

                                <div v-if="currentShareOption.id === 'x_circle'">
                                    <base-button
                                        label="Share" size="md" class="w--100"
                                        @submit="handleShareOnline('x_circle')"
                                    />
                                </div>

                                <div class="mt--15 w--100 flex justify-content-center">
                                    <base-button
                                        label="Go back" variant="ghost" inner-style="primary"
                                        size="sm" @submit="view = 'rank'"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import MainModal from "./MainModal.vue";
import IconSvg from "../icons/Icon-Svg.vue";
import { rankingList, rankObj } from "@/utils/dummy";
import BaseButton from "../buttons/BaseButton.vue";
import CopyButton from "../buttons/Copy.vue";
import InputMultipleEmails from "../input/InputMultipleEmails.vue";
import FormInput from "../input/FormInput.vue";

export default {
    name: "Rank",

    components: {
        MainModal,
        BaseButton,
        IconSvg,
        CopyButton,
    },

    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            rankingList,
            loading: false,
            view: "rank",
            fetchErr: false,
            fetchErrMsg: "",
            rankObj,
            rankShareIcons: ["link_circle", "linkedin_circle", "x_circle"],
            currentShareOption: {
                id: "",
                title: "",
                desc: "",
                link: "",
                name: "",
            },
            rankShareObj: {
                "link_circle": {
                    title: "Direct link",
                    desc: "Share your accomplishments via a direct link to your file.",
                    link: "cloudsolution.com/#2345t/projects/share?=true&is_this_a_joke_to_you=1",
                    name: "Direct Link",
                },
                "linkedin_circle": {
                    title: "Share on LinkedIn",
                    desc: "Share your accomplishments as a public post on LinkedIn.",
                    link: "",
                    name: "LinkedIn",
                },
                "email_circle": {
                    title: "Send as an email attachment",
                    desc: "Share your accomplishments via email with a PDF copy of your achievements.",
                    link: "",
                    name: "Email", 
                },
                "x_circle": {
                    title: "Share on X",
                    desc: "Share your accomplishments as a public post on X.",
                    link: "",
                    name: "X",
                },
            },
            form: {
                to: "",
                subject: "",
                from: "",
                msg: "",
            },
        };
    },

    computed: {
        compiledProjects() {
            return this.$store.getters["project/compiledProjects"];
        },

        pdfFile() {
            return this.$store.getters["app/pdfFile"];
        },

        completedProjectCount() {
            return this.$store.getters["app/completedProjectCount"];
        },

        currentRank() {
            if (this.completedProjectCount >= 5 && (this.completedProjectCount < 30)) {
                return "Shoshinsha";
            } else if (this.completedProjectCount >= 30 && (this.completedProjectCount < 70)) {
                return "Chūkyū";
            } else if (this.completedProjectCount >= 70 && (this.completedProjectCount < 160)) {
                return "Sensei";
            } else if (this.completedProjectCount >= 160) {
                return "Shishou";
            } else {
                return "";
            }
        },

        computeRankDesc() {
            return this.rankObj[this.currentRank] || "You are yet to complete up to 5 projects.";
        },
    },

    mounted() {
        this.currentShareOption = {
            ...this.rankShareObj["link_circle"],
            id: this.rankShareIcons[0],
        };

        
    },
    
    methods: {
        handleShareOnline(type) {
            try {
                if (!this.pdfFile || !type) return;

                const encodedUrl = encodeURIComponent(this.pdfFile);
                let shareUrl;
                
                // has to be a Blob, File or MediaSource object, not data itself.
                switch (type) {
                case "linkedin_circle":
                    shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=Client%20List&summary=Here%20is%20the%20list%20of%20clients&source=YourApp`;
                    break;

                case "x_circle":
                    shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=Client%20List&summary=Here%20is%20the%20list%20of%20clients&source=YourApp`;
                    break;

                case "whatsapp_circle":
                    shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=Client%20List&summary=Here%20is%20the%20list%20of%20clients&source=YourApp`;
                    break;
                    
                default:
                    shareUrl = `https://api.whatsapp.com/send?text=Here is the list of clients: ${pdfUrl}`;
                    break;
                }

                window.open(shareUrl, "_blank");
            } catch (error) {
                throw error;
            }
        },

        async startProjectGeneration() {
            try {
                this.loading = true;
                this.fetchErr = false;
                this.fetchErrMsg = "";
                
                await this.$store.dispatch("app/generatePdf");

                this.view = "share";
            } catch (error) {
                
            } finally {
                this.loading = false;
            }
        },

        setCurrentShareOption(id) {
            const item = this.rankShareObj[id];

            if (!id || !item) return;

            this.currentShareOption = {
                ...this.currentShareOption,
                ...this.rankShareObj[id],
                id,
            };
        },
    },
};
</script>

<style lang="scss">
.rank {
    &__group {
        &__text {
            &__title {
                color: #374151;
                font-size: 0.875rem;
                font-weight: 600;
            }

            &__desc {
                color: #667085;
                font-size: 0.875rem;
            }
        }
    }

    &__img {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 1.8rem;

        &__item {
            color: #64748B;
            font-size: 0.875rem;  
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;  
            max-width: 72px;
            position: relative;

            &:not(:last-of-type) {
                &::after {
                    min-width: 64px;
                    border-color: inherit;
                    height: 2px;
                    content: '';
                    position: absolute;
                    left: 100%;
                    bottom: 61%;
                    background: #A3B4D0;
                }
            }
            
            &__symbol {
                width: 72px;
                height: 72px;
                border-radius: 7px;
                border: 1px solid #A3B4D0;
                display: flex;
                align-items: center;
                padding: 10px;
                justify-content: center;

                &.active {
                    border: 1px solid #137B87;
                }
            }

            &__title {
                margin-top: 10px;
                text-align: center
            }

            &.active {
                color: #137B87;
                font-weight: 600;

                .rank__img__item__symbol {
                    border: 2px solid #137B87;
                }

                &::after {
                    background: #137B87;
                }
            }

            &__award {
                position: absolute;
                z-index: 10000;
                top: -19%;
                left: -41%;
            }
        }
    }

    &__desc {
        background: #F7F7F7;
        border-radius: 7px;
        padding: 15px;
        margin-top: 2rem;

        &__text {
            color: #3F3D56;
            margin-bottom: 10px;
            font-size: 0.875rem;
        }
    }

    &__generate {
        margin-top: 2.5rem;

        &__btn {
            margin-top: 1rem;
        }
    }

    &__share {
        &__text {
            .title {
                color: #374151;
                font-size: 1rem;
                font-weight: 600;
            }
            .desc {
                color: #687385;
                font-size: 0.875rem;
                margin-top: 5px
            }
        }

        &__action {
            margin-top: 2rem
        }

        &__icon {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            flex: 1;

            &__wrap {
                display: flex;
                align-items: center;
                width: 100%;
                margin-top: 2.5rem;
                margin-bottom: 2rem;
                align-items: center;
                justify-content: center
            }

            p {
                color: #687385 !important;
                margin-top: 7px;
            }

            svg {
                stroke: #B3BACA;

                circle {
                    stroke: #B3BACA;
                } 
                path {
                    stroke: #687385;
                    fill: #687385;
                }
            }

            &.selected {
                svg {
                    stroke: #137B87 !important;

                    circle, path {
                        stroke: #137B87 !important;
                    }

                    path {
                        fill: #137B87 !important;
                    }
                }
                p {
                    color: #137B87 !important;
                    font-weight: 600;
                }
            }
        }

        &__link {
            display: flex;
            margin-top: 2rem;
            align-items: center;

            &__text {
                background: #F7F7F7;
                padding: 10px;
                font-size: 0.75rem;
                color: #3F3D56;
                flex: 1;
                border-radius: 5px;
            }
        }
    }
}
</style>