<template>
    <div>
        <main-modal 
            :show-modal="showModal"
            size="sm" :title="title" @close="closeModal"
        >
            <template #body>
                <form @submit.prevent="">
                    <div class="client__modal__body scroll-height">
                        <FormInput 
                            v-if="hasField('name')"
                            id="name" v-model="form.name"
                            size="sm" info-type="required" label="Name"
                        />

                        <FormInput 
                            v-if="hasField('email')"
                            id="title" v-model="form.email"
                            type="email" size="sm" label="Email address" 
                            :is-error="isEmailInvalid" err-msg="Please enter a valid email address" 
                            info-type="required" required
                        />

                        <div v-if="hasField('industry')" class="form__item">
                            <FormLabel title="Industry" info-type="required" />
                            <CustomDropdown 
                                id="industryDropdown" 
                                v-model="form.industry" 
                                :options="industries"
                                size="sm"
                                btn-classes="text--capitalize text--normal" 
                                list-item-class="text--capitalize" 
                                list-type="industries" 
                                @search="searchIndustries" 
                                @select="v => form.industry = v" 
                            />
                        </div>

                        <div v-if="hasField('status')" class="form__item">
                            <FormLabel title="Status" />
                            <select v-model="form.status" class="form-select form-select-sm text--sm text--capitalize">
                                <option v-for="status in CLIENT_STATUSES" :key="status" :value="status">
                                    {{ status }}
                                </option>  
                            </select>
                        </div>

                        <div v-if="hasField('ccEmails')" class="form__item">
                            <FormLabel title="Additional emails" />
                            <MultipleBillingEmails
                                :is-flex="false" :items="form.ccEmails"
                                classes="w--100" :max-limit="6"
                                @add="addEmail" @delete="removeEmail"
                            />
                        </div>

                        <div v-if="hasField('country')" class="form__item">
                            <FormLabel title="Country" />
                            <CustomDropdown 
                                id="countryDropdown" 
                                v-model="form.country" 
                                :options="countries"
                                size="sm"
                                list-type="countries" 
                                index-label="name"
                                @search="searchCountries" 
                                @select="selectCountry" 
                            />
                        </div>

                        <div v-if="hasField('phoneNumber')" class="form__item">
                            <FormInput 
                                id="phoneNumber"
                                v-model="form.phoneNumber"
                                type="text" size="sm" no-margin-bottom
                                :label="'Phone Number'"                                    
                            />
                        </div>

                        <div v-if="hasField('address')" class="form__item">
                            <FormInput 
                                id="address"
                                v-model="form.address" label="Address"  
                                type="text" size="sm"
                            />
                        </div>

                        <div v-if="hasField('city')" class="form__item">
                            <FormInput 
                                id="city" v-model="form.city"           
                                label="City" no-margin-bottom
                                type="text" size="sm"
                            />
                        </div>

                        <div v-if="hasField('state')" class="form__item">
                            <FormInput 
                                id="city" v-model="form.state"           
                                label="State" no-margin-bottom
                                type="text" size="sm"
                            />
                        </div>

                        <div v-if="hasField('zipCode')" class="form__item">
                            <FormInput 
                                id="zipCode"
                                v-model="form.zipCode" label="Zip code"  
                                type="text" size="sm"
                            />
                        </div>
                    </div>
                </form>
            </template>

            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <BaseButton
                        class="mr--5" label="Cancel"
                        size="sm" variant="outline" :disabled="loading"
                        @submit="closeModal"
                    />
                    <BaseButton 
                        :label="btnLabel"
                        size="sm" :loading="loading" :disabled="isBtnDisabled"
                        @submit="handleSubmit"                                       
                    />
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import countryList from "@/assets/js/countrycodes.json";
import MainModal from "./MainModal.vue";
import FormLabel from "../forms/FormLabel.vue";
import { industryData } from "@/utils/dummy";
import MultipleBillingEmails from "../input/MultipleBillingEmails.vue";
import { validateEmail } from "@/utils/helpers";
import BaseButton from "../buttons/BaseButton.vue";
import FormInput from "../input/FormInput.vue";
import { CLIENT_STATUSES } from "@/utils/enums";
import CustomDropdown from "../dropdowns/CustomDropdown.vue";
import { updateClientDetailsFields } from "@/components/auth/auth";

export default {
    name: "CreateClient",

    components: {
        MainModal,
        FormLabel,
        MultipleBillingEmails,
        BaseButton,
        FormInput,
        CustomDropdown,
    },

    props: {
        actionType: { type: String, default: "add" },
        currentSelection: { type: String, default: "create" },
        showBillingInput: { type: Boolean, default: true },
        currentClient: { type: Object, default: () => {} },
        showModal: { type: Boolean, default: false },
    },

    data() {
        return {
            countryList,
            countries: countryList,
            form: {
                _id: "",
                name: "",
                email: "",
                phoneNumber: "",
                country: "",
                address: "",
                industry: "",
                ccEmails: [],
                dialCode: "",
                status: "inactive",
                rating: 0,
                zipCode: "",
                state: "",
                city: "",
            },
            industries: industryData, 
            loading: false,  
            isEmailInvalid: false,
            CLIENT_STATUSES,
            fields: updateClientDetailsFields,
        };
    },

    computed: {
        isBtnDisabled() {
            if (!this.form.name || !this.form.email) {
                return true;
            } else if (this.loading) {
                return true;
            } else {
                return false;
            }
        },

        numberOfBillingEmailsLeft() {
            if (this.form.ccEmails.length > 0) {
                return 3 - this.form.ccEmails.length;
            } else {
                return 0;
            }
        },

        clientCount() {
            return this.$store.getters["clients/pageData"]?.total;
        },

        btnLabel() {
            if (this.actionType === "edit") {
                return "Save updates";
            } else {
                return "Add client";
            }
        },

        title() {
            return this.fields[this.currentSelection]?.title || "";
        },
    },

    watch: {
        currentClient: {
            handler(newVal, oldVal) {
                if (newVal?._id || oldVal?._id) {
                    this.fillForm(newVal);
                }
            },
            immediate: true,
            deep: true,
        },

        currentSelection(newVal) {
            if (newVal) {
                if (this.fields[newVal] && this.fields[newVal]?.fields) {
                    this.populateFormBasedOnSelection(newVal);
                }
            }
        },
    },

    methods: {
        hasField(field) {
            return !this.fields[this.currentSelection] ? false : this.fields[this.currentSelection].fields[field]; 
        },

        populateFormBasedOnSelection(val) {
            const selectedItem = this.fields[val]?.fields;

            for (const key in selectedItem) {
                if (this.currentClient.hasOwnProperty(key)) {
                    this.form[key] = this.currentClient[key];
                }
            }
        },

        closeModal() {
            this.$emit("cancel", false);
        },

        resetForm() {
            this.form = {
                name: "",
                email: "",
                phoneNumber: "",
                country: "",
                address: "",
                industry: "",
                ccEmails: [],
                dialCode: "",
                status: "",
                zipCode: "",
                state: "",
                city: "",
            };
        },

        fillForm(val) {
            this.form = {
                _id: val?._id || "",
                name: val?.name || "",
                email: val?.email || "",
                phoneNumber: val?.phoneNumber || "",
                country: val?.country || "",
                zipCode: val?.zipCode || "",
                city: val?.city || "",
                state: val?.state || "",
                address: val?.address || "",
                industry: val?.industry || "",
                ccEmails: val?.ccEmails || [],
                status: val?.status || "",
            };
        },

        validateEmailInput(e) {
            const value = e?.target?.value;

            if (!value.includes("@")) return;

            const res = validateEmail(value);
             
            if (!res) {
                this.isEmailInvalid = true;
            } else {
                this.isEmailInvalid = false;
            }
        },

        buildPayload() {
            const payload = {
                name: this.form.name,
                email: this.form.email || "",
                phoneNumber: this.form.phoneNumber || "",
                ccEmails: this.form.ccEmails.join(","),
                country: this.form.country?.name || this.form.country || "",
                address: this.form.address || "",
                industry: this.form.industry || "",
                city: this.form.city,
                state: this.form.state,
                address: this.form.address,
                zipCode: this.form.zipCode,
                status: this.form.status || "inactive",
            };

            if (this.form._id) {
                payload._id = this.form._id;
            }

            return payload;
        },

        async createNewClient() {
            try {    
                this.loading = true;
                const payload = this.buildPayload();
                const resp = await this.$store.dispatch("clients/addClient", payload);

                this.$toast.success("Client created");
                this.resetForm();
                this.$emit("complete", resp);
            } catch (error) {
                const errMsg = error?.response?.message || error.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }
        },

        async updateClient() {
            try {
                if (!this.form._id) {
                    throw new Error("Client id is required");
                }

                this.loading = true;
                const payload = this.buildPayload();
                const res = await this.$store.dispatch("clients/updateClient", payload);

                this.$toast.success("Client info updated");
                this.$emit("complete", res);
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }
        },

        async handleSubmit() {
            if (this.actionType === "edit") {
                await this.updateClient();
            } else if (this.actionType === "add") {
                await this.createNewClient();
            }
        },

        removeEmail(email) {
            this.form.ccEmails = this.form.ccEmails.filter(item => item !== email);
        },

        addEmail(value) {
            this.form.ccEmails.push(value);
        },

        computePhoneNumber(dialCode) {
            if (dialCode) {
                this.form.dialCode = dialCode;
            }
            return;
        },

        upgradeAccount() {
            this.closeModal();
            this.$router.push("/dashboard/settings/billings-and-plans");
        },

        searchCountries(val) {
            this.countries = countryList.filter(item => item.name.toLowerCase().includes(val));
        },

        selectCountry(item) {
            this.form.country = item;
        },

        searchIndustries(val) {            
            this.industries = industryData.filter(item => item.toLowerCase().includes(val));
        },
    },
};
</script>

<style lang="scss" scoped>
.client__modal__body {    
    &.scroll-height {
        max-height: 490px;
        overflow: scroll;

        .form--control {
            &:not(:last-of-type) {
                margin-bottom: 15px !important;
            }
        }
    }
}
</style>