<template>
    <div>
        <MainModal
            :show-modal="showModal" 
            size="md" title="Manage time tracking" @close="closeModal"
        >
            <template #body>
                <div class="modal--content--inner pt--5">
                    <NonLinkedTabs :list="tabList" type="pill" position="end">
                        <template #nonLinkedTab="tab">
                            <NonLinkedTab :is-active="tab.name === 'Timer'">
                                <div class="mt--30">
                                    <div class="w--100 flex justify-content-center">
                                        <Circular 
                                            size="250" :progress-text="formattedTime" background-color="#d9d9d9"
                                            :is-inner-text="false"
                                        >
                                            <template #text>
                                                <div class="time__tracking__text">
                                                    {{ formattedTime }}
                                                </div>
                                            </template>
                                        </Circular>
                                    </div>

                                    <div class="w--100 flex justify-content-center mt--40">
                                        <BaseButton 
                                            size="sm" prefix-icon :label="isTracking ? 'STOP TIMER' : 'START TIMER'"
                                            variant="outline" inner-style="primary" icon-name="play-circle" 
                                            :disabled="trackLoading" @submit="toggleTracking"
                                        />
                                    </div>
                                </div>
                            </NonLinkedTab>

                            <NonLinkedTab :is-active="tab.name === 'Timesheets'">
                                <div class="text-left">
                                    <h3 class="text--2xl text--color-gray-800 mb--35 text--semi--bold">
                                        Time Logs:
                                    </h3>
                                    <div class="max-h-40 overflow-y-auto">
                                        <!-- <div
                                            v-for="(log, index) in activityLogs"
                                            :key="index" class="text-sm text-gray-600 mb-1"
                                        >
                                            {{ formatLogTimestamp(log.timestamp) }} - {{ log.type }}
                                            <p>{{ log.startDate }} {{ log.startTime }}</p>
                                            {{ log.duration > 0 ? `(${log.duration}s)` : '' }}
                                        </div> -->
                                        <CustomTable
                                            :columns="timeSheetHeaders" :is-action-btns="false"
                                            :data="activityLogs" :is-checkbox="false" row-type="link"
                                        >
                                            <template #rows="data">
                                                <td>
                                                    {{ data.startDate }} - {{ data.startTime }}
                                                </td>
                                                <td>
                                                    {{ data.endDate }} - {{ data.endTime }}
                                                </td>
                                                <td>
                                                    {{ data.duration }}
                                                </td>
                                            </template>
                                        </CustomTable>
                                    </div>
                                </div>
                            </NonLinkedTab>
                        </template>
                    </NonLinkedTabs>
                </div>
            </template>

            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <BaseButton
                        label="Close" size="sm" @submit="closeModal"
                    />
                </div>
            </template>
        </MainModal>
    </div>
</template>
  
<script>
import BaseButton from "../buttons/BaseButton.vue";
// import IconSvg from "../icons/Icon-Svg.vue";
import NonLinkedTabs from "../tabs/NonLinkedTabs.vue";
import NonLinkedTab from "../tabs/NonLinkedTab.vue";
import Circular from "../progress/Circular.vue";
import MainModal from "./MainModal.vue";
import CustomTable from "../tables/index.vue";
import { timeSheetHeaders } from "../../../utils/dummy";

export default {
    name: "ManageTimeTrackingModal",

    components: { 
        BaseButton, MainModal, CustomTable,
        NonLinkedTabs, NonLinkedTab, Circular,
    },

    props: {
        showModal: { type: Boolean, default: false },
        currentProject: { type: Object, default: () => {} },
        customText: { type: Boolean, default: false },
        isError: { type: Boolean, default: false },
        mimeTypes: { type: String, default: "" },
        progressValue: { type: Number, default: 0 },
        uploadType: { type: String, default: "" },
        headers: { type: Array, default: () => [] },
    },

    emits: ["cancel", "click"],

    data() {
        return {
            timeSheetHeaders,
            events: ["mousemove", "keydown", "click", "scroll", "visibilitychange"],
            reactiveShow: this.show || false,
            tabList: [
                { name: "Timer", iconFill: "#212529", iconName: "alarm" },
                { name: "Timesheets", iconFill: "#212529", iconName: "report" },
            ],
            isTracking: false,
            elapsedTime: 0,
            activityLog: [],
            startTime: null,
            timer: null,
            lastActive: Date.now(),
            idleCheckTimer: null,
            trackLoading: false,
        };
    },

    computed: {
        formattedTime() {
            const seconds = Math.floor((this.elapsedTime / 1000) % 60);
            const minutes = Math.floor((this.elapsedTime / (1000 * 60)) % 60);
            const hours = Math.floor(this.elapsedTime / (1000 * 60 * 60));
            
            return [hours, minutes, seconds]
                .map(n => n.toString().padStart(2, "0"))
                .join(":");
        },

        activityLogs() {
            return this.currentProject.timeTrackingLogs.map(item => {
                return {
                    startTime: new Date(item.startTime).toLocaleTimeString(),
                    endTime: !item.endTime ? "-" : new Date(item.endTime).toLocaleTimeString(),
                    startDate: new Date(item.startTime).toDateString(),
                    endDate: !item.endTime ? "-" : new Date(item.endTime).toDateString(),
                    duration: this.duration(item.endTime, item.startTime),
                };
            });
        },
    },

    watch: {
        show(newVal) {
            this.reactiveShow = newVal;
        },

        "currentProject": {
            handler(newVal) {                
                if (newVal.trackProject) {
                    const currentTrackingLog = newVal.timeTrackingLogs[0];

                    if (currentTrackingLog.startTime && !currentTrackingLog.endTime) {
                        this.elapsedTime = Date.now() - new Date(currentTrackingLog.startTime).getTime();
                        this.startTimer();
                        this.isTracking = true;
                    }
                } else {
                    this.isTracking = false;
                    this.stopTimer();
                }
            },
            immediate: true,
            deep: true,
        },
    },

    created() {
        this.setupEventListeners();
    },

    beforeDestroy() {
        this.stopTimer();
        this.removeEventListeners();
    },

    methods: {
        duration(end, start) {
            const elapsedTime = (end || new Date().getTime()) - start || 0;
            const seconds = Math.floor((elapsedTime / 1000) % 60);
            const minutes = Math.floor((elapsedTime / (1000 * 60)) % 60);
            const hours = Math.floor(elapsedTime / (1000 * 60 * 60));
            
            return [hours, minutes, seconds]
                .map(n => n.toString().padStart(2, "0"))
                .join(":");
        },
        
        closeModal(){
            this.$emit("cancel");
        },

        hide(event) {
            event.preventDefault();
            event.stopPropagation();
        },

        async handleTrackingChange(val, event) {            
            try {
                const payload = {
                    trackProject: val,
                    trackingType: this.currentProject.trackingType,
                    timeTrackingLogs: [{
                        eventType: event,
                    }],
                };

                if (this.currentProject.trackingType === "manual") {
                    payload.timeTrackingLogs[0].endTime = new Date().getTime();
                    payload.timeTrackingLogs[0].startTime = this.currentProject.timeTrackingLogs[0]?.startTime;
                }
                await this.$store.dispatch("project/toggleTimeTracking", { id: this.currentProject._id, payload });
            } catch (error) {
                throw error;
            }
        },

        async toggleTracking() {
            try {
                this.isTracking = !this.isTracking;
                this.trackLoading = true;
                // this.logActivity(this.isTracking ? "start" : "stop");
                
                if (this.isTracking) {
                    await this.handleTrackingChange(true, "start");
                    this.startTimer();
                } else {
                    await this.handleTrackingChange(false, "stop");
                    this.stopTimer();
                }
            } catch (error) {
                throw error;
            } finally {
                this.trackLoading = false;
            }
        },

        startTimer() {  
            const current = this.currentProject.timeTrackingLogs[0];

            if (current?.startTime && !current.endTime) {
                this.startTime = new Date(current.startTime).getTime();
            } else {
                this.startTime = Date.now() - this.elapsedTime;
            }

            this.timer = setInterval(this.updateTimer, 1000);
            // this.startIdleCheck();
        },

        async stopTimer() {
            clearInterval(this.timer);
            clearInterval(this.idleCheckTimer);

            this.elapsedTime = 0; 
        },

        updateTimer() {
            this.elapsedTime = Date.now() - this.startTime;
        },

        updateLastActive() {
            this.lastActive = Date.now();
        },

        startIdleCheck() {
            this.idleCheckTimer = setInterval(() => {
                const idleTime = Date.now() - this.lastActive;
                if (idleTime > 60000 && this.isTracking) { // 1 minute idle threshold
                    this.logActivity("idle", Math.floor(idleTime / 1000));
                }
            }, 30000);
        },

        // handleVisibilityChange() {
        //     this.logActivity(document.hidden ? "tab_inactive" : "tab_active");
        // },

        logActivity(type, duration = 0) {
            this.activityLog.push({
                timestamp: new Date().toISOString(),
                type,
                duration,
            });
        },

        formatLogTimestamp(timestamp) {
            return new Date(timestamp).toLocaleTimeString();
        },

        setupEventListeners() {
            const events = ["mousemove", "keydown", "click", "scroll"];
            events.forEach(event => {
                window.addEventListener(event, this.updateLastActive);
            });
            
            document.addEventListener("visibilitychange", this.handleVisibilityChange);
        },

        removeEventListeners() {
            const events = ["mousemove", "keydown", "click", "scroll"];
            events.forEach(event => {
                window.removeEventListener(event, this.updateLastActive);
            });
            
            document.removeEventListener("visibilitychange", this.handleVisibilityChange);
        },
    },
};
</script>

<style lang="scss" scoped>
.modal {
    &--overlay {
        position: fixed; 
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%; 
        height: 100%; 
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
        animation-timing-function: cubic-bezier(.4,0,.2,1);
        background-color: rgba(0,0,0,.5);
        display: flex;
        isolation: isolate;
        justify-content: center;
        overflow: hidden;
        transition: background-color .5s;
        z-index: 1000;
    }

    &--header {
        display: flex;
        position: relative;
        justify-content: space-between;
        box-shadow: inset 0 -1px #ebeef1;
        padding: 10px 15px;
    }

    &--content {
        background-color: #fefefe;
        margin: 5rem auto;
        border: 1px solid #888;
        width: 80%;
        border-radius: 10px;
        min-height: 566px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        span::after {
            font-size: 10px;
            position: absolute;
            top: 10rem;
            left: 1rem;
            font-family: monospace;
            content: attr(data-state);
            opacity: 0.2;
        }

        &--inner {
            // padding: 5px 20px 0px;
            min-height: 431px;
        }
    }

    &--body {
        align-items: center;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 32px;
        padding: 20px;
        width: 650px !important;

        @media screen and (max-width: 800px) {
            width: 500px;
        }
    }

    &--close {
        color: #aaaaaa;
        font-size: 28px;
        outline: none;
        text-align: center;
        border: none;
        background: transparent;
        cursor: pointer;
        font-weight: 600;

        &:hover, &:focus {
            color: #000;
        }
    }

    &--footer {
        display: flex; 
        align-items: center; 
        justify-content: flex-end;
        padding: 10px 15px;
        border-top: solid 1px #E0E0E0;  
    }
}

.time__tracking__text {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px solid red;
    height: 100%;
    width: 100%;
}
</style>