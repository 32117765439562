<template>
    <div class="h-100 content__page">
        <div>
            <template v-if="pageLoading">
                <PageSpinner />
            </template>

            <template v-else-if="pageErr">
                <EmptyPage state="error" :handle-submit="handleFetchRateCards" />
            </template>

            <template v-else>
                <div v-if="isListPresent" class="content__page__header">
                    <div class="list--count">
                        <p class="text--medium text--sm">
                            {{ rateCards.length === 1 ? `${rateCards.length} card` : `${rateCards.length} cards` }}
                        </p>
                    </div>

                    <div class="flex align-items-center" :class=" { 'disabled': checkUpgradeLoading } ">
                        <div v-if="selectedItems.length" class="mr--10">
                            <BaseButton 
                                variant="outline" inner-style="warning" size="sm"
                                @submit="toggleDelete"
                            >
                                Delete {{ selectedItems.length }} {{ selectedItems.length === 1 ? 'item' : 'items' }}
                            </BaseButton>
                        </div>

                        <BaseButton 
                            label="+ Add new rate card" size="sm" class="mr--5"
                            @submit="startAddCard"
                        />

                        <!-- <import-button
                            class="mr--5" label="Import" width="16px" icon-name="import" 
                            :list="['csv', 'json']" @select="setImportType"
                        /> -->
                        <MainFilter page-type="rateCards" />
                        <SortFilter 
                            v-if="rateCards.length > 5" class="ml--5" size="sm"
                            :value="displayType" @setType="setDisplayType" 
                        />
                    </div>
                </div>

                <div class="content__page__body" :class="{ 'empty': !rateCards.length }">
                    <div v-if="!rateCards.length">
                        <EmptyPage 
                            v-if="!isSearched" state="empty" icon-name="notes" fill="#BDBDBD"
                            width="60px" height="60px" type="rate card"
                        >
                            <template #button>
                                <base-button label="+ Add new rate card" size="sm" @submit="startAddCard" />
                            </template> 
                        </EmptyPage>
                        <EmptyPage v-else state="no_result" />
                    </div>
    
                    <div v-else class="mt--45"> 
                        <div class="rc__view">
                            <div 
                                v-for="item in rateCards" 
                                :key="item._id" class="rc__item"
                                :class="{ 'disabled': selectedItems.includes(item._id) && deleteLoading }"
                            >
                                <div class="rc__item__dropdown">
                                    <div class="left">
                                        <div class="form-check mb--0">
                                            <input 
                                                :id="item._id"
                                                v-model="selectedItems" class="form-check-input" 
                                                type="checkbox" :value="item._id"
                                            >
                                            <FormLabel :title="item.title" :html-for="`${item.title}`" label-type="menu" />
                                        </div>
                                    </div>
                                    <div class="right">
                                        <div
                                            id="rateCardActions" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false"
                                            class="rc__item__dropdown__toggle"
                                        >
                                            <div class="" tabindex="-1" title="More options">
                                                <icon-svg class="mr--0 flex" name="dots-horizontal-rounded" fill="#718096" width="16px" />  
                                            </div>
                                        </div>
    
                                        <ul class="dropdown-menu dropdown-menu--tag" aria-labelledby="rateCardActions">
                                            <li class="dropdown-item block width-100 pointer" @click="viewCard(item._id)">
                                                <div class="flex align-items-center">
                                                    <IconSvg class="mr--5 flex" name="show" fill="#718096" width="16px" />  
                                                    <span class="text--xs">
                                                        View card
                                                    </span>
                                                </div>
                                            </li>
                                            <li class="dropdown-item block width-100 pointer" @click="openCard(item)">
                                                <div class="flex align-items-center">
                                                    <IconSvg class="mr--5 flex" name="edit" fill="#718096" width="16px" />  
                                                    <span class="text--xs">
                                                        Edit card
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <p class="rc__item__text">
                                    {{ item.rateType }}
                                </p>
                            </div>
                        </div>

                        <Pagination v-if="showPagination" :pagination-data="pageData" />
                    </div>        
                </div>
            </template>
        </div>

        <MainModal 
            :show-modal="showAddModal" size="md"
            :title="`${action === 'add' ? 'Add' : 'Edit'} rate card`" @close="closeCard"
        >
            <template #body>
                <div>
                    <form class="rc__form" @submit.prevent="">
                        <FormInput 
                            id="title"
                            v-model="form.title"
                            label="Title" size="sm" info-type="required"
                        />

                        <FormInput 
                            id="description" v-model="form.description" size="sm" label="Description"
                        />
                        
                        <div class="form__row mb--15">
                            <div class="mr--5 mb--0 flex-1">
                                <FormLabel title="Currency" html-for="currency" info-type="required" />
                                <CustomDropdown 
                                    id="currencyDropdown" v-model="form.currency" :options="initialCurrencyList"
                                    list-type="currencies" index-label="currency" :add-on="selectedCurrency.symbol"
                                    class="dropdown-currency" btn-text-class="text--upper" 
                                    @search="searchCurrencies" @select="selectCurrency" 
                                />
                            </div>
                           
                            <div class="mb--0 flex-1">
                                <FormLabel title="Rate type" html-for="rateType" />
                                <CustomDropdown 
                                    id="rateTypeDropdown" v-model="form.rateType" :options="rateTypes"
                                    class="dropdown-rates" btn-text-class="text--capitalize" :searchable="false" 
                                    @select="v => form.rateType = v" 
                                />
                            </div>
                        </div>

                        <div class="col-12">
                            <FormLabel title="Memo" html-for="memo" label-type="form" />
                            <textarea
                                id="exampleFormControlTextarea1" v-model="form.memo" name="memo" 
                                class="form-control form-control-sm" rows="3" maxlength="200"
                            />
                        </div>

                        <div class="row invoice__row block ml--0">
                            <div class="flex justify-content-between pr--0 pl--0 mt--30">
                                <FormLabel 
                                    title="Socials" html-for="currency" 
                                    label-type="menu" class="text--upper" 
                                />
                                <BaseButton 
                                    class="p--0"
                                    label="+ Add new social" variant="ghost" inner-style="primary" 
                                    size="sm" @submit="addSocialItem"
                                />
                            </div>

                            <div class="mb--15 mr--0 pl--0">
                                <div class="invoice__calculate--wrap invoice__header mt--5 p--0 mb--0">
                                    <ul class="pr--0 mt--20 w--100">
                                        <li v-for="(item, i) in form.socials" :key="i" class="mr--0 ml--0 pr--0">
                                            <div class="flex align-items-center pb--5 pt--5 w--100">
                                                <div class="flex align-items-center flex-2 rc__form__social__inputs">
                                                    <select v-model="item.name" class="rc__form__social__select form-select form-select-sm text--sm filter-input text--capitalize">
                                                        <option v-for="social in socials" :key="social" :value="social">
                                                            {{ social }}
                                                        </option>
                                                    </select>
                                                    <input v-model="item.url" type="text" class="rc__form__social__input form-control form-control-sm ml--10">
                                                </div>

                                                <div v-if="i > 0" class="rc__form__social__close" @click="removeSocialItem(i)">
                                                    <IconSvg name="x-circle" width="16px" fill="#B9B9B9" />
                                                </div>
                                            </div>
    
                                            <span v-if="isSocialItemFilled(i)" class="form__errors flex align-items-center">
                                                <IconSvg name="alert" width="16px" fill="#ffea8a" />
                                                <span class="form__errors__text ml--5">
                                                    Enter a name and value for the field above
                                                </span>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="row invoice__row block ml--0 mr--0">
                            <div class="flex justify-content-between pl--0 pr--0">
                                <FormLabel 
                                    title="Services" html-for="currency" 
                                    label-type="menu" class="text--upper" 
                                />
                                <BaseButton 
                                    class="p--0"
                                    label="+ Add new item" variant="ghost" inner-style="primary" 
                                    size="sm" @submit="addAnotherItem"
                                />
                            </div>

                            <div class="invoice__calculate--wrap invoice__header mt--5 p--0 mb--0">
                                <div class="mt--20 rc__form__item__left ml--0">
                                    <FormLabel title="Service Name" html-for="rateType" info-type="required" />
                                </div>

                                <div class="mt--20 invoice__calculate--row rc__form__item__right">
                                    <div class="invoice__amount flex-1">
                                        <FormLabel title="Amount" html-for="rateType" info-type="required" />
                                    </div>
                                    <div class="invoice__price flex-1">
                                        <FormLabel title="Description" html-for="rateType" />
                                    </div>
                                </div>
                            </div>

                            <ul class="rc__item__wrap pr--0">
                                <li v-for="(item, i) in form.items" :key="i" class="mr--0 ml--0 pr--0 pl--0">
                                    <div class="invoice__calculate--wrap relative" :class="{ 'mt--20': i > 0 }">
                                        <div class="rc__form__item__left">
                                            <input v-model="item.serviceName" type="text" class="form-control form-control-sm">
                                        </div>
                    
                                        <div class="rc__form__item__right">
                                            <div class="invoice__calculate--wrap flex-1 w--40 mr--5">
                                                <money
                                                    v-model="item.amount" v-bind="config.number"
                                                    class="form-control form-control-sm"
                                                    spellcheck="false" @keydown.native="preventKeys"
                                                />
                                            </div>
                                            <div class="invoice__amount flex-1 w--40 mr--5">
                                                <input v-model="item.description" type="text" class="form-control form-control-sm">
                                            </div>
                                        </div>
                                        <div v-if="i > 0" class="invoice__calculate__close">
                                            <IconSvg name="close" width="16px" fill="#B9B9B9" @click="removeItem(i)" />
                                        </div>
                                    </div>

                                    <span v-if="isItemFilled(i)" class="form__errors flex align-items-center">
                                        <IconSvg name="alert" width="16px" fill="#ffea8a" />
                                        <span class="form__errors__text ml--5">
                                            Please fill in the service name and price for this item
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </template>
            
            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <BaseButton
                        label="Cancel" size="sm" variant="outline" class="mr--5"
                        inner-style="secondary" :disabled="formLoading" @submit="closeCard"
                    />
                    <BaseButton 
                        :label="action === 'add' ? 'Create' : 'Update'"
                        size="sm" :loading="formLoading" 
                        :disabled="formLoading || isBtnDisabled" type="submit" @submit="handleSubmit" 
                    />
                </div>
            </template>
        </MainModal>

        <MainModal 
            :show-modal="showSendModal"
            size="sm" title="Send rate card" @close="closeSendCard"
        >
            <template #body>
                <div class="mb--15 pr--10 pl--10">
                    <Alert type="primary" classes="text--sm">
                        <template #text>
                            <p class="text--xs">
                                Use this option if you want to automatically send a client this rate card with a pre-composed message body.
                            </p>
                        </template>
                    </Alert>

                    <form class="rc__form mb--20" @submit.prevent="">
                        <div class="mb--15">
                            <FormLabel title="Subject" html-for="subject" />
                            <p class="form-control form__input--none form-control-sm disabled">
                                {{ `Rate card for ${form.title}` }}
                            </p>
                        </div>

                        <div class="mb--0 flex-1">
                            <FormLabel title="Select a client" html-for="rateType" />
                            <CustomDropdown 
                                id="clientsDropdown" v-model="client" :options="clients"
                                list-type="client" index-label="name"
                                @search="handleFetchClients" @select="handleSelectClient" 
                            />
                        </div>

                        <!-- TO DO: use a transition for a smoother transition -->
                        <template v-if="client._id && form.title">
                            <div class="mb--5 mt--15">
                                <FormLabel title="Preview" html-for="subject" />
                            </div>

                            <div class="rc__preview">
                                <p class="rc__preview__text">
                                    To: <span class="ml--5">{{ client.email }}</span>
                                </p>

                                <!-- <div v-if="client.ccEmails" class="flex flex-wrap rc__preview__text">
                                        <p>cc: </p>
                                        <p v-for="email in client.ccEmails" :key="email" class="ml--5 text--sm text--color-gray-700">
                                            {{ email }},
                                        </p>
                                    </div> -->

                                <p class="rc__preview__text">
                                    Hello {{ client.name }},
                                </p>

                                <p class="rc__preview__text">
                                    Please find my rate card for {{ form.title }}, as discussed.
                                </p>

                                <p class="rc__preview__text">
                                    Thank you for your time. I look forward to speaking with you again!
                                </p>

                                <p class="rc__preview__text">
                                    Best regards,
                                </p>
                                        
                                <p class="rc__preview__text">
                                    {{ userProfile.fullName }}
                                </p>
                            </div>
                        </template>
                    </form>
                </div>
            </template>

            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <Base-Button
                        label="Cancel" size="sm" variant="outline" :disabled="formLoading"
                        @submit="closeSendCard"
                    />
                    <Base-Button 
                        label="Send as email"
                        size="sm" :loading="formLoading" 
                        type="submit" @submit="sendEmail" 
                    />
                </div>
            </template>
        </MainModal>

        <ViewRateCardModal 
            :show-modal="showViewModal" :card-id="viewCardId" 
            @close="closeViewCardModal" 
        />

        <FileUploadModal 
            :show="showUploadModal" mime-types="text/csv"
            :file-content="uploadContent" custom-text :is-error="uploadErr" upload-type="rate cards"
            :headers="rateCardUploadHeaders"
            :progress-value="uploadProgress" @update:files="handleFileUpload" @cancel="resetFileModal"
        >
            <template #custom-text>
                <Alert type="primary" classes="text--sm">
                    <template #text>
                        <p class="text--xs">
                            Note: check for duplicate titles before uploading. Allowed values for rate types are: <strong>hourly, one-off, weekly, monthly, and yearly</strong>.
                        </p>
                    </template>
                </Alert>
            </template>
        </FileUploadModal>

        <ConfirmDeletionTwo
            type="rate cards" custom-msg
            :show-modal="showDeleteModal" :items="selectedItems"
            :loading="deleteLoading"
            @cancel="showDeleteModal = false" 
            @delete="handleDeleteCards"
        >
            <template #custom-msg>
                <p class="text--sm">
                    These items will be removed from all associated projects. <strong>This action cannot be undone.</strong>
                </p>
            </template>
        </ConfirmDeletionTwo>
    </div>
</template>

<script>
import {
    PageSpinner,
    BaseButton,
    EmptyPage,
    IconSvg,
    MainModal,
    FormInput,
    MainFilter,
    SortFilter,
    FormLabel,
    FileUploadModal,
    ImportButton,
    Alert,
    Pagination, ViewRateCardModal,
    CustomDropdown,
    ConfirmDeletionTwo,
} from "@/components/ui/index";
import { assembleQueryList } from "@/utils/helpers";
import { rateCardUploadHeaders, socials } from "@/utils/dummy";
import Papa from "papaparse";
import { paymentSupportedCurrencies } from "@/plugins/countries";

export default {
    name: "RateCardView",

    components: {
        Alert,
        IconSvg,
        EmptyPage, ViewRateCardModal,
        PageSpinner, ConfirmDeletionTwo,
        BaseButton,
        MainModal,
        FormInput,
        MainFilter,
        SortFilter,
        FormLabel,
        FileUploadModal,
        ImportButton,
        Pagination,
        CustomDropdown,
    },

    data() {
        return {
            paymentSupportedCurrencies,
            rateCardUploadHeaders,
            action: "",
            showAddModal: false,
            deleteLoading: false,
            rateTypes: ["hourly", "weekly", "monthly", "yearly", "one-off"],
            form : {
                _id: "",
                title: "",
                rateType: "",
                currency: "",
                tax: 0,
                items: [{ amount: 0.00, serviceName: "", description: "" }],
                socials: [{ name: "", url: "" }],
                memo: "",
            },
            pageLoading: false,
            pageErr: false,
            pageErrMsg: "",
            formLoading: false,
            displayType: "",
            config: {
                number: {
                    decimal: ".",
                    thousands: ",",
                    precision: 0,
                    masked: false,
                },
            },
            currencyMap: this.$supported_currencies_map,
            initialCurrencyList: Object.values(this.$currencies_obj),
            importType: "",
            showViewModal: false,
            showUploadModal: false,
            uploadProgress: 0,
            uploadContent: [],
            uploadErr: false,
            client: {},
            showSendModal: false,
            fetchClientsLoading: false,
            fetchClientsLoadingErr: false,
            itemErrorsObj: {},                                                               
            selectedItems: [],
            showDeleteModal: false,
            showUpgradeAccount: false,
            checkUpgradeLoading: false,
            selectedCurrency: {
                currency: "", 
                symbol: "",
            },
            viewCardId: "",
            socials,
            socialItemErrors: {},
        };
    },

    computed: {
        isListPresent() {
            if (this.rateCards.length) {
                return true;
            } else if (!this.rateCards.length && this.isSearched) { // no result found state
                return true;
            } else {
                return false;
            }
        },

        userProfile() {
            return this.$store.getters["auth/profile"];
        },

        maxSharedSendLimit() {
            if (this.userProfile?.sharedRateCardCount > 2) {
                return true;
            } else {
                return false;
            }
        },

        rateCards() {
            return this.$store.getters["ratecards/rateCards"];
        },

        pageData() {
            return this.$store.getters["ratecards/pageData"];
        },

        isBtnDisabled() {
            if (!this.form.title || !this.form.currency) {
                return true;
            } 
            
            return false;
        },

        computeBillableText() {
            if (this.form.rateType) {
                switch (this.form.rateType) {
                case "hourly":
                    return "hours";
                case "yearly":
                    return "years";
                case "monthly":
                    return "months";
                case "weekly":
                    return "weeks";
                
                default:
                    return "hours";
                }
            } else {
                return "hours";
            }
        },

        showPagination() {
            if (this.pageData?.total > 50 && this.pageData?.totalPages > 1) {
                return true;
            } else {
                return false;
            }
        },

        clients() {
            return this.$store.getters["clients/clients"];
        },

        isSearched() {
            return Object.keys(this.$route.query).length > 0;
        },

        appCurrency() {
            return this.$store.getters["app/userSettings"]?.general?.currency;
        },
    },

    watch: {
        "$route.query": {
            async handler(newVal) {
                await this.handleFetchRateCards({ refresh: true, ...newVal });
            },
            immediate: false,
        },

        displayType(newType, oldType) {
            if (newType !== oldType) {
                this.sortRateCards();
            }
        },
    },

    methods: {
        resetFileModal() {
            this.showUpgradeAccount = false;
            this.showUploadModal = false;
        },

        selectCurrency(item) {
            const index = item && item.toLowerCase() || "";

            this.selectedCurrency = this.currencyMap[index];
            this.form.currency = item;
        },

        searchCurrencies(val) {
            const initialList = this.initialCurrencyList;

            if (val) {
                const filteredList = initialList.filter(item => item.toLowerCase().includes(val));
                this.initialCurrencyList = filteredList;
            } else {
                this.initialCurrencyList = Object.values(this.$currencies_obj);
            }
        },

        toggleDelete() {
            this.showDeleteModal = !this.showDeleteModal;
        },

        async setImportType(val) {
            try {
                this.checkUpgradeLoading = true;
                this.showUpgradeAccount = false;
                
                await this.$store.dispatch("app/checkUpgradeStatus", "rateCards");
                this.importType = val;
            } catch (error) {
                this.showUpgradeAccount = true;
            } finally {
                this.checkUpgradeLoading = false;
                this.showUploadModal = true;
            }
        },

        openSendCard(item) {
            this.form = {
                ...this.form,
                ...item,
            };
            this.showSendModal = true;
        },

        resetCloseSendToClient() {
            this. form = {
                _id: "",
                title: "",
                memo: "",
                billableHours: 0,
                rateType: "",
                currency: "",
                items: [{ description: "", rate: 0.00, serviceName: "" }],
            };
            this.showSendModal = false;
        },

        closeSendCard() {
            this.showSendModal = false;
            this.showUpgradeAccount = false;
            this.form = {
                _id: "",
                title: "",
                description: "",
                billableHours: 0,
                billingRate: 0,
                rateType: "",
                currency: "",
                amount: 0,
            };
            this.client = {};
        },

        preventKeys(event) {
            if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 190 || event.keyCode == 37 || event.keyCode == 39) {
            } else {
                return event.preventDefault();
            }
        },

        sortRateCards() {
            this.$store.dispatch("ratecards/sortRateCards", this.displayType);
        },

        setDisplayType(val) {
            this.displayType = val;
        },

        async handleFetchRateCards(params) {
            try {
                this.pageErr = false;
                this.pageErrMsg = "";
                this.pageLoading = true;

                const pageQueryObj = {
                    page: params?.page || 1,
                    ...params,
                    ...this.$route.query,
                };

                const buildResult = assembleQueryList(pageQueryObj);

                await this.$store.dispatch("ratecards/setRatesPagination", pageQueryObj.page);
                await this.$store.dispatch("ratecards/fetchRateCards",  {
                    payload: buildResult, 
                    refresh: params?.refresh, 
                });
                this.pageLoading = false;
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;

                this.pageErr = true;
                this.pageErrMsg = errMsg;
                this.pageLoading = false;
            }
        },

        openCard(item) {
            this.action = "edit";
            this.form = {
                ...this.form,
                ...item,
                currency: item.currency.toUpperCase() || this.appCurrency?.toUpperCase(),
            };
            this.showAddModal = true;
        },

        closeCard() {
            this.action = "";
            this.showAddModal = false;
            this.showUpgradeAccount = false;
            this.form = {
                _id: "",
                title: "",
                rateType: "",
                currency: "",
                amount: 0,
                tax: 0,
                items: [{ amount: 0.00, serviceName: "", description: "" }],
            };
        },

        viewCard(id) {
            this.viewCardId = id;
            this.showViewModal = true;
        },

        closeViewCardModal() {
            this.showViewModal = false;
            this.viewCardId = "";
        },

        async startAddCard() {
            try {
                this.checkUpgradeLoading = true;
                this.showUpgradeAccount = false;
                
                await this.$store.dispatch("app/checkUpgradeStatus", "rateCards");
   
                this.action = "add";
                this.showAddModal = true;
            } catch (error) {
                this.showUpgradeAccount = true;
            } finally {
                this.checkUpgradeLoading = false;
            }
        },

        isValidAmount(amount) {
            const numAmount = parseFloat(amount);
            return !isNaN(numAmount) && numAmount > 0;
        },

        validateSocialItems() {
            let isInvalid = false;
            this.socialItemErrors = {};

            const items = this.form.socials;

            items.forEach((item, index) => {
                if (!item.name.trim() || !item.url) {
                    this.socialItemErrors[index] = true;
                    isInvalid = true;
                }   
            });

            return isInvalid;
        },

        addSocialItem() {            
            const isEmpty = this.validateSocialItems();

            if (isEmpty) return;

            this.form.socials.push({
                name: "",
                url: "",
            });
        },

        isSocialItemFilled(index) {
            if (this.socialItemErrors[index]) {
                return true;
            } else {
                return false;
            }
        },

        removeSocialItem(index) {
            const arr = this.form.socials.filter((elem, idx) => {
                if (idx !== index) {
                    return idx !== index;
                }
            });
            
            this.form.socials = arr;
        },

        validateItems() {
            let isInvalid = false;
            this.itemErrorsObj = {};
            const items = this.form.items;

            items.forEach((item, index) => {
                if (!item.serviceName.trim() || !this.isValidAmount(item.amount)) {
                    this.itemErrorsObj[index] = true;
                    isInvalid = true;
                }   
            });

            return isInvalid;
        },

        addAnotherItem() {            
            const isEmpty = this.validateItems();

            if (isEmpty || this.firstItemIsEmpty) {
                return;
            }  

            this.form.items.push({
                serviceName: "",
                amount: 0,
                description: "",
            });
        },

        isItemFilled(index) {
            if (this.itemErrorsObj[index]) {
                return true;
            } else {
                return false;
            }
        },

        removeItem(index = this.form.items.length - 1) {
            this.form.items = this.form.items.filter((elem, idx) => idx !== index);
        },

        buildPayload() {
            const payload = {
                currency: this.form.currency,
                description: this.form.description,
                rateType: this.form.rateType,
                title: this.form.title,
                items: this.form.items,
                socials: this.form.socials,
                memo: this.form.memo,
            };

            if (this.action === "edit") {
                payload._id = this.form._id;
            }
            
            return payload;
        },

        async handleAddRateCard() {
            try {
                this.formLoading = true;

                const payload = this.buildPayload();
                await this.$store.dispatch("ratecards/addRateCard", payload);

                this.closeCard();

                // don't await
                this.$store.dispatch("app/fetchActivities", { refresh: true });
                
                this.$toast.success("New card added");
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;

                const msg = errMsg.includes("duplicate") ? "You already have a rate card with that title" : errMsg;
                this.$toast.error(msg);
            } finally {
                this.formLoading = false;
            }
        },

        async handleEditRateCard() {
            try {
                if (!this.form?._id) return;

                this.formLoading = true;

                const payload = this.buildPayload();
                await this.$store.dispatch("ratecards/updateRateCard", payload);
                
                this.$toast.success("Card updated");

                this.closeCard();
            } catch (error) {
                const errMsg = error?.response?.data?.error || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.formLoading = false;
            }
        },

        async handleDeleteCards() {
            try {                
                if (!this.selectedItems.length) return;
                
                this.deleteLoading = true;

                await this.$store.dispatch("ratecards/deleteRateCards", this.selectedItems);

                // don't await
                this.$store.dispatch("app/fetchActivities", { refresh: true });
                

                this.selectedItems = [];
                this.$toast.success("Cards deleted");
                this.showDeleteModal = false;
            } catch (error) {
                const errMsg = error?.response?.data?.error || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.deleteLoading = false;
            }
        },

        async handleSubmit() {
            const isInvalid = this.validateItems();

            if (isInvalid) {
                this.$toast.error("Fill in the required service items");
                return;
            }

            if (this.action === "add") {
                await this.handleAddRateCard();
            } else {
                await this.handleEditRateCard();
            }
        },
        
        upgradeAccount() {
            this.$router.push("/dashboard/settings/billings-and-plans");
        },

        computeBillingType(item) {
            let computedType = "";

            // if (item.billableHours >  && item.type === )

            switch (item.type) {
            case "hourly":
                return "hours";

            case "weekly":
                return "week(s)";

            case "monthly":
                return "month(s)";

            case "yearly":
                return "year";
            
            default:
                return "hours";
            }
        },

        handleFileUpload(event) {
            const file = event?.target?.files[0];

            if (file) {
                this.parseCSVFile(file);
            }
        },

        parseCSVFile(file) {
            Papa.parse(file, {
                header: true,
                complete: this.processCSVData,
                error: this.handleParseError,
            });
        },

        onUploadProgress(event) {
            this.uploadProgress = Math.round((event.loaded * 100) / event.total);
        },

        async processCSVData(results) {
            try {
                this.uploadErr = false;

                const rateCards = results.data;
                
                this.uploadContent = rateCards;

                const formData = new FormData();
                formData.append("rateCards", JSON.stringify(rateCards));
                
                await this.$store.dispatch("ratecards/uploadRateCardSheet", { formData, progressCB: this.onUploadProgress });
                
                this.$toast.success("Rates uploaded successfully");

                this.importType = "";
                this.uploadProgress = 0;
                this.showUploadModal = false;

                await this.handleFetchRateCards({ refresh: true });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                
                this.$toast.error(errMsg);
                this.uploadProgress = 0;
                this.uploadErr = true;
            }
        },

        handleParseError(error) {
            console.error("Error parsing CSV file", error);
        },

        async handleFetchClients(params) {
            try {
                this.fetchClientsLoadingErr = false;
                this.fetchClientsLoading = true;

                const pageQueryObj = {
                    page: 1,
                    type: params?.type || "all",
                    name: params?.name || "",
                };
    
                const buildResult = assembleQueryList(pageQueryObj);
    
                await this.$store.dispatch("clients/fetchClients", { params: buildResult, refresh: params?.refresh });
            } catch(error) {
                this.fetchClientsLoadingErr = true;
            } finally {
                this.fetchClientsLoading = false;
            }
        },

        handleSelectClient(item) {
            this.client = item;
        },

        async sendEmail() {
            try {    
                if (!this.client._id || !this.form._id) {
                    this.$toast.error("Select a client");
                    return;
                }
                
                this.formLoading = true;

                const payload = {
                    rateCard: {
                        title: this.form.title,
                        description: this.form.description,
                        billableHours: this.form.billableHours,
                        currency: this.form.currency,
                        amount: this.form.amount,
                        billingRate: this.form.billingRate,
                        rateType: this.form.rateType,
                        _id: this.form._id,
                    },
                    client: {
                        _id: this.client._id,
                        name: this.client.name,
                        companyName: this.client.companyName,
                        email: this.client.email,
                        ccEmails: this.client.ccEmails,
                    },
                };
    
                await this.$store.dispatch("ratecards/sendClientRateCard", payload);

                this.$toast.success("Follow-up email sent!");
                this.resetCloseSendToClient();
            } catch (error) {
                const errMsg = error?.response?.data?.message || error.message;
                this.$toast.error(errMsg);
            } finally {
                this.formLoading = false;
            }
        },
    },
};
</script>

<style lang="scss">
    .dropdown-rates {
        .dropdown--item {
            text-transform: capitalize;
        }
    }

    .dropdown-currency {
        .dropdown--item {
            text-transform: uppercase;
        }
    }
</style>