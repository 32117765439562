<template>
    <div>
        <div class="sidebar">
            <nav class="nav sidebar__nav" :class="[sidebarWidth === '30px' ? 'collapse-nav' : 'expand-nav', theme]">
                <div class="sidebar__section">
                    <div class="sidebar__section__content h--100">
                        <div class="sidebar__nav__brand" :class="[!isCollapse && showSettingsSidebar ? 'justify-content-start' : 'justify-content-end']">
                            <template v-if="!isCollapse && showSettingsSidebar">
                                <div class="flex align-items-center pointer" @click="goBackToMainSidebar">
                                    <icon-svg name="chevron-left" :width="'16px'" />   
                                    <span class="sidebar__section__title text--base text--color-gray-600 mb--0">Settings</span>
                                </div>
                            </template>

                            <template v-else>
                                <div class="sidebar__nav__toggle" @click="$emit('toggle')">
                                    <icon-svg 
                                        fill="rgba(125, 136, 157)" class="mr--0" 
                                        :name="!isCollapse ? 'chevrons-left' : 'chevrons-right'" width="16px"
                                    />  
                                </div>
                            </template>
                        </div>

                        <div class="sidebar__section__links">
                            <template v-if="!isCollapse">
                                <template v-if="!showSettingsSidebar">
                                    <div class="sidebar-content-one">
                                        <reports-navigation :active-name="mainPath" />
                                        <operations-navigation 
                                            :active-name="mainPath" 
                                        />
                                        <manage-navigation :active-name="mainPath" />
                                    </div>

                                    <div class="sidebar-content-settings" :class="{ 'disabled': fetchProjectCountLoading }">                                    
                                        <template v-if="fetchProjectCountErr">
                                            <div class="sidebar__award__rank justify-content-center" @click="handleCompletedProjectCount">
                                                <p class="sidebar__award__score__desc">
                                                    Reload count
                                                </p>
                                                <div class="sidebar__award__score__desc ml--5">
                                                    <icon-svg fill="#64748B" name="reload" :width="'16px'" />
                                                </div>
                                            </div>
                                        </template>
    
                                        <template v-else>
                                            <div class="sidebar__award__rank" :class="{ 'justify-content-center': fetchProjectCountLoading }" @click="$emit('openRank')">
                                                <template v-if="fetchProjectCountLoading">
                                                    <PageSpinner :is-page="false" />
                                                </template>
                                                <template v-else>
                                                    <div class="sidebar__award__icon">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                            fill="none"
                                                        >
                                                            <path d="M21 4H18V3C18 2.73478 17.8946 2.48043 17.7071 2.29289C17.5196 2.10536 17.2652 2 17 2H7C6.73478 2 6.48043 2.10536 6.29289 2.29289C6.10536 2.48043 6 2.73478 6 3V4H3C2.73478 4 2.48043 4.10536 2.29289 4.29289C2.10536 4.48043 2 4.73478 2 5V8C2 12.31 3.799 14.91 6.819 15.012C7.26111 15.7718 7.86475 16.4252 8.58722 16.926C9.3097 17.4267 10.1334 17.7627 11 17.91V20H9V22H15V20H13V17.91C13.8664 17.7621 14.6899 17.4259 15.4122 16.9252C16.1346 16.4245 16.7384 15.7714 17.181 15.012C20.201 14.91 22 12.31 22 8V5C22 4.73478 21.8946 4.48043 21.7071 4.29289C21.5196 4.10536 21.2652 4 21 4ZM4 8V6H6V12.83C4.216 12.078 4 9.299 4 8ZM12 16C9.794 16 8 14.206 8 12V4H16V12C16 14.206 14.206 16 12 16ZM18 12.83V6H20V8C20 9.299 19.784 12.078 18 12.83Z" fill="#64748B" />
                                                        </svg>
                                                    </div>
                                                    <div class="sidebar__award__score">
                                                        <p class="sidebar__award__score__title">
                                                            No rank
                                                        </p>
                                                        <p class="sidebar__award__score__desc">
                                                            Projects completed: <span class="text--color-primary text--bold">{{ completedProjectCount }}</span>
                                                        </p>
                                                    </div>
                                                </template>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                                <template v-else>
                                    <settings-navigation :active-name="mainPath" />
                                </template>
                            </template>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
import { IconSvg } from "../index";
import PageSpinner from "../loaders/PageSpinner.vue";
import { 
    ManageNavigation,
    ReportsNavigation,
    OperationsNavigation,
    SettingsNavigation,
} from "./index";

export default {
    name: "SideBarLayout",
    
    components: {
        IconSvg,
        OperationsNavigation,
        ManageNavigation,
        ReportsNavigation,
        SettingsNavigation,
        PageSpinner,
    },

    props: {
        isCollapse: {
            type: Boolean,
            default: false,
        },

        sidebarWidth: {
            type: String,
            default: "",
        },

        showSettingsSidebar: {
            type: Boolean,
            default: false,
        },
    },

    emits: ["reload-count", "toggle", "openRank"],
    
    data() {
        return {
            showOnboardingModal: false,
            fetchProjectCountLoading: false,
            fetchProjectCountErr: false,

        };
    },

    computed: {
        mainPath() {
            return this.$store.getters["app/currentRoute"]?.main;
        },

        paths() {
            return this.$store.getters["app/paths"];
        },

        completedProjectCount() {
            return this.$store.getters["app/completedProjectCount"];
        },

        user() {
            return this.$store.getters["auth/profile"] || {};
        },

        theme() {
            return this.$store.getters["app/userSettings"]?.appearance?.theme;
        },
    },
    
    watch:{
        "$route": "getRouteTwo",
    }, 

    async mounted() {
        this.getRouteTwo();
        await this.handleCompletedProjectCount();
    },

    methods: {
        getRouteTwo() {
            const currentUrlPath = window.location.pathname;
            this.$store.dispatch("app/setCurrentRouteTwo", currentUrlPath);
        },

        goBackToMainSidebar() {
            const lastVisitedStoredRoute = localStorage.getItem("lastVisited");

            if (lastVisitedStoredRoute) {
                this.$router.push(`${lastVisitedStoredRoute}`);
                return;
            } else {
                this.$router.go(-1);
            }
        },

        async handleCompletedProjectCount() {
            try {
                this.fetchProjectCountErr = false;
                this.fetchProjectCount = true;
                await this.$store.dispatch("app/fetchCompletedProjectCount", { refresh: true });
            } catch (error) {
                this.fetchProjectCountErr = true;
            } finally {
                this.fetchProjectCount = false;
            }
        },
    },
};
</script>