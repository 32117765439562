<template>
    <div class="empty--wrap" v-bind="$attrs">
        <div class="empty--image">
            <svg-asset
                v-if="state === 'error' || state === 'error_custom'" :fill="fill"
                name="error" width="50px" height="60px"
            />
            <svg-asset
                v-else-if="state === 'no_result'"
                name="search" width="50px" height="60px" fill="#BDBDBD"
            />
            <slot v-else-if="isCustomIcon" name="custom-icon" />
            
            <svg-asset
                v-else
                :name="iconName"
                :width="width"
                :height="height"
                :fill="fill"
            />
        </div>

        <div class="empty--text">   
            <div>
                <h2 class="empty--title mb--5">
                    {{ computeTextData().title }}
                </h2>
                <p class="empty--subtitle error">
                    {{ computeTextData().subtitle }}
                </p>
            </div>
            <div class="flex justify-content-center mt--15">
                <template v-if="state === 'error'">
                    <BaseButton 
                        label="Reload page" icon-name="reload" icon-width="16px" 
                        size="sm" prefix-icon @submit="handleSubmit"
                    />
                </template>
                
                <slot v-else name="button" />
            </div>
        </div>
    </div>
</template>

<script>
import SvgAsset from "../../ui/icons/SvgAssets.vue";
import BaseButton from "../../ui/buttons/BaseButton.vue";

export default {
    name: "EmptyPage",
    
    components: { SvgAsset, BaseButton },

    props: {
        isCustomIcon: {
            type: Boolean,
            default: false,
        },
        state: {
            type: String,
            default: "",
            validator: (value) => {
                return ["error", "error_custom", "empty_custom", "no_result", "empty"].includes(value);
            },
        },
        subtitle: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "",
        },
        iconName: {
            type: String,
            default: "",
        },
        width: {
            type: String,
            default: "60px",
        },
        height: {
            type: String,
            default: "60px",
        },
        fill: {
            type: String,
            default: "",
        },
        handleSubmit:  {
            type: Function,
            default: () => {},
        },
    },

    methods: {
        computeTextData() {                        
            switch (this.state) {
            case "error":
                return {
                    title: " Unable to load page",
                    subtitle: "Something happened while trying to fetching data for this page",
                };
            case "no_result":
                return {
                    title: " No result found",
                    subtitle: "Maybe try a different search keyword?",
                };
            case "empty_custom":
            case "error_custom":
                return {
                    title: this.title,
                    subtitle: this.subtitle,
                };
            case "empty":
                return {
                    title: `You have no ${this.type}s `,
                    subtitle: `Your ${this.type}s will show up here when you create them.`,
                };
            default:
                return {
                    title: `You have no ${this.type}s`,
                    subtitle: `Your ${type}s will show up here when you create them.`,
                };
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .empty {
        &--wrap {
            max-width: 550px;
            margin: 6rem auto;
        }

        &--image {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        &--text {
            text-align: center;
            margin-top: 1.25rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &--title {
            font-size: 1.25rem;
            color: #1a1f36;
            font-weight: 600;
        }
        
        &--subtitle {
            color: #697386;
            font-size: 0.875rem;
            max-width: 390px;

            &.error {
                max-width: 440px;
            }
        }
    }  
</style>