<template>
    <div class="pl--0 pr--0 pt--10 client__item__details__sub">
        <template v-if="fetchProjectLoading">
            <PageSpinner />
        </template>

        <template v-else-if="fetchProjectErr">
            <EmptyPage state="error" :handle-submit="handleFetchClientProjects" />
        </template>

        <template v-else>
            <div class="flex align-items-center justify-content-between width-100" :class=" { 'disabled': checkUpgradeLoading && checkUpgradeFields.projects } ">
                <div v-if="projects.length" class="list--count">
                    <p class="text--base">
                        {{ projects.length }} {{ projects.length === 1 ? 'project' : 'projects' }}
                    </p>
                </div>

                <div class="filter__actions--list" :class="{ 'disabled': checkUpgradeLoading && checkUpgradeFields.projects }">
                    <template v-if="showActionBtns">
                        <DropDownButton 
                            variant="default" inner-style="primary"
                            label="+ Add project" size="sm"
                        >
                            <template #dropdown-content>
                                <li class="dropdown-item pointer flex align-items-center" @click="initiateCreate('existing')">
                                    <IconSvg name="folder-open" width="16px" fill="#64748b" />
                                    <span class="text--xs text--color-gray-500 ml--5">Add an existing project</span>
                                </li>
                                <li class="dropdown-item text--sm pointer flex align-items-center" @click="initiateCreate('create')">
                                    <IconSvg name="folder" width="16px" fill="#64748b" />
                                    <span class="text--xs text--color-gray-500 ml--5"> Add new project</span>
                                </li>
                            </template>
                        </DropDownButton>
                        <!-- <ImportButton label="Import" icon-name="import" width="16px" @select="setImportType" /> -->
                    </template>
                </div>
            </div>

            <div class="w--100 mt--40">
                <template v-if="!projects.length">
                    <EmptyPage
                        state="empty_custom" icon-name="project" 
                        width="60px" height="60px" type="project"
                        title="No projects" subtitle="You have no projects with this client yet. Select an existing project or create a new one to get things rolling."
                    >
                        <template #button>
                            <DropDownButton 
                                variant="default" inner-style="primary"
                                label="+ New project" size="sm"
                            >
                                <template #dropdown-content>
                                    <li class="dropdown-item pointer flex align-items-center" @click="initiateCreate('existing')">
                                        <IconSvg name="folder-open" width="16px" fill="#64748b" />
                                        <span class="text--xs text--color-gray-500 ml--5">Add an existing project</span>
                                    </li>
                                    <li class="dropdown-item text--sm pointer flex align-items-center" @click="initiateCreate('create')">
                                        <IconSvg name="folder" width="16px" fill="#64748b" />
                                        <span class="text--xs text--color-gray-500 ml--5"> Create new project</span>
                                    </li>
                                </template>
                            </DropDownButton>
                        </template> 
                    </EmptyPage>
                </template>

                <template v-else>
                    <CustomTable
                        :columns="projectHeaders" :data="projects"
                        router-name="project-details-view" custom-action
                        row-type="btns" :is-checkbox="false"
                        @onRowClick="goToProjectDetailsPage"
                    >
                        <template #rows="data">
                            <td>
                                <p class="text--ellipsis title">
                                    {{ data.title || "-" }}
                                </p>
                            </td>
                            <td>
                                <Status type="project" :value="data.status" />
                            </td>
                            <td class="text--capitalize">
                                {{ $format_date(data.deadline) || "-" }}
                            </td>
                            <td class="text--capitalize">
                                {{ data.projectType || "-" }}
                            </td>
                            <td>{{ $format_date(data.createdAt) || "-" }}</td>
                        </template>

                        <template #customActionBtns="data">
                            <li class="flex align-items-center dropdown-item dropdown--item pointer" @click="openProject(data._id)">
                                <IconSvg name="show" fill="#667085" width="16px" />
                                <span class="ml--10 text--sm text--color-gray-500">View</span>
                            </li>
                            <li class="flex align-items-center dropdown-item dropdown--item pointer" @click="startDeleteProject(data._id)">
                                <IconSvg name="delete" fill="#e04060" width="16px" />
                                <span class="ml--10 text--sm text--color-warning">Remove from client</span>
                            </li>
                        </template>
                    </CustomTable>

                    <template v-if="pageData.totalPages > 1">
                        <Pagination :pagination-data="pageData" />
                    </template>
                </template>
            </div>
        </template>

        <CreateProjectModal 
            :show-modal="showCreateProjectModal" :loading="projectLoading"
            @close="showCreateProjectModal = false" @complete="createProject"
        />

        <FileUploadModal 
            :show="showUploadModal" 
            :mime-types="importType === 'csv' ? 'text/csv' : 'text/json'"
            :file-content="uploadContent" upload-type="projects"
            :is-error="uploadErr" :headers="projectUploadHeaders"
            :progress-value="uploadProgress" @cancel="showUploadModal = false" @update:files="handleFileUpload" 
        />

        <UpgradeAccountModal 
            :show-modal="showUpgradeModal && checkUpgradeFields.projects" type="projects" @cancel="closeUpgradeModal"
        />

        <MainModal 
            :show-modal="showExistingModal"
            size="sm" title="Select an existing project" @close="closeExistingProjectModal"
        >
            <template #body>
                <div class="">
                    <form class="pb--5" @submit.prevent="">
                        <CustomDropdown 
                            id="projectsDropdown" v-model="form.project" :options="existingProjects"
                            list-type="projects" index-label="title" :loading="searchProjectLoading" fetch-list-on-show
                            @search="searchProjects" @select="v => form.project = v" @fetch-list-show="searchProjects"
                        />
                    </form>
                </div>
            </template>

            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <Base-Button
                        label="Cancel" size="sm" variant="outline" :disabled="projectLoading"
                        class="mr--5" @submit="closeExistingProjectModal"
                    />
                    <Base-Button 
                        label="Add to client" size="sm" :loading="projectLoading" 
                        type="submit" @submit="addExistingProject" 
                    />
                </div>
            </template>
        </MainModal>

        <ConfirmDeletionTwo
            type="project" custom-msg 
            :show-modal="showDeleteModal" :loading="deleteLoading" 
            @cancel="showDeleteModal = false" @delete="removeProject"
        >
            <template #custom-msg>
                <p class="text--sm">
                    Are you sure you want the project removed from this client?
                </p>
            </template>
        </ConfirmDeletionTwo>
    </div>
</template>

<script>
import {
    EmptyPage, MainModal,
    PageSpinner, DropDownButton,
    BaseButton, IconSvg,
    CustomTable, CustomDropdown,
    Status, UpgradeAccountModal,
    Pagination, FileUploadModal,
    CreateProjectModal, ConfirmDeletionTwo,
} from "@/components/ui/index";
import { projectUploadHeaders, assembleQueryList } from "@/utils/helpers";

export default {
    name: "ClientProjects",

    components: {
        CustomTable, IconSvg, DropDownButton,
        EmptyPage, FileUploadModal, MainModal,
        PageSpinner, CustomDropdown,
        BaseButton, CreateProjectModal, ConfirmDeletionTwo,
        Status, Pagination, UpgradeAccountModal,
    },

    data() {
        return {
            projectUploadHeaders,
            fetchProjectLoading: false,
            fetchProjectErr: false,
            fetchProjectErrMsg: "",
            projectHeaders: [
                { id: "title", accessorKey: "title"},
                { id: "status", accessorKey: "status" },
                { id: "due", accessorKey: "due" },
                { id: "Payment Status", accessorKey: "projectType" },
                { id: "Date added", accessorKey: "createdAt" },
            ],
            showCreateProjectModal: false,
            projectLoading: false,
            isSearched: false,
            deleteLoading: false,
            projectIdToDelete: "",
            importType: "",
            showUploadModal: false,
            uploadProgress: 0,
            uploadContent: [],
            uploadErr: false,
            form: {
                project: {},
            },
            showExistingModal: false,
            searchProjectLoading: false,
            existingProjects: [],
            showDeleteModal: false,
        };
    },

    computed: {
        pageData() {
            return this.$store.getters["clients/clientProjects"]?.pageData;
        },

        projects() {
            return this.$store.getters["clients/clientProjects"]?.list;
        },

        showActionBtns() {
            if (this.pageData?.total) {
                return true;
            } else if (!this.pageData?.total && this.isSearched) {
                return true;
            } else {
                return false;
            }
        },

        completedProjectCount() {
            return this.$store.getters["app/completedProjectCount"];
        },

        showUpgradeModal() {
            return this.$store.getters["app/showUpgradeModal"];
        },

        checkUpgradeLoading() {
            return this.$store.getters["app/checkUpgradeLoading"];
        },

        checkUpgradeFields() {
            return this.$store.getters["app/checkUpgradeFields"];
        },
    },

    async mounted() {
        await this.handleFetchClientProjects();
    },

    methods: {
        async initiateCreate(val = "create") {
            try {                
                await this.$store.dispatch("app/checkUpgradeStatus", "projects");

                if (val === "create") {
                    this.showCreateProjectModal = true;
                }

                if (val === "existing") {
                    this.showExistingModal = true;
                }
            } catch (error) {
               
            }
        },

        closeUpgradeModal() {
            this.$store.dispatch("app/closeUpgradeModal", "projects");
        },

        closeExistingProjectModal() {
            this.showExistingModal = false;
            this.form.project = {};
        },

        async handleFetchClientProjects() {
            try {
                this.fetchProjectErr = false;
                this.fetchProjectLoading = true;

                const id = this.$route.params.id;

                const params = {
                    page: this.pageData?.currentPage,
                };

                await this.$store.dispatch("clients/getClientProjects", { id, params });
            } catch (error) {
                this.fetchProjectErr = true;
                const errMsg = error?.response?.data?.message || error?.message;
                
                this.fetchProjectErrMsg = errMsg;
            } finally {
                this.fetchProjectLoading = false;
            }
        },

        async searchProjects(query) {
            try {
                this.searchProjectLoading = true;
                
                const params = {
                    page: 1,
                    title: query,
                    type: "projects",
                    ...query,
                };

                const res = await this.$store.dispatch("clients/getClientProjects", { 
                    id: this.$route.params.id, 
                    params, 
                    external: true, 
                });
                
                this.existingProjects = res;
            } catch (error) {
                // console.log({ error });
            } finally {
                this.searchProjectLoading = false;
            }
        }, 

        goToProjectDetailsPage(row) {
            if (!row._id) return;
            this.$router.push(`/dashboard/projects/${row._id}`);
        },

        async addExistingProject() {
            try {                
                this.projectLoading = true;

                await this.$store.dispatch("clients/addExistingroject", { 
                    clientId: this.$route.params.id, 
                    payload: this.form.project, 
                });   

                this.$toast.success("Project added to client");
                this.closeExistingProjectModal();
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;                
                this.$toast.error(errMsg);
            } finally {
                this.projectLoading = false;
            }
        },

        async createProject({ payload, resetFn }) {
            try {                
                this.projectLoading = true;

                const updatedPayload = { ...payload, clientIds: [this.$route.params.id] };
                const resp = await this.$store.dispatch("clients/addNewProject", updatedPayload);   
            
                this.$store.dispatch("project/addProject", { payload, external: true }); // update global project list asynchronously
                this.$store.dispatch("app/fetchActivities", { refresh: true });        
                
                if (resp.status === "completed") {
                    await this.$store.dispatch("app/fetchCompletedProjectCount", { refresh: true, manualUpdate: true });
                }
                
                if ([5, 10, 25, 50].includes(this.completedProjectCount)) {
                    this.$store.dispatch("app/toggleRankConfetti", true);
                }

                if (resetFn) {
                    resetFn();
                }

                this.showCreateProjectModal = false;
                this.$toast.success("Project added to client");
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;                
                this.$toast.error(errMsg);
            } finally {
                this.projectLoading = false;
            }
        },

        startDeleteProject(id) {
            if (!id) {
                this.$toast.error("Missing project id");
            }

            this.currentProjectId = id;
            this.showDeleteModal = true;
        },

        async removeProject() {
            try {                
                this.deleteLoading = true;

                await this.$store.dispatch("clients/deleteProject", { 
                    clientId: this.$route.params.id, 
                    projectId: this.currentProjectId, 
                });   

                this.showDeleteModal = false;
                this.$toast.success("Project removed from client");
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;                
                this.$toast.error(errMsg);
            } finally {
                this.currentProjectId = "";
                this.deleteLoading = false;
            }
        },

        openProject(id) {
            return this.$router.push(`/dashboard/projects/${id}`);
        },

        async setImportType(val) {
            try {
                await this.$store.dispatch("app/checkUpgradeStatus", "projects");
                this.importType = val;
            } catch (error) {
                
            }
        },

        handleFileUpload(files) {
            const file = event?.target?.files[0];

            if (file) {
                this.parseCSVFile(file);
            }
        },

        parseCSVFile(file) {
            Papa.parse(file, {
                header: true,
                complete: this.processCSVData,
                error: this.handleParseError,
            });
        },

        onUploadProgress(event) {
            this.uploadProgress = Math.round((event.loaded * 100) / event.total);
        },

        async processCSVData(results) {
            try {
                this.uploadErr = false;

                const projects = results.data;
                this.uploadContent = projects;

                const formData = new FormData();
                formData.append("projects", JSON.stringify(projects));
                
                await this.$store.dispatch("project/uploadList", { formData, progressCB: this.onUploadProgress });
                
                this.$toast.success("File uploaded successfully");

                this.importType = "";
                this.uploadProgress = 0;
                this.showUploadModal = false;
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                
                this.$toast.error(errMsg);
                this.uploadProgress = 0;
                this.uploadErr = true;
            }
        },

        handleParseError(error) {
            console.error("Error parsing CSV file", error);
        },
    },
};
</script>

<style lang="scss" scoped>
    .title {
        max-width: 170px;
    }
</style>