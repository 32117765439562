<template>
    <div>
        <MainModal 
            :show-modal="showModal" 
            size="sm" :title="`Delete ${type}`" @close="$emit('cancel')"
        >
            <template #body>
                <div>
                    <template v-if="!customMsg">
                        <p v-if="items && items.length" class="text--sm">
                            {{ 
                                `Are you sure you want to delete these ${items.length} ${type}s?`
                            }}
                        </p>
                        <p v-else class="text--sm">
                            {{ `Are you sure you want to delete this ${type}?` }}
                        </p>
                    </template>
                    <template v-else>
                        <slot name="custom-msg" />
                    </template>
                </div>
            </template>
            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <BaseButton 
                        class="mr--5" label="Cancel" 
                        variant="outline" size="sm" inner-style="secondary"
                        @submit="$emit('cancel')"
                    />
                    <BaseButton 
                        label="Delete" variant="warning" 
                        :loading="loading" size="sm" 
                        @submit="$emit('delete')" 
                    />
                </div>
            </template>
        </MainModal>
    </div>
</template>

<script>
import BaseButton from "../buttons/BaseButton.vue";
import MainModal from "./MainModal.vue";

export default {
    name: "ConfirmDeletion",

    components: { MainModal, BaseButton },

    props: {
        type: {
            type: String,
            default: "",
        },
        loading: {
            type: Boolean,
            default: false,
        },
        items: {
            type: Array,
            default: () => [],
        },
        showModal: {
            type: Boolean,
            default: false,
        },
        customMsg: {
            type: Boolean,
            default: false,
        },
    },

    emits: ["cancel", "delete"],
};
</script>