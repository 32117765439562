<template>
    <div class="flex justify-content-center align-items-center" :class="{ 'mt--40 mb--45': isPage }" v-bind="$attrs">
        <div 
            class="spinner-border" 
            :class="[size]" role="status" 
        >
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "PageSpinner",
    props: {
        isPage: { type: Boolean, default: true },
        size: { 
            type: String, 
            default: "md",
            validator: (value) => {
                return ["sm", "md", "lg"].includes(value);
            },
        },
    },
};
</script>

<style scoped lang="scss">
    .spinner-border {
        color: var(--brand-500);
        
        &.sm {
            width: 1rem !important;
            height: 1rem !important;
            border-width: 1px !important;
        }

        .base_btn {
            color: #64748b !important;
        }
    }
</style>