<template>
    <div class="invoice__wrap" :class="{ 'disabled': isPageDisabled }">
        <div>
            <template v-if="pageLoading">
                <PageSpinner />
            </template>

            <template v-else-if="pageLoadErr">
                <EmptyPage state="error" :handle-submit="handleFetchInvoice" />
            </template>

            <template v-else-if="unableToLoadPage">
                <EmptyPage state="error" :handle-submit="backToInvoicesPage" />
            </template>

            <template v-else>
                <div>
                    <div class="invoice__details__row">
                        <div class="invoice__details__row__left">
                            <div class="invoice__details__header">  
                                <div class="row__item flex align-items-center pb--0">
                                    <FormLabel :title="`Invoice #${invoice.invoiceNo || 'N/A'}`" class="mb--0" label-type="title" />
                                    <Status type="invoice" :value="invoice.status" class="ml--10" />
                                </div>
                                <div class="relative flex align-items-center">
                                    <div v-if="!['fully paid', 'draft'].includes(invoice.status)" class="mr--5 relative pb--0">
                                        <BaseButton 
                                            label="Update status" size="sm" variant="ghost" inner-style="primary"
                                            @submit="openStatusModal"
                                        />
                                    </div>
        
                                    <div class="dropdown" :class="{ 'disabled': downloadLoading }">
                                        <div id="invoiceDetailsDropDown" class="pointer" data-bs-toggle="dropdown" aria-expanded="false">
                                            <div class="invoice__details__menu">
                                                <IconSvg name="dots-horizontal-rounded" fill="#667085" width="16px" />
                                            </div>
                                        </div>
        
                                        <ul class="dropdown-menu" aria-labelledby="invoiceDetailsDropDown">
                                            <li v-if="invoice.status === 'draft'" class="dropdown-item pointer" @click="goToEditPage">
                                                <div class="flex align-items-center text--color-gray-500">
                                                    <icon-svg name="edit" fill="#667085" />
                                                    <span class="ml--5 text--sm">Edit Invoice</span>
                                                </div>
                                            </li>
        
                                            <li class="dropdown-item pointer" @click="download">
                                                <div class="flex align-items-center text--color-gray-500">
                                                    <icon-svg name="download" fill="#667085" />
                                                    <span class="ml--5 text--sm">Download as PDF</span>
                                                </div>
                                            </li>
        
                                            <li v-if="!['draft', 'failed'].includes(invoice.status)" class="dropdown-item pointer" @click="handleDuplicateInvoice">
                                                <div class="flex align-items-center text--color-gray-500">
                                                    <icon-svg name="duplicate" fill="#667085" />
                                                    <span class="ml--5 text--sm">Duplicate invoice</span>
                                                </div>
                                            </li>

                                            <li><hr class="dropdown-divider"></li>
        
                                            <li class="dropdown-item pointer" @click="showDeleteModal = true">
                                                <div class="flex align-items-center text--color-gray-500">
                                                    <icon-svg name="delete" fill="#e04060" />
                                                    <span class="ml--5 text--sm text--color-warning">Delete invoice</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="invoice__details__item pb--0">
                                <div class="invoice__details__group">
                                    <div>
                                        <div class="flex align-items-center mr--10">
                                            <IconSvg name="money" width="1rem" fill="#64748B" />
                                            <FormLabel title="Currency" class="mb--0 ml--5" label-type="menu" />
                                        </div>
                                        <p class="text--upper invoice__detail text--sm mt--5">
                                            {{ invoice.currency }}
                                        </p>
                                    </div>
                                </div>

                                <div class="invoice__details__group">
                                    <div>
                                        <div class="flex align-items-center mr--10">
                                            <IconSvg name="tag" width="1rem" fill="#64748B" />
                                            <FormLabel title="Amount" class="mb--0 ml--5" label-type="menu" />
                                        </div>
                                        <p class="text--upper invoice__detail text--sm mt--5">
                                            {{ $format_number(invoice.totalAmount) }}
                                        </p>
                                    </div>
                                </div>

                                <div v-if="invoice.status !== 'draft'" class="invoice__details__group">
                                    <div>
                                        <div class="flex align-items-center mr--10">
                                            <IconSvg name="receipt" width="1rem" fill="#64748B" />
                                            <FormLabel title="Date Issued" class="mb--0 ml--5" label-type="menu" />
                                        </div>
                                        <p class="text--upper invoice__detail text--sm mt--5">
                                            {{ $format_date(invoice.dueIssued) }}
                                        </p>
                                    </div>
                                </div>

                                <div v-if="!['draft', 'fully paid'].includes(invoice.status)" class="invoice__details__group">
                                    <div>
                                        <div class="flex align-items-center mr--10">
                                            <IconSvg name="alert" width="1rem" fill="#64748B" />
                                            <FormLabel title="Due Date" class="mb--0 ml--5" label-type="menu" />
                                        </div>
                                        <p class="text--upper invoice__detail text--sm mt--5">
                                            {{ $format_date(invoice.dueDate) }}
                                        </p>
                                    </div>
                                </div>

                                <div class="invoice__details__group">
                                    <div>
                                        <div class="flex align-items-center mr--10">
                                            <IconSvg name="save" width="1rem" fill="#64748B" />
                                            <FormLabel title="Date Created" class="mb--0 ml--5" label-type="menu" />
                                        </div>
                                        <p class="text--upper invoice__detail text--sm mt--5 text--capitalize">
                                            {{ $format_date(invoice.createdAt) }}
                                        </p>
                                    </div>
                                </div>

                                <div v-if="!['draft', 'fully paid'].includes(invoice.status)" class="invoice__details__group">
                                    <div>
                                        <div class="flex align-items-center mr--10">
                                            <IconSvg name="hour-glass" width="1rem" fill="#64748B" />
                                            <FormLabel title="Amount Due" class="mb--0 ml--5" label-type="menu" />
                                        </div>
                                        <p class="text--upper invoice__detail text--sm mt--5 text--capitalize">
                                            {{ $format_date(invoice.dueDate) }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="invoice__details__item align-items-baseline pb--0">
                                <div class="invoice__details__group">
                                    <div>
                                        <div class="flex align-items-center mr--10 mb--5">
                                            <IconSvg name="envelope" width="1rem" fill="#64748B" />
                                            <FormLabel title="CC Emails" class="mb--0 ml--5" label-type="menu" />
                                        </div>

                                        <template v-if="!splitCCEmails(invoice.meta.ccEmails).length">
                                            <span class="text--sm text--color-gray-400">
                                                -
                                            </span>
                                        </template>

                                        <template v-else>
                                            <p v-for="email in splitCCEmails(invoice.meta.ccEmails)" :key="email" class="text--sm text--color-gray-400 mb--5 mt--5">
                                                {{ email || '-' }}
                                            </p>
                                        </template>
                                    </div>
                                </div>

                                <div class="invoice__details__group">
                                    <div>
                                        <div class="flex align-items-center mr--10">
                                            <IconSvg name="alarm" width="1rem" fill="#64748B" />
                                            <FormLabel title="Due Date" class="mb--0 ml--5" label-type="menu" />
                                        </div>
                                        <p class="text--sm text--color-gray-400 mb--5 mt--5">
                                            {{ $format_date(invoice.dueDate) || '-' }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="invoice__details__item pb--0">
                                <div class="invoice__details__group w--100">
                                    <div>
                                        <div class="flex align-items-center mr--10">
                                            <IconSvg name="notes" width="1rem" fill="#64748B" />
                                            <FormLabel title="Memo" class="mb--0 ml--5" label-type="menu" />
                                        </div>
                                        <p class="invoice__details--text text--sm">
                                            {{ invoice.memo || '-' }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="mt--20">
                                <div class="col-12 mb--0">
                                    <FormLabel title="Invoice Item Information" class="mb--0" label-type="title" />
                                </div>

                                <div class="col-12">
                                    <div class="invoice__item mt--5">
                                        <table class="table table-light mt--20">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="pl--0">
                                                        #
                                                    </th>
                                                    <th scope="col" class="pl--0">
                                                        Description
                                                    </th>
                                                    <th scope="col" class="pl--0">
                                                        Quantity
                                                    </th>
                                                    <th scope="col" class="pl--0">
                                                        Price
                                                    </th>
                                                    <th scope="col" class="pl--0">
                                                        Unit
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="(item, index) in invoice.meta.items" :key="item._id">
                                                <tr>
                                                    <td scope="row">
                                                        {{ index + 1 }}
                                                    </td>
                                                    <td>{{ item.itemName || '-' }}</td>
                                                    <td>{{ item.itemQuantity || 0 }}</td>
                                                    <td>{{ $country.getSymbol(invoice.currency, 'currency') }}{{ $format_number(item.itemPrice) || 0 }}</td>
                                                    <td>{{ $country.getSymbol(invoice.currency, 'currency') }}{{ $format_number(item.itemUnit) || 0 }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 mt--30">
                                <div class="mb--5">
                                    <FormLabel title="Others" class="mb--0" label-type="title" />
                                </div>

                                <div class="pb--15">
                                    <div class="mt--10 justify-content-between flex align-items-center">
                                        <div class="invoice__compile--label">
                                            Subtotal:
                                        </div>
                                        <div class="invoice__compile--value text--medium text--upper">
                                            {{ $country.getSymbol(invoice.currency, 'currency') }}{{ $format_number(computedSubTotal) }}
                                        </div>
                                    </div>

                                    <div class="mt--5 justify-content-between flex align-items-center">
                                        <div class="invoice__compile--label">
                                            <!-- Tax <span v-if="taxPercentage.show">({{ taxPercentage.value }}%)</span>: -->
                                            Tax:
                                        </div>
                                        <div class="mt--10 justify-content-between flex align-items-center text--sm text--medium text--upper">
                                            {{ $country.getSymbol(invoice.currency, 'currency') }}{{ $format_number(computedTaxes) }}
                                        </div>
                                    </div>
                                    
                                    <div class=" mt--10 flex align-items-center justify-content-between">
                                        <div class="invoice__details--text text--semi--bold">
                                            Total:
                                        </div>
                                        <div class="invoice__details--text">
                                            <span class="text--semi--bold text--upper">
                                                {{ $country.getSymbol(invoice.currency, 'currency') }}{{ $format_number(invoice.totalAmount) }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="invoice__details__divider" />

                            <div class="mt--30">
                                <FormLabel title="Payment Information" class="mb--15" label-type="title" />                        
                                <div class="col-12 flex align-items-baseline">
                                    <div class="flex-1">
                                        <div class="invoice__details__group w--100">
                                            <div>
                                                <FormLabel title="Billing Method" class="mb--0" label-type="menu" />
                                                <p class="invoice__details--text text--sm">
                                                    {{ getBillingMethodName(invoice.billingMethod) || '-' }}
                                                </p>
                                            </div> 
                                        </div>

                                        <div v-if="invoice.accountDetails && invoice.accountDetails.accountName" class="invoice__details__group w--100">
                                            <div>
                                                <p class="text--sm text--semi--bold text--color-gray-600 mb--5">
                                                    Account Details
                                                </p>
                                                <p class="invoice__details--text text--sm mb--5">
                                                    Account name: {{ invoice.accountDetails.accountName || '-' }}
                                                </p>
                                                <p class="invoice__details--text text--sm mb--5">
                                                    Bank name: {{ invoice.accountDetails && invoice.accountDetails.bankName || '-' }}
                                                </p>
                                                <p class="invoice__details--text text--sm mb--5">
                                                    Account number: {{ invoice.accountDetails && invoice.accountDetails.accountNumber || '-' }}
                                                </p>
                                                <p class="invoice__details--text text--sm mb--5">
                                                    IBAN: {{ invoice.accountDetails && invoice.accountDetails.iban || '-' }}
                                                </p>
                                                <p class="invoice__details--text text--sm mb--5">
                                                    Sort code: {{ invoice.accountDetails && invoice.accountDetails.sortCode || '-' }}
                                                </p>
                                            </div>
                                        </div>
                                        
                                        <div v-if="invoice?.providerData" class="invoice__details__group w--100">
                                            <div>
                                                <FormLabel title="Provider Details" class="mb--0" label-type="title" />
                                                <p class="invoice__details--text text--sm">
                                                    {{ invoice?.providerData && invoice?.providerData?.name || '-' }}
                                                </p>
                                            </div>             
                                        </div>
                                    </div>

                                    <div class="flex-1">
                                        <div class="invoice__details__group w--100">
                                            <div>
                                                <FormLabel title="Tax Details" class="mb--0" label-type="menu" />
                                                <template v-if="invoice.meta.tax && invoice.meta.tax.length">
                                                    <ul>
                                                        <li v-for="item in invoice.meta.tax" :key="item._id" class="flex align-items-center mb--10">
                                                            <div class="flex align-items-center">
                                                                <span class="box-icon block" :class="[item.taxType]" />
                                                                <span class="sub-title ml--5">{{ mapTaxTypes[item.taxName] }} | {{ item.taxType }} | {{ computeTaxValue(item) }}</span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </template>
                                            </div>
                                        </div>

                                        <div v-if="!invoice.status !== 'draft'" class="invoice__details__group w--100">
                                            <div class="invoice__client--bio">
                                                <FormLabel title="Payment Timeline" class="mb--0" label-type="menu" />

                                                <template v-if="invoice.paymentTimeline && invoice.paymentTimeline.length">
                                                    <Timeline :items="invoice.paymentTimeline">
                                                        <template #item="item">
                                                            <p class="text--xs text--upper text--color-gray-100 text--semi--bold">
                                                                {{ invoice.currency }} {{ $format_number(item.amount) }}
                                                            </p>
                                                            <p class="text--xs text--color-normal">
                                                                {{ $format_date(item.dateAdded) }}
                                                            </p>
                                                        </template>
                                                    </Timeline>
                                                </template>
                                                <template v-else>
                                                    <div>-</div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="invoice__details__row__right">
                            <div class="invoice__details__divider pb--15">
                                <FormLabel title="Client Information" html-for="menu" label-type="title" />
                                <template v-if="client">
                                    <div class="invoice__client--bio">
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Client Name
                                        </p>
                                        <p class="sub-title invoice__details--text">
                                            {{ client?.name || '-' }}
                                        </p>
                                    </div>
                                    
                                    <div class="invoice__client--bio">
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Phone Number
                                        </p>
                                        <p class="sub-title invoice__details--text">
                                            {{ client.phoneNumber || "-" }}
                                        </p>
                                    </div>
    
                                    <div class="invoice__client--bio">
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Email
                                        </p>
                                        <p class="sub-title invoice__details--text">
                                            {{ client.email || '-' }}
                                        </p>
                                    </div>
    
                                    <div class="invoice__client--bio">
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Status
                                        </p>
                                        <p class="sub-title text--capitalize invoice__details--text">
                                            {{ client.status || '-' }}
                                        </p>
                                    </div>
    
                                    <div class="invoice__client--bio">
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Additional emails
                                        </p>
    
                                        <template v-if="!splitCCEmails(client.ccEmails).length">
                                            <span class="text--sm text--color-gray-400">
                                                -
                                            </span>
                                        </template>
    
                                        <template v-else>
                                            <p v-for="email in splitCCEmails(client.ccEmails)" :key="email" class="text--sm text--color-gray-400 mb--5 mt--5">
                                                {{ email || '-' }}
                                            </p>
                                        </template>
                                    </div>
    
                                    <div class="invoice__client--bio">
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Country
                                        </p>
                                        <p class="sub-title invoice__details--text">
                                            {{ client.country || '-' }}
                                        </p>
                                    </div>
    
                                    <div class="invoice__client--bio">
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Address
                                        </p>
                                        <p v-if="client.address" class="sub-title invoice__details--text text--capitalize">
                                            {{ client.address }}, {{ client.state }}, {{ client.country }}
                                        </p>
                                        <p v-else>
                                            -
                                        </p>
                                    </div>
                                </template>
                                <template v-else>
                                    <p class="text--color-gray-200 text--sm">
                                        Client information not available
                                    </p>
                                </template>
                            </div>

                            <div class="pt--15">
                                <FormLabel title="Project Information" html-for="menu" label-type="title" />

                                <template v-if="project">
                                    <div class="invoice__client--bio">
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Project title
                                        </p>
                                        <p class="sub-title text--capitalize invoice__details--text">
                                            {{ project.title|| '-' }}
                                        </p>
                                    </div>
    
                                    <div class="invoice__client--bio">
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Project status
                                        </p>
                                        <Status type="project" :value="project.status || '-'" />
                                    </div>
    
                                    <div class="invoice__client--bio">
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Deadline
                                        </p>
                                        <p class="sub-title invoice__details--text">
                                            {{ $format_date(project.deadline) || '-' }}
                                        </p>
                                    </div>
    
                                    <div class="invoice__client--bio">
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Estimated time of delivery
                                        </p>
                                        <p class="sub-title invoice__details--text">
                                            {{ $format_date(project.timeEstimate) || '-' }}
                                        </p>
                                    </div>
                                </template>
                                <template v-else>
                                    <p class="text--color-gray-200 text--sm">
                                        Project information not available
                                    </p>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <ConfirmDeletionTwo
            type="invoice"
            :show-modal="showDeleteModal"
            :loading="deleteLoading"
            @cancel="toggleDelete" 
            @delete="handleDeleteInvoice"
        />

        <MainModal 
            :show-modal="updateStatusModal" 
            size="sm" title="Update status" @close="updateStatusModal = false" 
        >
            <template #body>
                <div>
                    <form class="mb--10" @submit.prevent="">
                        <div class="filter-item-input">
                            <FormLabel title="Industry" />
                            <select v-model="updateForm.status" class="form-select form-select-sm text--sm text--capitalize">
                                <option v-for="(status, index) in INVOICE_UPDATE_STATUSES" :key="index" :value="$str_to_lowercase(status)">
                                    {{ status }}
                                </option>
                            </select>
                        </div>

                        <template v-if="updateForm.status === 'partially paid'">
                            <Alert type="primary" classes="text--sm mt--20">
                                <template #text>
                                    <p class="text--xs">
                                        For partially paid invoices, enter the amount paid for payment tracking.
                                    </p>
                                </template>
                            </Alert>
    
                            <div class="">
                                <FormLabel title="Amount paid" html-for="hourlyRate" info-type="required" />
                                <money
                                    v-model="updateForm.amountPaid" v-bind="config.number"
                                    class="form-control form-control-sm"
                                    spellcheck="false" @keydown.native="preventKeys"
                                />
                            </div>
                            <p v-if="paymentAdditionErrMsg" class="form__err-msg">
                                {{ paymentAdditionErrMsg }}
                            </p>
                        </template>
                    </form>
                </div>
            </template>
            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <BaseButton 
                        class="mr--5" variant="outline"
                        label="Cancel" size="sm" :disabled="updateLoading"
                        @submit="closeStatusUpdateModal"
                    />

                    <BaseButton 
                        :loading="updateLoading" :disabled="updateStatusDisabled" 
                        type="submit" size="sm" label="Update" @submit="handleStatusUpdate"
                    />
                </div>
            </template>
        </MainModal>
    </div>
</template>

<script>
import { convertStringAmountToInt } from "@/utils/helpers";
import { mapTaxTypes, paymentProviders, billingMethods } from "@/utils/dummy";
import { invoiceStatusMap, INVOICE_UPDATE_STATUSES } from "@/utils/enums";
import {
    ConfirmDeletionTwo,
    PageSpinner,
    Status,
    BaseButton,
    EmptyPage,
    MainModal,
    IconSvg,
    FormLabel,
    Alert,
    Timeline,
} from "@/components/ui/index";

export default {
    name: "InvoiceDetails",
    
    components: {
        ConfirmDeletionTwo,
        PageSpinner,
        Status,
        BaseButton,
        EmptyPage,
        MainModal,
        IconSvg,
        FormLabel,
        Alert,
        Timeline,
    },

    data() {
        return {
            invoiceStatusMap, mapTaxTypes,
            INVOICE_UPDATE_STATUSES, paymentProviders, billingMethods,
            pageLoading: false,
            pageLoadErr: false,
            isPageDisabled: false,
            deleteLoading: false,
            updateLoading: false,
            showEditInvoiceView: false,
            invoice: {
                meta: {
                    items: [],
                    tax: [],
                    reminders: [],
                },
            },
            client: {},
            project: {},
            showDeleteModal: false,
            updateStatusModal: false,
            updateForm: {
                status: "",
                amountPaid: 0,
            },
            config: {
                number: {
                    decimal: ".",
                    thousands: ",",          // The currency name or symbol followed by a space.
                    prefix: "",
                    suffix: "",
                    precision: 0,
                    masked: false,
                },
            },
            unableToLoadPage: false,
            downloadLoading: false,
            paymentAdditionErrMsg: "",
        };
    },

    computed: {
        computedSubTotal() {
            if (this.invoice.meta?.items.length > 0) {
                const transformArr = this.invoice.meta?.items.map(item => item.itemPrice * item.itemQuantity);
                const sumItemsArr = transformArr.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
                return sumItemsArr;
            } else {
                return 0;
            }
        },

        taxPercentage() {
            let percentageTotal = 0;

            if (this.invoice?.meta.tax.length) {                
                for (let i = 0; i < this.invoice?.meta.tax.length; i++) {
                    const item = this.invoice?.meta.tax[i];

                    if (item.taxType === "percentage") {
                        percentageTotal = item.taxValue + percentageTotal;
                    }
                }

                if (percentageTotal > 0) {
                    return {
                        show: true,
                        value: percentageTotal,
                    };
                }
            }

            return {
                show: false,
                value: undefined,
            };
        },

        computedTaxes() {
            let taxCount = 0;
            let percentageTotal = 0;

            if (this.invoice?.meta.tax.length) {                
                for (let i = 0; i < this.invoice?.meta.tax.length; i++) {
                    const item = this.invoice?.meta.tax[i];

                    if (item.taxType === "percentage") {
                        percentageTotal = item.taxValue + percentageTotal;
                    } else {
                        taxCount = item.taxValue + taxCount;
                    }
                }
            }

            if (percentageTotal > 0) {
                taxCount = taxCount + ((percentageTotal / 100) * this.computedSubTotal);
            }

            return taxCount;
        },

        updateStatusDisabled() {
            if (this.updateLoading || !this.updateForm.status) {
                return true;
            }

            if (this.updateForm.status === "partially paid" && !this.updateForm.amountPaid) {
                return true;
            }

            return false;
        },
    },

    watch: {
        "invoice.status": {
            handler(val) {
                this.updateForm.status = val;
            },
        },
    },

    async mounted() {
        await this.handleFetchInvoice();
    },

    methods: {
        convertStringAmountToInt,

        openStatusModal() {
            this.updateForm.status = this.invoice.status;
            this.updateStatusModal = true;
        },

        getBillingMethodName(val) {
            return this.billingMethods.find(x => x.id === val)?.name;
        },

        splitCCEmails(emails) {
            if (!emails || typeof emails !== "string") return [];
            const res = emails.split(",") || [];
            return res;
        },

        computeTaxValue(tax) {
            if (tax.taxType === "percentage") {
                return `${tax.taxValue}%`;
            }
            return `${this.$country.getSymbol(this.invoice.currency, "currency")}${tax.taxValue}`;
        },

        backToInvoicesPage() {
            this.$router.push("/dashboard/invoices");
        },

        preventKeys(event) {
            if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 190 || event.keyCode == 37 || event.keyCode == 39) {
            } else {
                return event.preventDefault();
            }
        },

        toggleDelete() {
            this.showDeleteModal = !this.showDeleteModal;
        },

        createInvoicePayload(data) {
            const payload = {
                invoiceNo: this.invoice.invoiceNo,
                amountPaid: this.invoice.amountPaid,
                accountDetails: {
                    ...this.invoice.accountDetails,
                    accountNumber: String(this.invoice.accountDetails.accountNumber) || "",
                },
                providerData: this.invoice.providerData,
                currency: this.invoice.currency.toLowerCase(),
                clientId: this.client?._id,
                projectId: this.project?._id,
                status: this.invoice.status,
                title: this.invoice.title,
                dueDate: this.invoice.dueDate,
                datePaid: this.invoice.datePaid,
                memo: this.invoice.memo,
                meta: {
                    ...this.invoice.meta,
                    items: this.invoice.meta.items,
                    reminders: this.invoice.meta.reminders,
                    tax: this.invoice.meta.tax,
                    ccEmails: this.invoice.meta.ccEmails,
                },
                status: this.invoice.status,
                totalAmount: this.invoice.totalAmount,
                billingMethod: this.invoice.billingMethod || "manual_account",
                ...data,
            };

            return payload;
        },
        
        async handleDuplicateInvoice() {
            try {
                this.isPageDisabled = true;
    
                const payload = this.createInvoicePayload();
                const response = await this.$store.dispatch("invoice/duplicateInvoice", { payload, client: this.client });
                
                this.$toast.success("Duplicated invoice");
                this.$router.push({ name: "invoice-details-view", params: { id: response?._id } });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.isPageDisabled = false;
            };
        },

        async handleDeleteInvoice() {
            try {
                const id = this.$route.params.id;
                
                if (!id) return;
    
                this.deleteLoading = true;
                
                await this.$store.dispatch("invoice/deleteInvoices", [id]);

                this.$toast.success("Invoice deleted");

                $("#deleteInvoice").modal("hide");

                this.$router.push({ path: "/dashboard/invoices" });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.deleteLoading = false;
            };
        },

        async handleStatusUpdate() {
            try {    
                if (this.updateForm.amountPaid > this.invoice.totalAmount) {
                    this.paymentAdditionErrMsg = "Enter in an amount less than the invoice total amount";
                    return;
                }
                
                this.paymentAdditionErrMsg = "";
                this.updateLoading = true;

                const payload = {
                    ...this.createInvoicePayload({
                        _id: this.invoice._id,
                        status: this.updateForm.status,
                        amountPaid: Number(this.updateForm.amountPaid || 0),

                    }),
                };
                
                const res = await this.$store.dispatch("invoice/updateInvoice", payload);

                this.invoice.status = this.updateForm.status;
                this.updateStatusModal = false;
                this.$toast.success("Invoice status updated");

                this.updateForm = {
                    status: "",
                    amountPaid: 0,
                };

                this.invoice = {
                    ...this.invoice,
                    ...res,
                };
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.updateLoading = false;
            };
        },

        async handleFetchInvoice() {
            try {
                this.pageLoadErr = false;
                this.unableToLoadPage = false;
                const id = this.$route.params.id;

                if (id === "undefined" || !id) {
                    this.unableToLoadPage = true;
                    return;
                }
                
                this.pageLoading = true;

                const res = await this.$store.dispatch("invoice/getInvoice", { id });

                this.invoice = res;
                this.client = res?.client;
                this.project = res?.project;
            } catch (err) {
                const errMsg = err?.response?.data?.message || err?.message;
                this.pageLoadErr = true;
                this.$toast.error(errMsg);
            } finally {
                this.pageLoading = false;
            }
        },

        goToEditPage() {
            this.$router.push(`/dashboard/invoices/${this.$route.params.id}/edit`);
        },

        closeStatusUpdateModal() {
            this.updateForm = {
                status: "",
                totalAmount: 0,
            };
            this.updateStatusModal = false;
        },

        async download() {
            try {
                this.downloadLoading = true;

                const resp = await this.$store.dispatch("invoice/downloadInvoice", { 
                    id: this.invoice._id || this.$route.params.id,
                    config: {
                        params: { type: "pdf" }, 
                        responseType: "blob",
                        headers: {
                            "Accept": "application/pdf",
                        },
                    },
                });

                const blob = new Blob([resp.data], { type:  "application/pdf" });

                if (!blob || blob.size === 0) {
                    throw new Error("PDF file is empty");
                }

                const url = window.URL.createObjectURL(blob);
    
                // Create link element
                const link = document.createElement("a");
                link.href = url;
                
                // Get filename from response headers or set default
                const contentDisposition = resp.headers["content-disposition"];
                const fileName = contentDisposition
                    ? contentDisposition.split("filename=")[1].replace(/"/g, "")
                    : "rate_card.pdf";
                
                link.setAttribute("download", fileName);
                
                // Append link to body
                document.body.appendChild(link);
                
                // Start download
                link.click();
                
                // Clean up
                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);
             
                this.$toast.success("Invoice downloaded");
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.downloadLoading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .row.hidden-xs {
        border-bottom: 1px solid #f5f5f5;
        margin-bottom: 2rem;
    }

    .form__row__left {
        max-width: 500px;
    }

    .invoice__single--btns {
        .row__item {
            padding-right: 0px !important;
        }
    }
</style>