<template>
    <div class="revenue__page">
        <div class="content__page__body mt--5" :class="{ 'empty': !allIntegrations.length }">
            <div class="w--100">
                <template v-if="pageState.loading">
                    <PageSpinner />
                </template>

                <EmptyPage v-else-if="pageState.err" state="error" :handle-submit="handleFetchIntegrations" />
                <template v-else>
                    <div class="flex flex-wrap mt--20">
                        <div
                            v-for="(item, index) in allIntegrations" 
                            :key="index" class="settings__integrations__card__item"
                        >
                            <BaseCard
                                aria-label="inner content shows on hover"
                                card-classes="flex-1"
                            >
                                <template #card-body>
                                    <div class="outer__wrap"> 
                                        <div class="flex align-items-baseline w--100">
                                            <IntegrationLogos :name="item.name" />
                                            <div>
                                                <p class="ml--10 text--capitalize text--color-gray-400 text--semi--bold">
                                                    {{ item.name }}
                                                </p>
                                                <p class="text--xs text--color-gray- mt--20">
                                                    {{ item.desc }}
                                                </p>
                                            </div>
                                        </div>
                                        <!-- <p class="text--xs text--color-gray- mt--20">
                                            #{{ item.category }}
                                        </p>

                                        <div class="flex flex-wrap mt--10">
                                            <p v-for="tier in item.allowedAccountTiers" :key="tier" class="integration__tier text--color-gray-400">
                                                {{ tier || '-' }} plan
                                            </p>
                                        </div> -->
                                    </div>
                                    <!-- <div>
                                        <BaseButton 
                                            class-names="settings__integrations__card__btn" label="View more" size="sm"
                                            variant="outline" inner-style="secondary"
                                        />
                                    </div> -->
                                </template>
                            </BaseCard>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <MainModal>
            llll
        </MainModal>
    </div>
</template>

<script>
import { 
    IconSvg, 
    EmptyPage, 
    PageSpinner, BaseCard,
    BaseButton, IntegrationLogos, MainModal,
} from "@/components/ui/index";
import { integrationHeaders } from "@/utils/dummy";
import { integrationMap, integrationProvidersLogos } from "@/utils/enums";

export default {
    name: "ActiveIntegrations",

    components: {
        IconSvg, MainModal,
        EmptyPage, BaseCard,
        PageSpinner, IntegrationLogos,
        BaseButton,
    },

    data() {
        return {
            integrationHeaders,
            pageState: {
                loading: false,
                err: false,
                errMsg: "",
            },
            showNoteModal: false,
            integrationMap,
            integrationProvidersLogos,
        };
    },

    computed: {
        allIntegrations() {
            return this.$store.getters["integration/allIntegrations"];
        },

        userProfile() {
            return this.$store.getters["auth/profile"];
        },
    },
    
    async mounted() {     
        await this.handleFetchIntegrations({ refresh: true });
    },

    methods: {
        async handleFetchIntegrations(params = {}) {
            try {
                this.pageState.loading = true;
                this.pageState.errMsg = "";
                this.pageState.err = false;

                await this.$store.dispatch("integration/fetchIntegrations", { refresh: params?.refresh });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.pageState.err = true;
                this.pageState.errMsg = errMsg;
            } finally {
                this.pageState.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.text--tag {
    border: 1px solid #eee;
    border-radius: 50rem !important;
    padding: 5px 8px;
    display: inline !important;
    vertical-align: middle !important;
    color: #64748B;
    font-size: 0.75rem;
}

.integration__tier {
    display: flex;
    align-items: center;
    background: #e1e6f6;
    border-radius: 50px;
    padding: 3px 8px;
    justify-content: center;
    font-size: 12px;
    margin-right: 5px;
    margin-bottom: 10px;
    width: fit-content;
    text-transform: capitalize;
}
</style>