<template>
    <main-modal 
        :show-modal="showModal" 
        size="sm" :title="`${action === 'add' ? 'Add' : 'Edit'} meeting note`" @close="onCancel"
    >
        <template #body>
            <div class="modal__wrapper--onboarding">
                <div class="modal--section">
                    <form class="form--workspace__create mb--20" @submit.prevent="">
                        <FormInput 
                            id="noteValue"
                            v-model="noteValue"
                            required size="sm"
                            label="Note value" 
                        />
                    </form>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="w--100 flex justify-content-end align-items-center">
                <BaseButton 
                    class="mr--5" label="Cancel" size="sm"
                    variant="outline" @submit="onCancel"
                />
                <BaseButton 
                    :loading="loading" type="submit" :disabled="!noteValue || loading"
                    size="sm" :label="`${action === 'add' ? 'Add' : 'Save'} note`" @submit="handleSubmit"
                />
            </div>
        </template>
    </main-modal>
</template>

<script>
import BaseButton from "../buttons/BaseButton.vue";
import FormInput from "../input/FormInput.vue";
import MainModal from "./MainModal.vue";

export default {
    name: "MeetingNoteModal",

    components: {
        FormInput,
        BaseButton,
        MainModal,
    },

    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
        action: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: "",
        },
        currentNote: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            noteValue: "",
            loading: false,
        };
    },

    watch: {
        "currentNote"(newVal) {
            this.noteValue = newVal;
        },
    },

    methods: {
        async handleSubmit() {
            try {                
                this.loading = true;

                await this.$store.dispatch("schedule/modifyMeetingNote", { id: this.id, note: this.noteValue });

                if (this.action === "add") {
                    this.$toast.success("Note added");
                } else {
                    this.$toast.success("Note updated");
                }

                this.$emit("complete", this.noteValue);
                this.onCancel();
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }
        },

        onCancel() {
            this.noteValue = "";
            this.$emit("cancel");
        },
    },
};
</script>