export const authFormFields = {
    "login": {
        "email": true,
        "password": true,
        "dontHaveAnAccount": true,
        "forgotPassword": true,
        "googleAuth": true,
    },
    "signup": {
        "email": true,
        "password": true,
        "fullName": true,
        "haveAnAccount": true,
        "termsAndService": true,
        "googleAuth": true,
    },
    initiateReset: {
        "email": true,
        "haveAnAccount": true,
    },
    "reset": {
        "password": true,
        "confirmPassword": true,
        "haveAnAccount": true,
    },
};

export const authFieldsToValidate = {
    "login": {
        "email": true,
        "password": true,
    },
    "signup": {
        "email": true,
        "password": true,
        "fullName": true,
        "termsAndService": true,
    },
    initiateReset: {
        "email": true,
    },
    "reset": {
        "password": true,
        "confirmPassword": true,
    },
};

export const authCopy = {
    "login": {
        title: "Sign in",
        subtitle: "Login into your account to continue",
        linkText: "Sign up",
        btnLabel: "Login",
    },
    "signup": {
        title: "Create your account",
        linkText: "Login",
        btnLabel: "Sign up",
        subtitle: "Welcome to Ziner -- Let's create your account",
    },
    initiateReset: {
        title: "Initiate Password Reset",
        subtitle: "Enter an email address to get a password reset link",
        btnLabel: "Send password reset link",
        linkText: "",
    },
    "reset": {
        title: "Create a new password",
        subtitle: "",
        btnLabel: "Reset your password",
        linkText: "",
    },
};

export const updateProfileFields = {
    profile: {
        title: "Manage contact information",
        fields: {
            fullName: true,
            phoneNumber: true,
            address: true,
            country: true,
            zipCode: true,  
        },
    },
    emailSettings: {
        title: "Manage email address",
        fields: {
            email: true,
        },
    },
};

export const generalModalFields = {
    appearance: {
        title: "Update Apperance & theme",
        fields: {
            theme: true,
        },
    },
    timeSettings: {
        title: "Update time settings",
        fields: {
            timezone: true,
            timeFormat: true,
            dateFormat: true,
            dayOfWeek: true,  
        },
    },
    currency: {
        title: "Update language & currency",
        fields: {
            language: true,
            currency: true,
        },
    },
};

export const updateClientDetailsFields = {
    name: {
        title: "Edit name",
        fields: {
            name: true, 
        },
    },
    create: {
        title: "Add client",
        fields: {
            name: true,
            email: true,
            address: true,
            country: true,
            phoneNumber: true,
            city: true,
            state: true,
            zipCode: true,
            status: true,
            industry: true,
            ccEmails: true, 
        },
    },
    billingEmails: {
        title: "Manage additional emails",
        fields: {
            ccEmails: true, 
        },
    },
    clientDetails: {
        title: "Update client information",
        fields: {
            email: true,
            industry: true,
            address: true,
            country: true,
            phoneNumber: true,
            city: true,
            state: true,
            zipCode: true,
        },
    },
};

export const updateProjectDetailsFields = {
    create: {
        title: "Add project",
        fields: {
            title: true,
            deadline: true,
            startDate: true,
            description: true,
            billingType: true,
            nonBillableHours: true,
            billingRate: true,
            priority: true,
            projectType: true,
            isFavourite: true,
            tags: true,
            clients: true,
            status: true,
            rateCards: true,
            currency: true, 
            billableHours: true, 
            estimatedRevenue: true, 
            reminders: true, 
        },
        cta: "Create project",
    },
    name: {
        title: "Update project title",
        fields: {
            title: true, 
        },
        cta: "Update title",
    },
    metaDetails: {
        title: "Update meta information",
        fields: {
            description: true, 
            isFavourite: true,
            deadline: true,
            startDate: true,
        },
        cta: "Update",
    },
    billingDetails: {
        title: "Update billing information",
        fields: {
            projectType: true,
            estimatedRevenue: true,
            billableHours: true,
            nonBillableHours: true,
            billingRate: true,
            currency: true, 
        },
        cta: "Update",
    },
    trackingDetails: {
        title: "Project tracking settings",
        fields: {
            trackProject: true,
            trackingDays: true,
            trackingType: true,
            startTime: true,
            endTime: true,
        },
        cta: "Update",
    },
};