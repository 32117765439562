<template>
    <div>
        <main-modal 
            :show-modal="showModal" size="sm"
            title="Connect calendar" @close="closeModal"
        >
            <template #body>
                <div class="client__modal__body mb--25">
                    <form @submit.prevent="">
                        <div class="form--control">
                            <FormLabel classes="text--color-gray-200" title="Choose a provider" />
                            <select v-model="form.providerName" class="form-select form-select-sm text--sm text--capitalize">
                                <option v-for="provider in providers" :key="provider" :value="provider">
                                    {{ provider }}
                                </option>
                            </select>
                        </div>
                    </form>
                </div>
            </template>

            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <BaseButton
                        class="mr--5" label="Cancel"
                        size="sm" variant="outline" :disabled="loading"
                        @submit="closeModal"
                    />
                    <BaseButton 
                        label="Connect"
                        size="sm" :loading="loading" :disabled="!form.providerName || loading"
                        @submit="connectCalendar"                                       
                    />
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import MainModal from "./MainModal.vue";
import FormLabel from "../forms/FormLabel.vue";
import BaseButton from "../buttons/BaseButton.vue";
import { assembleQueryList } from "@/utils/helpers";

export default {
    name: "ConnectCalendarModal",

    components: {
        MainModal,
        FormLabel,
        BaseButton,
    },

    props: {
        showModal: { type: Boolean, default: false },
    },

    data() {
        return {
            form: {
                providerName: "",
            },
            providers: ["Google calendar", "Calendly"],
            loading: false,
        };
    },

    computed: {
    },

    methods: {
        closeModal() {
            this.form = {
                providerName: "",
            };
            this.$emit("close");
        },

        async handleFetchUserProfile() {
            try {
                await this.$store.dispatch("auth/fetchProfile", { refresh: true });
            } catch (error) {
                throw error;
            }
        }, 

        async connectCalendar() {
            try {    
                this.loading = true;

                const payload = {
                    providerName: this.form.providerName.toLowerCase().split(" ").join("_"),
                };

                const buildResult = assembleQueryList(payload);

                const res = await this.$store.dispatch("auth/connectCalendar", buildResult);

                if (!res.url) {
                    this.$toast.error("Unable to connect calendar");
                    return;
                }
               
                localStorage.setItem("z:calendar_provider", payload.providerName);
                localStorage.setItem("z:auth_type", "calendar");

                this.closeModal();

                window.location.href = res?.url;
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.client__modal__body {
    padding: 0px 15px;
    margin-bottom: 10px;
}
</style>