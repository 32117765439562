<template>
    <div class="report__wrap">
        <template v-if="pageLoading">
            <PageSpinner />
        </template>

        <template v-else-if="pageLoadingErr">
            <EmptyPage state="error" :handle-submit="handleFetchReports" />
        </template>

        <template v-else>
            <div class="report__header">
                <p class="text--color-gray-700 text--capitalize text--semi--bold">
                    {{ reportFilter.type }}
                </p>
                <div class="flex align-items-center">
                    <ReportFilter class="ml--5" />

                    <!-- <BaseButton
                        variant="outline" label="Download" size="sm" 
                        prefix-icon icon-name="download" icon-width="16px"
                        inner-style="secondary" class="ml--5"
                    />      -->
                </div>
            </div>

            <div class="report__item__wrap">
                <div v-if="reportFilter.type === 'clients'">
                    <ClientReports />
                </div>

                <div v-else-if="reportFilter.type === 'projects'">
                    <ProjectReports />
                </div>

                <!-- <div v-else-if="reportFilter.type === 'invoices'">
                    <InvoiceReports />
                </div> -->
            </div> 
        </template>
    </div>
</template>

<script>
import { EmptyPage, PageSpinner, BaseButton, ReportFilter } from "@/components/ui/index";
import ClientReports from "@/components/reports/ClientReports.vue";
import ProjectReports from "@/components/reports/ProjectReports.vue";

export default {
    name: "Reports",

    components: {
        ClientReports,
        EmptyPage, ProjectReports,
        PageSpinner, ReportFilter,
        BaseButton,
    },

    data() {
        return {
            pageLoading: false,
            pageLoadingErr: true,
            reportComponent: null,
        };
    },

    computed: {
        reportFilter() {
            return this.$store.getters["report/reportFilter"];
        },
    },

    watch: {
        "$route.query": "handleFetchReports",
    },

    async mounted() {
        this.setReportFilter(Object.keys(this.$route.query).length ? this.$route.query : { type: "clients" });
        await this.handleFetchReports(this.$route.query);
    },

    methods: {
        async setReportFilter(params) {
            // let reportComponent;
            this.$store.dispatch("report/setReportFilter", params);

            // switch(params.type) {
            // case "clients":
            //     reportComponent = await import("@/components/reports/ClientReports.vue");
            //     break;
            // case "projects":
            //     reportComponent = await import("@/components/reports/ProjectReports.vue");
            //     break;
            // // case "invoices":
            // //     reportComponent = await import("@/components/reports/InvoiceReports.vue");
            // //     break;
            // default:
            //     reportComponent = null;
            // }
            
            // this.reportComponent = reportComponent.default;
        },

        async handleFetchReports(params) {
            try {
                this.pageLoadingErr = false;
                this.pageLoading = true;

                const payload = {
                    type: params?.type || "clients",
                    year: params?.year || "2024",
                };

                await this.$store.dispatch("report/fetchReports", payload);
            } catch (error) {
                this.pageLoadingErr = true;
            } finally {
                this.pageLoading = false;
            }
        },

        // async generateReport(type) {
        //     let reportComponent;
      
        //     // Load report generators on demand
        //     switch(type) {
        //     case "client":
        //         reportComponent = await import("@/components/reports/ClientReport.vue");
        //         break;
        //     case "financial":
        //         reportComponent = await import("@/components/reports/FinancialReport.vue");
        //         break;
        //     case "project":
        //         reportComponent = await import("@/components/reports/ProjectReport.vue");
        //         break;
        //     }
      
        //     this.reportComponent = reportComponent.default;
        // },
    },
};
</script>