<template>
    <div class="h-100 content__page">
        <div class="flex align-items-center justify-content-start mb--20">
            <p class="text--semi--bold text--lg">
                <span class="text--capitalize">{{ title.one }}</span>
                <span style="margin-left: 3px;">{{ title.two }}</span>
            </p>
        </div>

        <LinkedTabs :list="meetingTabList" />
    </div>
</template>

<script>
import { LinkedTabs } from "@/components/ui/index";
import { meetingTabList } from "@/utils/dummy";

export default {
    name: "MeetingsView",

    components: {
        LinkedTabs,
    },

    data() {
        return {
            meetingTabList,
        };
    },

    computed: {
        calendarIntegrations() {
            return (this.$store.getters["auth/profile"]?.calendarIntegrations || [])?.filter(x => x?.settings?.syncEnabled);
        },

        title() {
            const path = this.$route.name.split("-");

            return {
                one: path[1],
                two: path[0],
            };
        },
    },

    methods: {
    },
};
</script>