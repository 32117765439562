<template>
    <div class="btn-group" v-bind="$attrs">
        <BaseButton 
            id="dropdownMenuClickableInside" 
            class="btn--filter" variant="outline" inner-style="secondary"
            aria-expanded="false" size="sm" v-bind="$attrs"
            data-bs-toggle="dropdown" data-bs-auto-close="true" 
        >
            <span class="flex align-items-center">
                <IconSvg name="filter" fill="#637579" width="12px" height="12px" />
                <span class="ml--5">Filter</span>
            </span>
        </BaseButton>

        <div class="dropdown-menu filter__dropdown-menu mb--35">
            <form @submit.prevent="">
                <div class="action--btns__wrap">
                    <button class="action--btns__clear action--btns" type="reset" @click="clearFilter">
                        Clear all
                    </button>
                    <BaseButton 
                        label="Run" size="sm"
                        :disabled="disabled" type="submit" @submit="handleFilter"
                    />
                </div>

                <div class="mb--15">
                    <div v-if="filter.name.allowedPaths.includes(pageType)" class="filter-item-input">
                        <FormLabel title="Name" />
                        <input id="name" v-model="filter.name.value" class="form-control form-control-sm filter-input" type="text">
                    </div>

                    <div v-if="filter.industry.allowedPaths.includes(pageType)" class="filter-item-input">
                        <FormLabel title="Industry" />
                        <select v-model="filter.industry.value" class="form-select form-select-sm text--sm filter-input text--capitalize">
                            <option v-for="(industry, index) in industryData" :key="index" :value="industry">
                                {{ industry }}
                            </option>
                        </select>
                    </div>

                    <div v-if="filter.fromDueDate.allowedPaths.includes(pageType)" class="filter-item-input">
                        <FormLabel title="Due date" />
                      
                        <div class="form__row">
                            <div class="flex-1 mr--5">
                                <v-date-picker v-model="filter.fromDueDate.value" class="text--sm" :max-date="new Date()">
                                    <template #default="{ inputValue, togglePopover }">
                                        <input
                                            class="px-2 py-1 border rounded due_date form-control form-control-sm"
                                            :value="inputValue"
                                            @click="togglePopover"
                                        >
                                    </template>
                                </v-date-picker> 
                            </div>
                            <div class="flex-1">
                                <v-date-picker v-model="filter.toDueDate.value" class="text--sm" :min-date="minEndDate" :max-date="new Date()">
                                    <template #default="{ inputValue, togglePopover }">
                                        <input
                                            class="px-2 py-1 border rounded due_date form-control form-control-sm"
                                            :value="inputValue"
                                            @click="togglePopover"
                                        >
                                    </template>
                                </v-date-picker>
                            </div>
                        </div>                 
                    </div>

                    <div v-if="filter.email.allowedPaths.includes(pageType)" class="filter-item-input">
                        <FormLabel title="Email" />
                        <input id="email" v-model="filter.email.value" class="form-control form-control-sm filter-input" type="email">
                    </div>

                    <div v-if="filter.status.allowedPaths.includes(pageType)" class="filter-item-input">
                        <FormLabel title="Status" />
                        <div>
                            <div v-for="status in statusList" :key="status" class="form-check form-check-inline">
                                <input
                                    :id="`status-${status}`" v-model="filter.status.value" class="form-check-input" type="checkbox"
                                    :value="status"
                                >
                                <label class="form-check-label text--capitalize" :for="`status-${status}`">{{ status }}</label>
                            </div>
                        </div>
                    </div>

                    <div v-if="filter.startDate.allowedPaths.includes(pageType)" class="filter-item-input">
                        <div class="form__row">
                            <div class="flex-1 mr--5">
                                <FormLabel title="Start Date" />
                                <v-date-picker v-model="filter.startDate.value" class="text--sm" :max-date="new Date()">
                                    <template #default="{ inputValue, togglePopover }">
                                        <input
                                            class="px-2 py-1 border rounded due_date form-control form-control-sm"
                                            :value="inputValue"
                                            @click="togglePopover"
                                        >
                                    </template>
                                </v-date-picker>
                            </div>
                            <div class="flex-1">
                                <FormLabel title="End Date" />
                                <v-date-picker v-model="filter.endDate.value" class="text--sm" :min-date="minEndDate" :max-date="new Date()">
                                    <template #default="{ inputValue, togglePopover }">
                                        <input
                                            class="px-2 py-1 border rounded due_date form-control form-control-sm"
                                            :value="inputValue"
                                            @click="togglePopover"
                                        >
                                    </template>
                                </v-date-picker>
                            </div>
                        </div>
                    </div>
                    
                    <div v-if="filter.title.allowedPaths.includes(pageType)" class="filter-item-input">
                        <FormLabel title="Title" />
                        <input id="projectTitle" v-model="filter.title.value" class="form-control form-control-sm filter-input" type="text">
                    </div>

                    <div v-if="filter.projectType.allowedPaths.includes(pageType)" class="filter-item-input">
                        <FormLabel title="Project type" />
                        <select v-model="filter.projectType.value" class="form-select form-select-sm text--sm filter-input">
                            <option v-for="status in ['paid', 'not paid']" :key="status" :value="status" class="text--capitalize">
                                {{ status }}
                            </option>
                        </select>
                    </div>

                    <div v-if="filter.priority.allowedPaths.includes(pageType)" class="filter-item-input">
                        <FormLabel title="Priority" />
                        <div>
                            <div v-for="priority in priorities" :key="priority" class="form-check form-check-inline">
                                <input
                                    id="inlineCheckbox1" v-model="filter.priority.value" class="form-check-input" type="checkbox"
                                    :value="priority"
                                >
                                <label class="form-check-label text--capitalize" for="inlineCheckbox1">{{ priority }}</label>
                            </div>
                        </div>
                    </div>

                    <div v-if="filter.rateType.allowedPaths.includes(pageType)" class="filter-item-input">
                        <FormLabel title="Rate card type" />
                        <div>
                            <div v-for="item in ['hourly', 'weekly', 'monthly', 'yearly']" :key="item" class="form-check form-check-inline">
                                <input
                                    :id="`check-${item}`" v-model="filter.rateType.value" :value="item" class="form-check-input"
                                    type="checkbox"
                                >
                                <label class="form-check-label text--capitalize" :for="`check-${item}`">{{ item }}</label>
                            </div>
                        </div>
                    </div>

                    <div v-if="filter.currency.allowedPaths.includes(pageType)" class="filter-item-input">
                        <FormLabel title="Currency" classes="ml--0" />
                        <input 
                            id="currency" v-model="filter.currency.value" class="form-control form-control-sm"                                    
                            type="text"
                        >
                    </div>

                    <div v-if="filter.invoiceNo.allowedPaths.includes(pageType)" class="filter-item-input">
                        <FormLabel title="Invoice No." />
                        <input id="invoiceNo" v-model="filter.invoiceNo.value" class="form-control form-control-sm" type="text">
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { projectFilterStatuses, priorities, taskStatus, invoiceFilterStatuses, clientFilterStatuses } from "@/utils/enums";
import { industryData } from "@/utils/dummy";
import { serilaizeQuery } from "@/utils/helpers";
import FormLabel from "../forms/FormLabel.vue";
import IconSvg from "../icons/Icon-Svg.vue";
import BaseButton from "../buttons/BaseButton.vue";

export default {
    name: "MainFilter",

    components: {
        FormLabel,
        IconSvg, BaseButton,
    },

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        pageType: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            query: {},
            projectFilterStatuses,
            priorities,
            taskStatus,
            invoiceFilterStatuses,
            clientFilterStatuses,
            industryData,
            currentPathName: this.$route.path || "",
            filter: {
                fromDueDate: { allowedPaths: ["projects", "invoices"], value: this.$route.query.fromDueDate || null },
                toDueDate: { allowedPaths: ["projects", "invoices"], value: this.$route.query.toDueDate || null },
                rateType: { allowedPaths: ["rateCards"], value: this.$route.query.rateType?.split(",") || [] },
                name: { allowedPaths: ["clients", "tags", "tasks"], value: this.$route.query.name || "" },
                industry: { allowedPaths: ["clients"], value: this.$route.query.industry || "" },
                priority: { allowedPaths: ["projects", "tasks"], value: this.$route.query.priority?.split(",") || [] },
                email: {
                    allowedPaths: ["projects", "clients", "invoices"],
                    value: this.$route.query.email || "",
                },
                title: { 
                    allowedPaths: ["projects", "rateCards", "meetings"], value: this.$route.query.title || "", 
                },
                status: { allowedPaths: ["projects", "clients", "invoices"], value: this.$route.query?.status?.split(",") || [] },
                invoiceNo: { allowedPaths: ["invoices"], value: this.$route.query.invoiceNo || "" },
                startDate: { 
                    allowedPaths: ["clients", "rateCards", "meetings", "tags", "tasks", "invoices", "projects", "revenue"], 
                    value: null, 
                },
                endDate: { 
                    allowedPaths: ["clients", "rateCards", "meetings", "tags", "tasks", "invoices", "projects", "revenue"], 
                    value: null, 
                },
                currency: { 
                    allowedPaths: ["invoices", "projects", "rateCards", "revenue"], 
                    value: this.$route.query.currency || "", 
                },
                projectType: { allowedPaths: ["projects"], value: this.$route.query.projectType || "" },
            },
            minEndDate: null,
        };
    },

    computed: {
        statusList() {
            switch (this.pageType) {
            case "projects":
                return this.projectFilterStatuses;
                
            case "invoices":
                return this.invoiceFilterStatuses;
                    
            case "clients":
                return this.clientFilterStatuses;
                    
            case "tasks":
                return this.taskStatus;
                
            default:
                return [];
            }
        },
    },

    watch: {
        "filter.startDate.value": {
            handler(newVal) {
                this.minEndDate = new Date(newVal);
            },
        },
    },

    mounted() {
        this.filter.startDate.value = this.$route.query.startDate ? new Date(this.$route.query.startDate).toLocaleDateString("en-GB") : null;
        this.filter.endDate.value = this.$route.query.endDate ? new Date(this.$route.query.endDate).toLocaleDateString("en-GB") : null;
    },

    methods: {
        serilaizeQuery, 

        clearFilter() {
            this.filter = {
                fromDueDate: { allowedPaths: ["projects", "invoices"], value: this.$route.query.dueDate || null },
                toDueDate: { allowedPaths: ["projects", "invoices"], value: this.$route.query.dueDate || null },
                rateType: { allowedPaths: ["rateCards"], value: [] },
                name: {allowedPaths: ["clients", "tags", "tasks"], value: "" },
                industry: { allowedPaths: ["clients"], value: "" },
                email: { allowedPaths: ["projects", "clients", "invoices"], value: "" },
                title: { allowedPaths: ["projects", "rateCards", "meetings"], value: "" },
                status: { allowedPaths: ["projects", "clients", "invoices"], value: [] },
                invoiceNo: { allowedPaths: ["invoices"], value: "" },
                startDate: { allowedPaths: ["clients", "invoices", "projects", "rateCards", "meetings", "tags", "tasks", "revenue"], value: null },
                endDate: { allowedPaths: ["clients", "rateCards", "meetings", "tags", "tasks", "invoices", "projects", "revenue"], value: null },
                currency: { allowedPaths: ["invoices", "projects", "rateCards", "revenue"], value: "" },
                projectType: { allowedPaths: ["invoices", "projects"], value: "" },
                priority: { allowedPaths: ["projects", "tasks"], value: [] },
            };

            const currentRoute = this.$route.path;
            const queryParam = this.serilaizeQuery({});
            
            this.$router.replace(`${currentRoute}?${queryParam}`);
        },

        handleFilter() {
            let query = this.query;

            if (this.$route.query.page){
                delete query.page;
            }

            if (this.$route.query.limit){
                delete query.limit;
            }

            if (this.filter.name.value) {
                query.name = this.filter.name.value;
            }

            if (this.filter.title.value) {
                query.title = this.filter.title.value;
            }

            if (this.filter.industry.value) {
                query.industry = this.filter.industry.value;
            }

            if (this.filter.email.value) {
                query.email = this.filter.email.value;
            }

            if (this.filter.status.value.length) {
                query.status = this.filter.status.value;
            }

            if (this.filter.startDate.value) {
                query.startDate = new Date(this.filter.startDate.value).toLocaleDateString("en-GB");
            }

            if (this.filter.endDate.value) {
                query.endDate = new Date(this.filter.endDate.value).toLocaleDateString("en-GB");
            }

            if (this.filter.currency.value) {
                query.currency = this.filter.currency.value;
            }

            if (this.filter.invoiceNo.value) {
                query.invoiceNo = this.filter.invoiceNo.value;
            }

            if (this.filter.priority.value.length) {
                query.priority = this.filter.priority.value;
            }

            if (this.filter.rateType.value.length) {
                query.rateType = this.filter.rateType.value;
            }

            if (this.filter.projectType.value) {
                query.projectType = this.filter.projectType.value;
            }

            if (this.filter.fromDueDate.value) {
                query.fromDueDate = new Date(this.filter.fromDueDate.value).toLocaleDateString("en-GB");
            }

            if (this.filter.toDueDate.value) {
                query.toDueDate = new Date(this.filter.toDueDate.value).toLocaleDateString("en-GB");
            }

            const currentRoute = this.$route.path;
            const queryParam = this.serilaizeQuery(query);
                        
            this.$router.replace(`${currentRoute}?${queryParam}`);
        },
    },
};
</script>

<style lang="scss" scoped>
    .action--btns__wrap {
        border-bottom: 1px solid #eee;
        padding-bottom: 10px;
        padding-top: 10px;
        background-color: #f6f8fa !important;
    }

    .dropdown-menu.show {
        transform: translate3d(-10px, 30px, 0px) !important;
        transform: translate3d(0px, 30px, 0px) !important;
        width: 300px !important;
        inset: 0px 0px auto auto !important;
        padding: 0px !important;
        box-shadow: inset 0 -1px rgba(235, 238, 241, 0) !important; 
        border-radius: 5px;
   }

   .filter {
        &-input {
            font-size: 12px !important;
            border-radius: 6px;
        }

        &-item-input {
            display: flex;
            flex-direction: column;
            padding: 10px 11px;

            &:not(:last-of-type) {
                border-bottom: 1px solid #eee;
            }

            &:last-of-type {
                padding-bottom: 0px;
            }

            label {
                font-size: 12px !important;
            }
        }

        &-status {
            &-item {
                flex-direction: row !important;
                flex-wrap: wrap;

                label {
                    margin-bottom: 0px !important;
                }
            }
        }
   }
</style>