<template>
    <div>
        <main-modal 
            :show-modal="showModal" 
            size="sm"
            :title="`Archive ${type}`" @close="$emit('cancel')"
        >
            <template #body>
                <div class="modal__wrapper--onboarding">
                    <div class="modal--section">
                        <div class="mb--20 mt--20">
                            <p class="text--sm">
                                {{ `Are you sure you want to archive this ${type}?` }}
                            </p>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <BaseButton 
                        label="Cancel" 
                        variant="outline" size="sm" inner-style="secondary"
                        @submit="$emit('cancel', false)"
                    />
                    <BaseButton label="Archive" :loading="loading" size="sm" @submit="$emit('archive')" />
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import BaseButton from "../buttons/BaseButton.vue";
import MainModal from "./MainModal.vue";

export default {
    name: "ArchiveRecord",

    components: { MainModal, BaseButton },

    props: {
        type: {
            type: String,
            default: "",
        },

        loading: {
            type: Boolean,
            default: false,
        },
        
        showModal: {
            type: Boolean,
            default: false,
        },
    },

    emits: ["cancel", "archive"],
};
</script>