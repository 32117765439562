<template>
    <div class="clients__page clients__page--view">
        <div>
            <div class="w-100">
                <div>
                    <p class="text--lg text--color-gray-800 text--semi--bold">
                        Integrations
                    </p>
                    <p v-if="accountPlan !== 'plus'" class="text--sm text--color-gray-100 mt--5">
                        You are currently on the <strong>{{ accountPlanTitle }}</strong> plan. Upgrade to the <strong>{{ nextUpgradePlan }}</strong> plan to get access to more integrations.
                    </p>
                    <p v-else>
                        Dummy text here
                    </p>
                </div>

                <div class="mt--30">
                    <LinkedTabs :list="integrationTabList" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { LinkedTabs } from "@/components/ui/index";
import { accountIntegrations, integrationTabList, accountPlanMap } from "@/utils/dummy";

export default {
    name: "Integrations",

    components: {
        LinkedTabs,
    },

    data() {
        return {
            accountIntegrations,
            integrationTabList,
            accountPlanMap,
        };
    },

    computed: {
        accountPlan() {
            return this.$store.getters["auth/profile"]?.accountPlan;
        },

        accountPlanTitle() {
            return this.accountPlanMap[this.accountPlan];
        },

        nextUpgradePlan() {
            switch (this.accountPlan) {
            case "free":
                return "Standard";
            case "standard":
                return "Plus";
            
            default:
                return "";
            }
        },
    },
};
</script>