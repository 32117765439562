import axios from "./axios";

const api = {
    app: {
        getActivityLogs: (params, $config) => axios.get("/user/activity", { params }, $config),
        markOne: (id, $body, $config) => axios.patch(`/user/activity/${id}/mark-one`, $body, $config),
        markAll: ($body, $config) => axios.patch("/user/activity/mark-all-read", $body, $config),
        getProjectCount: ($body, $config) => axios.get("/user/projects/completed-count", $body, $config),
        fetchUnreadCount: ($body, $config) => axios.get("/user/activity/count", $body, $config),
        checkUpgradeStatus: (params, $config) => axios.get("/user/users/check-upgrade", { params }, $config),
    },

    auth: {
        connectCalendar: (params, $config) => axios.get("/user/auth/connect-calendar", { params }, $config),
        connectResponseCb: (params, $config) => axios.get("/user/auth/connect-callback", { params }, $config),
        externalLogin: ($body, $config) => axios.post("/user/auth/external-auth", $body, $config),
        initiatePasswordReset: ($body, $config) => axios.post("/user/auth/initiate-reset", $body, $config),
        login: ($body, $config) => axios.post("/user/auth/login", $body, $config),
        logout: ($body, $config) => axios.post("/user/auth/logout", $body, $config),
        resetPassword: ($body, $config) => axios.put("/user/auth/reset-password", $body, $config),
        signup: ($body, $config) => axios.post("/user/auth/signup", $body, $config),
        verifyToken: ($body, $config) => axios.post("/user/auth/verify-token", $body, $config),
        verifySession: ($body, $config) => axios.get("/user/auth/check-session", $body, $config),
    },

    client: {
        create: ($body, $config) => axios.post("/user/clients", $body, $config),
        getAll: (params, $config) => axios.get("/user/clients", { params }, $config),
        getOne: (id, $config) => axios.get(`/user/clients/${id}`, $config),
        updateOne: (id, $body, $config) => axios.put(`/user/clients/${id}`, $body, $config),
        delete: ($body, $config) => axios.patch("/user/clients/delete", $body, $config),
        
        upload: ($body, $config) => axios.post("/user/clients/upload", $body, $config),
        followUp: ($body, $config) => axios.post("/user/clients/follow-up", $body, $config),
        duplicate: (id, $body, $config) => axios.post(`/user/clients/${id}/duplicate`, $body, $config),
        archive: (id, $body, $config) => axios.put(`user/clients/${id}/archive`, $body, $config),
        
        getProjects: (id, params, $config) => axios.get(`/user/clients/${id}/projects`, { params }, $config),
        addProject: (id, $body, $config) => axios.put(`/user/clients/${id}/projects/${$body.projectId}`, $body, $config),
        removeProject: (id, $body, $config) => axios.delete(`/user/clients/${id}/projects/${$body.projectId}`, $config),
        
        getNotes: (id, params, $config) => axios.get(`/user/clients/${id}/notes`, { params }, $config),
        createNote: ($body, $config) => axios.post(`/user/clients/${$body.clientId}/notes`, $body, $config),
        editNote: (id, $body, $config) => axios.put(`/user/clients/${id}/notes/${$body._id}`, $body, $config),
        deleteNote: (id, $body, $config) => axios.delete(`/user/clients/${id}/notes/${$body._id}`, $config),
    },

    integration: {
        getAll: (params, $config) => axios.get("/admin/integrations", { params }, $config),
        getOne: (id, params, $config) => axios.get(`/admin/integrations/${id}`, { params }, $config),
    },

    invoice: {
        getAll: (params, $config) => axios.get("/user/invoices", { params }, $config),
        getOne: (id, params, $config) => axios.get(`/user/invoices/${id}`, { params }, $config),
        sendAsDraft: ($body, $config) => axios.post("/user/invoices/draft", $body, $config),
        sendNew: ($body, $config) => axios.post("/user/invoices/send-new", $body, $config),
        sendExisting: ($body, $config) => axios.post("/user/invoices/send-existing", $body, $config),
        updateOne: (id, $body, $config) => axios.put(`/user/invoices/${id}`, $body, $config),
        delete: ($body, $config) => axios.patch("/user/invoices/delete", $body, $config),
        duplicate: ($body, $config) => axios.post("/user/invoices/duplicate", $body, $config),
        updateStatus: (id, $body, $config) => axios.put(`/user/invoices/${id}/update-status`, $body, $config),
        updateBillingData: (id, $body, $config) => axios.patch(`/user/invoices/${id}/billing-data`, $body, $config),
        download: (id, $config) => axios.get(`/user/invoices/${id}/download`, $config),
    },

    project: {
        addTag: (id, $body, $config) => axios.post(`/user/projects/${id}/tags`, $body, $config),
        archive: (id, $body, $config) => axios.put(`/user/projects/${id}/archive`, $body, $config),
        create: ($body, $config) => axios.post("/user/projects", $body, $config),
        createNote: ($body, $config) => axios.post(`/user/projects/${$body.projectId}/notes`, $body, $config),
        delete: ($body, $config) => axios.patch("/user/projects/delete", $body, $config),
        deleteNote: (id, $body, $config) => axios.delete(`/user/projects/${id}/notes/${$body._id}`, $config),
        getOne: (id, $config) => axios.get(`/user/projects/${id}`, $config),
        editNote: (id, $body, $config) => axios.put(`/user/projects/${id}/notes/${$body._id}`, $body, $config),
        getAll: (params, $config) => axios.get("/user/projects", { params }, $config),
        getInvoices: (id, params, $config) => axios.get(`/user/projects/${id}/invoices`, { params }, $config),
        getNotes: (id, params, $config) => axios.get(`/user/projects/${id}/notes`, { params }, $config),
        removeTag: (id, $config) => axios.delete(`/user/projects/${id}/tags`, $config),
        updateOne: (id, $body, $config) => axios.put(`/user/projects/${id}`, $body, $config),
        updateSub: (id, $body, $config) => axios.put(`/user/projects/${id}/subs`, $body, $config),
        toggleTimeTracking: (id, $body, $config) => axios.put(`/user/projects/${id}/toggle-time-tracking`, $body, $config),
        upload: ($body, $config) => axios.post("/user/clients/upload", $body, $config),
        duplicate: ($body, $config) => axios.post("/user/projects/duplicate", $body, $config),
    },

    rateCard: {
        create: ($body, $config) => axios.post("/user/rate-cards", $body, $config),
        getAll: (params, $config) => axios.get("/user/rate-cards", { params }, $config),
        getOne: (id, $config) => axios.get(`/user/rate-cards/${id}`, $config),
        updateOne: (id, $body, $config) => axios.put(`/user/rate-cards/${id}`, $body, $config),
        delete: ($body, $config) => axios.patch("/user/rate-cards/delete", $body, $config),
        upload: ($body, $config) => axios.post("/user/rate-cards/upload", $body, $config),
        download: (id, $config) => axios.get(`/user/rate-cards/${id}/download`, $config),
        sendRateCard: ($body, $config) => axios.post("/user/rate-cards/send-rate-card", $body, $config),
        diagnose: ($body, $config) => axios.post("/user/rate-cards/diagnose", $body, $config),
    },

    revenue: {
        getStats: (params, $config) => axios.get("/user/revenue/stats", { params }, $config),
        getHistory: (params, $config) => axios.get("/user/revenue/list", { params }, $config),
        getGoals: (params, $config) => axios.get("/user/revenue/goals", { params }, $config),
        createGoal: ($body, $config) => axios.post("/user/revenue/goals", $body, $config),
        editGoal: (id, $body, $config) => axios.put(`/user/revenue/goals/${id}`, $body, $config),
        deleteGoal: (id, params, $config) => axios.delete(`/user/revenue/goals/${id}`, { params }, $config),
    },

    user: {
        delete: ($body, $config) => axios.delete("/user/users/delete", $body, $config),
        get: (params, $config) => axios.get("/user/users/fetchprofile", { params }, $config),
        getCalendar: ($body, $config) => axios.post("/user/users/calendar", $body, $config),
        getReports: (params, $config) => axios.get("/user/users/reports", { params }, $config),
        update: ($body, $config) => axios.put("/user/users/editprofile", $body, $config),
        changePassword: ($body, $config) => axios.put("/user/users/change-password", $body, $config),
        updateCalendarProvider: (action, $body, $config) => axios.put(`/user/users/update-calendar-provider/${action}`, $body, $config),
    },

    meeting: {
        getMeeting: (id, $config) => axios.get(`/user/meetings/${id}`, $config),
        getMeetings: (params, $config) => axios.get("/user/meetings", { params }, $config),
        modifyNotes: (id, $body, $config) => axios.put(`/user/meetings/${id}`, $body, $config),
        connectCalendar: ($body, $config) => axios.post("/user/meetings/connect-calendar", $body, $config),
    },

    settings: {
        getGoals: (params, $config) => axios.get("/user/settings/goals", { params }, $config),
        getNotifications: (params, $config) => axios.get("/user/settings/notifications", { params }, $config),
        getIntegrations: (params, $config) => axios.get("/user/settings/integrations", { params }, $config),
        toggleIntegrationStatus: (id, $body, $config) => axios.put(`/user/settings/integrations/${id}`, $body, $config),
        removeIntegration: ($id, $config) => axios.delete(`/user/settings/integrations/${id}`, $body, $config),
        addIntegration: ($body, $config) => axios.post("/user/settings/integrations", $body, $config),
        getSettings: (params, $config) => axios.get("/user/settings", { params }, $config),
        updateGoals: ($body, $config) => axios.put("/user/settings/goals", $body, $config),
        updateNotifications: ($body, $config) => axios.put("/user/settings/notifications", $body, $config),
        updateSettings: ($body, $config) => axios.put("/user/settings", $body, $config),
    },

    tag: {
        create: ($body, $config) => axios.post("/user/tags", $body, $config),
        getTags: (params, $config) => axios.get("/user/tags", { params }, $config),
        updateTag: (id, $body, $config) => axios.put(`/user/tags/${id}`, $body, $config),
        deleteTag: ($body, $config) => axios.patch("/user/tags/delete", $body, $config),
    },
    
    task: {
        addTask: ($body, $config) => axios.post("/user/tasks", $body, $config),
        duplicateTask: ($body, $config) => axios.post("/user/tasks/duplicate", $body, $config),
        getTasks: (params, $config) => axios.get("/user/tasks", { params }, $config),
        getTask: (id, $config) => axios.get(`/user/tasks/${id}`, $config),
        editTask: (id, $body, $config) => axios.put(`/user/tasks/${id}`, $body, $config),
        deleteTask: (id, $config) => axios.delete(`/user/tasks/${id}`, $config),
        modifyNotes: (id, $body, $config) => axios.put(`/user/tasks/${id}/notes`, $body, $config),
    },

    waitlist: {
        joinWaitlist: ($body, $config) => axios.post("/user/waitlist/register", $body, $config),
    },
};

export default api;