<template>
    <div v-click-outside="closeDropdown" class="dropdown" :class="[disabled ? 'dropdown--disabled' : '']" v-bind="$attrs">
        <button type="button" :class="[btnClasses, `dropdown-btn-${size}`]" class="btn button--outline__secondary dropdown-btn" @click="toggleDropdown">
            <span v-if="reactiveSelected">
                <template v-if="typeof reactiveSelected === 'string'">
                    <div>
                        <span>{{ reactiveSelected }}</span>
                    </div>
                </template>

                <template v-else-if="typeof reactiveSelected === 'object'">
                    <template v-if="!multiple">
                        <div>
                            <span>{{ computeOptionItem(reactiveSelected) }}</span>
                        </div>
                    </template>

                    <template v-else>
                        <div class="dropdown--selection">
                            <span v-for="item in reactiveSelected" :key="item._id" class="dropdown--item--multiple">
                                <span class="dropdown--item-desc">{{ computeOptionItem(item) }}</span>
                                <span class="pointer inline-block" @click.stop="$emit('remove', item)">
                                    <IconSvg name="close" width="16px" fill="#ced4da" />
                                </span>
                            </span>
                        </div>
                    </template>
                </template> 

                <template v-else />
            </span>
            <template v-else>
                <p />
            </template>
            <span class="pointer inline-block dropdown-btn-icon">
                <IconSvg :name="showDropdown ? 'chevron-up' : 'chevron-down'" width="16px" fill="#ced4da" />
            </span>
        </button>

        <transition name="fade">
            <div v-if="showDropdown" class="dropdown--menu w--100">
                <form v-if="showSearchInput" class="dropdown-form" @submit.prevent="">
                    <input
                        v-model="searchQuery" name="search" type="text"
                        autofocus :placeholder="placeholder || `Search ${listType}...`"
                    > 
                    <span class="pointer" @click="clearSearch">
                        <icon-svg fill="rgba(194, 200, 212, 1)" :name="searchQuery ? 'close' : 'search'" width="16px" /> 
                    </span>
                </form>
    
                <div>
                    <template v-if="loading">
                        <div class="text--color-title text--sm p--25 text-center">
                            <PageSpinner class="mt--5 mb--5" size="sm" />
                        </div>
                    </template>
        
                    <template v-else>
                        <ul v-if="options.length" class="dropdown-list mb--0" :class="{ 'h--auto': options.length < 6 }">
                            <div v-if="showListTitle" class="dropdown--title">
                                All {{ listType }}
                            </div>
                            
                            <li
                                v-for="(item, index) in options"
                                :key="index" :class="[listItemClass]"
                                tabindex="0" class="dropdown-item dropdown--item"
                                @click="onSelect(item)" @keyup.enter="onSelect(item)"
                            >
                                <div class="flex flex__item--grow align-items-center">
                                    <slot name="prefix" :item="item" />
        
                                    <p class="dropdown--item-label">
                                        {{ computeOptionItem(item) }}
                                    </p>

                                    <slot name="suffix" :item="item" />
                                </div>
                            </li>
                        </ul>
        
                        <div v-else class="text--color-title text--sm p--25 text-center">
                            No results found
                        </div>
                    </template>
                </div>
            </div>
        </transition>
    </div>
</template>
  
<script>  
import IconSvg from "../icons/Icon-Svg.vue";
import PageSpinner from "../loaders/PageSpinner.vue";
import { debounce } from "@/utils/helpers";

export default {
    name: "CustomDropdown",

    components: { IconSvg, PageSpinner },

    directives: {
        "click-outside": {
            bind(el, binding) {
                const handler = (e) => {
                    if (!el.contains(e.target)) {
                        binding.value();
                    }
                };
                el.clickOutsideEvent = handler;
                document.addEventListener("click", handler);
            },
            unbind(el) {
                document.removeEventListener("click", el.clickOutsideEvent);
            },
        },
    },

    props: {
        options: { type: Array, default: () => [] },
        listType: { type: String, default: "" },
        indexLabel: { type: String, default: "" },
        disabled: { type: Boolean, default: false },
        searchable: { type: Boolean, default: true },
        loading: { type: Boolean, default: false },
        modelValue: { type: [String, Object, Array], default: undefined },
        multiple: { type: Boolean, default: false },
        showListTitle: { type: Boolean, default: false },
        closeOnSelect: { type: Boolean, default: true },
        clearOnSelect: { type: Boolean, default: false },
        prefix: { type: Boolean, default: false },
        suffix: { type: Boolean, default: false },
        btnClasses: { type: String, default: "" },
        placeholder: { type: String, default: "" },
        listItemClass: { type: String, default: "" },
        fetchListOnShow: { type: Boolean, default: false },
        size: { 
            type: String, 
            default: "md",
            validator: (value) => {
                return ["sm", "md", "lg"].includes(value);
            },
        },
    },

    emits: ["update:modelValue", "select", "search", "remove", "fetch-list-show"],

    data() {
        return {
            searchQuery: "",
            reactiveSelected: this.modelValue || undefined, // string, object or array of string or object items
            showDropdown: false,
        };
    },

    computed: {
        showSearchInput() {
            if (this.searchable && this.options.length < 5 && !this.searchQuery) {
                return false;
            }

            return true;
        },
    },

    watch: {
        modelValue(newValue) {
            this.updateDisplayValue(newValue);
        },

        searchQuery(newVal) {
            this.searchList(newVal);
        },

        showDropdown(newVal) {
            if (newVal && this.fetchListOnShow) {
                this.$emit("fetch-list-show");
            }
        },
    },

    methods: {
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },

        closeDropdown() {
            // console.log("run closeDropdown function");
            this.showDropdown = false;
        },

        computeOptionItem(item) { // returns a string or an object
            if (typeof item === "string") {
                return item;
            }
            
            if (typeof item === "object") {
                if (!this.indexLabel) {
                    return undefined;
                }
                return item[this.indexLabel];
            } 

            return item;
        },

        updateDisplayValue(value) { // string, object or an array            
            if (!value) return;

            if (this.multiple && Array.isArray(this.reactiveSelected)) { // model value is an array
                this.reactiveSelected = value;
                return;
            }

            // model value is an object or string
            const selectedItem = this.options.find((val) => {     
                // string
                if ((typeof value === "string" && typeof val === "string") && val === value) {
                    return value;  // arts
                }  
                
                // object 
                if ((typeof value === "object" && typeof val === "object") && this.indexLabel) {
                    return val[this.indexLabel] === value[this.indexLabel];
                }
            });

            if (!selectedItem) {
                this.reactiveSelected = undefined;
            } else {
                this.reactiveSelected = selectedItem;
            }
        },

        onSelect(item) {
            this.$emit("select", item);

            this.showDropdown = false;
            this.searchQuery = "";
        },

        clearSearch() {
            this.searchQuery = "";
        },

        searchList(newVal) {                        
            debounce(() => {
                this.$emit("search", newVal);
            }, 200, "search-list");
        },
    },
};

</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>