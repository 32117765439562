
<template>
    <div>
        <MainModal 
            :show-modal="showModal" size="sm" title="Update status" @close="closeModal"
        >
            <template #body>
                <form @submit.prevent="">
                    <div v-for="item in statusList" :key="item" class="form-check text--capitalize">
                        <input
                            id="status" v-model="formStatus" class="form-check-input" type="radio"
                            name="inlineRadioOptions" :value="$str_to_lowercase(item)"
                        >
                        <label class="form-check-label text--sm" for="status">{{ item }}</label>
                    </div>
                </form>
            </template>

            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <base-button 
                        class="mr--5" variant="outline"
                        label="Cancel" size="sm"
                        :disabled="loading" 
                        @submit="closeModal"
                    />

                    <base-button 
                        :loading="loading" :disabled="loading || !formStatus" 
                        type="submit" size="sm"
                        label="Update" @submit="handleContinue"
                    />
                </div>
            </template>
        </MainModal>
    </div>
</template>

<script>
import MainModal from "./MainModal.vue";
import BaseButton from "../buttons/BaseButton.vue";

export default {
    name: "UpdateStatus",

    components: { MainModal, BaseButton },

    props: {
        currentStatus: {
            type: String,
            default: "",
        },

        showModal: {
            type: Boolean,
            default: false,
        },

        statusList: {
            type: Array,
            default: () => [],
        },

        loading: {
            type: Boolean,
            default: false,
        },
    },

    emits: ["cancel", "submit"],

    data() {
        return {
            formStatus: this.currentStatus || "",
        };
    },

    watch: {
        currentStatus(newVal) {
            if (newVal) {
                this.formStatus = newVal;
            }
        },
    },

    mounted() {
        this.formStatus = this.currentStatus;
    },

    methods: {
        closeModal() {
            this.$emit("cancel");
        },

        handleContinue() {
            const _id = this.$route.params.id;
            const status = this.formStatus.toLowerCase() || "";

            this.$emit("submit", { _id, status });
        },
    },
};
</script>