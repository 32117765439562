<template>
    <div class="dropdown" v-bind="$attrs">
        <BaseButton 
            id="groupFilter" 
            type="BaseButton" variant="outline" inner-style="secondary"
            aria-expanded="false" size="sm"
            data-bs-toggle="dropdown" data-bs-auto-close="true" 
        >
            <span class="flex align-items-center justify-content-between">
                <div class="flex align-items-center mr--5">
                    <IconSvg name="archive" fill="#637579" width="16px" height="16px" />
                    <span class="ml--5 text--xs">Group</span>
                </div>

                <span v-if="value !== 'none'" class="group__active">
                    <IconSvg name="check" fill="#7d6ab9" width="12px" height="12px" />
                </span>
            </span>
        </BaseButton>
       
        <ul id="groupFilterList" class="dropdown-menu dropdown-menu--tag" aria-labelledby="groupFilter">
            <li 
                v-for="(item, index) in items" 
                :key="index" 
                :class="{ 'selected': value === item }"
                class="dropdown-item pointer text--xs" 
                @click="setDisplayType(item)"
            >
                <span class="flex align-items-center justify-content-between" :class="{ 'btn--ghost__primary': value === item }">
                    <span class="text--xs text--capitalize">{{ item }}</span>
                    <icon-svg
                        v-if="value === item" name="check" fill="#637579" width="16px"
                        height="16px"
                    />
                </span>
            </li>
        </ul>
    </div>
</template>

<script>
import BaseButton from "../buttons/BaseButton.vue";
import IconSvg from "../icons/Icon-Svg.vue";

export default {
    name: "GroupFilter",

    components: { IconSvg, BaseButton },
    
    props: {
        value: {
            type: String,
            default: "none",
        },
        items: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        setDisplayType(val) {
            this.$emit("select", val);
            
            setTimeout(() => {
                document.getElementById("groupFilter").classList.remove("show");
                document.getElementById("groupFilterList").classList.remove("show");
            }, 100);
        },
    },
};
</script>