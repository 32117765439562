import { textSpacer } from "./formatters";
import axios from "axios";

export function phraseToSentenceCase(phrase = "", ignored = []) {
    if (phrase.length === 0) {
        return phrase;
    }

    const words = phrase.split(" ").map((w) => {
        const v = w.toLowerCase();
        const prepositions = ["of", "for", "and"];
        if (prepositions.includes(v)) {
            return v;
        } else if (ignored.includes(w)) {
            return w;
        }
        return (v[0] || "").toUpperCase() + (v.slice(1) || "");
    });

    return words.join(" ");
}

export function capitalizeFirstLetter(str = "") {
    const words = (str + "").toLowerCase().split(" ");
    const firstWord = (words[0] || "");
    const capiitalizedFirstWord = (firstWord[0] || "").toUpperCase() + firstWord.slice(1);
    return capiitalizedFirstWord + " " + words.slice(1).join(" ");
}

export function capitalizeAllFirstLetters(str = "") {
    const words = (str + "").toLowerCase().split(" ");

    const result = words.map((word) => {
        if (word) {
            const firstChar = word.charAt(0).toUpperCase();
            return `${firstChar}${word.slice(1)}`;
        }

        return word;
    });

    return result.join(" ");
}

export function formatAmount(amount = 0, dp = 3) {
    try {
        return (parseFloat(amount).toFixed(dp) * 1).toLocaleString("en", { maximumFractionDigits: dp, minimumFractionDigits: dp });
    } catch {
        return 0.000;
    }
}

export function formatNumber(num) {
    if (num === null || num === undefined) {
        return 0;
    }
    num += "";
    num = num.replace(/,/g, ""); // This is so filter still works if it's used on an already formatted num
    num = num * 1;

    num = num.toFixed(2); // Keep the 2dp consistent
    num = num * 1; // Convert back to number so we can apply locale string

    num = num.toLocaleString("en"); // EN so we always get the commafied version.
    return num;
}

export function formatPhone(num = "") {
    num = num + "";
    if ((num).startsWith("+")) {
        return "+" + textSpacer(num.slice(1), [3, 3, 4]);
    } else {
        return textSpacer(num, [3, 3, 4]);
    }
}

export function formInitials(item = "") {
    if (item !== null && item !== "") {
        let initials = "";
        const name = item.split(" ");
        if (typeof name[1] === "undefined") {
            initials = item.slice(0, 2);
        } else {
            initials = name[0].slice(0, 1) + name[1].slice(0, 1);
        }
        return (initials + "").toUpperCase();
    }
};

export function htmlToText(str = "") {
    return str.replace(/<[^>]*>/g, "");
};

export function initials(str = "") {
    if (!str) {
        return "";
    }

    let initials = "";
    str = str + "";

    const name = str.split(" ");

    if (typeof name[1] === "undefined") {
        initials = str.slice(0, 2);
    } else {
        initials = name[0].slice(0, 1) + name[1].slice(0, 1);
    }

    return initials;
}

export function toSimpleLowercase(str, igoredWords = []) {
    if (!str || typeof str !== "string") return;

    return str.toLowerCase();
}

export function toLowercase(str, igoredWords = []) {
    const ignored = ["ip", "PAN", "PANs", ...igoredWords];
    if (!str) {
        return str;
    }

    const words = str.split(" ");
    const lowers = [];
    for (const word of words) {
        if (ignored.map(w => w.toLowerCase()).includes(word.toLowerCase())) {
            lowers.push(word);
        } else {
            lowers.push(word.toLowerCase());
        }
    }
    return lowers.join(" ");
}

const last4 = (value = "") => {
    let v = (value + "");
    v = v.slice(v.length - 4);
    return v;
};

export function formatDate(date) {
    if (!date) return "";
    
    return new Date(date).toDateString();
}

export const replaceCharInString = (value, char, replaceWith = " ") => {
    return value.replaceAll(char, replaceWith);
};

export function generateTagColours() {
    const colours = ["#E9E1FF", "#FADDE8", "#E5E5E5", "#D2CBF1", "#bed3e6"];

    var randomColour = colours[Math.floor(Math.random() * colours.length)];
    return randomColour;
}

export function splitStr(str) {
    if (!str || typeof str !== "string") return;

    // Split the input string based on capital letters
    const strArr = str.split(/(?=[A-Z])/);
    
    // Join the separated array with spaces
    const separatedString = strArr.join(" ");

    return separatedString;
}

export function makeStrLowerCase(str) {
    if (!str || typeof str !== "string") return;

    // Split the input string based on capital letters
    return str.toLowerCase();
}

export function makeStrUpperCase(str) {
    if (!str || typeof str !== "string") return;
    return str.toUpperCase();
}


export function mapSymbol(code) {
    if (!code || typeof code !== "string") return "";
    
    const obj = { 
        "usd": "$", 
        "eur": "€",
        "xof": "CFA",
        "xaf": "₣", 
        "cad": "C$", 
        "egp": "E£",
        "etb": "Br",
        "ghs": "GH₵",
        "kes": "KSh",
        "rwf": "FRw",
        "zar": "R",
        "ugx": "Ush",
        "gbp": "£",
        "ngn": "N",
    };

    return obj[code] ?? "";
}

export default {
    install: (app) => {
        // Plugin code goes here
        app.config.globalProperties.$initials = initials;
        app.config.globalProperties.$axios = axios;
        app.config.globalProperties.$phrase_to_sentence_case = phraseToSentenceCase;
        app.config.globalProperties.$capitalize_first_letter = capitalizeFirstLetter;
        app.config.globalProperties.$format_amount = formatAmount;
        app.config.globalProperties.$format_date = formatDate;
        app.config.globalProperties.$format_number = formatNumber;
        app.config.globalProperties.$form_initials = formInitials;
        app.config.globalProperties.$html_to_text = htmlToText;
        app.config.globalProperties.$to_lowercase = toLowercase;
        app.config.globalProperties.$to_simple_lowercase = toSimpleLowercase;
        app.config.globalProperties.$last4 = last4;
        app.config.globalProperties.$replace_char = replaceCharInString;
        app.config.globalProperties.$capitalize_all_first_letters = capitalizeAllFirstLetters;
        app.config.globalProperties.$generate_tag_colours = generateTagColours;
        app.config.globalProperties.$split_str = splitStr;
        app.config.globalProperties.$str_to_lowercase = makeStrLowerCase;
        app.config.globalProperties.$str_to_uppercase = makeStrUpperCase;
        app.config.globalProperties.$map_symbol = mapSymbol;
    },
};