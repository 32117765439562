<template>
    <div class="landing__footer">
        <div class="landing__footer__intro">
            <div>
                <span class="landing__nav__item one" href="">
                    <img src="@/assets/img/logo-large.svg">
                </span>
            </div>
            <div class="bg__control" @click="$emit('toggle-background')">
                <span v-if="isNoisyBg" class="mr--5">Turn off noisy background</span>
                <span v-else class="mr--5">Turn on noisy background</span>
                <span v-if="isNoisyBg">
                    <svg
                        xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"
                        style="transform: ;msFilter:;"
                    ><path d="m5.705 3.71-1.41-1.42C1 5.563 1 7.935 1 11h1l1-.063C3 8.009 3 6.396 5.705 3.71zm13.999-1.42-1.408 1.42C21 6.396 21 8.009 21 11l2-.063c0-3.002 0-5.374-3.296-8.647zM12 22a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22zm7-7.414V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.184 4.073 5 6.783 5 10v4.586l-1.707 1.707A.996.996 0 0 0 3 17v1a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-1a.996.996 0 0 0-.293-.707L19 14.586z" /></svg>
                </span>
                <span v-else>
                    <svg
                        xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"
                        style="transform: ;msFilter:;"
                    ><path d="M20.345 18.931A.993.993 0 0 0 21 18v-1a.996.996 0 0 0-.293-.707L19 14.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258c-1.323.364-2.463 1.128-3.346 2.127L3.707 2.293 2.293 3.707l18 18 1.414-1.414-1.362-1.362zM12 22a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22zM5 10v4.586l-1.707 1.707A.996.996 0 0 0 3 17v1a1 1 0 0 0 1 1h10.879L5.068 9.189C5.037 9.457 5 9.724 5 10z" /></svg>                </span>
            </div>
        </div>

        <div class="landing__footer__links">
            <ul class="landing__footer__list">
                Overview
                <li><span href="">Why Ziner?</span></li>
                <!-- <li><span href="">Templates</span></li> -->
                <!-- <li><span href="">Demo</span></li> -->
                <li><span href="">Pricing</span></li>
            </ul>
            <ul class="landing__footer__list">
                resources
                <li><span href="">Blog</span></li>
                <li><span href="">Features</span></li>
                <li><span href="">FAQs</span></li>
                <li><span href="">Integrations</span></li>
            </ul>
            <ul class="landing__footer__list">
                company
                <!-- <li><span href="">About us</span></li> -->
                <li><span href="">Contact</span></li>
            </ul>
            <ul class="landing__footer__list">
                legal
                <li><span href="">Terms</span></li>
                <li><span href="">Privacy policy</span></li>
            </ul>
        </div>
        <div class="landing__footer__copyright">
            <!-- <p>Copyright @Ziner |  {{ currentYear }}</p> -->
            <p>© Ziner |  {{ currentYear }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "LandingFooter",
    props: {
        isNoisyBg: {
            type: Boolean,
            default: true,
        },
    },
    data(){
        return {
            currentYear: undefined,
        };
    },
    mounted() {
        const currentYear = new Date().getFullYear();
        this.currentYear = currentYear;
    },

    methods: {
        
    },
};
</script>