import api from "@/api/url";
import { sortList } from "@/utils/helpers";

export default {
    namespaced: true,
    
    state: () => {
        return {
            activeIntegrations: [],
            allIntegrations: [],
        };
    },

    getters: {
        activeIntegrations: (state) => state.activeIntegrations,
        allIntegrations: (state) => state.allIntegrations,
    },

    mutations: {
        set(state, [key, value]) {
            state[key] = value;
        },
    },
    
    actions: {
        fetchIntegrations({ commit, state }, { params, refresh }) {
            if (!refresh && state.allIntegrations.length) return;

            return api.integration.getAll(params)
                .then((resp) => {
                    const data = resp?.data?.data;
                    commit("set", ["allIntegrations", data]);
                });
        },

        getOneIntegration(_, id) { 
            return api.integration.getOne(id)
                .then((resp) => {
                    const data = resp?.data?.data;
                    return data;
                });
        },

        addUserIntegration({ state, commit }, payload) {
            return api.client.create(payload)
                .then((resp) => {
                    const data = resp.data.data;

                    commit("set", ["clients", [ data, ...state.clients ]]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total + 1,
                        totalPages: Math.ceil(state.clients.length / 50),
                    }]);
                    return data;
                });
        },

        fetchUserIntegrations({ commit, state }, { params, refresh }) {
            if (!refresh && state.activeIntegrations.length) return;

            return api.settings.getIntegrations(params)
                .then((resp) => {
                    const data = resp?.data?.data;
                    commit("set", ["activeIntegrations", data]);
                });
        },

        updateClient({ state, commit }, payload) {
            return api.client.updateOne(payload?._id, payload)
                .then((resp) => {
                    const data = resp?.data?.data;
                    const clients = state.clients;

                    const updatedClients = clients.map(c => {
                        if (c?._id === payload?._id) {
                            return {
                                ...c,
                                ...data,
                            };
                        } else {
                            return c;
                        }
                    });
        
                    commit("set", ["clients", updatedClients]);         
                    return data;
                });
        },

        deleteClients({ state, commit }, ids) {
            return api.client.delete({ ids: ids })
                .then((resp) => {
                    const clients = state.clients;
            
                    const updatedClients = clients.filter(c => !ids.includes(c?._id));

                    commit("set", ["clients", updatedClients]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total - 1,
                        totalPages: Math.ceil(state.updatedClients.length / 50),
                    }]);
                });
        },

        setInvoiceListPagination({ state, commit, dispatch }, id) {            
            const projects = state.projects.filter(c => c?._id !== id);

            dispatch("setCurrentProject", { payload: null, type: "reset" });

            commit("set", ["projects", projects]); 
        },
    },
};