<template>
    <teleport to="body">
        <div v-if="showModal" class="modal" :class="{ show: showModal }" @click.self="closeModal">
            <div class="modal__dialog" :class="[`modal__dialog--${size}`]" :style="modalStyles">
                <div role="document" class="relative h--100">
                    <div v-if="closeBtn || title" class="modal__header modal__dialog--header" :class="{ 'with__title': title }">
                        <h2 v-show="title" class="modal__header__title">
                            {{ title }}
                        </h2>
                        <button v-if="closeBtn" class="close" @click="$emit('close')">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" style="fill: rgba(160, 158, 167, 1);transform: ;msFilter:;"
                            >
                                <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z" />
                            </svg>
                        </button>
                    </div>
                    
                    <div class="modal__body">
                        <slot name="body" />
                    </div>
                    
                    <div v-show="footer" class="modal__footer">
                        <slot name="footer" />
                    </div>
                </div>
            </div>
        </div>
    </teleport>      
</template>


<script>
export default {
    name: "MainModal",
    
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
        closeBtn: {
            type: Boolean,
            default: true,
        },
        size: {
            type: String,
            default: "md",
            validator: (value) => {
                return [ "sm", "md", "lg"].includes(value);
            },
        },
        width: {
            type: String,
            default: "",
        },
        position: {
            type: String,
            default: "center",
            validator: (value) => {
                return [ "center", "top-right", "top-left", "bottom-right", "bottom-left"].includes(value);
            },
        },
        title: {
            type: String,
            default: "",
        },
        footer: {
            type: Boolean,
            default: true,
        },
    },

    emits: ["close"],

    data() {
        return {
            modalStyles: {
                "width": !this.width ? "" : this.width,
                "margin-left": this.position === "center" ? "auto" : "",
                "margin-right": this.position === "center" ? "auto" : "",
            },
        };
    },
};
</script>