import api from "@/api/url";

export default {
    namespaced: true,

    state: () => {
        return {
            currentRoute: {
                main: "",
                sub: "",
            },
            paths: [],
            completionStatus: "",
            completedProjectCount: 0,
            pdfFile: null,
            userSettings: {
                twoFactorAuth: {
                    authenticatorApp: {},
                    sms: {},
                },
                general: {
                    language: "en-US",
                    timeZone: "",
                    timeFormat: "12h",
                    dateFormat: "DD/MMMM/YYYY",
                    dayOfWeek: "sunday",
                    currency: "usd",
                    monthlyTarget: 0,
                    currentMonthlyTarget: 0,
                },
                appearance: {
                    theme: "default",
                },
                integrations: [],
                notifications: [],
                goals: {
                    _id: "",
                    isGoalsEnabled: false,
                    isProductivityEnabled: false,
                    goals: [],
                    productivity: {
                        status: "",
                        workingDays: [],
                    },
                },
            },
            activities: {
                list: [],
                pageData: {
                    currentPage: 1,
                    totalPages: 0,
                    pageSize: 100,
                    total: 0,
                },
            },
            pageWrapperLoading: false,
            isNewUser: false,
            showRankModal: false,
            showConfetti: false,
            calendarRefreshList: false,
            checkUpgradeLoading: false,
            checkUpgradeFields: {
                invoices: false,
                clients: false,
                projects: false,
                rateCards: false,
                calendar: false,
                integrations: false,
            },
            showUpgradeModal: false,
            unReadActivityCount: 0,
        };
    },

    getters: {
        currentRoute: state => state.currentRoute,
        paths: state => state.paths,
        completionStatus: state => state.completionStatus,
        completedProjectCount: state => state.completedProjectCount,
        pdfFile: state => state.pdfFile,
        userSettings: state => state.userSettings,
        notifications: state => state.notifications,
        activities: state => state.activities,
        pageWrapperLoading: state => state.pageWrapperLoading,
        isNewUser: state => state.isNewUser,
        showRankModal: state => state.showRankModal,
        showConfetti: state => state.showConfetti,
        calendarRefreshList: state => state.calendarRefreshList,
        checkUpgradeLoading: state => state.checkUpgradeLoading,
        checkUpgradeFields: state => state.checkUpgradeFields,
        showUpgradeModal: state => state.showUpgradeModal,
        unReadActivityCount: state => state.unReadActivityCount,
    },

    mutations: {
        set(state, [key, value]) {
            state[key] = value;
        },
    },
    
    actions: {
        async checkUpgradeStatus({ state, commit }, type) {            
            try {
                commit("set", ["checkUpgradeLoading", true]);
                commit("set", ["checkUpgradeFields", {
                    ...state.checkUpgradeFields,
                    [`${type}`]: true,
                }]);
                commit("set", ["showUpgradeModal", false]);

                await api.app.checkUpgradeStatus({ type: type });

                commit("set", ["checkUpgradeLoading", false]);
                commit("set", ["checkUpgradeFields", {
                    ...state.checkUpgradeFields,
                    [`${type}`]: false,
                }]);
            } catch (error) {
                commit("set", ["checkUpgradeLoading", false]);
                const upgradeErrorMsg = error?.response?.data?.message;

                if (typeof upgradeErrorMsg === "string" && upgradeErrorMsg.includes("upgrade")) {
                    commit("set", ["checkUpgradeFields", {
                        ...state.checkUpgradeFields,
                        [`${type}`]: false,
                    }]);
                }             
                commit("set", ["showUpgradeModal", true]);
                throw new Error(error);
            }
        },

        closeUpgradeModal({ commit, state }, type) {
            commit("set", ["checkUpgradeFields", {
                ...state.checkUpgradeFields,
                [`${type}`]: false,
            }]);         
            commit("set", ["showUpgradeModal", false]);
        },
        
        setCurrentRouteTwo({ commit, state }, routePath) {
            const paths = typeof routePath === "string" && routePath.split("/");
            const modifiedPaths = [];

            for (let i = 0; i < paths.length; i++) {
                if (paths[i] !== "" && paths[i] !== "dashboard") {
                    if (paths[i].includes("and")) {
                        const replacedPath = paths[i].replace("-and-", " & ");
                        modifiedPaths.push(replacedPath);
                    } else if (paths[i].includes("-")) {
                        const replacedPath = paths[i].replace("-", " ");
                        modifiedPaths.push(replacedPath);
                    } else {
                        modifiedPaths.push(paths[i]);
                    }
                }
            }

            commit("set", ["paths", modifiedPaths]);
            commit("set", ["currentRoute", { ...state.currentRoute, main: modifiedPaths[0] }]);
        },

        fetchCompletedProjectCount({ commit, state }, { refresh = false, manualUpdate = false }) {            
            if (state.completedProjectCount && !refresh) {
                return;
            }

            // if (manualUpdate) {
            //     commit("set", ["completedProjectCount", state.completedProjectCount + 1]);
            // }

            return api.app.getProjectCount()
                .then((resp) => {
                    const count = resp?.data.data.count;
                    commit("set", ["completedProjectCount", count]);
                });
        },

        generatePdf({ commit, state }) {

        }, 

        getCompletedStatus({ commit, state, rootGetters }) {

        }, 

        updateCompletedStatus({ commit, state }, boolValue) {

        },

        togglePageLoading({ commit }, bool) {
            commit("set", ["pageWrapperLoading", bool]);
        },
        
        // USER SETTINGS ---------------------------
        fetchSettings({ commit, state }, { refresh }) {
            if (state.userSettings?.accountPlan && !refresh) {
                return state.userSettings;
            }

            return api.settings.getSettings()
                .then((res) => {
                    const data = res.data.data;
                    commit("set", ["userSettings", data]);
                });
        },

        updateGeneralSettings({ state, commit }, payload) {
            return api.settings.updateSettings(payload)
                .then((resp) => {
                    const data = resp.data.data;
                    
                    commit("set", ["userSettings", {
                        ...state.userSettings,
                        ...data,
                    }]);
                });
        },

        fetchNotifications({ commit, state }, { refresh }) {
            if (state.notifications?.length && !refresh) {
                return state.notifications;
            }

            return api.settings.getNotifications()
                .then((res) => {
                    const data = res.data.data;

                    commit("set", ["notifications", data]);
                    return data;
                });
        },

        updateNotifications({ state, commit }, payload) {
            return api.settings.updateNotifications(payload)
                .then((resp) => {
                    const data = resp.data.data;
                    
                    commit("set", ["notifications", payload]);
                });
        },

        enableTwoFA(_) {},

        fetchGoals(_) {
            return api.settings.getGoals()
                .then((res) => {
                    const data = res.data.data;
                    return data;
                });
        },

        updateGoals({ state, commit }, payload) {
            return api.settings.updateGoals(payload)
                .then((resp) => {
                    const data = resp.data.data;
                    
                    commit("set", ["goals", {
                        ...state.goals,
                        ...payload,
                    }]);
                });
        },

        toggleNewUser({ commit }, bool) {
            commit("set", ["isNewUser", bool]);
        },

        toggleRank({ commit }, bool) {
            commit("set", ["showRankModal", bool]);
        }, 

        toggleRankConfetti({ commit }, bool) {
            commit("set", ["showConfetti", bool]);
        },

        fetchCalendar({ state, commit }) {
            if (state.calendarData) {
                return state.calendarData;
            }

            return api.settings.getCalendar()
                .then((res) => {
                    const data = res.data.data;
                    commit("set", ["calendarData", data]);
                });
        },

        updatePassword(_, payload) {
            return api.user.changePassword(payload)
                .then((res) => {});
        },

        // USER CALENDAR INTEGRATIONS ---------------------------
        setRefreshCalendarIntegrations({ commit }, bool) {
            commit("set", ["calendarRefreshList", bool]);
        },

        // USER ACTIVITIES --------------------------------------
        fetchUnreadActivityCount({ commit }) {            
            return api.app.fetchUnreadCount()
                .then((resp) => {
                    const count = resp?.data.data.count;
                    commit("set", ["unReadActivityCount", count]);
                });
        },

        fetchActivities({ commit, state, dispatch }, { refresh, params }) {
            if (state.activities?.length && !refresh) {
                return state.activities;
            }            

            return api.app.getActivityLogs(params)
                .then((res) => {
                    const data = res.data.data;

                    commit("set", ["activities", {
                        ...state.activities,
                        list: data.data,
                        pageData: {
                            ...state.activities.pageData,
                            ...data.pageDetails,
                        },
                    }]);

                    // fetch activity count
                    dispatch("fetchUnreadActivityCount").then(resp => {});
                });
        },

        markOneRead({ dispatch, state, commit }, id) {            
            return api.app.markOne(id)
                .then((resp) => {
                    const updatedLog = state.activities.list.filter(x => x.id !== id);

                    commit("set", ["activities", {
                        ...state.activities,
                        list: updatedLog,
                        pageData: {
                            ...state.activities.pageData,
                            total: state.activities.pageData.total - 1,
                            totalPages: Math.ceil((state.activities.pageData.total - 1) / state.activities.pageData.pageSize),
                        },
                    }]);

                    // fetch activity count
                    dispatch("fetchUnreadActivityCount").then(resp => {});
                });
        },

        markAllRead({ dispatch }, ids) {            
            return api.app.markAll(ids)
                .then((resp) => {
                    dispatch("fetchActivities", { refresh: true, params: { unread: true } });
                });
        },
    },
};