<template>
    <div>
        <Transition name="fade" :duration="300" mode="out-in">
            <Teleport to="body">
                <div
                    v-if="reactiveShow"
                    id="modalOverlay" class="modal--overlay" tabindex="-1"
                    role="dialog"
                >
                    <div 
                        class="modal--body modal--body--md" 
                        @keyup.stop.esc="hide"
                    >
                        <div ref="focusElem" class="visually-hidden modal__outline" tabindex="0" />
                        <div class="modal--content" @click.stop="$emit('click')">
                            <div class="modal--header">
                                <button type="button" class="modal--close" style="line-height: 0px;" @click="closeModal">
                                    <svg viewBox="0 0 24 24" class="icon_close" width="24" height="24">
                                        <path fill="currentColor" fill-rule="nonzero" d="M5.146 5.146a.5.5 0 0 1 .708 0L12 11.293l6.146-6.147a.5.5 0 0 1 .638-.057l.07.057a.5.5 0 0 1 0 .708L12.707 12l6.147 6.146a.5.5 0 0 1 .057.638l-.057.07a.5.5 0 0 1-.708 0L12 12.707l-6.146 6.147a.5.5 0 0 1-.638.057l-.07-.057a.5.5 0 0 1 0-.708L11.293 12 5.146 5.854a.5.5 0 0 1-.057-.638z" />
                                    </svg>
                                </button>
                            </div>

                            <div class="modal--upload--wrap">
                                <div>
                                    <p class="text--semi--bold text--color-gray-700 text--md text-center">
                                        Upload {{ uploadType }} list
                                    </p>

                                    <div class="mt--20">
                                        <p class="text--sm text--color-gray-100 text-justify">
                                            For a successful upload, please ensure your CSV file includes the column headers listed below. Note that the column headers marked as (<span class="text--color-warning">*</span>) are <strong>required</strong> and header names must be specifically written as shown below.
                                        </p>
                                        <p class="text--sm text--color-gray-100 mt--10 text-justify">
                                            Submitting row empty fields under those columns will lead to that specific row being excluded from added to your records.
                                        </p>
                                    </div>

                                    <div class="file--upload--header">
                                        <p v-for="item in headers" :key="item.title">
                                            {{ item.title }}
                                            <span v-if="item.required" class="text--color-warning">*</span>
                                        </p>
                                    </div>

                                    <div v-if="customText" class="file--upload--custom">
                                        <slot name="custom-text" />
                                    </div>
                                    
                                    <div class="file--upload--inner">
                                        <template v-if="progressValue">
                                            <div class="message">
                                                <LineProgressBar :value="progressValue" />
                                            </div>  
                                        </template>
                                        <template v-else>
                                            <div @click="handleFileBtnClick">
                                                <div class="message">
                                                    <div v-if="!isError"> 
                                                        <div>
                                                            <img src="@/assets/img/upload.svg" alt="upload arrow">
                                                        </div>

                                                        <div>
                                                            <span class="blue text--sm">Click here to upload file</span>
                                                        </div>
                                                    </div>

                                                    <div v-else>
                                                        <h2 class="text--base mb--5 text--semi--bold">
                                                            Invalid values uploaded
                                                        </h2>
                                                        <span class="text--sm">Check for invalid or duplicate values and click to upload file again</span>
                                                    </div>
                                                </div>  
                                                <input
                                                    ref="fileRef"
                                                    type="file"
                                                    :accept="mimeTypes"
                                                    :style="{ display: 'none' }"
                                                    @change="e => handleFileChange(e)"
                                                >
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Teleport>
        </Transition>
    </div>
</template>
  
<script>
import LineProgressBar from "../progress/Line.vue";
import BaseButton from "../buttons/BaseButton.vue";

export default {
    name: "FileUploadModal",

    components: { LineProgressBar, BaseButton },

    props: {
        show: { type: Boolean, default: false },
        customText: { type: Boolean, default: false },
        isError: { type: Boolean, default: false },
        mimeTypes: { type: String, default: "" },
        progressValue: { type: Number, default: 0 },
        fileContent: { type: Array, default: () => [] },
        uploadType: { type: String, default: "" },
        headers: { type: Array, default: () => [] },
    },

    emits: ["cancel", "click", "update:files"],

    data() {
        return {
            file: "",
            isParsed: false,
            fileRef: null,
            isFileUpload: false,
            reactiveShow: this.show || false,
            fileExtensions: {
                "text/csv": "csv",
                "text/json": "json",
            },
        };
    },

    watch: {
        "show": {
            async handler(newVal) {
                this.reactiveShow = newVal;
            },
        },
    },

    methods: {
        closeModal(){
            this.fileRef = null;
            this.isFileUpload = false;
            this.$emit("cancel");
        },

        hide(event) {
            event.preventDefault();
            event.stopPropagation();
        },

        validFileType(type) {    
            return ["csv", "json"].includes(type);
        },

        handleFileBtnClick(){    
            this.$refs.fileRef.click();
        },

        handleFileChange(event){
            const file = (event.target)?.files[0];
            
            if (file) {            
                this.isFileUpload = true;
                this.$emit("update:files", file);
            }
        },

        upgradeAccount() {
            this.$router.push("/dashboard/settings/billings-and-plans");
        },
    },
};
</script>

<style lang="scss" scoped>

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}

a:hover {
  color: #535bf2;
}

.file--upload {
    &--wrap {
      font-size: 4vmin;
      display: grid;
      grid-template-rows: 1fr 2em 1em;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 1em;
      min-width: 16em;
      cursor: pointer;
  }

  &--inner {
    border: 1px dashed #4299E1;
    background: #EDF2F7;
    border-radius: 5px;
    padding: 40px 10px; 
    cursor: pointer;
  }

  &--header {
    background: #F6F8FA;
    border-radius: 5px;
    padding: 10px;
    margin-top: 30px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    font-size: 12px;
    justify-content: space-between;

    p {
      color: #2D3748
    }
  }

  &--custom {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}

.modal--header span::after {
  font-size: 10px;
  position: absolute;
  top: 10rem;
  left: 1rem;
  font-family: monospace;
  content: attr(data-state);
  opacity: 0.2;
}

.file--upload--wrap:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #E5E9EE;
  border-radius: 1em;
  transition: transform .3s cubic-bezier(.5, 0, .5, 1);;
  box-shadow: 0 1em 2em rgba(black, 0.1);
  border: 2px dashed #d3d1d1;
}

.file--upload--wrap [data-hidden] {
  transition: all 0.4s cubic-bezier(.5, 0, .5, 1), visibility 0s linear 0.4s;
}
  
.file--upload--wrap [data-hidden="true"] { 
  visibility: hidden;
  opacity: 0;
}
  
.file--upload--wrap [data-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.message {
  overflow: hidden;
  text-align: center;
  font-size: 18px;
}

.message .blue {
  font-weight: 600;
  color: #0570FF;
}

.message strong { 
  display: block; 
  width: 100%; 
  text-align: center;
}
  
.message [data-hidden="true"] { 
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(100%);
}

.file--uploaded--wrap {
  width: 100%;
  margin-top: 1.2rem;
}

.modal--overlay {
  position: fixed; 
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%; 
  height: 100%; 
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  animation-timing-function: cubic-bezier(.4,0,.2,1);
  background-color: rgba(0,0,0,.5);
  display: flex;
  isolation: isolate;
  justify-content: center;
  overflow: hidden;
  transition: background-color .5s;
  z-index: 1000;
}

.modal--header {
  display: flex;
  position: relative;
  margin-bottom: 0.75rem;
  justify-content: flex-end;
}

.modal--body {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 32px;
  width: 869px;

  &--sm {
    width: 500px !important;

        @media screen and (max-width: 450px) {
            width: 300px;
        }
    }

    &--md {
        width: 650px !important;

        @media screen and (max-width: 800px) {
            width: 500px;
        }
    }
}

.modal--content {
  background-color: #fefefe;
  margin: 5rem auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  border-radius: 10px;
  /* min-height: 407px; */
}

.modal--close {
  color: #aaaaaa;
  font-size: 28px;
  outline: none;
  text-align: center;
  border: none;
  background: transparent;
  cursor: pointer;
  font-weight: 600;
}

.modal--close:hover, .modal--close:focus {
  color: #000;
}
</style>