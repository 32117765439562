<template>
    <div class="h-100 content__page">
        <PageSpinner />
    </div>
</template>

<script>
import { PageSpinner } from "@/components/ui/index";

export default {
    name: "AuthHandlerView",

    components: {
        PageSpinner,
    },

    data() {
        return {
            pageLoading: false,
            pageErr: false,
            pageErrMsg: "",
            showConnectModal: false,
            authType: "",
        };
    },

    watch: {
        "pageErr": {
            async handler(newVal) {
                if (newVal) {
                    if (this.authType === "calendar") {
                        this.$router.push("/dashboard/settings/calendars");
                    }
                }
            },
            immediate: true,
        },
    },

    async mounted() {
        const params = new URLSearchParams(window.location.search);
        const code = params.get("code");
        const redirect_uri = params.get("redirect_uri");
        const calendarProviderName = localStorage.getItem("z:calendar_provider") || "";
        
        this.authType = localStorage.getItem("z:auth_type") || "";

        if (code && this.authType) {
            const payload = {
                code, 
                redirect_uri, 
            };

            if (this.authType === "calendar") {
                payload.providerName = calendarProviderName;
            }

            this.handleConnectResponse(payload);
        } 
    },

    methods: {
        async handleConnectResponse(data) {
            try {
                this.pageErr = false;
                this.pageLoading = true;

                await this.$store.dispatch("auth/connectResponseCb", data);

                if (this.authType === "calendar") {
                    this.$router.push("/dashboard/settings/calendars");
                }
            } catch (error) {
                this.pageErr = true;
                const errMsg = error?.response?.data?.message || error.message;
                this.$toast.error(errMsg);
            } finally {
                this.pageLoading = false;
            }
        },
    },
};
</script>