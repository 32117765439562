<template>
    <div class="btn-group" role="group">
        <BaseButton
            :label="label" v-bind="$attrs"
            class="dropdown-toggle" :size="size"
            data-bs-toggle="dropdown" aria-expanded="false"
            :loading="loading"
            :prefix-icon="prefixIcon"
            :suffix-icon="suffixIcon"
            :bold="bold"
            :medium="medium"
            :disabled="disabled"
            :icon-name="iconName"
            :icon-fill="iconFill"
            :icon-width="iconWidth"
            :type="type"
            :full-width="fullWidth"
            :variant="variant"
            :inner-style="innerStyle"
        />
        <ul class="dropdown-menu dropdown--menu">
            <slot name="dropdown-content" />
        </ul>
    </div>
</template>

<script>
import BaseButton from "./BaseButton.vue";

export default {
    name: "DropdownButton",

    components: {
        BaseButton,
    },

    props: {
        loading: { type: Boolean, default: false },
        prefixIcon: { type: Boolean, default: false },
        suffixIcon: { type: Boolean, default: false },
        bold: { type: Boolean, default: false },
        medium: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        iconName: { type: String, default: "" },
        iconFill: { type: String, default: "inherit" },
        iconWidth: { type: String, default: "16px" },
        type: { type: String, default: "button"},
        fullWidth: { type: Boolean, default: false },
        label: {
            type: String,
            default: "",
        },
        variant: { 
            type: String, 
            default: "default",
            validator: (value) => {
                return ["default", "secondary", "outline", "ghost", "warning"].includes(value);
            },
        },
        innerStyle: { 
            type: String, 
            default: "secondary",
            validator: (value) => {
                return ["", "primary", "secondary", "warning"].includes(value);
            },
        },
        size: { 
            type: String, 
            default: "md",
            validator: (value) => {
                return ["xs", "sm", "md", "lg"].includes(value);
            },
        },
    },
};
</script>