<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <div class="revenue__page">
        <div :class="{ 'min-h-inherit': !groupByKeys.length }">
            <template v-if="pageState.loading">
                <PageSpinner />
            </template>

            <template v-else-if="pageState.err">
                <EmptyPage state="error" type="earning" :handle-submit="handleFetchEarnings" />
            </template>

            <template v-else>
                <div 
                    class="content__page__body mt--20 w--100" 
                    :class="{ 
                        'min-h-inherit justify-content-center flex align-items-center': !groupByKeys.length 
                    }"
                >
                    <div v-if="earnings.length || isSearched" class="content__page__header filter__actions--list">
                        <main-filter page-type="revenue" />
                    </div>

                    <template v-if="!groupByKeys.length">
                        <EmptyPage v-if="isSearched" state="no_result" />
                        <EmptyPage 
                            v-else state="empty_custom" type="earning" icon-name="invoice" 
                            width="60px" height="60px" 
                            title="No recorded revenue" subtitle="When you complete a project and get paid with an issued invoice, it shows up on your revenue history."
                        />
                    </template>
    
                    <template v-else>
                        <ul v-for="item in groupByKeys" :key="item" class="revenue__wrap">
                            <div class="revenue__date__header">
                                {{ $format_date(item) }}
                            </div>
                            <li class="revenue__list__item">
                                <div 
                                    v-for="earning in groupedListByDate[item]" 
                                    :key="earning._id" 
                                    class="revenue__item pointer"
                                    @click="viewDetails(earning)"
                                >
                                    <div class="revenue__item__left">
                                        <div class="revenue__item__icon">
                                            <IconSvg name="navigation" width="16px" height="16px" fill="#70878D" />
                                        </div>
                                        <div class="revenue__item__left__text">
                                            <div class="revenue__item__title">
                                                {{ earning.project?.title || "Project unavailable" }}
                                            </div>
                                            <div v-if="earning.invoiceId" class="revenue__item__ref mt--5">
                                                <span>Invoice ref: {{ earning.invoice && earning.invoice.invoiceNo || '-' }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="revenue__item__right">
                                        <div class="revenue__item__amount">
                                            {{ $country.getSymbol($str_to_uppercase(earning.currency), 'currency') }}{{ $format_amount(earning.amount) }}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <Pagination v-if="showPagination" :pagination-data="pageData" />
                    </template>
                </div>
            </template>
        </div>

        <MainModal 
            :show-modal="showDetailsModal"
            size="sm" title="Revenue Details" @close="closeModal"
        >
            <template #body>
                <div>
                    <div class="revenue__info__item">
                        <div class="flex align-items-center">
                            <icon-svg name="money" fill="#637579" width="16px" height="16px" />
                            <p class="title ml--5">
                                Currency
                            </p>
                        </div>
                        <p class="text-uppercase subtitle">
                            {{ currentEarningItem.currency || 'N/A' }}
                        </p>
                    </div>
                    <div class="revenue__info__item">
                        <div class="flex align-items-center">
                            <icon-svg name="money" fill="#637579" width="16px" height="16px" />
                            <p class="title ml--5">
                                Amount paid
                            </p>
                        </div>
                        <p class="subtitle">
                            {{ $format_amount(currentEarningItem.amount) || 'N/A' }}
                        </p>
                    </div>
                    <div class="revenue__info__item">
                        <div class="flex align-items-center">
                            <icon-svg name="project" fill="#637579" width="16px" height="16px" />
                            <p class="title ml--5">
                                Project title
                            </p>
                        </div>
                        <p class="subtitle">
                            {{ currentEarningItem.project.title || 'Project unavailable' }}
                        </p>
                    </div>
                    <div class="revenue__info__item">
                        <div class="flex align-items-center">
                            <icon-svg name="receipt" fill="#637579" width="16px" height="16px" />
                            <p class="title ml--5">
                                Invoice Ref
                            </p>
                        </div>
                        <p class="subtitle">
                            #{{ currentEarningItem.invoice.invoiceNo || 'Invoice unavailable' }}
                        </p>
                    </div>
                    <div class="revenue__info__item">
                        <div class="flex align-items-center">
                            <icon-svg name="hour-glass" fill="#637579" width="16px" height="16px" />
                            <p class="title ml--5 text-capitalize">
                                Invoice Status
                            </p>
                        </div>
                        <Status 
                            type="invoice" class="subtitle"
                            :value="currentEarningItem.invoice.status || '-'" 
                        />
                    </div>
                    <div class="revenue__info__item">
                        <div class="flex align-items-center">
                            <icon-svg name="alarm" fill="#637579" width="16px" height="16px" />
                            <p class="title ml--5 text-capitalize">
                                Date created
                            </p>
                        </div>
                        <p class="subtitle">
                            {{ $format_date(currentEarningItem.createdAt) || 'N/A' }}
                        </p>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <BaseButton label="Close" size="sm" @submit="closeModal" />
                </div>
            </template>
        </MainModal>
    </div>
</template>

<script>
import { 
    IconSvg, 
    Pagination, 
    EmptyPage, 
    PageSpinner, 
    BaseButton, 
    MainModal, 
    Status,
    MainFilter,
} from "@/components/ui/index";
import { groupList, assembleQueryList } from "@/utils/helpers";

export default {
    name: "RevenueHistory",

    components: {
        IconSvg,
        EmptyPage,
        Pagination,
        PageSpinner,
        BaseButton,
        MainModal,
        Status,
        MainFilter,
    },

    data() {
        return {
            searchValue: "",
            showDetailsModal: false,
            pageState: {
                loading: false,
                err: false,
                errMsg: "",
            },
            currentEarningItem: {
                project: {
                    _id:  "",
                    title: "",
                },
                invoice: {
                    _id: "",
                    invoiceNo: "",
                    status: "",
                },
            },
        };
    },

    computed: {
        earnings() {
            return this.$store.getters["revenue/earnings"];
        },

        pageData() {
            return this.$store.getters["revenue/pageData"];
        },

        showSearchBar() {
            if (this.earnings.length) {
                return true;
            } else if (!this.earnings.length && this.isSearched) {
                return true;
            } else {
                return false;
            }
        },

        showPagination() {
            if (this.pageData?.total > 50 && this.pageData?.totalPages > 1) {
                return true;
            } else {
                return false;
            }
        },

        groupedListByDate() {
            if (!this.earnings.length) {
                return [];
            }

            const arr = this.groupList(this.earnings, (item) => {
                // createdAt comes in form of timestamp from API
                // Convert to YYYY-MM-DD
                const date = new Date(item.createdAt);

                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();

                const filter = `${year}-${month}-${day}`;

                return filter;
            });

            return Object.fromEntries(arr);
        },

        groupByKeys() {
            return Object.keys(this.groupedListByDate) || [];
        },

        isSearched() {
            return Object.keys(this.$route.query).length > 0;
        },
    },

    watch: {
        "$route.query": {
            async handler(newVal) {
                await this.handleFetchEarnings({ refresh: true, ...newVal });
            },
            immediate: true,
        },
    },

    methods: {
        groupList,
        assembleQueryList,
          
        checkIfSearched() {
            if (this.$route.query.name || this.$route.query.email || this.$route.query.industry) {
                this.isSearched = true;
            } else {
                this.isSearched = false;
            }
        },

        async handleFetchEarnings(params = {}) {
            try {
                this.pageState.loading = true;
                this.pageState.err = false;
                this.pageState.errMsg = "";

                const pageQueryObj = {
                    page: params?.pageNumber || this.pageData.currentPage,
                    ...params,
                };

                const buildResult = assembleQueryList(pageQueryObj);
                await this.$store.dispatch("revenue/fetchEarnings", { params: buildResult, refresh: params?.refresh });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error.message;
                this.pageState.err = true;
                this.pageState.errMsg = errMsg;
            } finally {
                this.pageState.loading = false;
            }
        },

        viewDetails(item) {
            if (!item._id) return;

            this.currentEarningItem = {
                ...this.currentEarningItem,
                ...item,
            };
            this.showDetailsModal = true;
        },

        closeModal() {
            this.currentEarningItem = {
                project: {
                    _id:  "",
                    title: "",
                },
                invoice: {
                    _id: "",
                    invoiceNo: "",
                    status: "",
                },
            };
            this.showDetailsModal = false;
        },
    },
};
</script>