import api from "@/api/url";

export default {
    namespaced: true,

    state: () => {
        return {
            tasks: [],
            refreshList: true,
            pageData: {
                currentPage: 1,
                totalPages: 0,
                pageSize: 0,
                total: 0,
            },
            cachedTasks: {
                _id: "all",
                tasks: [],
            },
        };
    },

    getters: {
        tasks: (state) => state.tasks,
        pageData: (state) => state.pageData,
        refreshList: state => state.refreshList,
        cachedTasks: state => state.cachedTasks,
    },

    mutations: {
        set(state, [key, value]) {
            state[key] = value;
        },
    },
    
    actions: {
        setRefreshList({ commit }, bool) {
            commit("set", ["refreshList", bool]);
        },

        fetchTasks({ commit, state }, { params, refresh }) {
            if (!refresh && state.tasks.length) return;

            return api.task.getTasks(params)
                .then((resp) => {
                    const data = resp?.data?.data;

                    commit("set", ["tasks", data.data]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        ... data.pageDetails,
                    }]);
                });
        },

        fetchTask(_, id) {
            return api.task.getTask(id)
                .then((resp) => {
                    const data = resp?.data?.data;
                    return data;
                });
        },

        addTask({ commit, state }, payload) {
            return api.task.addTask(payload)
                .then((resp) => {
                    const data = resp.data.data;

                    commit("set", ["tasks", [ ...state.tasks, data]]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total + 1,
                        totalPages: Math.ceil(state.tasks.length / 50),
                    }]);
                });
        },

        editTask({ commit, state, dispatch }, { payload, fullData, groupBy }) {
            return api.task.editTask(payload?._id, payload)
                .then((resp) => {
                    const data = resp.data?.data;
                    const tasks = state.tasks;
                    const updatedData = {  ...data, ...(fullData?._id ? fullData : payload) };

                    if (groupBy === "none") {
                        
                        let updatedTasks = [];
    
                        if (payload?.isCompleted) {
                            updatedTasks = tasks.filter(c => c._id !== payload?._id);
                        } else {
                            updatedTasks = tasks.map(c => {
                                if (c._id === payload._id) {
                                    return {
                                        ...c,
                                        ...updatedData,
                                    };
                                } else {
                                    return c;
                                }
                            });
                        }
    
                        commit("set", ["tasks", updatedTasks]);
                        // return updatedData;
                    } else {
                        dispatch("fetchTasks", { refresh: true, params: { 
                            groupBy: groupBy,
                            page: 1,
                        }}).then((res) => {});
                    }
                    return updatedData;
                });
        },

        deleteTask({ commit, state, dispatch }, { id, groupBy }) {
            return api.task.deleteTask(id)
                .then((resp) => {
                    if (groupBy === "none") {
                        const tasks = state.tasks;            
                        const updatedTasks = tasks.filter(c => c._id !== id);
    
                        commit("set", ["tasks", updatedTasks]);
                    } else {
                        dispatch("fetchTasks", { refresh: true, params: { 
                            groupBy: groupBy,
                            page: 1,
                        }}).then((res) => {});
                    }
                });
        },

        setCurrentTask({ commit }, task) {
            commit("set", ["currentTask", task]);
        },

        duplicateTask({ commit, state }, payload) {
            return api.task.duplicateTask(payload)
                .then((resp) => {
                    const data = resp.data.data;

                    commit("set", ["tasks", [ ...state.tasks,  data]]);
                });
        },

        // ------------ NOTES ---------------
        updateNotes(_, { taskId, payload }) {
            return api.task.modifyNotes(taskId, payload)
                .then((resp) => resp.data.data);            
        },
    },
};