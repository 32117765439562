// import { createApp } from 'vue/dist/vue.esm-bundler.js';
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vClickOutside from "./directives/click-outside";
import "bootstrap/dist/css/bootstrap.min.css";
import { PopoverRow, Calendar, DatePicker } from "v-calendar";
import { Money3Component } from "v-money3";
import VueChartkick from "vue-chartkick";
import "chartkick/chart.js";
import VueMultiselect from "@suadelabs/vue3-multiselect";
import vSelect from "vue-select";
import VueConfetti from "vue-confetti";
import VueDatePicker from "@vuepic/vue-datepicker";

import "@vuepic/vue-datepicker/dist/main.css";
import "v-calendar/dist/style.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "vue-select/dist/vue-select.css";

import filters from "./plugins/filters";
import countries from "./plugins/countries";
import ToastPlugin from "./plugins/toast";

createApp(App)
    .directive("click-outside", vClickOutside)
    .use(router)
    .use(store)
    .use(filters)
    .use(countries)
    .use(ToastPlugin)
    .use(VueChartkick)
    .use(VueConfetti)
    .component("vue-multi-select", VueMultiselect)
    .component("money", Money3Component)
    .component("popover-row", PopoverRow)
    .component("v-select", vSelect)
    .component("v-calendar", Calendar)
    .component("v-date-picker-two", VueDatePicker)
    .component("v-date-picker", DatePicker)
    .mount("#app");

window.debounceId = undefined;