import api from "@/api/url";

export default {
    namespaced: true,
    state: () => {
        return {
            reportFilter: {
                type: "clients",
                year: String(new Date().getFullYear()),
            },
            reportData: {
                clients: undefined,
                projects: undefined,
                invoices: undefined,
            },
        };
    },

    getters: {
        reportFilter: state => state.reportFilter,
        reportData: state => state.reportData,
    },

    mutations: {
        set(state, [key, value]) {
            state[key] = value;
        },
    },
    
    actions: {
        fetchReports({ commit, state }, params) {            
            const type = params?.type;

            if (state.reportData[type] && state.reportFilter.year === params?.year) return; // to avoid making unnecessary calls

            return api.user.getReports(params)
                .then((res) => {
                    const data = res.data.data;
                    const firstItem = data.length ? data[0] : {};

                    commit("set", ["reportData", {
                        ...state.reportData,
                        [type]: firstItem,
                    }]);
                });
        },

        setReportFilter({ commit, state }, payload) {
            const reportData = JSON.parse(localStorage.getItem("z_report_filter"));

            commit("set", ["reportFilter", {
                ...state.reportFilter,
                ...payload,
            }]);
            localStorage.setItem("z_report_filter", JSON.stringify({ ...reportData, ...payload }));
        },
    },
};