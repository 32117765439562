<template>
    <button 
        class="button"
        :type="type" 
        v-bind="$attrs"
        :class="[
            `button--${variant}`, 
            `button--${size}`,
            fullWidth ? 'w--100' : '',
            innerStyle ? `button--${variant}__${innerStyle}` : '',
            medium ? 'text--medium' : '',
            bold ? 'text--semi--bold' : '',
        ]" 
        :disabled="disabled || loading" 
        @click="$emit('submit')"
    >
        <span class="flex justify-content-center align-items-center">
            <PageSpinner 
                v-if="loading && showSpinner" class="mr--5 base_btn"
                size="sm" :is-page="false" 
            />
            <div v-if="!loading && prefixIcon">
                <IconSvg
                    class="sidebar__icon" :name="iconName" :fill="iconFill || 'inherit'" :width="iconWidth"
                    :height="iconWidth"
                />
            </div>
            
            <span v-if="label" :class="{ 'ml--5': prefixIcon, 'mr--5': suffixIcon }">
                {{ label }}
            </span>

            <span v-if="suffixIcon">
                <IconSvg
                    class="sidebar__icon" :name="iconName" :fill="iconFill || 'inherit'" :width="iconWidth"
                    :height="iconWidth"
                />                
            </span>
        </span>
        
        <slot />
    </button>
</template>

<script>
import IconSvg from "../icons/Icon-Svg.vue";
import PageSpinner from "../loaders/PageSpinner.vue";

export default {
    name: "BaseButton",

    components: { IconSvg, PageSpinner },

    props: {
        loading: { type: Boolean, default: false },
        prefixIcon: { type: Boolean, default: false },
        suffixIcon: { type: Boolean, default: false },
        bold: { type: Boolean, default: false },
        medium: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        label: { type: String, default: "" },
        iconName: { type: String, default: "" },
        iconFill: { type: String, default: "inherit" },
        iconWidth: { type: String, default: "16px" },
        type: { type: String, default: "button"},
        fullWidth: { type: Boolean, default: false },
        showSpinner: { type: Boolean, default: true },
        variant: { 
            type: String, 
            default: "default",
            validator: (value) => {
                return ["default", "secondary", "outline", "ghost", "warning"].includes(value);
            },
        },
        innerStyle: { 
            type: String, 
            default: "secondary",
            validator: (value) => {
                return ["", "primary", "secondary", "warning"].includes(value);
            },
        },
        size: { 
            type: String, 
            default: "md",
            validator: (value) => {
                return ["xs", "sm", "md", "lg"].includes(value);
            },
        },
    },
};
</script>