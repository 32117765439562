<template>
    <div>
        <main-modal :show-modal="showModal" size="sm">
            <template #body>
                <div class="modal__wrapper--onboarding mb--35">
                    <div class="modal--section pt--10 mt--30 success__modal__body">
                        <div class="mb--15">
                            <div class="flex align-items-center justify-content-center success__img__wrap">
                                <img src="@/assets/img/success_bg.png">
                                <div v-if="showImage" class="success__img__stacked">
                                    <slot name="img" />
                                </div>
                            </div>
                            <template v-if="customText">
                                <slot name="customText" />
                            </template>

                            <template v-else>
                                <p class="text-center text--3xl text--medium mb--20 mt--15">
                                    {{ title }}
                                </p>
                                <p class="text-center lh-20 text--color-info mt--15 mb--20">
                                    {{ desc }}
                                </p>
                            </template>

                            <div class="flex align-items-center justify-content-center mt--20">
                                <base-button :label="'Close'" class="text--sm" @submit="$emit('close')" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import BaseButton from "../buttons/BaseButton.vue";
import MainModal from "./MainModal.vue";

export default {
    name: "ProgressSuccess",

    components: {
        MainModal,
        BaseButton,
    },

    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
        showImage: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        desc: {
            type: String,
            default: "",
        },
        customText: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>

.success {
    &__img {
        &__wrap {
            position: relative;
        }

        &__stacked {
            position: absolute;
            bottom: 29%;
            right: 34%;
            bottom: 5%;
        }
    }
}
    .success__modal__body {
        // border: 1px dashed #2121;
        padding: 15px 15px 5px !important
        // border-radius: 5px;
    }
</style>