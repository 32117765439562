<template>
    <div class="tabs horizontal ">
        <div class="w--100 flex align-items-center" :class="[`justify-content-${position}`]">
            <ul class="tab-list" role="tabList" :class="[type]">
                <li 
                    v-for="(tab, index) in tabs"
                    :key="tab.id"
                    class="tab-list__item"
                    tabIndex="0"
                    role="tabItem" :class="{ 'active': selectedIndex === index }"
                    :aria-selected="selectedIndex === index ? true : false"
                    @click="getCurrentTab(index)"
                >
                    <template v-if="isIcon">
                        <icon-svg :name="tab.iconName" :fill="tab.iconFill" :width="getSize(tab.size)" :height="getSize(tab.size)" />
                    </template>
                    <span>{{ tab.name }}</span>
                </li>
            </ul>
        </div>
        
        <slot name="nonLinkedTab" v-bind="currentTab" />
    </div>
</template>

<script>
import IconSvg from "../icons/Icon-Svg.vue";

export default {
    name: "NonLinkedTabs",

    components: { IconSvg },

    props: {
        list: {
            type: Array,
            default: () => [],
        },
        isIcon: {
            type: Boolean,
            default: false,
        },
        type: { 
            type: String, 
            default: "line",
            validator: (value) => {
                return ["line", "pill"].includes(value);
            },
        },
        position: { 
            type: String, 
            default: "start",
            validator: (value) => {
                return ["start", "center", "end"].includes(value);
            },
        },
    },

    emits: ["select"],

    data() {
        return {
            selectedIndex: 0, // the index of the selected tab,
            tabs: this.list, 
            currentTab: {
                index: 0,
                name: "",
                component: "",
                isActive: false,
                route: "",
            },
        };
    },

    mounted() {
        this.getCurrentTab(0);
    },

    methods: {
        getCurrentTab(i) {
            this.selectedIndex = i;
            
            this.tabs.forEach((tab, index) => {
                tab.isActive = (index === i);

                if (index === i) {
                    this.currentTab = {
                        index: index,
                        name: tab.name,
                        component: tab.component,
                        isActive: tab.isActive,
                    };
                    this.$emit("select", this.currentTab);
                }
            });
        },

        getSize(val) {
            switch (val) {
            case "sm": return "12px";
            case "md": return "16px";
            case "lg": return "24px";
            
            default: return "12px";
            }
        },
    },
};
</script>