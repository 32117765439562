<template>
    <BaseDrawer drawer-id="activityDrawer" aria-id="ariaActivityDrawer" title="Activity Feed" header-class="activity-header">
        <template #body>
            <div class="flex align-items-center justify-content-between w--100  mb--35">
                <div class="form-check form-switch flex justify-content-end align-items-end">
                    <input
                        id="flexSwitchCheckDefault" class="form-check-input pointer" type="checkbox" role="switch"
                        :value="unreadActivitiesOnly" :disabled="pageLoading || pageErr"
                        :checked="unreadActivitiesOnly" @change="toggleUnread"
                    >
                    <label class="form-check-label text--xs mb--0 ml--5 pb--0" for="flexSwitchCheckDefault">Show unread</label>
                </div>

                <BaseButton 
                    v-if="activities.length > 2"
                    label="Mark all as read" variant="ghost" inner-style="primary" 
                    class="p--0" size="sm" @submit="markAllRead"
                />
            </div>

            <template v-if="pageLoading">
                <PageSpinner />
            </template>

            <template v-else-if="pageErr">
                <EmptyPage state="error" type="earning" :handle-submit="handleFetchActivities" />
            </template>

            <template v-else>
                <EmptyPage 
                    v-if="!activities.length"
                    state="empty_custom" title="Oops! No notifications available for now" 
                    icon-name="activities" subtitle=""
                />

                <div v-else>
                    <ul v-for="(activity, index) in activities" :key="index">
                        <li class="activity__item">
                            <div class="flex">
                                <div class="activity__item__wrap__icon">
                                    <div class="activity__item__icon" :class="[computeIconStyles(activity.eventKey).fill]">
                                        <IconSvg :name="computeIconStyles(activity.eventKey).name" fill="#212529" width="16px" height="16px" />
                                    </div>
                                </div>
                                <div class="activity__item__wrap__text__content">
                                    <div class="flex align-items-center justify-content-between">
                                        <p class="msg">
                                            {{ activity.message }}
                                        </p>

                                        <div class="dropdown">
                                            <div id="activityTextDropDown" class="pointer" data-bs-toggle="dropdown" aria-expanded="false">
                                                <IconSvg name="dots-horizontal-rounded" fill="#667085" width="16px" />
                                            </div>
            
                                            <ul class="dropdown-menu" aria-labelledby="priorityDropDown">
                                                <li class="dropdown-item pointer text--xs" @click="markRead(activity._id)">
                                                    Mark as read
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div 
                                        v-if="activity.entityId" class="activity__item__link"
                                        @click="viewEntity(`${computeActivityItem(activity.collectionType)}/${activity.entityId}`)"
                                    >
                                        View more
                                    </div>

                                    <p class="text--xs text--medium text--color-light-300 activity__item__date">
                                        {{ $format_date(activity.createdAt) }}
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </template>
        </template>
    </BaseDrawer>
</template>

<script>
import BaseDrawer from "./BaseDrawer.vue";
import EmptyPage from "../emptyPage/EmptyPage.vue";
import IconSvg from "../icons/Icon-Svg.vue";
import PageSpinner from "../loaders/PageSpinner.vue";
import BaseButton from "../buttons/BaseButton.vue";
import { assembleQueryList } from "@/utils/helpers";

export default {
    name: "ActivityDrawer",

    components: {
        IconSvg,
        BaseDrawer,BaseButton,
        EmptyPage, PageSpinner,
    },

    data() {
        return {
            unreadActivitiesOnly: true,
            pageLoading: false,
            pageErr: false,
            pageErrMsg: "",
            isSearched: false,
            currentLogId: "",
            pageDisabled: false,
        };
    },

    computed: {
        activities() {
            return this.$store.getters["app/activities"]?.list;
        },
    },

    async mounted() {
        await this.handleFetchActivities({ refresh: true });
    },

    methods: {
        assembleQueryList, 

        viewEntity(path) {
            this.$router.push(`${path}`);
        },

        computeActivityItem(type) {
            switch (type) {
            case "clients":
                return "/dashboard/clients";
            case "projects":
                return "/dashboard/projects";
            case "invoices":
                return "/dashboard/invoices";
            case "rate_cards":
                return "/dashboard/rate-cards";
            case "user_integrations":
                return "/dashboard/settings/integrations";
            case "tasks":
                return "/dashboard/tasks";
            
            default:
                return "";
            }
        },

        computeIconStyles(eventKey) {            
            switch (eventKey) {
            case "create_doc":
            case "create_multiple_docs":
                return {
                    name: "add",
                    fill: "create",
                };
            case "update_doc":
                return {
                    name: "edit",
                    fill: "edit",
                };
            case "update_doc_status":
                return {
                    name: "hash",
                    fill: "status",
                };
            case "archive_doc":
            case "delete_doc":
            case "delete_multiple_docs":
                return {
                    name: "lock",
                    fill: "delete",
                };
            case "milestone":
            case "priority":
            case "reminder":
                return {
                    name: "map-pin",
                    fill: "others",
                };
            
            default:
                return {
                    name: "",
                    fill: "",
                };
            }
        },

        async handleFetchActivities(params) {
            try {
                this.pageLoading = true;
                this.pageErr = false;
                this.pageErrMsg = "";

                const pageQueryObj = {
                    page: params?.page || 1,
                    unread: params?.unread || this.unreadActivitiesOnly,
                    ...params,
                };

                const queryKeys = Object.keys(params);

                if (queryKeys.length) {
                    this.isSearched = true;
                } else {
                    this.isSearched = false;
                }
                
                await this.$store.dispatch("app/fetchActivities", { params: pageQueryObj, refresh: params?.refresh });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;

                this.pageErr = true;
                this.pageErrMsg = errMsg;
            } finally {
                this.pageLoading = false;
            }
        },

        async markRead(id) {
            try {
                this.currentLogId = id;
                await this.$store.dispatch("app/markOneRead", id);
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.currentLogId = "";
            }
        },

        async markAllRead() {
            try {
                this.pageDisabled = true;
                const ids = this.activities.map(x => x._id) || [];
                await this.$store.dispatch("app/markAllRead", ids);
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.pageDisabled = false;
            }
        },

        async toggleUnread(event) {
            const checked = event?.target?.checked;            
            await this.handleFetchActivities({ unread: checked, refresh: true });
        },
    },
};
</script>