<template>
    <div>
        <template v-if="tokenValidateLoading">
            <PageSpinner />
        </template>
        <template v-else-if="tokenValidateErr">
            <EmptyPage state="error_custom" title="Token expired" :subtitle="tokenValidateErrMsg">
                <template #button>
                    <div class="flex align-items-center flex-col justify-content-center">
                        <BaseButton 
                            label="Back to login page" size="sm" @submit="goBackToLoginPage"
                        />
                        <BaseButton 
                            label="Re-initiate a password reset request" size="sm" class="mt--20 text--underline" variant="ghost"
                            inner-style="secondary" @submit="goBackToForgotPasswordPage"
                        />
                    </div>
                </template> 
            </EmptyPage>
        </template>
        <template v-else>
            <AuthForm auth-type="reset" :loading="resetLoading" :disabled="resetLoading" @form-submit="resetPassword" />
        </template>
    </div>
</template>

<script>
import { PageSpinner, EmptyPage, BaseButton, Logo } from "@/components/ui";
import AuthForm from "@/components/auth/AuthForm.vue";
import { validatePassword } from "@/utils/helpers";

export default {
    name: "ResetPage",

    components: {
        AuthForm, PageSpinner, EmptyPage, BaseButton,
    },

    data() {
        return {
            tokenValidateLoading: false,
            tokenValidateErr: false,
            tokenValidateErrMsg: false,
            resetLoading: false,
            resetError: false,
            email: "",
        };
    },

    async mounted() { 
        const params = new URLSearchParams(window.location.search);
        const token = params.get("token");
        const email = params.get("email");

        this.email = email;

        await this.validateToken({ token, email });
    },

    methods: {
        goBackToLoginPage() {
            this.$router.push("/auth/login");
        },

        goBackToForgotPasswordPage() {
            this.$router.push("/initiate-reset");
        },

        async validateToken({ token, email }) {
            try {
                this.tokenValidateErr = false;
                this.tokenValidateErrMsg = "";
                this.tokenValidateLoading = true;

                if (!token || !email) {
                    this.tokenValidateErr = true;
                    this.tokenValidateErrMsg = "Invalid or expired token";
                    return;
                }

                await this.$store.dispatch("auth/verifyResetToken", { email, token });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.tokenValidateErr = true;

                if (errMsg === "This token has been used already") {
                    this.tokenValidateErrMsg = "Oops! It appears you've used this one-time token already. Please request a new one to continue with your password reset.";
                } else {
                    this.tokenValidateErrMsg = errMsg;
                }
            } finally {
                this.tokenValidateLoading = false;
            };
        },

        async validatePasswords(values) {
            if (values.password !== values.confirmPassword) {
                this.$toast.error("Passwords do not match");
                return;
            }

            const isPasswordValid = validatePassword(values.password);

            if (!isPasswordValid) {
                this.$toast.error("Passwords do not match required rules");
                return;
            }
        },

        async resetPassword(data) {
            try {
                this.validatePasswords({ 
                    password: data?.password,
                    confirmPassword: data?.confirmPassword,
                });
                
                this.resetLoading = true;
                
                await this.$store.dispatch("auth/resetPassword",  { 
                    email: this.email || "",
                    newPassword: data?.password,
                    confirmNewPassword: data?.confirmPassword,
                });

                this.$store.dispatch("auth/setAuthStatus", true);
                this.$store.dispatch("auth/setLastChecked", Date.now());
                this.$toast.success("Reset successful");
                this.$router.push(`/dashboard/reports?type=clients&year=${new Date().getFullYear()}`);
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.resetError = true;
                this.$toast.error(errMsg);
            } finally {
                this.resetLoading = false;
            };
        },
    },
};
</script>