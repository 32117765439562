<template>
    <div class="tabs horizontal" v-bind="$attrs">
        <div class="w--100 flex align-items-center" :class="[`justify-content-${position}`]">
            <ul class="tab-list" role="tabList" :class="[type]">
                <li 
                    v-for="tab in tabs"
                    :key="tab.id" class="tab-list__item"
                    tabIndex="0" role="tabItem" 
                    :class="{ 'active': isTabActive(tab) }" :aria-selected="isTabActive(tab)"
                >
                    <router-link :to="`/dashboard/${tab.route}`">
                        <template v-if="isIcon">
                            <IconSvg :name="tab.iconName" :fill="tab.iconFill" :width="getSize(tab.size)" :height="getSize(tab.size)" />
                        </template>
                        {{ tab.id }}
                    </router-link>
                </li>
            </ul>
        </div>
        
        <div class="tab-panel h-auto" role="tabPanel">
            <div class="tab-content h-auto">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
import IconSvg from "../icons/Icon-Svg.vue";

export default {
    name: "LinkedTabs",
    components: { IconSvg },

    props: {
        list: {
            type: Array,
            default: () => [],
        },
        isIcon: {
            type: Boolean,
            default: false,
        },
        type: { 
            type: String, 
            default: "line",
            validator: (value) => {
                return ["line", "pill"].includes(value);
            },
        },
        position: { 
            type: String, 
            default: "start",
            validator: (value) => {
                return ["start", "center", "end"].includes(value);
            },
        },
    },

    data() {
        return {
            tabs: this.list, 
        };
    },

    methods: {
        isTabActive(tab) {
            if (this.$route.path.includes(this.$to_simple_lowercase(tab.id))) {
                return true;
            } else if (this.$route.name === tab.name) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>