import api from "@/api/url";
import { sortList } from "@/utils/helpers";

export default {
    namespaced: true,
    
    state: () => {
        return {
            clients: [],
            pageData: {
                currentPage: 1,
                totalPages: 0,
                pageSize: 0,
                total: 0,
            },
            clientProjects: {
                list: [],
                pageData: {
                    currentPage: 1,
                    totalPages: 0,
                    pageSize: 50,
                    total: 0,
                },
            },
            clientInvoices: {
                _id: "",
                invoices: [],
                pageData: {
                    currentPage: 1,
                    totalPages: 0,
                    pageSize: 50,
                    total: 0,
                },
            },
            documents: [],
            refreshList: true,
        };
    },

    getters: {
        clients: (state) => state.clients,
        pageData: (state) => state.pageData,
        clientNotes: (state) => state.clientNotes,
        clientProjects: (state) => state.clientProjects,
        clientInvoices: state => state.clientInvoices,
        refreshList: state => state.refreshList,
    },

    mutations: {
        set(state, [key, value]) {
            state[key] = value;
        },
    },
    
    actions: {
        setRefreshList({ commit }, bool) {
            commit("set", ["refreshList", bool]);
        },

        addClient({ state, commit, dispatch }, payload) {
            // optimistic update
            const currentClients = state.clients;

            commit("set", ["clients", [ payload, ...currentClients ]]);
            commit("set", ["pageData", {
                ...state.pageData,
                total: state.pageData.total + 1,
                totalPages: Math.ceil((state.pageData.total + 1) / state.pageData.pageSize),
            }]);

            return api.client.create(payload)
                .then((resp) => {
                    dispatch("app/fetchActivities", { page: 1 }, { root: true });
                    return resp.data.data;
                }).catch(error => {
                    // rollback update
                    commit("set", ["clients", currentClients]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total - 1,
                        totalPages: Math.ceil((state.pageData.total - 1) / state.pageData.pageSize),
                    }]);
                    throw error; // Re-throw for component handling
                });
        },

        fetchClients({ commit, state }, { params, refresh, config = {}, external = false }) {
            if (!refresh && state.clients.length) return;

            return api.client.getAll(params, config)
                .then((resp) => {
                    if (external) {
                        return resp?.data?.data?.data;
                    }

                    if (config?.responseType) {
                        return resp?.data;
                    } 

                    const data = resp?.data?.data;

                    commit("set", ["clients", data.data]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        ... data.pageDetails,
                    }]);
                });
        },

        getClient(_, id) { 
            return api.client.getOne(id)
                .then((resp) => {
                    const data = resp?.data?.data;
                    return data;
                });
        },

        updateClient({ state, commit, dispatch }, payload) {
            return api.client.updateOne(payload?._id, payload)
                .then((resp) => {
                    const data = resp?.data?.data;
                    const clients = state.clients;

                    const updatedClients = clients.map(c => {
                        if (c?._id === payload?._id) {
                            return {
                                ...c,
                                ...data,
                            };
                        } else {
                            return c;
                        }
                    });
        
                    commit("set", ["clients", updatedClients]);  
                    
                    dispatch("app/fetchActivities", { page: 1 }, { root: true });
                    return data;
                });
        },

        archiveClient({ state, commit }, id) {
            return api.client.archive(id)
                .then((resp) => {
                    const data = resp.data.data;
                    const clients = state.clients;

                    const updatedClients = clients.map(c => {
                        if (c?._id === data?._id) {
                            return {
                                ...c,
                                ...data,
                            };
                        } else {
                            return c;
                        }
                    });

                    // update client list
                    commit("set", ["clients", updatedClients]); 
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total - 1,
                        totalPages: Math.ceil(state.updatedClients.length / 50),
                    }]);

                    // fetch activities 
                    dispatch("app/fetchActivities", { page: 1 }, { root: true });
                });
        },

        deleteClients({ state, commit, dispatch }, ids) {
            const clients = state.clients;
            const updatedClients = clients.filter(c => !ids.includes(c?._id));

            commit("set", ["clients", updatedClients]);
            commit("set", ["pageData", {
                ...state.pageData,
                total: state.pageData.total - ids.length,
                totalPages: Math.ceil((state.pageData.total - ids.length) / state.pageData.pageSize),

            }]);

            return api.client.delete({ ids: ids })
                .then((resp) => {
                    // fetch activities 
                    dispatch("app/fetchActivities", { page: 1 }, { root: true });
                }).catch(error => {
                    // rollback update
                    commit("set", ["clients", clients]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total + ids.length,
                        totalPages: Math.ceil((state.pageData.total + ids.length) / state.pageData.pageSize),
                    }]);
                    throw error; // Re-throw for component handling
                });
        },

        uploadMutlipleClients(_, { formData, progressCB }) {            
            return api.client.upload(formData, { headers: { "Content-Type": "multipart/form-data" }, onUploadProgress: progressCB })
                .then((resp) => {
                    // fetch activities 
                    dispatch("app/fetchActivities", { page: 1 }, { root: true });
                    dispatch("app/fetchUnreadActivityCount");
                });
        },

        followUpWithClient(_, payload) {
            return api.client.followUp(payload)
                .then((resp) => {});
        },

        sortClients({ commit, state }, sortVal) {
            const copiedArr = state.clients.slice();
            
            sortList(sortVal, copiedArr, "name");
                        
            commit("set", ["clients", copiedArr]);
        },

        duplicateClient({ state, dispatch, commit }, payload) {      
            // optimistic update
            const currentClients = state.clients;

            commit("set", ["clients", [ payload, ...currentClients ]]);
            commit("set", ["pageData", {
                ...state.pageData,
                total: state.pageData.total + 1,
                totalPages: Math.ceil((state.pageData.total + 1) / state.pageData.pageSize),
            }]);

            return api.client.duplicate(payload._id, payload)
                .then((resp) => {
                    const data = resp.data?.data;

                    dispatch("app/fetchActivities", { page: 1 }, { root: true });

                    return data;
                }).catch(error => {
                    // rollback update
                    commit("set", ["clients", currentClients]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total - 1,
                        totalPages: Math.ceil((state.pageData.total - 1) / state.pageData.pageSize),
                    }]);
                    throw error; // Re-throw for component handling
                });
        },

        // ------------- NOTES ------------------
        getClientNotes(_, { id, params }) {
            return api.client.getNotes(id, params)
                .then((resp) => {
                    return resp.data.data;
                });
        },

        addNewNote(_, payload) {
            return api.client.createNote(payload)
                .then((resp) => {
                    return resp.data.data;
                });
        },

        updateNote(_, payload) {
            return api.client.editNote(payload.clientId, payload)
                .then((resp) => {
                    return resp.data.data?.data;
                });
        },

        deleteNote(_, payload) {
            return api.client.deleteNote(payload.clientId, payload)
                .then((resp) => {
                    return resp.data.data;
                });
        },

        // -------------- PROJECTS ----------------
        getClientProjects({ commit, state }, { id, params, external = false }) {
            return api.client.getProjects(id, params)
                .then((resp) => {
                    const data = resp.data.data;

                    if (external) {
                        return data.data;
                    }

                    commit("set", ["clientProjects", { 
                        ...state.clientProjects, 
                        list: data.data, 
                        pageData: data.pageDetails,
                    }]);
                });
        },

        addNewProject({ state, commit, dispatch }, payload) {
            const currentProjects = state.clientProjects.list;
            dispatch("app/fetchActivities", { page: 1 }, { root: true });

            return api.project.create(payload)
                .then((resp) => {
                    // fetch activities 
                    dispatch("app/fetchActivities", { page: 1 }, { root: true });
                     
                    commit("set", ["clientProjects", {
                        ...state.clientProjects,
                        list: [{ ...payload, ...resp.data.data }, ...currentProjects],
                        pageData: {
                            ...state.clientProjects.pageData,
                            total: state.clientProjects.pageData.total + 1,
                            totalPages: Math.ceil((state.clientProjects.pageData.total + 1) / state.clientProjects.pageData.pageSize),
                        },
                    }]);
                    return resp.data.data;
                }).catch(error => {
                    throw error; // Re-throw for component handling
                });
        },

        addExistingroject({ state, commit }, { clientId, payload }) {
            const currentProjects = state.clientProjects.list;

            commit("set", ["clientProjects", {
                ...currentProjects,
                list: [payload, ...currentProjects],
                pageData: {
                    ...state.clientProjects.pageData,
                    total: state.clientProjects.pageData.total + 1,
                    totalPages: Math.ceil((state.clientProjects.pageData.total + 1) / state.clientProjects.pageData.pageSize),
                },
            }]);

            return api.client.addProject(clientId, { projectId: payload._id })
                .then((resp) => {
                    return resp.data.data;
                }).catch(error => {
                    // rollback update
                    commit("set", ["clientProjects", {
                        ...state.clientProjects,
                        list: currentProjects,
                        pageData: {
                            ...state.clientProjects.pageData,
                            total: state.clientProjects.pageData.total - 1,
                            totalPages: Math.ceil((state.clientProjects.pageData.total - 1) / state.clientProjects.pageData.pageSize),
                        },
                    }]);
                    throw error; // Re-throw for component handling
                });
        },

        deleteProject({ state, commit }, { clientId, projectId }) {
            return api.client.removeProject(clientId, { projectId })
                .then(() => {
                    const projects = state.clientProjects.list;

                    commit("set", ["clientProjects", {
                        ...state.clientProjects,
                        list: projects.filter(x => x._id !== projectId),
                        pageData: {
                            ...state.clientProjects.pageData,
                            total: state.clientProjects.pageData.total - 1,
                            totalPages: Math.ceil((state.clientProjects.pageData.total - 1) / state.clientProjects.pageData.pageSize),
                        },
                    }]);  
                }); 
        },
        
        // ------------- INVOICES ------------------
        getClientInvoices({ commit, state }, { id, params }) {
            return api.invoice.getAll(params)
                .then((resp) => {
                    const data = resp.data.data;

                    if (params?.isScroll) {
                        commit("set", ["clientInvoices", { 
                            ...state.clientInvoices, 
                            projects: [ ...state.clientInvoices.projects, ...data.data ], 
                        }]);
                    } else {
                        commit("set", ["clientInvoices", { 
                            ...state.clientInvoices, 
                            projects: data.data, 
                        }]);
                    }

                    commit("set", ["clientInvoices", { 
                        ...state.clientInvoices, 
                        _id: id,
                        pageData: data.pageDetails,
                    }]);
                });
        },

        sortInvoices({ commit, state }, sortVal) {
            const copiedArr = state.invoicesData.invoices.slice();
            sortList(sortVal, copiedArr, "status");
            commit("set", ["invoicesData", { ...state.invoicesData, invoices: copiedArr }]);
        },
    },
};