<template>
    <div class="flex align-items-center justify-content-end pagination-wrap">
        <span class="mr--35 text--sm">{{ paginationData.currentPage }} - 50 of {{ paginationData.total }}</span>
        <nav aria-label="Page navigation example">
            <ul class="pagination mb--0">
                <li class="page-item" :class="{ 'disabled': isFirstPage }" @click="changePaginationNum(1)">
                    <span class="pagination__link" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                    </span>
                </li>
                <li v-for="page in paginationData.totalPages" :key="page" class="page-item">
                    <span class="pagination__link" :class="{ 'active': paginationData.currentPage === page}" @click="changePaginationNum(page)">
                        {{ page }}
                    </span>
                </li>
                <li class="page-item" :class="{ 'disabled': isLastPage }" @click="changePaginationNum(paginationData.totalPages)">
                    <a class="pagination__link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import { formatCount, serilaizeQuery } from "@/utils/helpers";

export default {
    name: "Pagination",

    props: {
        paginationData: {
            type: Object,
            default: () => {},
        },
        routeChange: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        isFirstPage(){
            return this.paginationData.currentPage === 1;
        },

        isLastPage(){
            return this.paginationData.currentPage === this.paginationData.totalPages;
        },

        // paginationStart() {
        //     if (this.paginationData.currentPage > 5) {
        //         return this.paginationData.currentPage - 5;
        //     } else {
        //         return 0
        //     }
        // }
    },

    methods: {
        formatCount,
        serilaizeQuery,

        changePaginationNum(page) {
            this.$emit("pageChange", page);
            
            if (this.routeChange) {
                const pageObject = {
                    ...this.$route.query,
                    page: page ? page : this.paginationData.currentPage,
                };
    
                const currentRoute = this.$route.path;
                const queryParam = this.serilaizeQuery(pageObject);
                        
                this.$router.replace(`${currentRoute}?${queryParam}`);
            }
        },
    },
};
</script>