<template>
    <div class="dropdown" v-bind="$attrs">
        <BaseButton 
            id="import" 
            class="btn--filter" v-bind="$attrs" 
            aria-expanded="false" variant="outline" inner-style="secondary"
            data-bs-toggle="dropdown" size="sm"
            data-bs-auto-close="true" 
            :disabled="disabled" 
        >
            <span class="mr--5 flex align-items-center">
                <icon-svg
                    v-if="iconName" :name="iconName" fill="#637579" width="16px"
                    height="16px"
                />
                <span class="ml--5 text--xs">{{ label }}</span>
            </span>
        </BaseButton>

        <ul id="importList" class="dropdown-menu dropdown-menu--tag" aria-labelledby="importList">
            <li 
                v-for="(item, index) in list" 
                :key="index" 
                class="dropdown-item pointer text--xs text--upper"
                @click="$emit('select', item)"
            >
                {{ item }}
            </li>
        </ul>
    </div>
</template>

<script>
import IconSvg from "../icons/Icon-Svg.vue";
import BaseButton from "./BaseButton.vue";

export default {
    name: "DropdownButton",

    components: { IconSvg, BaseButton },
    
    props: {
        label: {
            type: String,
            default: "",
        },
        iconName: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        list: {
            type: Array,
            default: () => ["csv", "json"],
        },
    },

    data() {
        return {
            file: "",
            fileContent: [],
            isParsed: false,
        };
    },
};
</script>

<style lang="scss" scoped>
    .dropdown-menu--tag {
        min-width: 5rem !important;
        font-size: 14px !important;
    }
</style>