import { createStore, createLogger } from "vuex";
import auth from "./auth";
import clients from "./clients";
import invoice from "./invoices";
import project from "./projects";
import report from "./reports";
import tag from "./tags";
import app from "./app";
import revenue from "./revenue";
import ratecards from "./ratecards";
import meeting from "./meetings";
import task from "./tasks";
import integration from "./integrations";

const debug = process.env.NODE_ENV !== "production";

export default createStore({
    modules: {
        app,
        auth,
        clients,
        integration,
        invoice,
        meeting,
        project,
        report,
        revenue,
        report,
        tag,
        task,
        ratecards,
    },
    strict: debug,
    plugins: debug ? [createLogger()] : [],
});