<template>
    <div class="pl--0 pr--0 pt--10 client__item__details__sub">
        <template v-if="fetchInvoiceLoading">
            <PageSpinner />
        </template>

        <template v-else-if="fetchInvoiceErr">
            <EmptyPage state="error" :handle-submit="handleFetchInvoices" />
        </template>

        <template v-else>
            <div class="w--100 mt--20">
                <template v-if="!invoices.length">
                    <EmptyPage
                        state="empty" icon-name="invoice" width="60px" height="60px"
                        type="invoice"
                    >
                        <template #button>
                            <BaseButton 
                                label="+ Create invoice" size="sm" variant="outline" inner-style="primary"
                                :disabled="upgradeLoadingDisabled" :show-spinner="false" @submit="goToCreateInvoice"
                            />
                        </template> 
                    </EmptyPage>
                </template>

                <template v-else>
                    <div class="flex align-items-center mb--15 justify-content-between">
                        <p class="text--bold text--color-gray-400 mt--20 mb--15">
                            Invoices
                        </p>
                        <BaseButton
                            label="+ Add invoice" size="sm" :loading="upgradeLoadingDisabled" :disabled="upgradeLoadingDisabled"
                            :show-spinner="false" variant="ghost" inner-style="primary" 
                            class="text--semi--bold" @submit="initiateCreate"
                        />
                    </div>
                    <div class="flex mt--20">
                        <CustomTable
                            :columns="invoiceHeaders"
                            :data="invoices"
                            router-name="invoice-details-view"
                            row-type="link" :is-checkbox="false"
                            @onRowClick="goToInvoiceDetailsPage"
                        >
                            <template #rows="data">
                                <td>
                                    <Status type="invoice" :value="data.status" />
                                </td>
                                <td class="text--upper">
                                    {{ data.currency }} {{ $format_number(data.totalAmount) }}
                                </td>
                                <td>
                                    {{ data.invoiceNo || "-" }}
                                </td>
                                <td>{{ $format_date(data.dueDate) }}</td>
                                <td>{{ $format_date(data.createdAt) || "-" }}</td>
                            </template>
                        </CustomTable>
                    </div>
                    <div class="flex align-items-center justify-content-between">
                        <p class="text--color-gray-200 text--medium text--xs">
                            COUNT: <span>{{ invoices.length }}</span>
                        </p>
                        <pagination v-if="showPagination" :pagination-data="pageData" />
                    </div>
                </template>
            </div>
        </template>

        <UpgradeAccountModal :show-modal="showUpgradeModal && checkUpgradeFields.invoices" type="invoices" @cancel="closeUpgradeModal" />
    </div>
</template>

<script>
import {
    EmptyPage,
    PageSpinner,
    BaseButton,
    CustomTable,
    Status,
    Pagination,
    UpgradeAccountModal,
} from "@/components/ui/index";

export default {
    name: "ClientInvoices",

    components: {
        CustomTable, Pagination,
        EmptyPage, UpgradeAccountModal,
        PageSpinner,
        BaseButton,
        Status,
    },

    props: {
        currentClient: { type: Object, default: () => {} },
    },

    data() {
        return {
            fetchInvoiceLoading: false,
            fetchInvoiceErr: false,
            fetchInvoiceErrMsg: "",
            invoiceHeaders: [
                { id: "Status", accessorKey: "Status", iconName: "user"},
                { id: "Amount", accessorKey: "amount", iconName: "buildings"},
                { id: "Invoice No.", accessorKey: "invoiceNo", iconName: "reports-solid"},
                { id: "Due Date", accessorKey: "dueDate", iconName: "timer-solid"},
                { id: "Date added", accessorKey: "createdAt", iconName: "timer-solid"},
            ],
        };
    },

    computed: {
        pageData() {
            return this.$store.getters["clients/clientInvoices"]?.pageData;
        },

        invoices() {
            return this.$store.getters["clients/clientInvoices"]?.invoices;
        },

        showUpgradeModal() {
            return this.$store.getters["app/showUpgradeModal"];
        },

        checkUpgradeLoading() {
            return this.$store.getters["app/checkUpgradeLoading"];
        },

        checkUpgradeFields() {
            return this.$store.getters["app/checkUpgradeFields"];
        },

        upgradeLoadingDisabled() {
            return this.checkUpgradeLoading && this.checkUpgradeFields.invoices;
        },
    },

    async mounted() {
        await this.handleFetchInvoices();
    },

    methods: {
        async handleFetchInvoices(payload) {
            try {
                this.fetchInvoiceErr = false;
                this.fetchInvoiceLoading = true;

                const id = this.$route.params.id;

                const params = {
                    page: payload?.pageNumber || this.pageData?.currentPage,
                    clientId: this.$route.params.id,
                };

                await this.$store.dispatch("clients/getClientInvoices", { id, params });
            } catch (error) {
                this.fetchInvoiceErr = true;
                const errMsg = error?.response?.data?.message || error?.message;
                
                this.fetchInvoiceErrMsg = errMsg;
            } finally {
                this.fetchInvoiceLoading = false;
            }
        },

        async goToCreateInvoice() {
            try {
                await this.$store.dispatch("app/checkUpgradeStatus", "invoices");
                this.$store.dispatch("invoice/setSelectedClient", this.currentClient);
                return this.$router.push({ path: "/dashboard/invoices/create", query: { from_route: "client" } });
            } catch (error) {
                // console.log({ error });
            }
        },

        closeUpgradeModal() {
            this.$store.dispatch("app/closeUpgradeModal", "invoices");
        },

        goToInvoiceDetailsPage(row) {
            if (!row._id) return;
            this.$router.push(`/dashboard/invoices/${row._id}`);
        },
    },
};
</script>

<style lang="scss" scoped>
    .title {
        max-width: 170px;
    }
</style>