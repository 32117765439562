<template>
    <AuthForm 
        auth-type="login"
        :loading="loading"
        :error="error"
        @form-submit="handleLogin"
    />
</template>

<script>
import AuthForm from "@/components/auth/AuthForm.vue";

export default {
    name: "LoginPage",

    components: {
        AuthForm,
    },

    data() {
        return {
            error: {
                show: false,
                value: "",
            },
            loading: false,
        };
    },

    methods: {
        async handleLogin(payload) {
            try {
                this.error.show = false;
                this.error.value = "";
                this.loading = true;

                await this.$store.dispatch("auth/login", payload);

                this.$store.dispatch("auth/setAuthStatus", true);
                this.$store.dispatch("auth/setLastChecked", Date.now());
                
                this.$toast.success("Login successful");

                const redirectPath = this.$route.query.redirect;

                if (redirectPath) {
                    return this.$router.push(`${redirectPath}`);
                } else {
                    return this.$router.push("/dashboard/reports?type=clients");
                }
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                
                this.error.value = errMsg;
                this.error.show = true;

                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            };
        },
    },
};
</script>