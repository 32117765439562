<template>
    <div class="content__page" :class="{ 'disabled': duplicateLoading }">
        <div>
            <template v-if="pageLoading">
                <PageSpinner />
            </template>

            <template v-else-if="pageLoadingErr">
                <EmptyPage state="error" :handle-submit="handleFetchProject" />
            </template>

            <template v-else-if="unableToLoadPage">
                <EmptyPage state="error">
                    <template #button>
                        <base-button label="Back to projects page" size="sm" @submit="backToInvoicesPage" />
                    </template> 
                </EmptyPage>
            </template>

            <template v-else>
                <div class="project__header__wrap">
                    <div class="project__header__status justify-content-end w--100">
                        <BaseButton
                            variant="outline" icon-name="task" icon-width="16px"
                            inner-style="secondary" class="mr--5" label="View tasks" 
                            prefix-icon size="sm" @submit="goToTasks"
                        />
                        <BaseButton
                            variant="outline" icon-name="alarm" icon-width="16px"
                            prefix-icon inner-style="secondary" label="Manage time tracking"
                            :disabled="checkUpgradeLoading && checkUpgradeFields.projects"
                            size="sm" @submit="openTineTrackingModal"
                        />
                    </div>
                </div>

                <div class="mt--15 pb--35">
                    <div class="invoice__details__row">
                        <div class="invoice__details__row__left project__details__row__left">
                            <div class="invoice__details__header pb--10 pt--10">  
                                <div class="">
                                    <p class="text--semi--bold text--capitalize text--color-gray-400 text--2xl">
                                        {{ currentProject.title }}
                                    </p>
                                    <div class="project__item project__item__flex mt--5 mb--0">
                                        <span class="project__item__title text--normal mt--0 text--xs">
                                            Created on: {{ $format_date(currentProject.createdAt) }}
                                        </span>
                                        <span class="ml--10 mr--10 project__item__title text--normal"> | </span>
                                        
                                        <div>
                                            <div class="dropdown">
                                                <div id="statusDropdown" class="pointer" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <div class="project__item__title flex align-items-center text--capitalize text--xs">
                                                        <Status type="project" :value="currentProject.status || '-'" />
                                                        <IconSvg name="chevron-down" fill="#667085" width="12px" />
                                                    </div>
                                                </div>
                
                                                <ul class="dropdown-menu" aria-labelledby="statusDropdown">
                                                    <li v-for="status in projectFilterStatuses" :key="status" class="dropdown-item align-items-center" @click="handleStatusUpdate(status)">
                                                        <span class="text--xs text--capitalize text--color-gray-500">{{ status }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <span class="ml--10 mr--10 project__item__title text--normal"> | </span>

                                        <div>
                                            <div class="dropdown">
                                                <div id="priorityDropDown" class="pointer" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <div class="project__item__title flex align-items-center text--capitalize text--xs">
                                                        <span class="box-icon" :class="[currentProject.priority]" />
                                                        <span> {{ currentProject.priority || "-" }} priority</span>
                                                        <IconSvg name="chevron-down" fill="#667085" width="12px" />
                                                    </div>
                                                </div>
                
                                                <ul class="dropdown-menu" aria-labelledby="priorityDropDown">
                                                    <li v-for="priority in priorities" :key="priority" class="dropdown-item align-items-center" @click="updatePriority(priority)">
                                                        <span class="box-icon" :class="[priority]" />
                                                        <span class="text--xs text--capitalize text--color-gray-500">{{ priority }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="relative flex align-items-center">
                                    <div class="dropdown">
                                        <div id="invoiceDetailsDropDown" class="pointer" data-bs-toggle="dropdown" aria-expanded="false">
                                            <div class="invoice__details__menu">
                                                <IconSvg name="dots-horizontal-rounded" fill="#667085" width="16px" />
                                            </div>
                                        </div>
        
                                        <ul class="dropdown-menu" aria-labelledby="invoiceDetailsDropDown">
                                            <li class="dropdown-item" @click="openEditModal('name')">
                                                <IconSvg name="edit" fill="#667085" width="16px" />
                                                <span class="ml--10 text--sm text--color-gray-500">Edit project title</span>
                                            </li>

                                            <!-- <li class="dropdown-item" @click="openEditModal('name')">
                                                <IconSvg name="edit" fill="#667085" width="16px" />
                                                <span class="ml--10 text--sm text--color-gray-500">Share project</span>
                                            </li> -->

                                            <li class="dropdown-item" @click="duplicateProject">
                                                <IconSvg name="duplicate" fill="#667085" width="16px" />
                                                <span class="ml--10 text--sm text--color-gray-500">Duplicate project</span>
                                            </li>
                                            
                                            <li><hr class="dropdown-divider"></li>
                                            <li class="dropdown-item" @click="showDeleteModal = true">
                                                <IconSvg name="delete" fill="#e04060" width="16px" />
                                                <span class="ml--10 text--sm text--color-warning">Delete project</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="invoice__details__item pb--10 pt--0">
                                <div class="w--100">
                                    <div class="col-12 mb--10 flex align-items-center justify-content-between mt--10">
                                        <FormLabel title="Meta" html-for="meta" label-type="title" />
                                        <BaseButton 
                                            variant="ghost" label="Edit" size="sm"
                                            class="text--color-primary p--0 mr--10 text--semi--bold" 
                                            @submit="openEditModal('metaDetails')"            
                                        />
                                    </div>
    
                                    <div class="mb--20 col-12 mt--20">
                                        <FormLabel title="Description" html-for="description" label-type="menu" />
                                        <p class="invoice__detail text--sm">
                                            {{ currentProject.description || '-' }}
                                        </p>
                                    </div>

                                    <div class="flex flex-wrap pt--10 pb--0 pt--10">        
                                        <div class="invoice__details__group">
                                            <div>
                                                <FormLabel title="Favourite" html-for="meta" label-type="menu" />
                                                <p class="text--capitalize invoice__detail text--sm">
                                                    {{ currentProject.isFavourite ? 'Yes' : 'No' }}
                                                </p>
                                            </div>
                                        </div>
        
                                        <div class="invoice__details__group">
                                            <div>
                                                <FormLabel title="Deadline" html-for="meta" label-type="menu" />
                                                <p class="invoice__details--text text--sm">
                                                    {{ $format_date(currentProject.deadline) || 'N/A' }}
                                                </p>
                                            </div>                                    
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="invoice__details__item pb--10 pt--10">
                                <div class="col-12 mb--10 flex align-items-center justify-content-between">
                                    <FormLabel title="Estimate & billing details" html-for="meta" label-type="title" />
                                    <BaseButton 
                                        variant="ghost" label="Edit" size="sm" class="text--color-primary p--0 mr--10 text--semi--bold"
                                        @submit="openEditModal('billingDetails')"            
                                    />
                                </div>

                                <div class="invoice__details__group mb--10">
                                    <div>
                                        <FormLabel title="Currency" html-for="meta" label-type="menu" />
                                        <p class="text--upper invoice__detail text--sm">
                                            {{ currentProject.currency || '-' }}
                                        </p>
                                    </div>
                                </div>

                                <div class="invoice__details__group mb--10">
                                    <div>
                                        <FormLabel title="Paid project" html-for="meta" label-type="menu" />
                                        <p class="text--upper invoice__detail text--sm">
                                            {{ currentProject.projectType === 'not paid' ? 'NO' : 'YES' }}
                                        </p>
                                    </div>
                                </div>

                                <div class="invoice__details__group mb--10">
                                    <div>
                                        <FormLabel title="Estimated revenue" html-for="meta" label-type="menu" />
                                        <p class="text--upper invoice__detail text--sm">
                                            {{ currentProject.currency }} {{ $format_amount(currentProject.estimatedRevenue) }}
                                        </p>
                                    </div>
                                </div>

                                <div class="invoice__details__group mb--10">
                                    <div>
                                        <FormLabel title="Billable hours" html-for="meta" label-type="menu" />
                                        <p class="text--capitalize invoice__detail text--sm">
                                            {{ currentProject.billableHours || 0 }} hours
                                        </p>
                                    </div>
                                </div>

                                <div class="invoice__details__group">
                                    <div>
                                        <FormLabel title=" Non-billable hours" html-for="meta" label-type="menu" />
                                        <p class="text--capitalize invoice__detail text--sm">
                                            {{ currentProject.nonBillableHours || 0 }} hours
                                        </p>
                                    </div>
                                </div>

                                <div class="invoice__details__group">
                                    <div>
                                        <FormLabel title="Billable Rate" html-for="meta" label-type="menu" />
                                        <p class="text--upper invoice__detail text--sm">
                                            {{ currentProject.billingRate || 0 }} %
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="invoice__details__item pb--10 pt--10">
                                <div class="col-12 mb--10 flex align-items-center justify-content-between">
                                    <FormLabel title="Tracking details" html-for="meta" label-type="title" />
                                    <BaseButton 
                                        variant="ghost" label="Edit" class="text--color-primary p--0 mr--10 text--semi--bold"
                                        size="sm" @submit="openEditModal('trackingDetails')"            
                                    />
                                </div>

                                <div class="invoice__details__group">
                                    <div>
                                        <FormLabel title="Tracking Type" html-for="meta" label-type="menu" />
                                        <p class="text--capitalize invoice__detail text--sm">
                                            {{ currentProject.trackingType || "-" }}
                                        </p>
                                    </div>
                                </div>

                                <div class="invoice__details__group">
                                    <div>
                                        <FormLabel title="Track project" html-for="meta" label-type="menu" />
                                        <p class="text--capitalize invoice__detail text--sm">
                                            {{ currentProject.trackProject ? "Yes" : "No" }}
                                        </p>
                                    </div>
                                </div>

                                <!-- <div class="invoice__details__group">
                                    <div class="w--100">
                                        <p class="text--color-gray-100 text--sm text--medium">
                                            Tracking days
                                        </p>                                       
                                       
                                        <template v-if="currentProject.trackingDays && currentProject.trackingDays.length">
                                            <div class="flex align-items-center w--100 flex-wrap">
                                                <div 
                                                    v-for="(day, index) in currentProject.trackingDays" :key="day" 
                                                    class="block project__item__desc mr--5"
                                                >
                                                    {{ index < currentProject.trackingDays.length - 1 ? `${day},` : `${day}` }}
                                                </div>
                                            </div>
                                        </template>

                                        <template v-else>
                                            <p class="project__item__desc">
                                                -
                                            </p>
                                        </template>
                                    </div>
                                </div> -->
                            </div>

                            <div v-if="currentProject.projectType === 'paid'" class="col-12">
                                <ProjectInvoices :current-project="currentProject" />
                            </div>
                        </div>

                        <div class="invoice__details__row__right">
                            <div class="invoice__details__divider pb--15">
                                <div class="flex align-items-center justify-content-between mt--10 mb--10">
                                    <div class="flex align-items-center">
                                        <FormLabel title="Linked Clients" html-for="meta" label-type="menu" class="mr--5 mb--0" />
                                        <span class="badge rounded-pill tag--grey text-dark">
                                            {{ reminders && reminders.length || 0 }}
                                            <span class="visually-hidden">reminders count</span>
                                        </span>
                                    </div>

                                    <BaseButton 
                                        variant="ghost" label="(Manage)" size="sm" 
                                        class="text--color-primary p--0 text--semi--bold"
                                        @submit="showClientsModal = true"
                                    />
                                </div>

                                <template v-if="currentProject.clients && currentProject.clients.length">
                                    <div class="">
                                        <router-link 
                                            v-for="client in currentProject.clients" 
                                            :key="client._id" 
                                            class="block project__item__desc text--underline mr--10"
                                            :to="`/dashboard/clients/${client._id}`"
                                        >
                                            {{ client.name }}
                                        </router-link>
                                    </div>
                                </template>

                                <template v-else>
                                    <p class="project__item__desc">
                                        No clients added yet.
                                    </p>
                                </template>
                            </div>

                            <div class="invoice__details__divider pb--15">
                                <div class="flex align-items-center justify-content-between mt--10 mb--10">
                                    <div class="flex align-items-center">
                                        <FormLabel title="Reminders" html-for="meta" label-type="menu" class="mr--5 mb--0" />
                                        <span class="badge rounded-pill tag--grey text-dark">
                                            {{ reminders && reminders.length || 0 }}
                                            <span class="visually-hidden">reminders count</span>
                                        </span>
                                    </div>

                                    <BaseButton 
                                        variant="ghost" label="(Manage)" size="sm" 
                                        class="text--color-primary p--0 text--semi--bold"
                                        @submit="openRemindersModal"
                                    />
                                </div>

                                <template v-if="reminders && reminders.length">
                                    <span 
                                        v-for="reminder in reminders" 
                                        :key="reminder" 
                                        class="block project__item__desc text--xs mb--5"
                                    >
                                        {{ mapReminders[reminder] }}
                                    </span>
                                </template>

                                <template v-else>
                                    <p class="project__item__desc">
                                        No reminders added
                                    </p>
                                </template>
                            </div>

                            <div class="invoice__details__divider pb--15">
                                <div class="flex align-items-center justify-content-between mt--10 mb--10">
                                    <div class="flex align-items-center">
                                        <FormLabel title="Rate cards" html-for="meta" label-type="menu" class="mr--5 mb--0" />
                                        <span class="badge rounded-pill tag--grey text-dark">
                                            {{ currentProject.rateCards && currentProject.rateCards.length || 0 }}
                                            <span class="visually-hidden">rateCards count</span>
                                        </span>
                                    </div>

                                    <BaseButton 
                                        variant="ghost" label="(Manage)" size="sm" 
                                        class="text--color-primary p--0 text--semi--bold"
                                        @submit="showRateCardsModal = true"
                                    />
                                </div>

                                <template v-if="currentProject.rateCards && currentProject.rateCards.length">
                                    <div>
                                        <div 
                                            v-for="item in currentProject.rateCards" :key="item._id" 
                                            class="project__item__desc mb--5"
                                        >
                                            {{ item.title }}
                                        </div>
                                    </div>
                                </template>

                                <template v-else>
                                    <p class="project__item__desc">
                                        No rate cards added
                                    </p>
                                </template>
                            </div>

                            <ProjectNotes />
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <ConfirmDeletionTwo
            type="project" custom-msg 
            :loading="deleteState.loading" :show-modal="showDeleteModal" 
            @cancel="toggleDelete" @delete="handleDeleteProject"
        >
            <template #custom-msg>
                <p class="text--sm text--color-gray-800">
                    All data for this project will be permamently removed but its associated clients and invoices will remain. <strong>This action cannot be undone.</strong>
                </p>
            </template>
        </ConfirmDeletionTwo>

        <CreateProjectModal
            :show-modal="showEditModal" action-type="edit" :current-selection="editModalType" :loading="editProjectLoading"
            :current-project="currentProject" @close="showEditModal = false" @complete="handleUpdateProject"
        />

        <ArchiveRecord 
            type="project" 
            :loading="pageLoading" 
            :show-modal="showArchiveModal" 
            @cancel="toggleArchiveModal" 
            @archive="handleArchiveProject"
        />

        <ManageRemindersModal 
            :initial-reminders="reminders" :loading="editProjectLoading" :show-modal="showRemindersModal"
            @cancel="showRemindersModal = false" @complete="manageReminders"
        />

        <MainModal 
            :show-modal="showClientsModal"
            size="sm" title="Manage clients" @close="resetClientSelection"
        >
            <template #body>
                <div class="modal__wrapper--onboarding mb--15">
                    <form class="pb--5" @submit.prevent="">
                        <CustomDropdown 
                            id="clientDropdown" v-model="form.clients" :options="clients" multiple
                            list-type="clients" index-label="name" :loading="searchItemsLoading"
                            @search="searchClients" @select="selectClient" @remove="removeClientSelection"
                        />
                    </form>
                </div>
            </template>

            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <BaseButton 
                        class="mr--5" label="Cancel" variant="outline" size="sm"
                        @submit="resetClientSelection"
                    />
                    <BaseButton 
                        type="submit" size="sm"
                        :loading="editProjectLoading" :disabled="editProjectLoading" 
                        label="Update" @submit="manageClients"
                    />
                </div>
            </template>
        </MainModal>

        <MainModal 
            :show-modal="showRateCardsModal"
            size="sm" title="Manage rate cards" @close="resetRateCardSelection"
        >
            <template #body>
                <div class="modal__wrapper--onboarding mb--15">
                    <form class="pb--5" @submit.prevent="">
                        <CustomDropdown 
                            id="rateCardDropdown" v-model="form.rateCards" :options="storedRateCards" multiple
                            list-type="rate cards" index-label="title" :loading="searchItemsLoading"
                            @search="searchRateCards" @select="selectRateCard" @remove="removeRateCardSelection"
                        />
                    </form>
                </div>
            </template>

            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <BaseButton 
                        class="mr--5" label="Cancel" variant="outline" size="sm"
                        @submit="resetRateCardSelection"
                    />
                    <BaseButton 
                        type="submit" size="sm"
                        :loading="editProjectLoading" :disabled="editProjectLoading" 
                        label="Update" @submit="manageRateCards"
                    />
                </div>
            </template>
        </MainModal>

        <ManageTimeTrackingModal 
            :show-modal="showTimeTrackingModal" 
            :current-project="currentProject" @cancel="showTimeTrackingModal = false"
        />

        <UpgradeAccountModal 
            :show-modal="showUpgradeModal" type="projects" @cancel="closeUpgradeModal"
        />
    </div>
</template>

<script>
import {
    BaseButton,
    Status,
    EmptyPage,
    PageSpinner,
    CreateProjectModal,
    UpgradeAccountModal,
    ArchiveRecord, ManageTimeTrackingModal,
    ConfirmDeletionTwo,
    IconSvg, CustomDropdown,
    ManageRemindersModal,
    MainModal, FormLabel,
} from "@/components/ui/index";
import { projectFilterStatuses, priorities} from "@/utils/enums";
import ProjectInvoices from "./ProjectInvoices.vue";
import ProjectNotes from "./ProjectNotes.vue";
import { mapReminders } from "@/utils/dummy";
import { assembleQueryList } from "@/utils/helpers";

export default {
    name: "ProjectDetailsView",

    components: {
        IconSvg,
        BaseButton,
        Status, MainModal,
        EmptyPage,
        PageSpinner,
        ProjectInvoices,
        UpgradeAccountModal,
        CreateProjectModal,
        CustomDropdown,
        ArchiveRecord,
        ConfirmDeletionTwo, FormLabel,
        ManageTimeTrackingModal,
        ProjectNotes, ManageRemindersModal,
    },

    data() {
        return {
            pageLoading: false,
            pageLoadingErr: false,
            pageLoadingErrMsg: "",
            form: {
                clients: [],
                rateCards: [],
            },
            deleteState: {
                loading: false,
                errMsg: "",
                err: false,
            },
            showEditModal: false,
            showDeleteModal: false,
            projectFilterStatuses,
            mapReminders,
            priorities,
            projectSubType: "",
            showArchiveModal: false,
            unableToLoadPage: false,
            showRemindersModal: false,
            reminders: [],
            selectedRateCard: {},
            editModalType: "",
            currentProject: {},
            editProjectLoading: false,
            searchItemsLoading: false,
            searchItemsErr: false,
            showClientsModal: false,
            showRateCardsModal: false,
            clients: [],
            rateCards: [],
            duplicateLoading: false,
            showTimeTrackingModal: false,
            showProjectSettingsModal: false,
        };
    },

    computed: {
        timeTrackingCopy() {
            if (this.accountPlan === "free") {
                return "Upgrade to add time tracking";
            } 
            
            if (this.currentProject.trackProject) {
                return "Disable time tracking";
            } else {
                return "Enable time tracking";
            }
        },

        storedClients() {
            return this.$store.getters["clients/clients"];
        },

        storedRateCards() {
            return this.$store.getters["ratecards/rateCards"];
        },

        showUpgradeModal() {
            return this.$store.getters["app/showUpgradeModal"];
        },

        checkUpgradeLoading() {
            return this.$store.getters["app/checkUpgradeLoading"];
        },

        checkUpgradeFields() {
            return this.$store.getters["app/checkUpgradeFields"];
        },
        
        completedProjectCount() {
            return this.$store.getters["app/completedProjectCount"];
        },
    },

    watch: {
        "showClientsModal": {
            async handler(val) {
                if (val) {
                    if (this.storedClients?.length) {
                        this.clients = this.storedClients;
                        return;
                    }

                    if (!this.storedClients?.length && !this.clients.length) {
                        return await this.searchClients();
                    }
                }
            },
        },

        "showRateCardsModal": {
            async handler(val) {
                if (val) {
                    if (this.storedRateCards?.length) {
                        this.rateCards = this.storedRateCards;
                        return;
                    }

                    if (!this.storedRateCards?.length && !this.rateCards.length) {
                        return await this.searchRateCards();
                    }
                }
            },
        },
    },

    async mounted() {
        await this.handleFetchProject();

        // this.$toast.success("Success", {
        //     links: [
        //         { url: "/dashboard/projects", label: "View projects" },
        //     ],
        // });
    },

    methods: {
        closeUpgradeModal() {
            this.$store.dispatch("app/closeUpgradeModal", "projects");
        },

        async openTineTrackingModal() {
            try {
                // await this.$store.dispatch("app/checkUpgradeStatus", "projects");
                this.showTimeTrackingModal = true;
            } catch (error) {
                // console.log({ openCreateModal: error });
            }
        },

        goToTasks() {
            this.$router.push({ path: `/dashboard/projects/${this.$route.params.id}/tasks`});
        },

        selectClient(val) {
            const item = this.form.clients.find(x => x?._id === val?._id);

            if (!item) {
                this.form.clients = [ ...this.form.clients, val];
            };
        },

        removeClientSelection(item) {
            this.form.clients = this.form.clients.filter(x => x?._id !== item?._id);
        },

        selectRateCard(val) {
            const item = this.form.rateCards.find(x => x?._id === val?._id);

            if (!item) {
                this.form.rateCards = [ ...this.form.rateCards, val];
            };
        },

        removeRateCardSelection(item) {
            this.form.rateCards = this.form.rateCards.filter(x => x?._id !== item?._id);
        },
        
        async handleFetchProject() {
            try {
                this.unableToLoadPage = false;
                const id = this.$route.params.id;

                if (id === "undefined" || !id) {
                    this.unableToLoadPage = true;
                    return;
                }

                this.pageLoadingErr = false;
                this.pageLoadingErrMsg = "";
                this.pageLoading = true;

                const res = await this.$store.dispatch("project/getProject", id);
                this.currentProject = res;
                this.reminders = res.reminders;
                this.form.clients = res.clients;
                this.form.rateCards = res.rateCards;
            } catch (error) {
                const msg = error?.message || error?.message;

                this.pageLoadingErr = true;
                this.$toast.error(msg);
            } finally {
                this.pageLoading = false;
            }
        },

        async onCompleteSubUpdate(resp) {
            if (!resp.key) return;

            this.currentProject = { ...this.currentProject, [`${resp.key}`]: resp.list };
            this.closeSubModal();
        },

        async handleStatusUpdate(val) {
            try {
                if (val === this.currentProject.status) return;

                const tempStatus = this.currentProject.status;

                await this.handleUpdateProject({ payload: { status: val }});
                
                if (val === "completed" || tempStatus === "completed") {
                    this.$store.dispatch("app/fetchCompletedProjectCount", { refresh: true, manualUpdate: true });
                    
                    if ([5, 10, 25, 50].includes(this.completedProjectCount)) {
                        this.$store.dispatch("app/toggleRankConfetti", true);
                    }
                }     

                // don't await
                this.$store.dispatch("app/fetchActivities", { refresh: true });
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            }
        },

        async handleDeleteProject() {
            try {
                this.deleteState.loading = true;
                this.deleteState.err = false;
                this.deleteState.errMsg = "";

                const ids = [this.currentProject?._id];
                await this.$store.dispatch("project/deleteProjects", ids);

                this.$toast.success("Project deleted successfully");

                // don't await
                this.$store.dispatch("app/fetchActivities", { refresh: true });

                return this.$router.push({ path: "/dashboard/projects" });
            } catch (error) {
                const errMsg = error?.response?.message || error.message;

                this.deleteState.err = false;
                this.deleteState.errMsg = errMsg;
                this.$toast.error(errMsg);
            } finally {
                this.deleteState.loading = false;
            }
        },

        async handleArchiveProject() {
            try {
                const id = this.currentClient?._id || this.$route.params.id;

                if (!id) {
                    this.$toast.error("No project id passed");
                    return;
                }

                this.pageLoadingErr = false;
                this.pageLoadingErrMsg = "";
                this.pageLoading = true;

                await this.$store.dispatch("project/archiveProject", id);

                this.showArchiveModal = false;
                this.$toast.success("Project archived");
                this.$router.push("/dashboard/projects");
            } catch (error) {
                const msg = error?.message;

                this.pageLoadingErr = true;
                this.$toast.error(msg);
            } finally {
                this.pageLoading = false;
            }
        },

        openEditModal(val) {
            this.editModalType = val;
            this.showEditModal = true;
        },
        
        toggleDelete() {
            this.showDeleteModal = !this.showDeleteModal;
        },

        toggleArchiveModal(bool) {
            this.showArchiveModal = bool;
        },

        openRemindersModal() {
            if (!this.currentProject.deadline) {
                this.$toast.error("Set a deadline for this project");
                return;
            }
            this.showRemindersModal = true;
        },

        upgradeAccount() {
            this.$router.push("/dashboard/settings/billings-and-plans");
        },

        async enableTimeTracking() {
            try {
                if (!this.$route.params.id) {
                    this.$toast.error("Project id required");
                    return;
                }

                this.pageLoadingErr = false;
                this.pageLoadingErrMsg = "";
                this.pageLoading = true;

                await this.$store.dispatch("project/archiveProject", this.$route.params.id);

                this.showArchiveModal = false;
                this.$toast.success("Project archived");
                this.$router.push("/dashboard/projects");
            } catch (error) {
                const msg = error?.message;

                this.pageLoadingErr = true;
                this.$toast.error(msg);
            } finally {
                this.pageLoading = false;
            }
        },

        disableTimeTracking() {

        },

        async duplicateProject() {
            try {
                this.duplicateLoading = true;
                this.showUpgradeModal = false;
                
                await this.$store.dispatch("app/checkUpgradeStatus", "projects");

                const payload = {
                    ...this.currentProject,
                    reminders: this.reminders,
                    clientIds: this.currentProject.clients.map(x => x._id),
                    rateCardIds: this.currentProject.rateCards.map(x => x._id),
                };
                
                const res = await this.$store.dispatch("project/duplicateProject", payload);

                if (!res._id) {
                    this.$toast.error("Unable to duplicate project");        
                }

                return this.$router.push(`/dashboard/projects/${res._id}`);
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;

                if (errMsg.includes("You need to upgrade your account to create more projects")) {
                    this.showUpgradeModal = true;
                } else {
                    this.$toast.error(errMsg);
                }
            } finally {
                this.duplicateLoading = false;
            }
        },

        buildSubPayload() {
            const payload = {
                _id: this.currentProject._id,
                title: this.currentProject.title,
                description: this.currentProject.description,
                status: this.currentProject.status?.toLowerCase(),
                deadline: this.currentProject.deadline,
                priority: this.currentProject.priority,
                projectType: this.currentProject.projectType?.toLowerCase(),
                estimatedRevenue: this.currentProject.estimatedRevenue,
                isFavourite: this.currentProject.isFavourite === "no" ? false : true,
                currency: this.currentProject.currency,
                billableHours: Number(this.currentProject.billableHours || ""),
                reminders: this.currentProject.reminders,
                trackProject: this.currentProject.trackProject === "Yes" ? true : false,
                trackingDays: this.currentProject.trackingDays,
                trackingType: this.currentProject.trackingType || "none",
            };

            return payload;
        },

        async handleUpdateProject({ payload, resetFn }) {              
            try {                
                const initialPayload = { ...this.buildSubPayload() };
                this.editProjectLoading = true;
                let res;

                if (this.editModalType === "trackingDetails") {
                    res = await this.$store.dispatch("project/toggleTimeTracking", { 
                        id: this.currentProject._id, 
                        payload, 
                    });
                } else {
                    res = await this.$store.dispatch("project/updateProject", { 
                        id: this.currentProject._id, 
                        payload: { ...initialPayload, ...payload }, 
                    });
                }
                
                this.currentProject = {
                    ...this.currentProject,
                    ...res,
                };

                this.showEditModal = false;

                if (resetFn) {
                    resetFn();
                }
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.editProjectLoading = false;
            }
        },

        async updatePriority(val) {
            const payload = { priority: val };

            if (val === this.currentProjectpriority) return;

            this.currentProjectpriority = val;
            return await this.handleUpdateProject({ payload });
        },

        async manageClients() {
            const payload = { clientIds: this.form.clients.length ? this.form.clients.map(x => x._id) : [] };    
            
            await this.handleUpdateProject({ payload });
            
            this.currentProject.clients = this.form.clients;
            this.showClientsModal = false;
        },

        async manageRateCards() {
            const payload = { rateCardIds: this.form.rateCards.length ? this.form.rateCards.map(x => x._id) : [] };    
            
            await this.handleUpdateProject({ payload });
            
            this.currentProject.rateCards = this.form.rateCards;
            this.showRateCardsModal = false;
        },

        async manageReminders(data) {
            try {
                const payload = { reminders: data };
                
                await this.handleUpdateProject({ payload });

                this.reminders = data;
                this.showRemindersModal = false;
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            }
        },

        resetClientSelection() {
            this.form.clients = this.currentProject.clients;
            this.showClientsModal = false;
        },

        resetRateCardSelection() {
            this.form.rateCards = this.currentProject.rateCards;
            this.showRateCardsModal = false;
        },

        async searchClients(query) {
            try {
                this.searchItemsErr = false;
                this.searchItemsLoading = true;

                const pageQueryObj = {
                    page: 1,
                    type: "all",
                    name: query,
                };

                const buildResult = assembleQueryList(pageQueryObj);
                this.clients = await this.$store.dispatch("clients/fetchClients", { params: buildResult, refresh: true, external: true });
            } catch (error) {
                this.searchItemsErr = true;
            } finally {
                this.searchItemsLoading = false;
            }
        },

        async searchRateCards(query) {            
            try {
                this.searchItemsErr = false;
                this.searchItemsLoading = true;

                const pageQueryObj = {
                    page: 1,
                    title: query,
                };

                const buildResult = assembleQueryList(pageQueryObj);

                this.rateCards = await this.$store.dispatch("revenue/fetchRateCards", { payload: buildResult, refresh: true, external: true });
            } catch (error) {
                this.searchItemsErr = true;
            } finally {
                this.searchItemsLoading = false;
            }
        },

        async updateTimeTrackingSelection(val) {
            try {
                this.editProjectLoading = true;
                await this.handleUpdateProject({ payload: { trackingType: val }}); 
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.editProjectLoading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .dropdown-item {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
</style>