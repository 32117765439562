<template>
    <div class="logo__wrap" :class="[position]" v-bind="$attrs" @click="goToLandingPage">
        <div class="logo__inner">
            <img src="@/assets/img/logo.svg">
        </div>
    </div>
</template>

<script>
export default {
    name: "Logo",
    props: {
        position: {
            type: String,
            default: "center",
            validator: (value) => {
                return ["center", "left", "right"].includes(value);
            },
        },
    },

    methods: {
        goToLandingPage() {
            return this.$router.push("/");
        },
    },
};
</script>

<style lang="scss" scoped>
.logo {
    &__wrap {
        margin-bottom: 1rem;
        display: flex;
        cursor: pointer; 
        padding: 10px 20px;  
        
        &.left {
            width: 100%;
            justify-content: flex-start;
        }

        &.center {
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
            justify-content: center;
        }
    }
}
</style>