<template>
    <div id="offcanvasRight" class="offcanvas z_offcanvas offcanvas-end" tabindex="-1" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header">
            <button
                type="button"
                class="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
            />
        </div>
        <div class="offcanvas-body">
            <div class="invoice__preview--wrap">
                <div class="w--100">
                    <div class="invoice__preview--contents">
                        <div class="invoice__preview__top" />
                        <div class="pl--15 pr--15">
                            <div class="invoice__preview--header preview__header">
                                <img src="">
                                <h1 class="mr--5">
                                    Invoice
                                </h1>
                                <p class="invoice__preview__header__no">
                                    Invoice No: <span>#{{ invoiceData.invoice.invoiceNo }}</span>
                                </p>
                            </div>
                        </div>
                        <div class="invoice__preview--card">
                            <div class="preview__dates">
                                <div class="flex-1">
                                    <p class="text--sm text--semi--bold">
                                        Date Issued
                                    </p>
                                    <p class="text--sm">
                                        {{ $format_date(invoiceData.invoice.createdAt) || '-' }}
                                    </p>
                                </div>
                                <div class="flex-1">
                                    <p class="text--sm text--semi--bold">
                                        Due Date
                                    </p>
                                    <p class="text--sm">
                                        {{ $format_date(invoiceData.invoice.dueDate) || '-' }}
                                    </p>
                                </div>
                            </div>

                            <div class="preview__details">
                                <div class="preview__details__item">
                                    <CustomText size="base" color="blue" transform="upper" weight="semi--bold">
                                        From:
                                    </CustomText>
                                    <div class="preview__card__item">
                                        <p class="preview__card__item__title">
                                            {{ invoiceData.user.fullName }}
                                        </p>
                                        <p class="preview__card__item__address">
                                            {{ invoiceData.user.address }}
                                        </p>
                                        <p>{{ invoiceData.user.phoneNumber || '-' }}</p>
                                        <p>{{ invoiceData.user.email || '-' }}</p>
                                    </div>
                                </div>
                                <div class="preview__details__item mr--10">
                                    <CustomText size="base" color="blue" transform="upper" weight="semi--bold">
                                        Billed to:
                                    </CustomText>
                                    <div v-if="invoiceData.client.name" class="preview__card__item">
                                        <p class="preview__card__item__title">
                                            {{ invoiceData.client.name }}
                                        </p>
                                        <p>{{ invoiceData.client.email }}</p>
                                        <p>{{ invoiceData.client.phoneNumber || '-' }}</p>
                                        <p>{{ invoiceData.client.address || '-' }}</p>
                                        <p>{{ invoiceData.client.country || '-' }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="mt--40 mb--10 text--xl text--medium">
                                {{ items.length }} {{ computeItemsLengthText }}
                            </div>

                            <table class="table table-light mt--20">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            Description
                                        </th>
                                        <th scope="col">
                                            Quantity
                                        </th>
                                        <th scope="col">
                                            Price
                                        </th>
                                        <th scope="col">
                                            Amount
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-for="(item, index) in items" :key="index">
                                    <tr>
                                        <td>{{ item.itemName || "-" }}</td>
                                        <td>{{ item.itemQuantity || 0 }}</td>
                                        <td>{{ item.itemPrice || 0 }}</td>
                                        <td>{{ item.itemUnit || 0 }}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="mt--30">
                                <div class="preview__row">
                                    <div class="text--sm">
                                        Subtotal:
                                    </div>
                                    <div class="preview__row__text">
                                        {{ $country.getSymbol(invoiceData.invoice.currency, 'currency') || '-' }}{{ invoiceData.invoice.subtotal || 0 }}
                                    </div>
                                </div>
                                <div class="preview__row">
                                    <div class="text--sm">
                                        Tax:
                                    </div>
                                    <div class="preview__row__text">
                                        {{ $country.getSymbol(invoiceData.invoice.currency, 'currency') || '-' }}{{ invoiceData.invoice.tax }}
                                    </div>
                                </div>
                                <div class="preview__row">
                                    <div class="text--sm">
                                        Total:
                                    </div>
                                    <div class="preview__row__text">
                                        {{ $country.getSymbol(invoiceData.invoice.currency, 'currency') || 'N/A' }}{{ invoiceData.invoice.total || 0 }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { formatDateStrings } from "../../utils/helpers";
import CustomText from "../ui/text/Text.vue";

export default {
    name: "PreviewInvoice",
    components: {
        CustomText,
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        invoiceData: {
            type: Object,
            default: () => {},
        },
    },

    computed: {
        computeItemsLengthText() {
            if (this.items.length > 1 || !this.items.length) {
                return "items";
            } else {
                return "item";
            }
        },
    },
    
    methods: {
        formatDate(dateString) {
            if (typeof dateString === "object") {
                let stringDate = dateString.toString();
                return formatDateStrings(stringDate) || "N/A";
            }
            return dateString && typeof dateString === "string" ? formatDateStrings(dateString) : "N/A";
        },
    },
};
</script>

<style lang="scss" scoped>
    .offcanvas-end {
        width: 650px;
    }

    thead {
        border-style: solid !important;
    }

    th {
        padding-left: 0px !important;
    }

    .table tbody td {
        overflow-x: auto !important;
        white-space: normal !important;
        text-overflow: unset !important;
    }

    .preview {
        &__header {
            border-bottom: 1px solid #eee;
            padding-bottom: 15px;
        }

        &__dates {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            border-bottom: 1px solid #eee;
            padding-bottom: 10px;
        }

        &__card {
            padding-top: 1.3rem;
        }

        &__row {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            width: 100%;
            justify-content: flex-end;

            &__text {
                font-size: 0.875rem;
                font-weight: 600;
                text-transform: uppercase;
                margin-left: 10px;
            }
        }

        &__card__item {
            &__title {
                font-weight: 500;
                margin-bottom: 3px !important;
                margin-top: 10px;
            }

            p {
                font-size: 0.875rem !important;    
            }
        }

        &__details {
            display: flex;
            align-items: baseline;

            &__item {
                flex: 1;
            }
        }
    }
</style>