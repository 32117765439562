<template>
    <div class="mr--5" v-bind="$attrs">
        <BaseButton 
            id="reportTypeFilter" 
            class="btn--filter dropdown-toggle" 
            type="BaseButton" variant="outline" inner-style="secondary"
            aria-expanded="false" size="sm"
            data-bs-toggle="dropdown" 
            data-bs-auto-close="true" 
        >
            <span class="w--100 justify-content-between flex align-items-center">
                <IconSvg name="filter" fill="#637579" width="12px" height="12px" />
                <div class="ml--5 flex align-items-center">
                    <span class="mr--5 text--xs">Filter</span>
                    <span class="zm__count__badge">{{ filteredCount }}</span>
                </div>
            </span>
        </BaseButton>

        <div class="dropdown-menu">
            <form @submit.prevent="">
                <div class="mb--15">
                    <div class="filter-item-input">
                        <FormLabel title="Report type" />
                        <select v-model="filter.type" class="form-select form-select-sm text--xs filter-input text--capitalize">
                            <option v-for="(type, index) in types" :key="index" :value="type">
                                {{ type }}
                            </option>
                        </select>
                    </div>   
        
                    <div class="filter-item-input mt--10">
                        <FormLabel title="Year" />
                        <select v-model="filter.year" class="form-select form-select-sm text--xs filter-input text--capitalize">
                            <option v-for="year in reportDateTypes" :key="year" :value="year">
                                {{ year }}
                            </option>
                        </select>
                    </div> 
                </div>
                <div class="flex justify-content-center w--100">
                    <BaseButton  
                        :disabled="disabled" size="sm"
                        full-width label="Run" type="submit" 
                        @submit="handleFilter" 
                    />
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import BaseButton from "../buttons/BaseButton.vue";
import FormLabel from "../forms/FormLabel.vue";
import IconSvg from "../icons/Icon-Svg.vue";
import { serilaizeQuery } from "@/utils/helpers";

export default {
    name: "ReportFilter",

    components: {
        FormLabel,
        IconSvg, BaseButton,
    },

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    
    data() {
        return {
            query: {},
            filter: {
                type: this.$route.query.type || "clients",
                year: this.$route.query.year || "2024",
            },
            reportDateTypes: ["2024", "2025"],
            types: ["clients", "projects"],
            filteredCount: 0,
        };
    },

    watch: {
        "$route.query": {
            handler(newVal) {                
                // const count = Object.keys(newVal).filter((key) => newVal[key]).length;
                const count = Object.keys(newVal).length;
                this.filteredCount = count;
                this.setReportFilter(newVal);
            },
            deep: true,
        },
    },

    mounted() {
        const count = Object.keys(this.$route.query).length;                 
        this.filteredCount = count;
    },

    methods: {
        serilaizeQuery,

        setReportFilter(params) {
            this.$store.dispatch("report/setReportFilter", params);
        },

        handleFilter() {
            let query = this.query;

            if (this.filter.type) {
                query.type = this.filter.type;
            }

            if (this.filter.year) {
                query.year = this.filter.year;
            }

            const currentRoute = this.$route.path;
            const queryParam = this.serilaizeQuery(query);
                        
            this.$router.replace(`${currentRoute}?${queryParam}`);
        },
    },
};
</script>

<style lang="scss" scoped>
    .dropdown-menu {
        min-width: 12rem !important;
        padding: 0.5rem 1rem;

    }
    .report--item {
        padding: 10px;
        padding-bottom: 5px;
        border-top: 1px solid #e0e1e4;
        padding-top: 15px;
    }
    .zm__count__badge {
        margin-right: 0px;
        border: 1px solid #e5e5e5;
        padding: 1px 4px;
        border-radius: 50%;
        font-size: 8px;
        color: #757676;
    }
</style>