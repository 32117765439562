<template>
    <div>
        <main-modal 
            :show-modal="showModal"
            size="sm" title="Rate card details"
        >
            <template #body>
                <div class="mb--15 pr--10 pl--10">
                    <div class="rc__form mb--20">
                        <div class="revenue__info__item">
                            <div class="flex align-items-center">
                                <icon-svg name="board" fill="#637579" width="16px" height="16px" />
                                <p class="title ml--5">
                                    Title
                                </p>
                            </div>
                            <p class="subtitle">
                                {{ item.title }}
                            </p>
                        </div>

                        <div class="revenue__info__item">
                            <div class="flex align-items-center">
                                <icon-svg name="money" fill="#637579" width="16px" height="16px" />
                                <p class="title ml--5">
                                    Currency
                                </p>
                            </div>
                            <p class="text-uppercase subtitle">
                                {{ item.currency }}
                            </p>
                        </div>

                        <div class="revenue__info__item">
                            <div class="flex align-items-center">
                                <icon-svg name="hash" fill="#637579" width="16px" height="16px" />
                                <p class="title ml--5">
                                    Rate type
                                </p>
                            </div>
                            <p class="subtitle text--capitalize">
                                {{ item.rateType }}
                            </p>
                        </div>
                       
                        <div class="revenue__info__item">
                            <div class="flex align-items-center">
                                <icon-svg name="receipt" fill="#637579" width="16px" height="16px" />
                                <p class="title ml--5">
                                    Description
                                </p>
                            </div>
                            <p class="subtitle">
                                {{ item.description || 'N/A' }}
                            </p>
                        </div>

                        <div class="revenue__info__item">
                            <div class="flex align-items-center">
                                <icon-svg name="alarm" fill="#637579" width="16px" height="16px" />
                                <p class="title ml--5 text-capitalize">
                                    Date created
                                </p>
                            </div>
                            <p class="subtitle">
                                {{ $format_date(item.createdAt) }}
                            </p>
                        </div>

                        <div class="revenue__info__item">
                            <div class="flex align-items-center">
                                <icon-svg name="envelope" fill="#637579" width="16px" height="16px" />
                                <p class="title ml--5 text-capitalize">
                                    Items
                                </p>
                            </div>
                           
                            <template v-if="item.items && item.items.length">
                                <table class="table table-light mt--30">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="pl--0">
                                                #
                                            </th>
                                            <th scope="col">
                                                Service Name
                                            </th>
                                            <th scope="col">
                                                Amount
                                            </th>
                                            <th scope="col">
                                                Platform
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(field, index) in item.items" :key="index">
                                        <tr>
                                            <td scope="row">
                                                {{ index + 1 }}
                                            </td>
                                            <td>{{ field.serviceName || "-" }}</td>
                                            <td>{{ field.amount || 0 }}</td>
                                            <td>{{ field.platform || '-' }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </template>
                            <template v-else>
                                <p class="text--sm">
                                    No items available
                                </p>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <Base-Button label="Close" size="sm" @submit="closeModal" />
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import MainModal from "./MainModal.vue";
import BaseButton from "../buttons/BaseButton.vue";
import IconSvg from "../icons/Icon-Svg.vue";


export default {
    name: "RateCardModal",

    components: {
        MainModal,
        BaseButton,
        IconSvg,
    },

    props: {
        showModal: { type: Boolean, default: false },
        item: { type: Object, default: () => {} },
    },

    methods: {
        closeModal() {
            this.$emit("close");
        },
    },
};
</script>