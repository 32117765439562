<template>
    <div>
        <div class="project__page--view">
            <template v-if="pageLoading">
                <page-spinner />
            </template>

            <template v-else-if="pageLoadingErr">
                <EmptyPage state="error" type="task" :handle-submit="handleFetchTasks" />
            </template>

            <template v-else>
                <div class="w-100 pb--25 pt--25">
                    <div v-if="showActionBtns" class="flex align-items-center w-100 mb--15 justify-content-between">
                        <div class="list--count">
                            <p class="text--sm text--semi--bold text--color-gray-700">
                                {{ tasks.length }} {{ tasks.length === 1 ? 'Task' : 'Tasks' }}
                            </p>
                        </div>
        
                        <div class="filter__actions--list">
                            <main-filter page-type="tasks" />
                        </div>
                    </div>

                    <template v-if="!tasks.length">
                        <EmptyPage v-if="isSearched" state="no_result" />
                        <EmptyPage
                            v-else
                            state="empty" type="task" icon-name="tasks"
                            width="60px" height="60px"
                        >
                            <template #button>
                                <BaseButton 
                                    label="+ New task" size="sm"
                                    class="flex align-items-center" @submit="showNewTask = true"
                                />
                            </template>
                        </EmptyPage>
                    </template>

                    <template v-else>
                        <div class="w--100 justify-content-center">
                            <div class="mt--40 mb--15">
                                <div   
                                    v-for="(item, index) in tasks" :key="item._id" 
                                    :ref="`taskRef${index}`" class="client__notes__item task__item" 
                                >
                                    <div class="w--100 flex align-items-center justify-content-between" :class="{ 'disabled': taskId === item._id }">
                                        <div class="pointer flex-1">
                                            <div class="form-check mb--0">
                                                <input id="flexCheckDefault" class="form-check-input" type="checkbox" @change.stop="e => markTaskAsCompleted(e, item)">
                                                <label class="form-check-label task__item__label text--sm pointer" for="flexCheckDefault" @click.prevent="startTaskEdit(item)">
                                                    {{ item.name }}
                                                    <div class="flex align-items-center mt--5">
                                                        <div v-if="item.deadline" class="flex align-items-center">
                                                            <span class="mr--5">
                                                                <icon-svg name="calendar" fill="#BF2C6F" width="12px" height="12px" />
                                                            </span>
                                                            <span class="task__item__deadline">{{ $format_date(item.deadline) }}</span>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="dropdown">
                                            <BaseButton 
                                                id="noteDropdown" data-bs-auto-close="true" 
                                                aria-expanded="false" data-bs-toggle="dropdown" variant="ghost"
                                                label="+ Add new item" size="xs" inner-style="secondary" 
                                                prefix-icon icon-name="dots-horizontal-rounded" icon-fill="#637579" icon-width="12px"
                                            />
                                            <ul id="noteDropdownList" class="dropdown-menu dropdown-menu--tag" aria-labelledby="noteDropdown">
                                                <li class="dropdown-item pointer text--xs" @click="duplicateTask(item, index)">
                                                    Duplicate task
                                                </li>
                                                <li class="dropdown-item pointer text--xs" @click="handleDeleteTask(item)">
                                                    Delete task
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <div class="w-100">
                        <form class="w--100 client__notes__form mt--5" @submit.prevent="">
                            <div>
                                <input 
                                    id="taskName"
                                    ref="newTask" 
                                    v-model="form.name" 
                                    class="form-control form-control-sm task__item__inline__input main" 
                                    type="text"
                                    placeholder="What do you want to do?"
                                >
                                <input 
                                    id="taskDescription" 
                                    v-model="form.description" 
                                    class="form-control form-control-sm sub task__item__inline__input" 
                                    type="text"
                                    placeholder="Add task description here..."
                                >
                            </div>

                            <div class="client__notes__action__btns task__action__btns pr--0">
                                <base-button
                                    class="mr--5" label="Cancel" variant="outline" size="sm" 
                                    @submit="showNewTask = false"
                                />
                                <base-button 
                                    type="submit" label="Add" :loading="addTaskLoading"
                                    size="sm" :disabled="isNewBtnDisabled || addTaskLoading" @submit="handleAddTask"
                                />
                            </div>
                        </form>
                    </div>
                </div>              
            </template>
        </div>

        <TaskDetails :current-item-id="currentTaskId" task-type="child" />
    </div>
</template>

<script>
import {
    IconSvg,
    EmptyPage,
    PageSpinner,
    BaseButton,
    MainFilter,
    TaskDetails,
} from "@/components/ui/index";
import { assembleQueryList } from "@/utils/helpers";

export default {
    name: "ProjectTasksView",

    components: {
        EmptyPage,
        IconSvg,
        BaseButton,
        PageSpinner,
        MainFilter,
        TaskDetails,
    },

    data() {
        return {
            pageLoading: false,
            pageLoadingErr: false,
            pageLoadingErrMsg: "",
            showEditModal: false,
            showNewTask: false,
            isSearched: false,
            form: {
                name: "",
                description: "",
            },
            currentTaskId: "",
            addTaskLoading: false,
            taskId: "",
            notes: [],
            notePageData: {
                currentPage: 1,
                totalPages: 1,
                pageSize: 50,
                total: 0,
            },
            tasks: [],
            tasksPageData: {
                currentPage: 1,
                totalPages: 1,
                pageSize: 50,
                total: 0,
            },
            updateTaskLoading: false,
        };
    },

    computed: {
        showActionBtns() {
            if (this.tasks.length) {
                return true;
            } else if (!this.tasks.length && this.isSearched) {
                return true;
            } else {
                return false;
            }
        },

        isNewBtnDisabled() {
            if (!this.form.name) {
                return true;
            } else {
                return false;
            }
        },

        currentProject() {
            return this.$store.getters["project/currentProject"];
        },

        projects() {
            return this.$store.getters["project/projects"];
        },
    },

    watch: {
        "$route.query": {
            async handler(newVal) {
                const queryKeys = Object.keys(newVal);

                if (queryKeys.length) {
                    this.isSearched = true;
                } else {
                    this.isSearched = false;
                }

                await this.handleFetchTasks({ refresh: true, ...newVal });
            },

            deep: true,
            immediate: true,
        },

        showNewTask(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    this.$refs.newTask?.focus();
                });
            }
        },
    },

    async mounted() {
        await this.handleFetchTasks({ refresh: true });
    },

    methods: {
        assembleQueryList,

        async handleFetchProject() {
            try {
                if (!this.$route.params.id) {
                    this.pageLoadingErr = true;
                    return;
                }

                this.pageLoadingErr = false;

                await this.$store.dispatch("project/getProject", this.$route.params.id);
            } catch (error) {
                this.pageLoadingErr = true;
            }
        },

        async handleFetchTasks(params) {
            try {
                this.pageLoading = true;
                this.pageLoadingErr = false;
                this.pageLoadingErrMsg = "";

                const query = this.$route.query;

                let pageQueryObj = {
                    page: query?.page || 1,
                    type: "project",
                    projectId: this.$route.params.id,
                };

                const queryKeys = Object.keys(query);

                if (queryKeys.length) {
                    this.isSearched = true;
                } else {
                    this.isSearched = false;
                }

                if (query.priority) {
                    pageQueryObj.priority = query.priority;
                }

                if (query.startDate) {
                    pageQueryObj.startDate = query.startDate;
                }

                if (query.endDate) {
                    pageQueryObj.endDate = query.endDate;
                }

                const buildResult = assembleQueryList(pageQueryObj);

                this.tasks = await this.$store.dispatch("project/fetchTasks", { params: buildResult, refresh: params?.refresh });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.pageLoadingErr = true;
                this.pageLoadingErrMsg = errMsg;
            } finally {
                this.pageLoading = false;
            }
        },  

        async handleDeleteTask(task) {
            try {
                if (!task?._id) return;

                this.updateTaskLoading = true;
                this.taskId = task._id;

                await this.$store.dispatch("project/deleteTask", this.taskId);
                
                const updatedTasks = this.tasks.filter(c => c._id !== task._id);

                this.tasks = updatedTasks;
                this.tasksPageData.total = this.tasksPageData.total - 1;
                this.tasksPageData.totalPages = Math.ceil(this.tasks.length / 50);
            } catch (error) {
                const errMsg = error?.response?.message;
                this.$toast.error(errMsg);
            } finally {
                this.taskId = "";
                this.updateTaskLoading = false;
            }
        },

        startTaskEdit(task) {
            this.currentTaskId = task?._id;
            this.showEditModal = true;
        },

        closeEditModal() {
            this.showEditModal = false;
        },

        async handleAddTask() {
            try {
                this.addTaskLoading = true;

                const payload = { 
                    name: this.form.name, 
                    description: this.form.description || null,
                    deadline: null,
                    projectId: this.$route.params.id,
                    priority: null,
                    isCompleted: false,
                    tags: [],
                };

                const res = await this.$store.dispatch("project/addTask", payload);
                this.tasks = [ ...this.tasks, { ...res, project: { ...this.currentProject } } ];
                
                this.tasksPageData.total = this.tasksPageData.total + 1;
                this.tasksPageData.totalPages = Math.ceil(this.tasks.length / 50);

                this.form.name = "";
                this.form.description = "";
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.addTaskLoading = false;
            }
        },

        async markTaskAsCompleted(e, item) {
            try {
                this.currentTaskId = item._id;
                this.updateTaskLoading = true;
                
                const val = e?.target?.checked;
 
                const payload = { 
                    _id: item._id,
                    isCompleted: val,
                    projectId: this.$route.params?.id,
                    name: item.name,
                };

                await this.$store.dispatch("project/editTask", payload);

                const updatedTasks = this.tasks.filter(c => c._id !== item._id);            
                this.tasks = updatedTasks;
                this.$toast.success("Task completed");
                this.currentTaskId = "";
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.updateTaskLoading = false;
            }
        },

        async duplicateTask(task, index) {
            try {
                if (!task?._id) return;

                this.currentTaskId = task._id;

                const payload = { 
                    name: task.name, 
                    description: task.description,
                    deadline: task.deadline,
                    projectId: task.project._id,
                    priority: task.priority,
                    isCompleted: task.isCompleted,
                };

                const res = await this.$store.dispatch("project/duplicateTask", payload);
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.taskId = "";
            }
        },

        async onEditComplete(task) {  
            const updatedTasks = this.tasks.map(c => {
                if (c?._id === task?._id) {
                    return {
                        ...c,
                        ...task,
                    };
                } else {
                    return c;
                }
            });
            
            this.tasks = updatedTasks;
            this.closeEditModal();
        },
    },
};
</script>