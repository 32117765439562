import api from "@/api/url";

export default {
    namespaced: true,

    state: () => {
        return {
            isAuthenticated: false,
            profile: {},
            logOutLoading: false,
            lastChecked: null,
        };
    },

    getters: {
        isAuthenticated: state => state.isAuthenticated,
        profile: state => state.profile,
        logOutLoading: state => state.logOutLoading,
    },

    mutations: {
        set(state, [key, value]) {
            state[key] = value;
        },
    },
    
    actions: {
        setAuthStatus({ commit }, bool) {
            commit("set", ["isAuthenticated", bool]);
        },

        setLogoutLoading({ commit }, bool) {            
            commit("set", ["logOutLoading", bool]);
        },

        setLastChecked({ commit }, val) {
            commit("set", ["lastChecked", val]);
            localStorage.setItem("z:cached_time", val);
        },

        login(_, { email, password }) {
            return api.auth.login({ email, password })
                .then((resp) => {
                    const data = resp.data.data;
                    return data;
                });
        },

        signup(_, payload) {
            return api.auth.signup(payload)
                .then((resp) => {
                    return resp.data.data;;
                });
        },

        externalLogin(_, payload) {
            return api.auth.externalLogin(payload)
                .then((resp) => {
                    return resp.data;
                });
        },

        signOut({ commit }) {
            return api.auth.logout()
                .then((resp) => {
                    commit("set", ["isAuthenticated", false]);
                    commit("set", ["profile", {}]);
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.replace(`${window.location.origin}/auth/login`);
                }); 
        },

        fetchProfile({ commit, state, dispatch }, { refresh }) {
            if (state.profile?.email && !refresh) {
                dispatch("setAuthStatus", true);
                return state.profile;
            }

            return api.user.get()
                .then((res) => {
                    const data = res.data.data;

                    commit("set", ["profile", data]);
                    dispatch("setAuthStatus", true);
                });
        },

        updateProfile({ commit, state }, payload) {
            return api.user.update(payload)
                .then((res) => {
                    const data = res.data.data;

                    commit("set", ["profile", { 
                        ...state.profile,
                        ...data, 
                    }]);
                });
        },

        deleteProfile(_) {
            return api.user.delete()
                .then((res) => {});
        },

        resetPassword(_, payload) {
            return api.auth.resetPassword(payload)
                .then((res) => {});
        },

        joinWaitlist(_, { email }) {
            return api.waitlist.joinWaitlist({ email })
                .then((res) => {
                    const data = res.data;
                    return data;
                });
        },

        async checkAuth({ dispatch, state }) {
            try {
                const now = Date.now();
                const cachedTime = localStorage.getItem("z:cached_time");
                const parsedTime = Number(cachedTime) || 0;                    
    
                if (now < parsedTime) {
                    return false; // Use cached value
                }
    
                if (state.isAuthenticated) {
                    return true; // Use store value
                }
                
                await api.auth.verifySession();
                
                dispatch("setAuthStatus", true);
                dispatch("setLastChecked", now);

                return true;
            } catch (error) {
                dispatch("setAuthStatus", false);
                dispatch("setLastChecked", Date.now());
                
                return false;
            }
        },

        connectCalendar(_, params) {
            console.log({ connectCalendar: params });
            return api.auth.connectCalendar(params)
                .then((res) => res.data.data);
        },

        connectResponseCb(_, params) {
            return api.auth.connectResponseCb(params)
                .then((res) => res);
        },

        updateCalendarProvider({ state, commit }, { payload, action }) {
            return api.user.updateCalendarProvider(action, payload)
                .then((res) => {
                    const data = res.data.data;

                    commit("set", ["profile", {
                        ...state.profile,
                        ...data,
                    }]);
                });
        },

        initiatePasswordRequest(_, payload) {
            return api.auth.initiatePasswordReset(payload)
                .then((res) => {
                    return res.data.data;
                });
        },

        verifyResetToken(_, payload) {
            return api.auth.verifyToken(payload)
                .then((res) => {
                    return res.data.data;
                });
        },
    },
};