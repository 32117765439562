<template>
    <div class="landing__nav__icon pointer" :class="{ 'transform': isTransform }" @click="$emit('toggle')">
        <div class="bar one" />
        <div class="bar two" />
        <div class="bar three" />
    </div>
</template>

<script>
export default {
    name: "MobileNavIcon",

    props: {
        isTransform: {
            type: Boolean,
            default: false,
        },
    },

    emits: ["toggle"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .home--content__wrap {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;
        padding-left: 0;
    }
</style>