<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <div class="content__page h--100 relative min-h-inherit">
        <LinkedTabs :list="revenueTabList" class="h--100" />
    </div>
</template>

<script>
import { LinkedTabs } from "@/components/ui/index";
import { revenueTabList } from "@/utils/dummy";

export default {
    name: "Revenue",

    components: {
        LinkedTabs,
    },

    data() {
        return {
            revenueTabList,
        };
    },

    computed: {
        currentYear() {
            return this.$store.getters["revenue/currentYear"];
        },
    },

    methods: {
        getPathTitle() {
            const splitPaths = this.$route.path.split("/");
            return splitPaths[splitPaths.length - 1] || "";
        },
    },
};
</script>