<template>
    <div>
        <main-modal 
            :show-modal="showModal" 
            size="sm" title="Upgrade account" @close="$emit('cancel')"
        >
            <template #body>
                <div class="modal__wrapper--onboarding">
                    <div class="modal--section">
                        <div>
                            <span class="text--sm">You have exceeded the allowed number of {{ type }} for your account. 
                                To create more {{ type }}, <strong>upgrade</strong> your account.
                            </span>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <BaseButton 
                        label="Cancel" variant="outline" size="sm"
                        @submit="$emit('cancel', false)"
                    />
                    <BaseButton 
                        label="Upgrade" size="sm" 
                        class="ml--5" @submit="upgradeAccount"
                    />
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import BaseButton from "../buttons/BaseButton.vue";
import MainModal from "./MainModal.vue";

export default {
    name: "UpgradeAccount",

    components: { MainModal, BaseButton },

    props: {
        type: {
            type: String,
            default: "",
        },
        showModal: {
            type: Boolean,
            default: false,
        },
    },

    emits: ["cancel"],

    methods: {
        upgradeAccount() {
            this.$emit("cancel");
            this.$router.push("/dashboard/settings/billings-and-plans");
        },
    },
};
</script>