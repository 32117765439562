<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <div class="h--100 relative min-h-inherit">
        <div :class="{ 'disabled': deleteLoading, 'min-h-inherit': !clients.length }">
            <template v-if="fetchClients.loading">
                <PageSpinner />
            </template>

            <template v-else-if="fetchClients.err">
                <EmptyPage state="error" :handle-submit="handleFetchClients" />
            </template>

            <template v-else>
                <div class="flex align-items-center justify-content-end width-100">
                    <div v-if="showActionBtns" class="filter__actions--list" :class="{ 'disabled': checkUpgradeLoading && checkUpgradeFields.clients }">
                        <div v-if="selectedClients.length > 0" class="mr--5">
                            <BaseButton 
                                variant="warning" size="sm"
                                @submit="toggleDelete"
                            >
                                Delete {{ selectedClients.length }} items
                            </BaseButton>
                        </div>
                        <ImportButton v-if="showImportButton" class="mr--5" label="Import" width="16px" icon-name="import" @select="setImportType" />
                        <MainFilter page-type="clients" class="mr--5" />
                        <SortFilter :value="displayType" size="sm" class="mr--5" @setType="setDisplayType" />
                        <BaseButton 
                            label="+ Add client" size="sm"
                            :disabled="checkUpgradeLoading && checkUpgradeFields.clients"
                            class="mr--5" @submit="openCreateModal"
                        />
                        <ImportButton label="Download" icon-name="download" :disabled="downloadLoading" @select="setDownloadType" />
                    </div>
                </div>

                <div class="w--100" :class="{ 'min-h-inherit justify-content-center flex align-items-center': !clients.length }">
                    <template v-if="!clients.length">
                        <EmptyPage v-if="queryKeysLength" state="no_result" />
                        
                        <EmptyPage v-else state="empty" type="client" icon-name="client" width="60px" height="60px">
                            <template #button>
                                <BaseButton 
                                    label="+ New client" size="sm"
                                    :disabled="checkUpgradeLoading && checkUpgradeFields.clients"
                                    @submit="openCreateModal"
                                />
                            </template>
                        </EmptyPage>
                    </template>
                    
                    <template v-else>
                        <div class="flex mt--40">
                            <CustomTable
                                :columns="clientHeaders"
                                :data="clients" :selected-for-deletion="selectedClients"
                                router-name="client-details-view"
                                row-type="link" table-type="striped"
                                @onRowClick="openClientDetailsPage"
                                @onRowSelected="selectClients"
                            >
                                <template #rows="data">
                                    <td class="text--capitalize pl--0">
                                        {{ data.name || "-" }}
                                    </td>
                                    <td class="pl--0">
                                        {{ data.email || "-" }}
                                    </td>
                                    <td class="text--capitalize pl--0">
                                        {{ data.industry || "-" }}
                                    </td>
                                    <td class="pl--0">
                                        <Status type="client" :value="data.status" />
                                    </td>
                                    <td class="pl--0">
                                        {{ $format_date(data.createdAt) || "-" }}
                                    </td>
                                </template>
                            </CustomTable>
                        </div>

                        <div class="table__striped__footer mt--5">
                            <p class="table__striped__footer__results">
                                {{ clients.length }} {{ clients.length === 1 ? 'result': 'results' }}
                            </p>
                            <pagination v-if="showPagination" :pagination-data="pageData" />
                        </div>
                    </template>
                </div>
            </template>            
        </div>

        <CreateClientModal 
            :show-modal="showAddModal" @cancel="closeCreateModal" @complete="onCreateComplete"
        />

        <ConfirmDeletionTwo
            type="client" custom-msg
            :show-modal="showDeleteModal" :items="selectedClients"
            :loading="deleteLoading" 
            @cancel="showDeleteModal = false" 
            @delete="deleteMultipleClients"
        >
            <template #custom-msg>
                <p class="text--sm">
                    The selected item(s) will be removed from all related projects, meetings, and invoices. <strong>This action cannot be undone.</strong>
                </p>
            </template>
        </ConfirmDeletionTwo>

        <FileUploadModal 
            :show="showUploadModal" 
            :mime-types="importType === 'csv' ? 'text/csv' : 'text/json'"
            :file-content="uploadContent" upload-type="clients"
            :is-error="uploadErr" :headers="clientUploadHeaders"
            :progress-value="uploadProgress" @cancel="closeFileModal" @update:files="handleFileUpload" 
        />

        <UpgradeAccountModal 
            :show-modal="showUpgradeModal" type="clients" @cancel="closeUpgradeModal"
        />
    </div>
</template>

<script>
import {
    MainFilter,
    SortFilter,
    Pagination,
    EmptyPage,
    CreateClientModal,
    ConfirmDeletionTwo,
    PageSpinner,
    BaseButton,
    CustomTable,
    ImportButton,
    Status,
    IconSvg,
    FileUploadModal, UpgradeAccountModal,
} from "@/components/ui/index";
import { assembleQueryList } from "@/utils/helpers";
import { clientHeaders, clientUploadHeaders } from "@/utils/dummy";
import Papa from "papaparse";

export default {
    name: "ClientsPage",

    components: {
        ImportButton,
        CustomTable,
        CreateClientModal,
        ConfirmDeletionTwo,
        EmptyPage,
        Pagination,
        MainFilter,
        SortFilter,
        PageSpinner,
        BaseButton,
        Status,
        IconSvg, UpgradeAccountModal,
        FileUploadModal,
    },

    data() {
        return {
            clientUploadHeaders,
            clientHeaders,
            showAddModal: false,
            isSearched: false,
            displayType: "",
            selectedClients: [],
            deleteLoading: false,
            showDeleteModal: false,
            fetchClients: {
                loading: false,
                err: false,
                msg: "",
            },
            importType: "",
            downloadType: "",
            showUploadModal: false,
            uploadProgress: 0,
            uploadContent: [],
            uploadErr: false,
            downloadLoading: false,
        };
    },

    computed: {
        queryKeysLength() {
            return Object.keys(this.$route.query).length > 0;
        },

        pageData() {
            return this.$store.getters["clients/pageData"];
        },

        clients() {
            return this.$store.getters["clients/clients"];
        },

        userProfile() {
            return this.$store.getters["auth/profile"];
        },

        showActionBtns() {
            if (this.pageData.total) {
                return true;
            } else if (!this.pageData.total && this.queryKeysLength) {
                return true;
            } else {
                return false;
            }
        },

        showPagination() {
            if (this.pageData?.total > 50 && this.pageData?.totalPages > 1) {
                return true;
            } else {
                return false;
            }
        },

        refreshList() {
            return this.$store.getters["project/refreshList"];
        },

        showUpgradeModal() {
            return this.$store.getters["app/showUpgradeModal"];
        },

        checkUpgradeLoading() {
            return this.$store.getters["app/checkUpgradeLoading"];
        },

        checkUpgradeFields() {
            return this.$store.getters["app/checkUpgradeFields"];
        },

        showImportButton() {
            if (this.userProfile?.accountPlan === "free" && this.clients.length < 2) {
                return true;
            }

            return false;
        },
    },

    watch: {
        "$route.query": {
            async handler(newVal) {                
                await this.handleFetchClients({ refresh: true, ...newVal });
            },
            immediate: true,
            deep: true,
        },

        displayType(newType, oldType) {
            if (newType !== oldType) {
                this.sortClients();
            }
        },
    },
    
    methods: {
        async openCreateModal() {
            try {
                await this.$store.dispatch("app/checkUpgradeStatus", "clients");
                this.showAddModal = true;
            } catch (error) {
                // console.log({ openCreateModal: error });
            }
        },

        openClientDetailsPage(row) {
            if (!row?._id) return;

            this.$router.push(`/dashboard/clients/${row._id}`);
        },

        closeFileModal() {
            this.showUploadModal = false;
        },

        closeUpgradeModal() {
            this.$store.dispatch("app/closeUpgradeModal", "clients");
        },

        async setImportType(val) {
            try {                
                await this.$store.dispatch("app/checkUpgradeStatus", "clients");
                
                this.showUploadModal = true;
                this.importType = val;
            } catch (error) {
                // console.log({ openCreateModal: error });
            }
        },

        setDownloadType(val) {
            this.downloadClients(val);
        },

        async downloadClients(downloadType) {
            try {
                this.downloadLoading = true;
                const headerType = downloadType === "csv" ? "text/csv" : "application/json";

                const resp = await this.$store.dispatch("clients/fetchClients", { 
                    params: { download: true, type: "all", downloadType }, 
                    refresh: true, 
                    config: {
                        responseType: "blob", // Important for handling binary data,
                        headers: {
                            "Accept": headerType,
                        },
                    },
                });
                
                // Set file name and MIME type
                const fileName = downloadType === "csv" ? "clients.csv" : "clients.json";
                const url = window.URL.createObjectURL(new Blob([resp], { type: headerType }));
                const link = document.createElement("a");
    
                link.href = url;
                link.setAttribute("download", fileName); // or the filename you want
                    
                document.body.appendChild(link);
                link.click();
    
                URL.revokeObjectURL(url);
                document.body.removeChild(link);
                
                this.$toast.success("File downloaded successfully");
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.downloadLoading = false;
            }
        },

        closeCreateModal() {
            if (this.$route.query?.is_client) {
                this.$router.replace("/dashboard/clients");
            }
            this.showAddModal = false;
        },

        async onCreateComplete(resp) {
            // don't await
            this.$store.dispatch("app/fetchActivities", { refresh: true });

            this.showAddModal = false;
            this.$router.push({ name:"client-details-view", params:{ id: resp?._id } });
        },

        setDisplayType(val) {
            this.displayType = val;
        },

        toggleDelete() {
            this.showDeleteModal = !this.showDeleteModal;
        },

        async deleteMultipleClients() {
            try {
                this.deleteLoading = true;
                await this.$store.dispatch("clients/deleteClients", this.selectedClients);

                this.$toast.success("Deleted");
                this.selectClients([]);

                // don't await
                this.$store.dispatch("app/fetchActivities", { refresh: true });

                this.showDeleteModal = false;
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.deleteLoading = false;
            }
        },

        sortClients() {
            this.$store.dispatch("clients/sortClients", this.displayType);
        },

        selectClients(val) {
            this.selectedClients = val;
        },

        async handleFetchClients(params) {
            try {
                this.fetchClients.loading = true;
                this.fetchClients.err = false;
                this.fetchClients.msg = "";

                const pageQueryObj = {
                    page: params?.page || 1,
                    ...params,
                };

                const buildResult = assembleQueryList(pageQueryObj);
                await this.$store.dispatch("clients/fetchClients", { params: buildResult, refresh: params?.refresh });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                
                this.fetchClients.err = true;
                this.fetchClients.msg = errMsg;
            } finally {
                this.fetchClients.loading = false;
            }
        },

        handleFileUpload(files) {
            const file = event?.target?.files[0];

            if (file) {
                this.parseCSVFile(file);
            }
        },

        parseCSVFile(file) {
            Papa.parse(file, {
                header: true,
                complete: this.processCSVData,
                error: this.handleParseError,
            });
        },

        onUploadProgress(event) {
            this.uploadProgress = Math.round((event.loaded * 100) / event.total);
        },

        async processCSVData(results) {
            try {
                this.uploadErr = false;

                const clients = results.data;
                this.uploadContent = clients;

                const formData = new FormData();
                formData.append("clients", JSON.stringify(clients));
                
                await this.$store.dispatch("clients/uploadMutlipleClients", { formData, progressCB: this.onUploadProgress });
                
                this.$toast.success("File uploaded successfully");

                this.importType = "";
                this.uploadProgress = 0;
                this.showUploadModal = false;

                // don't await
                this.$store.dispatch("app/fetchActivities", { refresh: true });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                
                this.$toast.error(errMsg);
                this.uploadProgress = 0;
                this.uploadErr = true;
            }
        },

        handleParseError(error) {
            console.error("Error parsing CSV file", error);
        },
    },
};
</script>