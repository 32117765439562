<template>
    <div 
        v-show="isActive" 
        class="tab-panel h-auto" 
        :class="{ 'block': isActive }" 
        role="tabPanel"
        v-bind="$attrs"
    >
        <div class="tab-content h-auto">
            <slot />
        </div>
    </div>
</template>
<script>

export default {
    name: "NonLinkedTab",
    props: {
        title: {
            type: String,
            default: "",
        },
        isActive: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.tab-panel {
  .tab-content {
    padding: 0.5rem;
    padding-left: 0;
    padding-right: 0;
  }
}
</style>