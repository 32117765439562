<template>
    <div>
        <main-modal 
            :show-modal="showModal" 
            size="sm" :title="title" @close="closeModal"
        >
            <template #body>
                <form @submit.prevent>
                    <FormInput
                        v-if="hasField('fullName')" 
                        id="fullName"
                        v-model="form.fullName"
                        size="sm"
                        autocomplete="" label="Full name"
                    />

                    <FormInput
                        v-if="hasField('phoneNumber')" 
                        id="phoneNumber"
                        v-model="form.phoneNumber"
                        size="sm"
                        autocomplete="" label="Phone Number"
                    />

                    <FormInput
                        v-if="hasField('address')" 
                        id="address"
                        v-model="form.address"
                        size="sm" autocomplete="" label="Address"
                    />

                    <FormInput
                        v-if="hasField('country')" 
                        id="country"
                        v-model="form.country"
                        size="sm" autocomplete="" label="Country"
                    />

                    <FormInput
                        v-if="hasField('zipCode')" 
                        id="zipCode"
                        v-model="form.zipCode"
                        size="sm" 
                        autocomplete="" 
                        label="Zip code"
                        no-margin-bottom
                    />

                    <FormInput 
                        v-if="hasField('email')"
                        id="newEmail" 
                        v-model="form.email" 
                        type="email"
                        required 
                        size="sm" 
                        no-margin-bottom
                        label="New email address" 
                        :is-error="newEmail.isError" 
                        :err-msg="newEmail.errMsg"
                    />
                </form>
            </template>

            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <BaseButton 
                        label="Cancel" variant="outline" size="sm"
                        class="mr--5" @submit="closeModal"
                    />
                    <BaseButton label="Save" size="sm" :loading="loading" @submit="handleUpdateProfile" />
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import countryList from "@/assets/js/countrycodes.json";
import { updateProfileFields } from "@/components/auth/auth";
import MainModal from "./MainModal.vue";
import FormLabel from "../forms/FormLabel.vue";
import BaseButton from "../buttons/BaseButton.vue";
import FormInput from "../input/FormInput.vue";

export default {
    name: "UpdateProfile",

    components: {
        MainModal,
        FormLabel,
        BaseButton,
        FormInput,
    },

    props: {
        currentSelection: {
            type: String,
            default: "",
        },
        showModal: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            countryList,
            loading: false,  
            accountModalFields: updateProfileFields,
            form: {
                fullName: "",
                phoneNumber: "",
                address: "",
                country: "",
                zipCode: "",  
                email: "",
            },
            newEmail: {
                errMsg: "",
                isErr: false,
            }, 
            formFields: {
                fullName: {
                    title: "Update Full name",
                    fields: {
                        fullName: true,
                    },
                },
                contactDetails: {
                    title: "Update contact details",
                    fields: {
                        phoneNumber: true,
                        address: true,
                        country: true,
                        zipCode: true,  
                    },
                },
            },
        };
    },

    computed: {
        profile() {
            return this.$store.getters["auth/profile"];
        },

        title() {
            return this.accountModalFields[this.currentSelection]?.title || "";
        },
    },

    watch: {
        currentSelection(newVal) {
            if (newVal) {
                if (this.accountModalFields[newVal] && this.accountModalFields[newVal]?.fields) {
                    this.populateFormBasedOnSelection(newVal);
                }
            }
        },
    },

    mounted() {
        this.prefillForm(this.profile);
    },

    methods: {
        hasField(field) {
            return !this.accountModalFields[this.currentSelection] ? false : this.accountModalFields[this.currentSelection].fields[field]; 
        },

        prefillForm(data) {
            this.form = {
                ...this.form,
                fullName: data.fullName || "",
                phoneNumber: data.phoneNumber || "",
                address: data.address || "",
                country: data.country || "",
                zipCode: data.zipCode || "",  
                email: data.email || "",
            };
        },

        populateFormBasedOnSelection(val) {
            const selectedItem = this.formFields[val]?.fields;

            for (const key in selectedItem) {
                if (this.profile.hasOwnProperty(key)) {
                    this.form[key] = this.profile[key];
                }
            }
        },

        resetForm() {
            this.form = {
                fullName: "",
                phoneNumber: "",
                address: "",
                country: "",
                zipCode: "",  
                email: "",
            };
        },

        async handleUpdateProfile() {
            try {
                this.loading = true;
                const payload = {};

                const selectedItem = this.accountModalFields[this.currentSelection]?.fields;

                for (const key in selectedItem) {
                    if (this.form.hasOwnProperty(key)) {
                        payload[key] = this.form[key];
                    }
                }
                
                await this.$store.dispatch("auth/updateProfile", payload);
                this.$toast.success("Profile updated");
                this.closeModal();
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }
        },

        closeModal() {
            this.resetForm();
            this.$emit("cancel");
        },

        validateEmailInput(e) {
            const value = e?.target?.value;

            if (!value.includes("@")) return;

            const res = validateEmail(value);
             
            if (!res) {
                this.isEmailInvalid = true;
            } else {
                this.isEmailInvalid = false;
            }
        },

        // validateFormFields(field) {
        //     const selectedFields = this.accountModalFields[this.currentSelection]?.fields;

        //     for (const key in selectedFields) {
        //         if (this.form.hasOwnProperty(key) && !this.form[key]) {
        //             this.form[key] = this.profile[key];
        //         }
        //     }
        // }
    },
};
</script>