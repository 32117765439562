<template>
    <!-- <router-view v-slot="{ route }"> -->
    <transition v-if="$route.meta.modal" name="modal">
        <div class="modal fade show">
            <div class="modal__dialog modal__dialog--sm">
                <div role="document" class="relative">
                    <div class="modal__header modal__dialog--header with__title">
                        <h2 class="modal-title">
                            Task details
                        </h2>
                        <button type="button" class="close" @click="onCancel">
                            <IconSvg name="close" width="24px" fill="rgba(160, 158, 167, 1)" />
                        </button>
                    </div>
                        
                    <div class="modal__body">
                        <div class="modal__wrapper--onboarding mt--5">
                            <template v-if="pageLoading">
                                <PageSpinner />
                            </template>

                            <template v-else>
                                <form @submit.prevent="">
                                    <FormInput 
                                        id="title" v-model="form.name"
                                        size="sm" info-type="required"
                                        label="Name" required
                                    />

                                    <FormTextArea 
                                        id="taskDescription" v-model="form.description"
                                        size="sm" html-for="taskDescription"
                                        label="Description"
                                    />
                                        
                                    <div class=" form__row mb--15">
                                        <div class="mr--15 mb--0 flex-1">
                                            <FormLabel title="Priority" html-for="priority" />
                                            <select v-model="form.priority" class="form-select text--capitalize form-select-sm text--sm">
                                                <option v-for="priority in priorities" :key="priority" :value="priority">
                                                    {{ priority }}
                                                </option>
                                            </select>
                                        </div>
                
                                        <div class="m-w-100 mb--0 flex-1">
                                            <FormLabel title="Due date" />
                                            <v-date-picker v-model="form.deadline" class="text--sm">
                                                <template #default="{ inputValue, togglePopover }">
                                                    <input
                                                        class="px-2 py-1 border rounded due_date form-control form-control-sm text--sm"
                                                        :value="inputValue"
                                                        @click="togglePopover"
                                                    >
                                                </template>
                                            </v-date-picker>
                                        </div>
                                    </div>
            
                                    <div v-if="taskType === 'task'" class=" form__row mb--15 mt--20">
                                        <div class="flex-1">
                                            <FormLabel title="Parent project" html-for="priority" />
                                            <CustomDropdown 
                                                id="taskDetailsDropdown" v-model="form.project"
                                                :options="projects" list-type="project" :loading="searchProjectsLoading"
                                                index-label="title" show-search-field
                                                @select="setParentProject" @search="searchProjects"
                                            />
                                        </div>
                                    </div>
            
                                    <div class=" form__row mb--15 mt--20">
                                        <div class="flex-1">
                                            <FormLabel title="Labels" html-for="tags" />
                                            <vue-multi-select 
                                                v-model="form.tags" :options="tags"
                                                tag-placeholder="Add this as new tag" placeholder="Search or add a tag" 
                                                multiple label="name" track-by="name"
                                                :show-labels="false" taggable :loading="btnLoading"
                                                :close-on-select="false" @tag="addTag" @search-change="searchTags"
                                            >
                                                <template #option="props">
                                                    <span class="tags--item--text">{{ props.option.name }}</span>
                                                </template>
                                            </vue-multi-select>
                                        </div>
                                    </div>
                                </form>
                            </template>
                        </div>
                    </div>
                        
                    <div class="modal__footer">
                        <div class="w--100 flex justify-content-end align-items-center">
                            <Base-Button
                                class="mr--5" label="Cancel"
                                size="sm" variant="outline" :disabled="btnLoading"
                                @submit="onCancel"
                            />
                            <Base-Button 
                                label="Save" size="sm" :loading="btnLoading" :disabled="btnLoading || isBtnDisabled" 
                                @submit="handleSubmit"                                       
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    </transition>
    <!-- </router-view> -->
</template>

<script>
import { projectFilterStatuses, priorities } from "@/utils/enums";
import FormLabel from "../forms/FormLabel.vue";
import FormInput from "../input/FormInput.vue";
import FormTextArea from "../input/FormTextArea.vue";
import BaseButton from "../buttons/BaseButton.vue";
import IconSvg from "../icons/Icon-Svg.vue";
import CustomDropdown from "../dropdowns/CustomDropdown.vue";
import { assembleQueryList, debounce } from "@/utils/helpers";
import PageSpinner from "../loaders/PageSpinner.vue";

export default {
    name: "TaskDetailsModal",

    components: {
        FormLabel,
        BaseButton, PageSpinner,
        IconSvg,
        CustomDropdown,
        FormInput,
        FormTextArea,
    },

    props: { 
        route: { type: Object, default: () => {} },
        taskType: { type: String, default: "" },
    },
    
    data() {
        return {
            statuses: projectFilterStatuses,
            form: {
                _id: "",
                name: "",
                description: "", 
                deadline: "", 
                priority: "low", 
                project: {
                    _id: "",
                    title: "",
                },
                tags: [],
            },
            formAction: "add",
            priorities,
            searchProjectsLoading: false,
            searchTagsLoading: false,
            pageLoading: true,
            pageLoadingErr: false,
            btnLoading: false,
            projects: [],
            tags: [],
        };
    },

    computed: {
        isBtnDisabled() {
            if (!this.form.project?._id || !this.form?._id || !this.form?.name) {
                return true;
            } else {
                return false;
            }
        },
    },
    
    async mounted() {
        const storedProjects = this.$store.getters["project/projects"];
        const storedTags = this.$store.getters["tag/tags"] || [];

        await this.handleFetchTask(this.$route.params.id);
        
        // if (storedProjects.length) {
        //     this.projects = storedProjects;
        // } else {
        //     await this.searchProjects();
        // }

        // if (storedTags.length) {
        //     this.tags = storedTags;
        // } else {
        //     await this.fetchTags();
        // }
        
        this.$refs?.titleRef?.focus();
    },

    methods: {
        async handleFetchTask(id) {
            try {
                if (!id) return;
                
                this.pageLoadingErr = false;

                const res = await this.$store.dispatch("task/fetchTask", id);

                this.form = {
                    ...this.form,
                    ...res,
                };
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.pageLoadingErr = true;
            } finally {
                this.pageLoading = false;
            }
        },  

        onCancel() {
            this.formAction = "add";
            this.$router.push("/dashboard/tasks");
        },

        addTag(newTag) {
            this.form.tags.push({
                name: newTag,
            });
        },

        async handleSubmit() {   
            try {
                if (!this.form.project._id || !this.form._id || !this.form.name) return;

                this.btnLoading = true;

                const payload = {
                    _id: this.form._id,
                    name: this.form.name,
                    description: this.form.description || null,
                    priority: this.form.priority || null,
                    deadline: this.form.deadline || null,
                    projectId: this.form.project._id,
                    tags: (this.form.tags || []).map(x => ({ name: x.name, _id: x._id })),
                };

                const fullData = {
                    ...payload,
                    project: this.form.project,
                };

                await this.$store.dispatch("task/editTask", { payload, fullData });
                
                this.$emit("complete", fullData);
                this.$toast.success("Task updated");
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.btnLoading = false;
            };
        },

        prefillForm(val) {
            this.form = {
                ...this.form,
                ...val,
            };
        },

        setParentProject(project) {
            this.form.project._id = project._id;
            this.form.project.title = project.title;
        },

        async searchProjects(query) {
            try {
                this.searchProjectsLoading = true;
                
                const pageQueryObj = {
                    page: 1,
                    type: "active",
                    title: query,
                };

                const buildResult = assembleQueryList(pageQueryObj);
                const res = await this.$store.dispatch("project/fetchProjects", { params: buildResult, refresh: true , external: true });
                this.projects = res.data;
            } catch (error) {
                // console.log({ error });
            } finally {
                this.searchProjectsLoading = false;
            }
        }, 

        async fetchTags(query) {
            try {
                this.searchTagsLoading = true;
                
                const pageQueryObj = {
                    name: query,
                };

                const buildResult = assembleQueryList(pageQueryObj);
                const res = await this.$store.dispatch("tag/fetchTags", { params: buildResult, refresh: true , external: true });
                this.tags = res;
            } catch (error) {
                // console.log({ error });
            } finally {
                this.searchTagsLoading = false;
            }
        }, 

        searchTags(newVal) {            
            debounce(() => {
                this.fetchTags(newVal);
            }, 500, "search-list");
        },
    },
};
</script>