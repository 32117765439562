<template>
    <div class="clients__page clients__page--view">
        <template v-if="pageLoading">
            <PageSpinner />
        </template>

        <template v-else-if="loadingErr">
            <EmptyPage state="error" :handle-submit="handleFetchNotifications" />
        </template>

        <template v-else>
            <div>
                <div>
                    <p class="text--sm">
                        Manage your notification settings to tailor updates according to your preferences. Select features you would like to be notified about.
                        <!-- <CustomLink to="/resources/goals" class="text--primary">
                            Learn more
                        </CustomLink>. -->
                    </p>
                </div>
    
                <div>
                    <ul v-for="item in groupByKeys" :key="item" class="earning__wrap">
                        <div class="settings__auth__header mt--30 mb--10">
                            <div class="flex align-center justify-content-between w-100">
                                <CustomText size="sm" color="gray-800" transform="capitalize" weight="semi--bold">
                                    {{ item }} notifications
                                </CustomText>
                            </div>
                        </div>
                        <li v-for="notification in groupedListByType[item]" :key="notification._id" class="form-check">
                            <div>
                                <input
                                    id="flexCheckDefault" v-model="notification.isActive" class="form-check-input" 
                                    type="checkbox" :value="notification.isActive"
                                >
                                <label class="form-check-label text--sm" for="flexCheckDefault">
                                    {{ notification.name }}
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
    
                <div class="flex justify-content-end w-100 mt--20 mb--45">
                    <BaseButton 
                        size="sm" :loading="updateLoading" 
                        label="Save changes" @submit="handleUpdate" 
                    />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {
    BaseButton,
    CustomLink,
    EmptyPage,
    PageSpinner,
    CustomText,
} from "@/components/ui/index";
import { groupList } from "@/utils/helpers";

export default {
    name: "Notifications",

    components: {
        BaseButton,
        CustomLink,
        EmptyPage,
        PageSpinner,
        CustomText,
    },

    data() {
        return {
            pageLoading: false,
            loadingErr: false,
            updateLoading: false,
            tempList: [],
        };
    },

    computed: {
        groupedListByType() {
            const list = this.groupList(this.tempList, (item) => {
                const key = item.notificationType;
                return key;
            });

            return Object.fromEntries(list);
        },

        groupByKeys() {
            return Object.keys(this.groupedListByType);
        },
    },

    async mounted() {        
        await this.handleFetchNotifications();
    },

    methods: {
        groupList,

        async handleFetchNotifications(params) {
            try {
                this.loadingErr = false;
                this.pageLoading = true;

                const res = await this.$store.dispatch("app/fetchNotifications", { refresh: params?.refresh });
                this.tempList = res;
            } catch (error) {
                this.loadingErr = true;
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.pageLoading = false;
            }
        },

        async handleUpdate() {
            try {
                this.updateLoading = true;
                const payload = this.tempList;
                
                await this.$store.dispatch("app/updateNotifications", payload);
                this.$toast.success("Notifications updated");
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.updateLoading = false;
            }
        },
    },
};
</script>