<template>
    <div class="mt--10">
        <div class="client__notes">
            <template v-if="fetchNotes.loading">
                <PageSpinner />
            </template>

            <template v-else>
                <EmptyPage
                    v-if="!notes.length" type="note" icon-name="notes" 
                    state="empty" width="50px" height="50px"
                />

                <div v-else>
                    <div v-for="item in notes" :key="item._id" class="client__notes__item" :class="{ 'disabled': currentNoteId === item._id && deleteLoading }">
                        <div class="text--sm">
                            <p>{{ item.value }}</p>
                            <p class="text--xs text--color-light-300">
                                {{ formatDateStrings(item.createdAt) }}
                            </p>
                        </div>
                        <div class="dropdown">
                            <button 
                                id="noteDropdown" 
                                class="btn--ghost btn text--xs btn-sm text--bold" 
                                type="button"
                                aria-expanded="false"
                                data-bs-toggle="dropdown" 
                                data-bs-auto-close="true" 
                            >
                                <span class="mr--5 flex align-items-center">
                                    <icon-svg name="dots-horizontal-rounded" fill="#637579" width="12px" height="12px" />
                                </span>
                            </button>

                            <ul id="noteDropdownList" class="dropdown-menu dropdown-menu--tag" aria-labelledby="noteDropdown">
                                <li class="dropdown-item flex align-items-center pointer text--xs" @click="handleCopyNoteContent(item)">
                                    <icon-svg class="mr--5" name="copy" fill="#212529" /> 
                                    <span>Copy content</span>
                                </li>
                                <li class="dropdown-item flex align-items-center pointer text--xs" @click="startNoteEdit(item)">
                                    <icon-svg class="mr--5" name="edit" fill="#212529" /> 
                                    <span>Edit note</span>
                                </li>
                                <li class="dropdown-item flex align-items-center pointer text--xs text--color-warning" @click="handleDeleteNote(item)">
                                    <icon-svg class="mr--5" name="delete" fill="#e04060" /> 
                                    <span>Delete note</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="w-100">
                    <form class="client__notes__form" @submit.prevent>
                        <div class="form__textarea__group">
                            <div class="form__textarea__wrap">
                                <CustomEditor v-model="noteValue" @blur="noteOnBlur" @update="setCurrentNoteValue" />
                            </div>

                            <div v-if="showActionBtns" class="client__notes__action__btns">
                                <base-button
                                    class-names="mr--5" label="Cancel" variant="outline" size="sm"
                                    @submit="resetForm"
                                />
                                <base-button 
                                    type="submit" size="sm" :loading="loading"
                                    :label="action === 'add' ? 'Add' : 'Save'" 
                                    @submit="handleFormSubmit"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { IconSvg, EmptyPage, PageSpinner, BaseButton, CustomEditor } from "@/components/ui/index";
import { replaceStringsAll } from "@/plugins/formatters";
import { formatDateStrings } from "@/utils/helpers";

export default {
    name: "ClientNotes",

    components: {
        IconSvg,
        EmptyPage,
        PageSpinner,
        BaseButton,
        CustomEditor,
    },

    data() {
        return {
            noteValue: "",
            currentNoteId: "",
            action: "add",
            showActionBtns: true,
            fetchNotes: {
                loading: false,
                err: false,
                msg: "",
            },
            pageData: {
                currentPage: 1,
                totalPages: 0,
                pageSize: 50,
                total: 0,
            },
            notes: [],
            loading: false,
            deleteLoading: false,
        };
    },

    async mounted() {
        await this.handleFetchClientNotes();
    },

    methods: {
        replaceStringsAll,
        formatDateStrings,

        setCurrentNoteValue() {
            this.noteValue = note.value;
        },

        startNoteEdit(note) {
            if (!note?._id || !note?.value) return;

            this.currentNoteId = note?._id;
            this.noteValue = note.value;

            this.action = "edit";
            this.showActionBtns = true;
        },

        noteOnBlur(e) {
            // console.log({ noteOnBlur: e });
        },

        resetForm() {
            this.noteValue = "";
            this.action = "";
            this.showActionBtns = false;
            this.currentNoteId = "";
        },

        async handleFetchClientNotes(params) {
            try {
                this.fetchNotes.err = false;
                this.fetchNotes.loading = true;

                const payload = {
                    page: params?.page || this.pageData.currentPage,
                    limit: 50,
                };

                const result = await this.$store.dispatch("clients/getClientNotes", { id: this.$route.params.id, params: payload });

                this.notes = result.data;
                this.pageData = result.pageDetails;
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;

                this.fetchNotes.err = true;
                this.fetchNotes.msg = errMsg;
            } finally {
                this.fetchNotes.loading = false;
            }
        },
        
        async handleAddNote() {
            try {
                const id = this.$route.params.id;

                if (!id || !this.noteValue) {
                    this.$toast.error("Missing route id or note value");                
                }

                this.loading = true;

                const regex =  /<\/?p>/g;

                const payload = {
                    clientId: this.$route.params.id,
                    value: replaceStringsAll(this.noteValue, regex),
                };

                const res = await this.$store.dispatch("clients/addNewNote", payload);

                this.notes = [ ...this.notes, res ];
                this.pageData.total = this.pageData.total + 1;
                this.pageData.totalPages = Math.ceil(this.notes.length / 50);
                this.resetForm();
            
                // console.log("this.noteValue 2", this.noteValue);
                // this.$toast.success("Note added");
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }
        },

        async handleUpdateNote() {
            try {
                const clientId = this.$route.params.id;

                if (!clientId || !this.noteValue || !this.currentNoteId) {
                    this.$toast.error("Missing note id"); 
                    return;               
                }

                if (!this.noteValue) {
                    this.$toast.error("Note value is required"); 
                    return;                     
                }

                this.loading = true;

                const payload = {
                    _id: this.currentNoteId,
                    clientId,
                    value: replaceStringsAll(this.noteValue, /<\/?p>/g),
                };

                const res = await this.$store.dispatch("clients/updateNote", payload);

                if (!res) {
                    this.$toast.error("Unable to update note");
                    return;
                }

                this.notes = this.notes.map(c => {
                    if (c?._id === res?._id) {
                        return {
                            ...c,
                            ...res,
                        };
                    } else {
                        return c;
                    }
                });

                this.$toast.success("Note updated");
                this.resetForm();
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }            
        },

        handleFormSubmit() {
            if (this.action === "add") {
                this.handleAddNote();
            } else {
                this.handleUpdateNote();
            }
        },

        async handleDeleteNote(item) {
            try {
                if (!item._id) {
                    this.$toast.error("Missing note id required"); 
                    return;               
                }

                this.currentNoteId = item._id;
                this.deleteLoading = true;

                await this.$store.dispatch("clients/deleteNote", {
                    clientId: this.$route.params.id,
                    _id: item._id,
                });

                this.notes = this.notes.filter(c => c._id !== item._id);
                this.pageData.total = this.pageData.total - 1;
                this.pageData.totalPages = Math.ceil(this.notes.length / 50);

                this.$toast.success("Note deleted");
                this.resetForm();
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.deleteLoading = false;
                this.currentNoteId = "";
            }      
        },

        handleCopyNoteContent(note) {
            if (!note._id && note.value) return;


        },
    },
};
</script>

<style scoped lang="scss">
    textarea.form-control {
        border-radius: 4px !important;
    }
</style>