<template>
    <div class="">
        <div class="">
            <div class="mt--5">
                <template v-if="pageLoading">
                    <PageSpinner />
                </template>

                <template v-else-if="isEmpty">
                    <EmptyPage
                        state="empty_custom" type="task" icon-name="task" width="60px"
                        height="60px" title="No selected task" subtitle="Click on a task to view details"
                    />
                </template>
    
                <template v-else>
                    <div class="col-12 mb--20 invoice__details__divider pb--5 flex align-items-center justify-content-between">
                        <div class="flex align-items-center">
                            <FormLabel label-type="title" html-for="taskDetails" title="Task details" class="mb--0" />
                            <span v-if="taskIdToModify === task._id" class="ml--5">
                                <PageSpinner size="sm" :is-page="false" />
                            </span>
                        </div>

                        <div v-if="!isEdit" class="flex align-items-center justify-content-end">
                            <BaseButton 
                                label="Edit" size="sm" class="mr--10"
                                variant="ghost" inner-style="primary" @submit="isEdit = true"           
                            />

                            <div class="dropdown">
                                <div id="invoiceDetailsDropDown" class="pointer" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div class="invoice__details__menu">
                                        <IconSvg name="dots-horizontal-rounded" fill="#667085" width="16px" />
                                    </div>
                                </div>

                                <ul id="noteDropdownList" class="dropdown-menu dropdown-menu--tag" aria-labelledby="noteDropdown">
                                    <li class="dropdown-item pointer flex align-items-center" @click="duplicateTask">
                                        <IconSvg name="duplicate" fill="#667085" width="16px" />
                                        <span class="ml--10 text--xs text--color-gray-500">Duplicate</span>
                                    </li>
                                    <li class="dropdown-item pointer flex align-items-center" @click="handleDeleteTask">
                                        <IconSvg name="delete" fill="#e04060" width="16px" />
                                        <span class="ml--10 text--sm text--color-warning">Delete</span> 
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <form @submit.prevent="">
                        <div class="mb--20">
                            <FormLabel info-type="required" html-for="name" title="Name" label-type="menu" />
                            
                            <template v-if="!isEdit">
                                <p class="text--color-gray-400 text--sm">
                                    {{ form.name }}
                                </p>
                            </template>
                            <template v-else>
                                <FormInput id="title" v-model="form.name" size="sm" />
                            </template>
                        </div>

                        <div class="mb--20">
                            <FormLabel html-for="description" title="Description" label-type="menu" />
                            <template v-if="!isEdit">
                                <p class="text--color-gray-400 text--sm">
                                    {{ form.description || '-' }}
                                </p>
                            </template>
                            <template v-else>
                                <FormTextArea 
                                    id="description" v-model="form.description"
                                    size="sm" html-for="taskDescription"
                                />
                            </template>
                        </div>

                        <div class="mb--20">
                            <FormLabel html-for="priority" title="Priority" label-type="menu" />
                            <template v-if="!isEdit">
                                <p class="text--color-gray-400 text--sm text--capitalize">
                                    {{ form.priority || '-' }}
                                </p>
                            </template>
                            <template v-else>
                                <select v-model="form.priority" class="form-select text--capitalize form-select-sm text--sm">
                                    <option v-for="priority in priorities" :key="priority" :value="priority">
                                        {{ priority }}
                                    </option>
                                </select>
                            </template>
                        </div>

                        <div class="mb--20">
                            <FormLabel html-for="dueDate" title="Due date" label-type="menu" />
                            <template v-if="!isEdit">
                                <p class="text--color-gray-400 text--sm">
                                    {{ $format_date(form.deadline) || '-' }}
                                </p>
                            </template>
                            <template v-else>
                                <v-date-picker v-model="form.deadline" class="text--sm">
                                    <template #default="{ inputValue, togglePopover }">
                                        <input
                                            class="px-2 py-1 border rounded due_date form-control form-control-sm text--sm"
                                            :value="inputValue"
                                            @click="togglePopover"
                                        >
                                    </template>
                                </v-date-picker>
                            </template>
                        </div>

                        <div v-if="taskType === 'task'" class="mb--20">
                            <FormLabel html-for="project" title="Parent project" label-type="menu" />
                            <template v-if="!isEdit">
                                <p class="text--color-gray-400 text--sm">
                                    {{ form.project.title || 'N/A' }}
                                </p>
                            </template>
                            <template v-else>
                                <CustomDropdown 
                                    id="taskDetailsDropdown" v-model="form.project"
                                    :options="projects" list-type="project" :loading="searchProjectsLoading"
                                    index-label="title" show-search-field
                                    @select="setParentProject" @search="searchProjects"
                                />
                            </template>
                        </div>

                        <div>
                            <FormLabel html-for="tags" title="Labels" label-type="menu" />
                            <template v-if="!isEdit">
                                <div v-if="form.tags && form.tags.length" class="project__tags">
                                    <div 
                                        v-for="tag in form.tags" :key="tag._id" 
                                        class="mr--10 tag default tag--grey text--xs text--normal"
                                    >
                                        {{ tag.name }}
                                    </div>
                                </div>
                                <template v-else>
                                    <p class="project__item__desc">
                                        No tags added.
                                    </p>
                                </template>
                            </template>
                            <template v-else>
                                <vue-multi-select 
                                    v-model="form.tags" :options="tags"
                                    tag-placeholder="Add this as new tag" placeholder="Search or add a tag" 
                                    multiple label="name" track-by="name" :disabled="!isEdit"
                                    :show-labels="false" taggable :loading="btnLoading"
                                    :close-on-select="false" @tag="addTag" @search-change="searchTags"
                                >
                                    <template #option="props">
                                        <span class="tags--item--text">{{ props.option.name }}</span>
                                    </template>
                                </vue-multi-select>
                            </template>
                        </div>

                        <div v-if="isEdit" class="mt--30 flex align-items-center justify-content-end">
                            <BaseButton 
                                variant="ghost" label="Cancel" 
                                inner-style="secondary" size="sm" 
                                @submit="onCancel"           
                            />
                            <BaseButton 
                                label="Save" 
                                size="sm" :loading="btnLoading" :disabled="isBtnDisabled || btnLoading" 
                                @submit="handleTaskUpdate"           
                            />
                        </div>
                    </form>
                </template>
            </div>
        </div>
    </div>        
</template>

<script>
import { projectFilterStatuses, priorities } from "@/utils/enums";
import {
    FormLabel, FormInput, FormTextArea, BaseButton, IconSvg,
    CustomDropdown, PageSpinner, EmptyPage,
} from "@/components/ui/index";
import { assembleQueryList, debounce } from "@/utils/helpers";

export default {
    name: "TaskDetails",

    components: {
        FormLabel,
        BaseButton, PageSpinner,
        EmptyPage,
        CustomDropdown,
        FormInput, IconSvg,
        FormTextArea,
    },

    props: { 
        currentItemId: { type: String, default: ""},
        taskType: { type: String, default: "" },
        groupSelection: { type: String, default: ""},
    },
    
    data() {
        return {
            statuses: projectFilterStatuses,
            form: {
                _id: "",
                name: "",
                description: "", 
                deadline: new Date(), 
                priority: "", 
                project: {
                    _id: "",
                    title: "",
                },
                tags: [],
            },
            task: {},
            formAction: "add",
            priorities,
            searchProjectsLoading: false,
            searchTagsLoading: false,
            pageLoading: false,
            pageLoadingErr: false,
            btnLoading: false,
            projects: [],
            tags: [],
            isEdit: false,
            isEmpty: false,
            taskIdToModify: "",
        };
    },

    computed: {
        isBtnDisabled() {
            if (!this.form?._id || !this.form?.name) {
                return true;
            } else {
                return false;
            }
        },
    },

    watch: {
        "currentItemId": {
            async handler(newVal) {
                await this.handleFetchTask(newVal);
            },
        },
    },

    async mounted() {
        const storedProjects = this.$store.getters["project/projects"];
        this.projects = storedProjects;
        
        const storedTags = this.$store.getters["tag/tags"] || [];
        
        await this.handleFetchTask(this.currentItemId);

        if (storedTags.length) {
            this.tags = storedTags;
        } else {
            await this.fetchTags();
        }
    },

    methods: {
        async handleFetchTask(id) {
            try {
                const isValidId = /^[a-fA-F0-9]{24}$/.test(id);

                if (!isValidId) {
                    this.isEmpty = true;
                    return;
                };

                this.isEmpty = false;
                this.pageLoading = true;
                this.pageLoadingErr = false;

                const res = await this.$store.dispatch("task/fetchTask", this.currentItemId);

                this.form = {
                    ...this.form,
                    ...res,
                };
                this.task = res;
                this.pageLoading = false;
            } catch (error) {
                this.pageLoading = false;
                this.pageLoadingErr = true;
            }
        },  

        async handleDeleteTask() {
            try {
                if (!this.task?._id) return;

                this.taskIdToModify = this.task._id;

                await this.$store.dispatch("task/deleteTask", { id: this.taskIdToModify, groupBy: this.groupSelection });
                this.task = {};
                this.taskIdToModify = "";
                this.form = {
                    _id: "",
                    name: "",
                    description: "", 
                    deadline: new Date(), 
                    priority: "", 
                    project: {
                        _id: "",
                        title: "",
                    },
                    tags: [],
                };
                this.isEmpty = true;

                this.$toast.success("Task deleted");
            } catch (error) {
                const errMsg = err?.response?.data?.message || error.message;
                this.$toast.error(errMsg);
            } finally {
                this.taskIdToModify = "";
            }
        },

        async duplicateTask() {
            try {
                if (!this.task?._id) return;

                this.taskIdToModify = this.task._id;

                const payload = { 
                    name: this.task.name, 
                    description: this.task.description,
                    deadline: this.task.deadline,
                    projectId: this.task.project._id,
                    priority: this.task.priority,
                    isCompleted: this.task.isCompleted,
                };

                await this.$store.dispatch("task/duplicateTask", payload);
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.taskIdToModify = "";
            }
        },

        onCancel() {
            this.form = {
                ...this.form,
                ...this.task,
            };
            this.isEdit = false;
        },

        addTag(newTag) {
            this.form.tags = [ ...this.form.tags, {
                name: newTag,
            }];
        },

        async handleTaskUpdate() {   
            try {
                this.btnLoading = true;

                const payload = {
                    _id: this.form._id,
                    name: this.form.name,
                    description: this.form.description || null,
                    priority: this.form.priority || null,
                    deadline: this.form.deadline || null,
                    projectId: this.form.project._id || null,
                    tags: (this.form.tags || []).map(x => ({ name: x.name, _id: x._id })),
                };

                const fullData = {
                    ...payload,
                    project: this.form.project,
                };

                const res = await this.$store.dispatch("task/editTask", { payload, fullData, groupBy: this.groupSelection });

                this.isEdit = false;
                this.task = res;
                this.form = { ...this.form, ...res };
                this.$toast.success("Task updated");
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.btnLoading = false;
            };
        },

        setParentProject(project) {
            this.form.project._id = project._id;
            this.form.project.title = project.title;
        },

        async searchProjects(query) {
            try {
                this.searchProjectsLoading = true;
                
                const pageQueryObj = {
                    page: 1,
                    type: "active",
                    title: query,
                };

                const buildResult = assembleQueryList(pageQueryObj);
                const res = await this.$store.dispatch("project/fetchProjects", { params: buildResult, refresh: true , external: true });
                this.projects = res.data;
            } catch (error) {
                // console.log({ error });
            } finally {
                this.searchProjectsLoading = false;
            }
        }, 

        async fetchTags(query) {
            try {
                this.searchTagsLoading = true;
                
                const pageQueryObj = {
                    name: query,
                    page: 1,
                };

                const buildResult = assembleQueryList(pageQueryObj);
                const res = await this.$store.dispatch("tag/fetchTags", { params: buildResult, external: true });
                this.tags = res;
            } catch (error) {
                // console.log({ error });
            } finally {
                this.searchTagsLoading = false;
            }
        }, 

        searchTags(newVal) {            
            debounce(() => {
                this.fetchTags(newVal);
            }, 500, "search-list");
        },
    },
};
</script>

<style lang="scss">
    .delete-icon {
        svg {
            fill: var(--error-600) !important;
        }
    }
</style>