<template>
    <div>
        <main-modal 
            :show-modal="showModal" size="sm" :title="`Manage ${dataByType.name}`" 
            @close="onCancel"
        >
            <template #body>
                <div class="modal__wrapper--onboarding mb--15">
                    <form class="pb--5" @submit.prevent="">
                        <div class="mb--15 m-w-100">
                            <FormLabel :title="`Add one or more ${dataByType.name}`" class="mb--10" />
                            <CustomDropdown
                                :id="`${subData.type}Dropdown`" :options="dataByType.list" :list-type="subData.type" multi
                                :placeholder="`Search or add new ${subData.type}`" :index-label="dataByType.indexLabel"
                                @select="addItem" @search="searchCountry"
                            />
                        </div>
                    </form>

                    <div v-if="form.items.length" class="project__sub__wrap ml--0 mt--10 mb--10">
                        <div v-for="item in form.items" :key="item._id" class="project__sub__item">
                            <div class="flex align-items-center">
                                <IconSvg :name="dataByType.iconName" width="16px" fill="#5e6c84" />
                                <span class="ml--5 title">{{ item[dataByType.indexLabel] }}</span>
                            </div>
                            <span class="close" type="button" @click="removeItem(item)">
                                <IconSvg name="close" width="16px" fill="#5e6c84" />
                            </span>
                        </div>
                    </div>
                </div>
            </template>

            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <base-button 
                        class="mr--5" label="Cancel" variant="outline" size="sm"
                        @submit="onCancel"
                    />
                    <base-button 
                        type="submit" size="sm"
                        :loading="loading" :disabled="loading" 
                        label="Update" @submit="handleUpdateProject"
                    />
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import MainModal from "./MainModal.vue";
import FormLabel from "../forms/FormLabel.vue";
import BaseButton from "../buttons/BaseButton.vue";
import CustomDropdown from "../dropdowns/CustomDropdown.vue";
import IconSvg from "../icons/Icon-Svg.vue";
import { invoiceReminders, daysOfWeek } from "@/utils/dummy";

export default {
    name: "ProjectSubModal",

    components: {
        MainModal,
        FormLabel,
        BaseButton, CustomDropdown,
        IconSvg,
    },

    props: {
        indexLabel: { type: String, default: "", required: false },
        showModal: { type: Boolean, default: false, required: true },
        multiple: { type: Boolean, default: false, required: false },
        subData: { type: Object, default: () => {}, required: true },
        initialList: { type: Array, default: () => [], required: true },
    },

    emits: ["select", "close", "complete", "search"],

    data() {
        return {
            form: {
                items: [],
            },
            loading: false,
            itemsToRemove: [],
            itemsToAdd: [],
            invoiceReminders,
            daysOfWeek,
        };
    },

    computed: {
        clients() {
            return this.$store.getters["clients/clients"];
        },

        rateCards() {
            return this.$store.getters["revenue/rateCards"]?.list;
        },

        dataByType() {
            switch (this.subData?.type) {
            case "clients":
                return {
                    list: this.clients || [],
                    indexLabel: "name",
                    iconName: "user",
                    payloadTitle: "clientIds",
                    name: "clients",
                };
        
            case "rateCards":
                return {
                    list: this.rateCards || [],
                    indexLabel: "title",
                    iconName: "money",
                    payloadTitle: "rateCardIds",
                    name: "rate cards",
                };
        
            default:
                return {
                    list: undefined,
                    indexLabel: "",
                    iconName: "",
                    payloadTitle: "",
                    name: "",
                };
            }
        },
    },

    watch: {
        initialList: {
            handler(newVal) {
                if (newVal?.length) {
                    this.form.items = newVal;
                }
            },
            immediate: true,
            deep: true,
        },
    },

    methods: {
        addItem(item) {
            if (item._id) {
                const isItemExist = this.form.items.find(x => x._id === item._id);

                if (!isItemExist) {
                    this.form.items = [ ...this.form.items, item ];
                }
            }
        },

        removeItem(item) {
            this.form.items = this.form.items.filter(i => i._id !== item._id);
            this.itemsToRemove = [ ...this.itemsToRemove, item._id ];
        },

        onCancel() {
            this.form.items = [];
            this.itemsToRemove = [];
            this.$emit("close");
        },

        buildPayload() {
            if (!this.dataByType.payloadTitle) return null;

            const payloadArr = this.form.items.map(x => x._id);

            const payload = {
                [`${this.dataByType.payloadTitle}`]: payloadArr,
            };

            return payload;
        },

        async handleUpdateProject() {  
            try {
                const payload = this.buildPayload();

                if (!payload) {
                    this.$toast.error("Unable to make update");
                    return;
                }

                this.loading = true;
                
                await this.$store.dispatch("project/updateProjectSubs", { id: this.$route.params.id, payload });
                
                this.$emit("complete", {
                    key: this.subData.type,
                    list: this.form.items,
                });
                this.form.items = [];
                this.itemsToRemove = [];
                this.$toast.success("Project info updated");
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }           
        },
    },
};
</script>