<template>
    <div class="report__item__wrap">
        <div>
            <div class="flex align-items-start w--100">
                <BaseCard class="pl--10 pr--10 flex-2 mr--10" is-shadow>
                    <template #card-body>
                        <div class="report__item__header">
                            <p class="text--semi--bold text--sm mb--10">
                                Number of projects created per month
                            </p>
                            <bar-chart :data="transformedData.projectsByMonth" />
                        </div>
                    </template>
                </BaseCard>

                <div class="flex-1">
                    <BaseCard class="pl--10 pr--10" is-shadow>
                        <template #card-body>
                            <p class="text--semi--bold text--sm">
                                Project distribution by status
                            </p>
                            <p class="text--bold text--3xl mt--15">
                                {{ totalNum }} <span class="text--sm text--normal text--color-gray-200">projects</span>
                            </p>
                            <div class="report__client__status">
                                <div v-for="(item, index) in statusData" :key="index" class="report__client__status__item">
                                    <div class="flex align-items-baseline">
                                        <span 
                                            class="tag badge rounded-pill mr--5"  
                                            :class="[projectStatusMap[item.id].tag || '']" 
                                        />
                                        <div class="">
                                            <span class="text--xs text--color-gray-700 text--semi--bold text--capitalize">
                                                {{ item.id }}
                                            </span>
                                            <p class="text--xs text--color-gray-300">
                                                {{ item.count }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </BaseCard>

                    <BaseCard class="pl--10 pr--10 mt--15" is-shadow>
                        <template #card-body>
                            <p class="text--semi--bold text--sm">
                                Total % of completed vs blocked projects
                            </p>
                            <div class="mt--15">
                                <div class="flex align-items-center mb--10">
                                    <span class="text--medium text--sm text--color-gray-400 mr--5">Completed projects:</span>
                                    <span class="stats__rate green">{{ completedRate }}%</span>
                                </div>
                                <div class="flex align-items-center">
                                    <span class="text--medium text--sm text--color-gray-400 mr--5">Blocked projects:</span>
                                    <span class="stats__rate green">{{ blockedRate }}%</span>
                                </div>
                            </div>
                        </template>
                    </BaseCard>
                </div>
            </div>
            
            <div class="report__item mt--40 w--100">
                <div class="flex-2">
                    <BaseCard class="pl--10 pr--10" is-shadow>
                        <template #card-body>
                            <div class="report__item__header">
                                <p class="text--semi--bold text--sm">
                                    Project distribution by currency
                                </p>
                            </div>
                            
                            <div>
                                <template v-if="transformedData.projectByCurrency.length">
                                    <bar-chart :data="transformedData.projectByCurrency" />
                                </template>
                                <template v-else>
                                    <p>No data available</p>
                                </template>
                            </div>
                        </template>
                    </BaseCard>
                </div>

                <div class="flex-1 ml--10">
                    <BaseCard class="pl--10 pr--10" is-shadow>
                        <template #card-body>
                            <p class="mb--35 text--semi--bold text--sm">
                                Project distribution by payment type
                            </p>
                           
                            <template v-if="transformedData.projectType.length">
                                <pie-chart :data="transformedData.projectType" class="text--capitalize" />
                            </template>
                            <template v-else>
                                <p>0 projects created</p>
                            </template>
                        </template>
                    </BaseCard>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BaseCard } from "@/components/ui/index";
import { getMonthFromNum } from "@/utils/helpers";
import { projectStatusMap }from "@/utils/enums";

export default {
    name: "ProjectReports",

    components: {
        BaseCard,
    },

    data() {
        return {
            projectStatusMap,
        };
    },

    computed: {
        reportDataProps() {
            return this.$store.getters["report/reportData"]?.projects;
        },

        transformedData() {
            if (!this.reportDataProps) return {};

            const data = {};
            const keys = Object.keys(this.reportDataProps);

            if (!keys && keys.length) return {};

            for (let i = 0; i < keys.length; i++) {
                const item = keys[i];
                const list = this.reportDataProps[item]; 

                switch(item) {
                case "projectType":
                    data.projectType = list.map(i => (i?.data)) || [];
                    break;
                        
                case "projectsByMonth":
                    data.projectsByMonth = list.map(i => ([this.getMonthFromNum(i?._id), i?.count])) || [];
                    break;

                case "projectByCurrency":
                    data.projectByCurrency = list.map(x => ([x._id, x.count])) || [];
                    break;

                case "projectsByStatus":
                    data.projectsByStatus = list.map(i => (i?.data)) || [];
                    break;

                case "totalNum":
                    data.totalNum = list.length && list[0]?.count || 0;
                    break;

                default:
                    break;
                }
            }

            return data;
        },

        completedRate() {
            const count = this.statusData.find(x => x.id === "completed")?.count;
            const percentageValue = (count / this.totalNum) * 100;
            return percentageValue && typeof percentageValue === "number" && percentageValue.toFixed(2) || 0;
        },

        blockedRate() {
            const count = this.statusData.find(x => x.id === "blocked")?.count;
            const percentageValue = (count / this.transformedData.totalNum) * 100;
            return percentageValue && typeof percentageValue === "number" && percentageValue.toFixed(2) || 0;
        },

        statusData() {
            return this.transformedData?.projectsByStatus?.map(x => ({ id: x[0], count: x[1] })) || [];
        },

        totalNum() {
            return this.transformedData?.totalNum;
        },
    },

    methods: {
        getMonthFromNum,
    },
};
</script>

<style lang="scss" scoped>
    .tag {
        border-radius: 50%;
        height: 5px;
        width: 5px;
        padding: 0.1rem;

        &--cornsilk {
           background:  rgb(210, 105, 30)!important;
        }
   
        &--purple {
            background: #6b21a8 !important;
        }
    
        &--pink {
            background: #DA7DA0 !important;
        }
    
        &--red {
            background: rgb(144, 22, 69) !important;
        }
        
        &--green {
            background: #228d2d !important;
        }
    
        &--blue {
            background: #1e40af !important;
        }
    
        &--yellow {
            background: #e8bd0d !important;
        }
    
        &--grey {
            background: #2c3234 !important;
        }
    }
</style>