import api from "@/api/url";

export default {
    namespaced: true,

    state: () => {
        return {
            selectedYear: new Date().getFullYear(),
            currentPageSelection: "Calendar",
            upcomingMeetings: {
                list: [],
                pageData: {
                    currentPage: 1,
                    totalPages: 0,
                    pageSize: 0,
                    total: 0,
                },
            },
            unconfirmedMeetings: {
                list: [],
                pageData: {
                    currentPage: 1,
                    totalPages: 0,
                    pageSize: 0,
                    total: 0,
                },
            },
            recurringMeetings: {
                list: [],
                pageData: {
                    currentPage: 1,
                    totalPages: 0,
                    pageSize: 0,
                    total: 0,
                },
            },
            canceledMeetings: {
                list: [],
                pageData: {
                    currentPage: 1,
                    totalPages: 0,
                    pageSize: 0,
                    total: 0,
                },
            },
        };
    },

    getters: {
        upcomingMeetings: state => state.upcomingMeetings,
        unconfirmedMeetings: state => state.unconfirmedMeetings,
        recurringMeetings: state => state.recurringMeetings,
        canceledMeetings: state => state.canceledMeetings,
        selectedYear: state => state.selectedYear,
        currentPageSelection: state => state.currentPageSelection,
    },

    mutations: {
        set(state, [key, value]) {
            state[key] = value;
        },
    },
    
    actions: {
        setSelectedYear({ commit }, year) {
            commit("set", ["selectedYear", year]);
        },

        setCurrentPageSelection({ commit }, val) {
            commit("set", ["currentPageSelection", val]);
        },
        
        // MEETINGS ---------------------------
        fetchUpcomingMeetings({ commit, state }, { refresh, params }) {
            if (state.upcomingMeetings?.list.length && !refresh) {
                return;
            }

            return api.meeting.getMeetings(params)
                .then((res) => {
                    const data = res.data.data;
                    commit("set", [state.upcomingMeetings.list, data]);
                });
        },

        fetchUnconfirmedMeetings({ commit, state }, { refresh, params }) {
            if (state.unconfirmedMeetings?.list.length && !refresh) {
                return state.unconfirmedMeetings.list;
            }

            return api.meeting.getMeetings(params)
                .then((res) => {
                    const data = res.data.data;
                    commit("set", [state.unconfirmedMeetings.list, data]);
                });
        },

        fetchRecurringMeetings({ commit, state }, { refresh, params }) {
            if (state.unconfirmedMeetings?.list.length && !refresh) {
                return state.unconfirmedMeetings.list;
            }

            return api.meeting.getMeetings(params)
                .then((res) => {
                    const data = res.data.data;
                    commit("set", [state.unconfirmedMeetings.list, data]);
                });
        },

        fetchOneMeeting({ commit, state }, id) {
            return api.meeting.getMeeting(id)
                .then((res) => {
                    const data = res.data.data;
                    return data;
                });
        },

        modifyMeetingNote({ state, commit }, { id, ...note }) {
            return api.meeting.modifyNotes(id, note)
                .then((resp) => {});
        },
    },
};