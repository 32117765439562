import axios from "axios";
import store from "@/store/index";
import { matchUrl } from "@/utils/helpers";
import env from "@/config/env";

const axiosInstance = axios.create({
    baseURL: env.VUE_APP_BASE_API_URL,
    headers: {
        "Content-Type": "application/json", 
    },
    withCredentials: true,
    timeout: 80000,
    timeoutErrorMessage: "Request timed out",
});

function handleGeneralApiResponse(response) {
    try {
        // TO DO: No explicit response type checking. Could cause issues with non-JSON responses
        //  Add response type validation        
        return response;
    } catch (error) {
        return response; // Still return response even if logging fails
    }
}

async function handleGeneralApiErrors(error) {
    const status = error?.response?.status || 500;
    const url = error?.config?.url || undefined;
    
    if (status === 500) {
        return Promise.reject(error);
    } 
    
    if ([401, 403].includes(status)) {
        const IGNORED_401_ENDPOINTS = ["auth/login", "auth/register", "auth/reset", "auth/initiate-reset"];

        const isPrivateEndpoint = !IGNORED_401_ENDPOINTS.some(p => matchUrl(p, url));
        const invalidTokenErr = error?.response?.data?.message?.includes("Bearer token is invalid");

        if (isPrivateEndpoint || invalidTokenErr) {
            try {
                await store.dispatch("auth/signOut");
            } catch (signOutError) {
                console.error("Sign out failed:", signOutError);
                // Prevent infinite loop by not re-throwing
                return Promise.reject(new Error("Authentication and logout failed"));
            }
        }
    }

    if (status === 404 && url === "/user/users/fetchprofile") {
        try {
            await store.dispatch("auth/signOut");
        } catch (signOutError) {
            console.error("Sign out failed:", signOutError);
            // Prevent infinite loop by not re-throwing
            return Promise.reject(new Error("Authentication and logout failed"));
        }
    }

    return Promise.reject(error);
}

axiosInstance.interceptors.request.use(
    (config) => {
        return config;
    }, 
    (error) => handleGeneralApiErrors(error),
);

// response interceptor
axiosInstance.interceptors.response.use(
    (resp) => handleGeneralApiResponse(resp), 
    (error) => handleGeneralApiErrors(error),
);

export default axiosInstance;