<template>
    <div class="mt--10">
        <div class="client__notes">
            <div class="">
                <div class="list--count mt--10 mb--10">
                    <p class="text--medium text--bold text--color-dark">
                        Notes <span v-if="notes.length" class="zm__count__badge" :class="[notes.length > 10 ? 'multiple' : 'single']">{{ notes.length }}</span>
                    </p>
                </div>
        
                <div class="col-12">
                    <div class="w--100 invoice__details__item block pt--0">
                        <template v-if="fetchNotes.loading">
                            <page-spinner />
                        </template>
            
                        <template v-else>
                            <EmptyPage
                                v-if="!notes.length" state="empty" type="note" icon-name="notes"
                                width="60px" height="60px"
                            />
            
                            <div v-else class="mt--10">
                                <div v-for="item in notes" :key="item._id" class="client__notes__item" :class="{ 'disabled': currentNoteId === item._id && deleteLoading }">
                                    <div class="text--sm">
                                        <p>{{ sanitizeNoteContent(item.value) }}</p>
                                        <p class="text--xs text--color-light-300">
                                            {{ formatDateStrings(item.createdAt) }}
                                        </p>
                                    </div>
            
                                    <div class="dropdown">
                                        <BaseButton 
                                            label="Cancel" variant="outline" size="sm" class="text--xs text--semi--bold"
                                            aria-expanded="false" data-bs-toggle="dropdown" data-bs-auto-close="true" type="button"
                                            prefix-icon icon-name="dots-horizontal-rounded" icon-width="12px" icon-fill="#637579"
                                        />            
                                        <ul id="noteDropdownList" class="dropdown-menu dropdown-menu--tag" aria-labelledby="noteDropdown">
                                            <li class="dropdown-item flex align-items-center pointer text--xs" @click="handleCopyNoteContent(item)">
                                                <icon-svg class="mr--5" name="copy" :style="{ fill: '#212529' }" :width="'16px'" /> 
                                                <span>Copy content</span>
                                            </li>
                                            <li class="dropdown-item flex align-items-center pointer text--xs" @click="startNoteEdit(item)">
                                                <icon-svg class="mr--5" name="edit" :style="{ fill: '#212529' }" :width="'16px'" /> 
                                                <span>Edit note</span>
                                            </li>
                                            <li class="dropdown-item flex align-items-center pointer text--xs" @click="handleDeleteNote(item)">
                                                <icon-svg class="mr--5" name="delete" :style="{ fill: '#212529' }" :width="'16px'" /> 
                                                <span>Delete note</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
            
                            <div class="w-100">
                                <form class="client__notes__form" @submit.prevent>
                                    <div class="form__textarea__group">
                                        <div class="form__textarea__wrap">
                                            <CustomEditor v-model="noteValue" />
                                        </div>
            
                                        <div v-if="showActionBtns" class="client__notes__action__btns">
                                            <base-button
                                                class-names="mr--5" label="Cancel" variant="outline" size="sm"
                                                @submit="resetForm"
                                            />
                                            <base-button 
                                                type="submit" size="sm" :loading="loading"
                                                :label="action === 'add' ? 'Add' : 'Save'" 
                                                @submit="handleFormSubmit"
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { IconSvg, EmptyPage, PageSpinner, BaseButton, CustomEditor } from "@/components/ui/index";
import { replaceStringsAll } from "@/plugins/formatters";
import { formatDateStrings } from "@/utils/helpers";
import sanitizeHtml from "sanitize-html";

export default {
    name: "ProjectNotes",

    components: {
        IconSvg,
        EmptyPage,
        PageSpinner,
        BaseButton,
        CustomEditor,
    },

    data() {
        return {
            noteValue: "",
            currentNoteId: "",
            action: "add",
            showActionBtns: true,
            fetchNotes: {
                loading: false,
                err: false,
                msg: "",
            },
            pageData: {
                currentPage: 1,
                totalPages: 0,
                pageSize: 50,
                total: 0,
            },
            notes: [],
            loading: false,
            deleteLoading: false,
            msg: "",
        };
    },

    computed: {},

    async mounted() {
        await this.handleFetchNotes();
    },

    methods: {
        replaceStringsAll,
        formatDateStrings,

        sanitizeNoteContent(text) {
            return sanitizeHtml(text, {
                allowedTags: ["a"],
                allowedAttributes: {
                    "a": [ "href" ],
                },
            });
        },

        startNoteEdit(note) {
            if (!note?._id || !note?.value) return;

            this.currentNoteId = note?._id;
            this.noteValue = note.value;

            this.action = "edit";
            this.showActionBtns = true;
        },

        resetForm() {
            this.action = "add";
            this.currentNoteId = "";
            this.noteValue = "";
        },

        async handleFetchNotes(params) {
            try {
                this.fetchNotes.err = false;
                this.fetchNotes.loading = true;

                const payload = {
                    page: params?.page || this.pageData.currentPage,
                    limit: 50,
                };

                const result = await this.$store.dispatch("project/getNotes", { id: this.$route.params.id, params: payload });

                this.notes = result.data;
                this.pageData = result.pageDetails;
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;

                this.fetchNotes.err = true;
                this.fetchNotes.msg = errMsg;
            } finally {
                this.fetchNotes.loading = false;
            }
        },
        
        async handleAddNote() {
            try {
                const id = this.$route.params.id;

                if (!id || !this.noteValue) {
                    throw new Error("Missing note or project id");              
                }

                this.loading = true;

                const regex =  /<\/?p>/g;

                const payload = {
                    projectId: this.$route.params.id,
                    value: replaceStringsAll(this.noteValue, regex),
                };

                const res = await this.$store.dispatch("project/addNewNote", payload);

                this.notes = [ ...this.notes, res ];
                this.pageData.total = this.pageData.total + 1;
                this.pageData.totalPages = Math.ceil(this.notes.length / 50);
            
                this.$toast.success("Note added");
                this.resetForm();
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }
        },

        async handleUpdateNote() {
            try {
                const id = this.$route.params.id;

                if (!id || !this.noteValue || !currentNoteId) {
                    this.$toast.error("Missing route or note id"); 
                    return;               
                }

                if (!this.noteValue) {
                    this.$toast.error("Note value is required"); 
                    return;                     
                }

                this.loading = true;

                const regex =  /<\/?p>/g;

                const payload = {
                    _id: id,
                    projectId: this.$route.params.id,
                    value: replaceStringsAll(this.noteValue, regex),
                };

                const res = await this.$store.dispatch("project/updateNote", payload);

                if (!res) {
                    this.$toast.error("Unable to update note");
                } else {
                    this.notes = this.notes.map(c => {
                        if (c?._id === res?._id) {
                            return {
                                ...c,
                                ...data,
                            };
                        } else {
                            return c;
                        }
                    });

                    this.$toast.success("Note updated");
                    this.resetForm();
                }
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }            
        },

        handleFormSubmit() {
            if (this.action === "add") {
                this.handleAddNote();
            } else {
                this.handleUpdateNote();
            }
        },

        async handleDeleteNote(item) {
            try {
                if (!item._id) {
                    this.$toast.error("Missing note id required"); 
                    return;               
                }

                this.currentNoteId = item._id;
                this.deleteLoading = true;

                await this.$store.dispatch("project/deleteNote", {
                    projectId: this.$route.params.id,
                    _id: item._id,
                });

                this.notes = this.notes.filter(c => c._id !== item._id);
                this.pageData.total = this.pageData.total - 1;
                this.pageData.totalPages = Math.ceil(this.notes.length / 50);

                this.$toast.success("Note deleted");
                this.resetForm();
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.deleteLoading = false;
                this.currentNoteId = "";
            }      
        },

        async handleCopyNoteContent(note) {
            if (!note.value) return;

            if ("clipboard" in navigator) { 
                await navigator.clipboard.writeText(note.value);
                this.$toast.success("Text copied!");
            }

        },
    },
};
</script>

<style scoped lang="scss">
    textarea.form-control {
        border-radius: 4px !important;
    }

    .zm__count__badge {
        margin-right: 4px;
        border: 1px solid #e5e5e5;
        border-radius: 50%;
        font-size: 12px;
        color: #757676;
        background: #f3efff;

        .single {
            padding: 4px 6px;
        }

        .multiple {
            padding: 4px;
        }
    }
</style>