<template>
    <p 
        class="text" 
        v-bind="$attrs" 
        :class="[
            `text--${weight}`,
            `text--${transform}`,
            `text--${size}`,
            `text-${align}`,
            `text--color-${color}`,
            `${underline ? 'text--underline' : ''}`,
        ]"
    >
        <slot />
    </p>
</template>

<script>
export default {
    name: "CustomText",
    
    props: {
        title: {
            type: String,
            default: "",
        },
        underline: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: "sm",
            validator: value => !value || [
                "8xl", "7xl", "6xl", "5xl", "4xl", "3xl", "2xl", "xl", "lg", "md", "sm", "xs", "base",
            ].includes(value),
        },
        weight: {
            type: String,
            default: "normal",
            validator: value => !value || [
                "bold", "medium", "semi--bold", "normal",
            ].includes(value),
        },
        transform: {
            type: String,
            default: "none",
            validator: value => !value || [
                "upper", "capitalize", "lower", "none",
            ].includes(value),
        },
        align: {
            type: String,
            default: "left",
            validator: value => !value || [
                "center", "left", "right",
            ].includes(value),
        },
        color: {
            type: String,
            default: "gray-700",
            validator: value => !value || [
                "primary", "warning", "purple", "blue", "gray-100", "gray-200", "gray-300", 
                "gray-400", "gray-500", "gray-600", "gray-700", "gray-800", "gray-900", "black", "white",
            ].includes(value),
        },
    },
};
</script>