<template>
    <DropDownButton 
        label="Sort" prefix-icon icon-name="sort" icon-width="16px"
        variant="outline" inner-style="secondary" :size="size"
    >
        <template #dropdown-content>
            <li 
                v-for="(item, index) in displayTypeList" 
                :key="index" 
                :class="{ 'selected': value === item }"
                class="dropdown-item pointer text--xs" 
                @click="setDisplayType(item)"
            >
                {{ item }}
            </li>
        </template>
    </DropDownButton>
</template>

<script>
import DropDownButton from "../buttons/DropDownButton.vue";

export default {
    name: "SortFilter",

    components: { DropDownButton },
    
    props: {
        value: {
            type: String,
            default: "Ascending",
        },
        size: {
            type: String,
            default: "",
        },
    },
    
    data() {
        return {
            displayTypeList: ["Ascending", "Descending"],
        };
    },

    methods: {
        setDisplayType(val) {
            this.$emit("setType", val);
            setTimeout(() => {
                document.getElementById("sortFilter").classList.remove("show");
                document.getElementById("sortFilterList").classList.remove("show");
            }, 100);
        },
    },
};
</script>