<template>
    <div>
        <MainModal title="Rate card details" :show-modal="showModal" size="md" @close="closeModal">
            <template #body>
                <div class="rc__view__wrap">
                    <template v-if="loading">
                        <PageSpinner />
                    </template>

                    <template v-else-if="noIdErr">
                        <EmptyPage state="error" />
                    </template>

                    <template v-else-if="loadingErr">
                        <EmptyPage state="error" :handle-submit="handleFetchRateCard" />
                    </template>

                    <template v-else>
                        <div class="">
                            <div class="flex justify-content-between align-items-center w--100">
                                <span class="project__item__title text--normal mt--0 text--xs">
                                    Created on: {{ $format_date(currentItem.createdAt) }}
                                </span>
                                <div class="flex align-items-center">
                                    <span 
                                        class="status__map text--medium text--xs tag bold badge rounded-pill mr--5" 
                                        :class="[rateTypeMap[currentItem.rateType]]"
                                    >
                                        <span class="status__circle" :class="[`${rateTypeMap[currentItem.rateType]}__text`]" />
                                        {{ $capitalize_first_letter(currentItem.rateType) }}
                                    </span>
                                    <BaseButton 
                                        prefix-icon icon-name="download" icon-width="16px" variant="outline"
                                        label="Download" size="sm" :loading="downloadLoading" @submit="downloadCard" 
                                    />
                                </div>
                            </div>

                            <div>
                                <p class="rc__view__header">
                                    {{ currentItem.title }}
                                </p>
                                <p class="rc__view__desc">
                                    {{ currentItem.description }}
                                </p>
                            </div>
                        </div>

                        <div v-if="currentItem.socials && currentItem.socials.length" class="mt--40">
                            <FormLabel 
                                label-type="menu" html-for="socialItems" 
                                title="Social accounts" class="text--base mb--10 text--upper" 
                            />
                            <CustomTable
                                :columns="rateCardSocialHeaders" :is-checkbox="false"
                                :data="currentItem.socials" table-type="striped" :is-action-btns="false"
                            >
                                <template #rows="data">
                                    <td>{{ data.name || '-' }}</td>
                                    <td>
                                        {{ data.url || '-' }}
                                    </td>
                                </template>
                            </CustomTable>
                            
                            <div class="table__striped__footer mt--5 pl--0">
                                <p class="table__striped__footer__results text--color-gray-200 text--xs">
                                    COUNT: {{ currentItem.socials.length }}
                                </p>
                            </div>
                        </div>

                        <div class="rc__view__total mt--30">
                            <FormLabel 
                                label-type="menu" 
                                html-for="socialItems" 
                                :title="`${currentItem.items.length || ''} service ${computeItemsLengthText}`" 
                                class="text--base text--upper mb--15" 
                            />

                            <CustomTable
                                :columns="rateCardServiceHeaders" :is-action-btns="false"
                                :data="currentItem.items" :is-checkbox="false"
                            >
                                <template #rows="data">
                                    <td>{{ data.serviceName || '-' }}</td>
                                    <td>{{ data.description || "-" }}</td>
                                    <td>{{ $country.getSymbol(currentItem.currency) }}{{ $format_amount(data.amount || 0) }}</td>
                                </template>
                            </CustomTable>

                            <div class="mt--30">
                                <div class="rc__view__row">
                                    <div class="text--sm">
                                        Subtotal:
                                    </div>
                                    <div class="rc__view__row__text">
                                        {{ $country.getSymbol(currentItem.currency) || '-' }}{{ $format_amount(subTotal) || 0 }}
                                    </div>
                                </div>
                                <!-- <div class="rc__view__row">
                                    <div class="text--sm">
                                        Tax:
                                    </div>
                                    <div class="rc__view__row__text">
                                        {{ $country.getSymbol(currentItem.currency) || '-' }}{{ currentItem.tax || 0.00 }}
                                    </div>
                                </div> -->
                                <div class="rc__view__row">
                                    <div class="text--sm">
                                        Total:
                                    </div>
                                    <div class="rc__view__row__text">
                                        {{ $country.getSymbol(currentItem.currency) || '-' }}{{ $format_amount(total) || 0 }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <BaseButton label="Close" size="sm" @submit="closeModal" />
                </div>
            </template>
        </MainModal>
    </div>
</template>

<script>
import BaseButton from "../buttons/BaseButton.vue";
import MainModal from "./MainModal.vue";
import PageSpinner from "../loaders/PageSpinner.vue";
import EmptyPage from "../emptyPage/EmptyPage.vue";
import IconSvg from "../icons/Icon-Svg.vue";
import { rateTypeMap } from "@/utils/enums";
import CustomTable from "../tables/index.vue";
import { rateCardSocialHeaders, rateCardServiceHeaders } from "@/utils/dummy";
import FormLabel from "../forms/FormLabel.vue";

export default {
    name: "ViewRateCard",

    components: { 
        MainModal, BaseButton, 
        PageSpinner, EmptyPage, IconSvg,
        CustomTable, FormLabel,
    },

    props: {
        showModal: {
            type: Boolean,
            default: false,
        },

        cardId: {
            type: String,
            default: "",
        },
    },

    emits: ["close"],

    data() {
        return {
            loading: false,
            loadingErr: false,
            noIdErr: false,
            currentItem: {
                title: "",
                items: [],
                rateType: "",
                currency: "",
                tax: 0,
                createdAt: null,
            },
            downloadLoading: false,
            rateTypeMap, rateCardSocialHeaders, rateCardServiceHeaders,
        };
    },

    computed: {
        userProfile() {
            return this.$store.getters["auth/profile"];
        },

        computeItemsLengthText() {
            if (this.currentItem.items.length > 1 || !this.currentItem.items.length) {
                return "items";
            } else {
                return "item";
            }
        },

        subTotal() {
            return this.currentItem.items.reduce((previous, current) => previous + current.amount, 0);
        },

        total() {
            return (this.currentItem.tax || 0) + this.subTotal;
        },
    },

    watch: {
        "cardId": {
            async handler(newVal) {
                if (!newVal) {
                    this.noIdErr = true;
                } else {
                    await this.handleFetchRateCard();
                }
            },
            immediate: true,
        },
    },

    methods: {
        async downloadCard() {
            try {
                this.downloadLoading = true;

                const resp = await this.$store.dispatch("ratecards/downloadRateCard", { 
                    id: this.currentItem._id,
                    config: {
                        params: { type: "pdf" }, 
                        responseType: "blob",
                        headers: {
                            "Accept": "application/pdf",
                        },
                    },
                });

                // await this.$store.dispatch("ratecards/diagnose", { blob: resp.data });

                const blob = new Blob([resp.data], { type:  "application/pdf" });

                if (!blob || blob.size === 0) {
                    throw new Error("PDF file is empty");
                }

                const url = window.URL.createObjectURL(blob);
    
                // Create link element
                const link = document.createElement("a");
                link.href = url;
                
                // Get filename from response headers or set default
                const contentDisposition = resp.headers["content-disposition"];
                const fileName = contentDisposition
                    ? contentDisposition.split("filename=")[1].replace(/"/g, "")
                    : "rate_card.pdf";
                
                link.setAttribute("download", fileName);
                
                // Append link to body
                document.body.appendChild(link);
                
                // Start download
                link.click();
                
                // Clean up
                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);
             
                this.$toast.success("Rate card downloaded");
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.downloadLoading = false;
            }
        },

        async handleFetchRateCard() {
            try {
                this.noIdErr = false;
                this.loadingErr = false;
                this.loading = true;

                const res = await this.$store.dispatch("ratecards/fetchRateCard", this.cardId);

                this.currentItem = {
                    ...this.currentItem,
                    ...res,
                };
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.loadingErr = true;
            } finally {
                this.loading = false;
            }
        },

        closeModal() {
            this.$emit("close");
            
            this.currentItem = {
                title: "",
                items: [],
                rateType: "",
                currency: "",
                tax: 0,
                createdAt: null,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.rc {
    &__view {
        &__header {
            &__date {
                color: #38393b;
                font-size: 0.875rem;
                margin-left: 5px;
            }

            &__type {
                color: #38393b;
                font-size: 14px;
                text-transform: capitalize;
                margin-top: 5px;
            }

            &__download {
                border-radius: 5px;
                padding: 5px 8px;
                background: #eee;
                cursor: pointer;
            }
        }

        &__row {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            width: 100%;
            justify-content: flex-end;

            &__text {
                font-size: 0.875rem;
                font-weight: 600;
                text-transform: uppercase;
                margin-left: 10px;
            }
        }

        &__total {
            margin-bottom: 25px;
        }
    }
}
</style>