<template>
    <div class="report__item__wrap">
        <div>
            <div class="flex align-items-start">
                <BaseCard class="flex-2 mr--10" is-shadow>
                    <template #card-body>
                        <div class="report__item__header">
                            <p class="text--semi--bold text--sm mb--10">
                                Number of clients created per month
                            </p>
                            <bar-chart :data="transformedData.clientsByMonth" />
                        </div>
                    </template>
                </BaseCard>
                <div class="flex-1">
                    <BaseCard is-shadow card-body-class="pt--0 pb--10 pl--0 pr--0">
                        <template #card-body>
                            <div class="report__item__header">
                                <p class="text--semi--bold text--sm">
                                    Client distribution by status
                                </p>
                            </div>

                            <div class="report__client__status pl--10 pr--10">
                                <div v-for="(item, index) in statusData" :key="index" class="report__client__status__item">
                                    <div class="flex align-items-baseline">
                                        <span 
                                            class="tag badge rounded-pill mr--5"  
                                            :class="[clientStatusMap[item.id].tag || '']" 
                                        />
                                        <div class="">
                                            <span class="text--xs text--color-gray-700 text--semi--bold text--capitalize">
                                                {{ item.id }}
                                            </span>
                                            <p class="text--xs text--color-gray-300">
                                                {{ item.count }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </BaseCard>
                    <BaseCard class="mt--15" is-shadow>
                        <template #card-body>
                            <p class="text--semi--bold text--sm">
                                Total % of active vs inactive clients
                            </p>
                            <div class="mt--15">
                                <div class="flex align-items-center mb--10">
                                    <span class="text--medium text--sm text--color-gray-400 mr--5">Active clients:</span>
                                    <span class="stats__rate green">{{ activeRate }}%</span>
                                </div>
                                <div class="flex align-items-center">
                                    <span class="text--medium text--sm text--color-gray-400 mr--5">Inactive clients:</span>
                                    <span class="stats__rate green">{{ inactiveRate }}%</span>
                                </div>
                            </div>
                        </template>
                    </BaseCard>
                </div>
            </div>
            
            <div class="report__item mt--40">
                <div class="flex-2">
                    <BaseCard is-shadow card-body-class="pt--10 pb--10 pl--0 pr--0">
                        <template #card-body>
                            <div class="report__item__header">
                                <p class="text--semi--bold text--sm">
                                    Client distribution by industry
                                </p>
                            </div>
                            
                            <div class="pl--10 pr--10 mt--20">
                                <template v-if="transformedData.clientsByIndustry.length">
                                    <bar-chart :data="transformedData.clientsByIndustry" />
                                </template>
                                <template v-else>
                                    <p>No data available</p>
                                </template>
                            </div>
                        </template>
                    </BaseCard>
                </div>

                <div class="flex-1 ml--20">
                    <BaseCard class="pl--10 pr--10" is-shadow>
                        <template #card-body>
                            <div class="report__item__header">
                                <p class="text--semi--bold text--sm">
                                    Geographic distribution of clients
                                </p>
                            </div>
                            <template v-if="transformedData.clientsByCountry.length">
                                <pie-chart :data="transformedData.clientsByCountry" />
                            </template>
                            <template v-else>
                                <p>No data available</p>
                            </template>
                        </template>
                    </BaseCard>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BaseCard } from "@/components/ui/index";
import { getMonthFromNum } from "@/utils/helpers";
import { clientStatusMap }from "@/utils/enums";

export default {
    name: "ClientReports",

    components: {
        BaseCard,
    },

    data() {
        return {
            clientStatusMap,
        };
    },

    computed: {
        reportDataProps() {
            return this.$store.getters["report/reportData"].clients;
        },

        transformedData() {
            if (!this.reportDataProps) return {};
            
            const data = {};
            const keys = Object.keys(this.reportDataProps);

            if (!keys && keys.length) return {};

            for (let i = 0; i < keys.length; i++) {
                const item = keys[i];
                const list = this.reportDataProps[item]; // an array

                switch(item) {
                case "clientsByCountry":                    
                    data.clientsByCountry = list.map(x => ([x._id, x.count])) || [];
                    break;
                        
                case "clientsByMonth":
                    data.clientsByMonth = list.map(i => ([this.getMonthFromNum(i?._id), i?.count])) || [];
                    break;

                case "clientsByStatus":
                    data.clientsByStatus = list.map(i => (i?.data)) || [];
                    break;

                case "clientsByIndustry":                    
                    data.clientsByIndustry = list.map(x => ([x._id, x.count])) || [];
                    break;

                case "totalNum":
                    data.totalNum = list.length && list[0]?.count || 0;
                    break;

                default:
                    break;
                }
            }

            return data;
        },

        activeRate() {
            const count = this.statusData.find(x => x.id === "active")?.count;
            const percentageValue = (count / this.transformedData.totalNum) * 100;
            return percentageValue && typeof percentageValue === "number" && percentageValue.toFixed(2) || 0;
        },

        inactiveRate() {
            const count = this.statusData.find(x => x.id === "inactive")?.count;
            const percentageValue = (count / this.transformedData.totalNum) * 100;
            return percentageValue && typeof percentageValue === "number" && percentageValue.toFixed(2) || 0;
        },

        statusData() {
            return this.transformedData.clientsByStatus?.map(x => ({ id: x[0], count: x[1] }));
        },

        totalNum() {
            return this.transformedData?.totalNum;
        },
    },

    methods: {
        getMonthFromNum,
    },
};
</script>

<style lang="scss" scoped>
    .tag {
        border-radius: 50%;
        height: 5px;
        width: 5px;
        padding: 0.1rem;

        &--cornsilk {
           background:  rgb(210, 105, 30)!important;
        }
   
        &--purple {
            background: #6b21a8 !important;
        }
    
        &--pink {
            background: #DA7DA0 !important;
        }
    
        &--red {
            background: rgb(144, 22, 69) !important;
        }
        
        &--green {
            background: #228d2d !important;
        }
    
        &--blue {
            background: #1e40af !important;
        }
    
        &--yellow {
            background: #e8bd0d !important;
        }
    
        &--grey {
            background: #2c3234 !important;
        }
    }
</style>