<template>
    <div class="content__page" :class="{ 'disabled': duplicateLoading || checkUpgradeLoading && checkUpgradeFields.clients }">
        <div>
            <template v-if="pageLoading">
                <PageSpinner />
            </template>

            <template v-else-if="pageLoadingErr">
                <EmptyPage state="error" :handle-submit="handleFetchClient" />
            </template>

            <template v-else>
                <div class="project__header__status justify-content-end w--100">
                    <!-- <BaseButton
                        variant="outline" 
                        inner-style="secondary" 
                        icon-width="16px"
                        label="Schedule meeting" 
                        prefix-icon
                        size="sm" 
                        icon-name="alarm" 
                        class="mr--10"
                        @submit="initiateScheduleMeetings"
                    /> -->

                    <div class="relative flex align-items-center">
                        <div class="dropdown">
                            <div id="invoiceDetailsDropDown" class="pointer" data-bs-toggle="dropdown" aria-expanded="false">
                                <div class="invoice__details__menu">
                                    <IconSvg name="dots-horizontal-rounded" fill="#667085" width="16px" />
                                </div>
                            </div>
    
                            <ul class="dropdown-menu" aria-labelledby="invoiceDetailsDropDown">
                                <li class="dropdown-item" @click="handleDuplicateClient">
                                    <IconSvg name="duplicate" fill="#667085" width="16px" />
                                    <span class="ml--10 text--sm text--color-gray-500">Duplicate client</span>
                                </li>
                                <li class="dropdown-item" @click="toggleArchiveModal(true)">
                                    <IconSvg name="bank" fill="#667085" width="16px" />
                                    <span class="ml--10 text--sm text--color-gray-500">Archive client</span>
                                </li>

                                <li><hr class="dropdown-divider"></li>
                                <li class="dropdown-item" @click="toggleDelete">
                                    <IconSvg name="delete" fill="#e04060" width="16px" />
                                    <span class="ml--10 text--sm text--color-warning">Delete client</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="mt--15 pb--35">
                    <div class="invoice__details__row">
                        <div class="invoice__details__row__left flex-1 pl--0">
                            <div class="invoice__details__header pb--10 pt--10">  
                                <div class="w--100">
                                    <p 
                                        class="text--semi--bold text--capitalize text--color-gray-400 text--2xl pointer"
                                        @click="openDetailsModal('name')"
                                    >
                                        {{ currentClient.name }}
                                    </p>
                                    <div class="project__item project__item__flex mt--5 mb--0">
                                        <span class="project__item__title text--normal mt--0 text--xs">
                                            Added on: {{ $format_date(currentClient.createdAt) }}
                                        </span>
                                        <span class="ml--10 mr--10 project__item__title text--normal"> | </span>
                                        <div class="dropdown">
                                            <div id="statusDropdown" class="pointer" data-bs-toggle="dropdown" aria-expanded="false">
                                                <div class="project__item__title flex align-items-center text--capitalize text--xs">
                                                    <Status type="client" :value="currentClient.status || '-'" />
                                                    <IconSvg name="chevron-down" fill="#667085" width="12px" />
                                                </div>
                                            </div>
            
                                            <ul class="dropdown-menu" aria-labelledby="statusDropdown">
                                                <li 
                                                    v-for="status in CLIENT_STATUSES" 
                                                    :key="status" 
                                                    class="dropdown-item align-items-center" 
                                                    @click="handleStatusUpdate(status)"
                                                >
                                                    <span 
                                                        class="text--xs text--capitalize text--color-gray-500"
                                                        :class="{ 'text--color-primary text--semi--bold': currentClient.status === status }"
                                                    >{{ status }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div class="invoice__details__item pb--10 pt--10">
                                    <div class="col-12 mb--10 flex align-items-center justify-content-between">
                                        <FormLabel title="Client information" html-for="info" label-type="title" />
                                        <BaseButton 
                                            variant="ghost" label="Edit" 
                                            class="text--xs text--color-primary p--0" 
                                            @submit="openDetailsModal('clientDetails')"           
                                        />
                                    </div>

                                    <div>
                                        <div class="mb--10">
                                            <div>
                                                <FormLabel title="Email" html-for="email" label-type="menu" />
                                                <p class="invoice__detail text--sm">
                                                    {{ currentClient.email || 'N/A' }}
                                                </p>
                                            </div>
                                        </div>
                                        
                                        <div class="mb--10">
                                            <div>
                                                <FormLabel title="Industry" html-for="industry" label-type="menu" />
                                                <p class="text--capitalize invoice__detail text--sm">
                                                    {{ currentClient.industry || 'N/A' }}
                                                </p>
                                            </div>
                                        </div>

                                        <div class="mb--10">
                                            <div>
                                                <FormLabel title="Phone number" html-for="phoneNumber" label-type="menu" />
                                                <p class="text--capitalize invoice__detail text--sm">
                                                    {{ currentClient.phoneNumber || '-' }}
                                                </p>
                                            </div>
                                        </div>
    
                                        <div class="mb--10">
                                            <div>
                                                <FormLabel title="Country" html-for="country" label-type="menu" />
                                                <p class="text--capitalize invoice__detail text--sm">
                                                    {{ currentClient.country || "-" }}
                                                </p>
                                            </div>
                                        </div>
    
                                        <div class="mb--10">
                                            <div>
                                                <FormLabel title="City" html-for="city" label-type="menu" />
                                                <p class="text--capitalize invoice__detail text--sm">
                                                    {{ currentClient.city || "-" }}
                                                </p>
                                            </div>
                                        </div>
    
                                        <div class="mb--10">
                                            <div>
                                                <FormLabel title="State" html-for="State" label-type="menu" />
                                                <p class="text--capitalize invoice__detail text--sm">
                                                    {{ currentClient.state || "-" }}
                                                </p>
                                            </div>
                                        </div>
    
                                        <div>
                                            <div>
                                                <FormLabel title="Zip code" html-for="zipCode" label-type="menu" />
                                                <p class="text--capitalize invoice__detail text--sm">
                                                    {{ currentClient.zipCode || "-" }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="invoice__details__item pb--10 pt--10">
                                    <div class="col-12 mb--10 flex align-items-center justify-content-between">
                                        <FormLabel title="Additional emails" html-for="ccEmails" label-type="menu" />
                                        <BaseButton 
                                            variant="ghost" label="Edit" 
                                            class="text--xs text--color-primary p--0 mr--10" 
                                            @submit="openDetailsModal('billingEmails')"            
                                        />
                                    </div>
    
                                    <div>
                                        <div class="email__wrap">
                                            <div v-if="!currentClient.ccEmails.length">
                                                <span class="text--sm text--color-gray-400">
                                                    N/A
                                                </span>
                                            </div>
    
                                            <div v-else class="">
                                                <p v-for="email in currentClient.ccEmails" :key="email" class="sub__tag text--sm text--color-gray-400 mb--5">
                                                    {{ email || '-' }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>                               
                            </div>
                        </div>

                        <div class="invoice__details__row__right flex-2">
                            <div class="client__item__meta">
                                <NonLinkedTabs :list="clientTabList">
                                    <template #nonLinkedTab="data">
                                        <NonLinkedTab :is-active="data.name === 'Notes'">
                                            <ClientNotes />
                                        </NonLinkedTab>
                                        <NonLinkedTab :is-active="data.name === 'Projects'">
                                            <ClientProjects />
                                        </NonLinkedTab>
                                        <NonLinkedTab :is-active="data.name === 'Invoices'">
                                            <ClientInvoices :current-client="currentClient" />
                                        </NonLinkedTab>
                                    </template>
                                </NonLinkedTabs>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <ConfirmDeletionTwo
            type="client" custom-msg 
            :show-modal="showDeleteModal" :loading="deleteClient.loading" 
            @cancel="toggleDelete" @delete="handleDeleteClient"
        >
            <template #custom-msg>
                <p class="text--sm">
                    This client will be removed from all related projects, meetings, and invoices. <strong>This action cannot be undone.</strong>
                </p>
            </template>
        </ConfirmDeletionTwo>

        <ArchiveRecord 
            type="client" 
            :loading="archiveLoading" :show-modal="showArchiveModal" 
            @cancel="toggleArchiveModal" @archive="handleArchiveClient"
        />

        <FollowUpEmailModal
            :show-modal="showFollowUpModal"  
            :form-data="currentClient" 
            @close="showFollowUpModal = false" 
            @send="sendFollowUp"
        />

        <CreateClientModal
            :show-modal="showEditModal" :current-client="currentClient" :current-selection="editModalType"
            action-type="edit" @cancel="resetEditModal" @complete="handleDetailsUpdate"
        />

        <UpgradeAccountModal 
            :show-modal="showUpgradeModal && checkUpgradeFields.clients" type="clients" @cancel="closeUpgradeModal"
        />

        <CreateMeetingModal :show-modal="showScheduleMeetingModal" @close="showScheduleMeetingModal = false" />

        <MainModal 
            :show-modal="showConnectModal" size="sm" title="Connect your calendar"
            @close="showConnectModal = false"
        >
            <template #body>
                <div class="modal__wrapper--onboarding">
                    <div class="modal--section">
                        <div class="">
                            <p class="text--sm">
                                You have no existng calendar integrations. To schedule meetings with clients, add a calendar tool to your account.
                            </p>
                            <p class="text--sm mt--20">
                                Go to <strong>Settings</strong> > <strong>Integrations</strong> >  <strong>Integrations directory</strong> 
                            </p>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <BaseButton 
                        label="Cancel" variant="outline" size="sm"
                        @submit="showConnectModal = false"
                    />
                    <BaseButton label="Add integration" size="sm" @submit="goToIntegrationPage" />
                </div>
            </template>
        </MainModal>
    </div>
</template>

<script>
import {
    IconSvg,
    EmptyPage,
    ConfirmDeletionTwo,
    PageSpinner,
    BaseButton,
    CreateClientModal,
    Status,
    FormLabel,
    FormInput,
    NonLinkedTabs,
    NonLinkedTab,
    ArchiveRecord, 
    UpgradeAccountModal, 
    CreateMeetingModal,
    FollowUpEmailModal, 
    MainModal,
} from "@/components/ui/index";
import { ClientDocuments, ClientInvoices, ClientNotes, ClientProjects } from "@/components/clients/index";
import { industryData, clientTabList } from "@/utils/dummy";
import { CLIENT_STATUSES } from "@/utils/enums";

export default {
    name: "ClientDetailsView",

    components: {
        EmptyPage, 
        CreateMeetingModal,
        IconSvg,
        BaseButton,
        PageSpinner, 
        MainModal,
        ConfirmDeletionTwo, 
        UpgradeAccountModal,
        NonLinkedTabs,
        NonLinkedTab, 
        FormLabel,
        FormInput,
        // ClientDocuments,
        ClientInvoices,
        ClientNotes,
        ClientProjects,
        ArchiveRecord,
        CreateClientModal,
        Status,
        FollowUpEmailModal,
    },

    beforeRouteLeave(to, from, next) {
        // clear stored ongoing invoice if invoice is new
        if (from.name === "create-invoice-view") { 
            localStorage.removeItem("z_invoice_ongoing");
            this.resetInvoiceInfo();
        }
        next();
    },

    data() {
        return {
            CLIENT_STATUSES,
            pageLoading: false,
            pageLoadingErr: false,
            industries: industryData,
            showEditModal: false,
            editModalType: "",
            showDeleteModal: false,
            showArchiveModal: false,
            fetchProjects: {
                loading: false,
                err: false,
                msg: "",
            },
            deleteClient: {
                loading: false,
                err: false,
            },
            loading: false,          
            clientTabList,
            archiveLoading: false,
            showFollowUpModal: false,
            currentClient: {
                ccEmails: [],
            },
            duplicateLoading: false,
            showScheduleMeetingModal: false,
            showConnectModal: false,
            clientName: "",
            updateNameLoading: false,
        };
    },

    computed: {
        userProfile() {
            return this.$store.getters["auth/profile"];
        },

        calendarIntegrations() {
            return this.userProfile?.calendarIntegrations || [];
        },

        enabledIntegrations() {
            return this.calendarIntegrations.filter(x => x?.settings?.syncEnabled);
        },

        showUpgradeModal() {
            return this.$store.getters["app/showUpgradeModal"];
        },

        checkUpgradeLoading() {
            return this.$store.getters["app/checkUpgradeLoading"];
        },

        checkUpgradeFields() {
            return this.$store.getters["app/checkUpgradeFields"];
        },

        activeIntegrations() {
            return this.$store.getters["integration/activeIntegrations"];
        },
    },

    async mounted() {
        await this.handleFetchClient();
    },

    beforeDestroy() {
        this.currentClient = {
            ccEmails: [],
        };

        const storedClient = localStorage.getItem("z_current_client");
        
        if (storedCleient) {
            localStorage.removeItem("z_current_client");
        }
    },

    methods: {
        initiateScheduleMeetings() {
            if (!this.activeIntegrations.length) {
                this.showConnectModal = true;
            } else {
                this.showScheduleMeetingModal = true;
            }
        },

        goToIntegrationPage() {
            return this.$router.push("/dashboard/settings/integrations");
        },

        async handleFetchClient() {
            try {
                this.pageLoadingErr = false;
                this.pageLoadingErrMsg = "";
                this.pageLoading = true;

                const res = await this.$store.dispatch("clients/getClient", this.$route.params.id);
                this.currentClient = {
                    ...res,
                    ccEmails: res.ccEmails ? res.ccEmails.split(",") : [],
                };
                this.clientName = res.name;
                localStorage.setItem("z_current_client", JSON.stringify(this.currentClient));
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
               
                this.pageLoadingErr = true;
                this.pageLoadingErrMsg = errMsg;
            } finally {
                this.pageLoading = false;
            }
        },

        async handleDeleteClient() {
            try {
                const id = this.currentClient?._id || this.$route.params.id;
                
                this.deleteClient.err = false;
                this.deleteClient.loading = true;

                await this.$store.dispatch("clients/deleteClients", [`${id}`]);
                
                // don't await
                this.$store.dispatch("app/fetchActivities", { refresh: true });
                this.$toast.success("Client deleted");

                return this.$router.push("/dashboard/clients");
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                
                this.deleteClient.err = true;
                this.$toast.error(errMsg);
            } finally {
                this.deleteClient.loading = false;
            }
        },

        async handleArchiveClient() {
            try {
                this.archiveLoading = true;

                const id = this.currentClient?._id || this.$route.params.id;

                if (!id) {
                    this.$toast.error("No client id passed");
                    return;
                }

                await this.$store.dispatch("clients/archiveClient", id);
                this.$toast.success("Client archived");

                // don't await
                this.$store.dispatch("app/fetchActivities", { refresh: true });
                
                return this.$router.push({ path: "/dashboard/clients" });
            } catch (error) {
                const errMsg = error?.response?.message || error.message;
                
                this.$toast.error(errMsg);
            } finally {
                this.archiveLoading = false;
            }
        },

        openDetailsModal(type) {
            this.editModalType = type;
            this.showEditModal = true;
        },

        resetEditModal() {  
            this.editModalType = "";
            this.showEditModal = false;
        },

        toggleDelete() {
            this.showDeleteModal = !this.showDeleteModal;
        },

        toggleArchiveModal(bool) {
            this.showArchiveModal = bool;
        },
        
        async handleStatusUpdate(val) {
            try {
                if (val === this.currentClient.status) return;

                this.loading = true;

                const res = await this.$store.dispatch("clients/updateClient", { _id: this.$route.params.id, status: val });
                this.handleDetailsUpdate(res);
                this.$toast.success("Client status updated");
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }
        },

        async handleNameUpdate(event) {
            try {
                const value = event.target.value.trim();
                
                if (value === this.currentClient.name) return;

                this.updateNameLoading = true;

                const res = await this.$store.dispatch("clients/updateClient", { _id: this.$route.params.id, name: value });
                this.handleDetailsUpdate(res);
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.updateNameLoading = false;
            }
        },

        async openFollowUpEmail() {
            this.showFollowUpModal = true; 
        },

        async sendFollowUp() {
            try {
                this.followUpLoading = true;

                const payload = {
                    email: this.currentClient.email || "",
                };

                await this.$store.dispatch("clients/followUpWithClient", payload);
            } catch (error) {
                throw error;
            } finally {
                this.followUpLoading = false;
            }
        },

        handleDetailsUpdate(data) {
            this.currentClient = {
                ...this.currentClient,
                ...data,
                ccEmails: data.ccEmails ? data.ccEmails.split(",") : [],
            };

            this.showEditModal = false;
        },

        closeUpgradeModal() {
            this.$store.dispatch("app/closeUpgradeModal", "clients");
        },

        async handleDuplicateClient() {
            try {
                this.duplicateLoading = true;
                await this.$store.dispatch("app/checkUpgradeStatus", "clients");

                const res = await this.$store.dispatch("clients/duplicateClient", {
                    ...this.currentClient,
                    ccEmails: this.currentClient.ccEmails.join(","),
                });

                if (!res._id) {
                    this.$toast.error("Unable to duplicate client");        
                }

                // don't await
                this.$store.dispatch("app/fetchActivities", { refresh: true });

                return this.$router.push(`/dashboard/clients/${res._id}`);
            } catch (error) {
            } finally {
                this.duplicateLoading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .dropdown-item {
        display: flex;
        align-items: baseline;
        cursor: pointer;

        &:last-of-type {
            padding-bottom: 0.1rem !important;
        }
    }
</style>