<template>
    <div class="svg-icon" :style="styles" @click="$emit('action')">
        <keep-alive>
            <component
                :is="{ template: iconName }" :width="width" :height="height || width" :fill="fill"
                v-bind="$attrs"
            />
        </keep-alive>
    </div>
</template>

<script>
import IconMain from "./Icon.vue";
import { icons } from "../../../utils/icons";
    
export default {
    name: "IconSvg",

    components: {
        IconMain,
    },

    props: {
        name: {
            type: String,
            required: true,
        },

        width: {
            type: String,
            default: "16px",
        },

        height: {
            type: String,
            default: "16px",
        },

        fill: {
            type: String,
            default: undefined,
        }, 

        styles: {
            type: [ String, Object ],
            default: undefined,
        },
    },

    data() {
        return {
            iconMap: icons,
        };
    },

    computed: {
        iconName() {
            return this.iconMap[this.name];
        },
    },
};
</script>

<style scoped lang="scss">
    .svg-icon {
        width: fit-content;
        height: fit-content;
    }
    .svg-icon svg {
        display: block;
    }
</style>