// plugins/toast.js

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import CustomToast from "../components/ui/drawer/Toast";
import { h } from "vue";

const defaultOptions = {
    autoClose: 3000,
    theme: "colored",
    pauseOnFocusLoss: false,
    pauseOnHover: true,
    toastClassName: "toast-class",
    dangerouslyHTMLString: true,
};

export default {
    install(app) {
        const createToastMethod = (type) => (message, options) => {    
            const mergedOptions = { ...defaultOptions, ...options };

            return toast({
                render: (props) =>
                    h(CustomToast, {
                        message,
                        actions: options?.actions || [],
                        links: options?.links || [],
                        ...props,
                    }),
            }, { type, ...mergedOptions },
            );
        };

        app.config.globalProperties.$toast = {
            success: createToastMethod("success"),
            error: createToastMethod("error"),
            warning: createToastMethod("warning"),
            info: createToastMethod("info"),
        };
    },
};