<template>
    <div ref="editor" />
</template>
  
<script>
import Quill from "quill";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.core.css";
  
export default {
    name: "RichTextEditor",

    model: {
        prop: "value",
        event: "input",
    },
  
    emit: ["update:modelValue", "input", "blur", "change", "focus", "cleared"],
  
    props: {
        modelValue: { default: "", type: String },
        disabled: { default: false, type: Boolean },
    },
    
    data() {
        return {
            editor: null,
            isUpdating: false,
        };
    },

    watch: {
        modelValue(newValue) {
            if (this.editor) {
                try {
                    this.isUpdating = true;
                    
                    const currentContent = this.editor && this.editor.root.innerHTML;

                    if (currentContent !== newValue) {
                        // this.editor.clipboard.dangerouslyPasteHTML(newValue);
                        // this.editor.setContents(newValue);
                        this.editor.setText(newValue);
                    }

                    this.isUpdating = false;
                } catch (error) {
                    console.error("Error updating editor content:", error);
                    this.isUpdating = false;
                }
            }
        },
    },
  
    mounted() {
        this.$nextTick(() => {
            this.onLoad();
        });
    },

    beforeDestroy() {
        if (this.editor) {
            this.editor = null;
        }
    },
  
    methods: {
        emitValue() {
            const updatedValue = this.editor.getText() ? this.editor.root.innerHTML : "";
            this.$emit("update:modelValue", updatedValue);
        },
  
        onLoad() {
            try {
                if (!this.$refs.editor) {
                    console.error("Editor ref is not available.");
                    return;
                }

                this.editor = new Quill(this.$refs.editor, {
                    modules: {
                        toolbar: [
                            [{ header: [1, 2, 3, 4, false] }],
                            ["bold", "italic", "underline"],
                        ],
                    },
                    theme: "snow",
                    formats: ["bold", "italic", "underline", "header", "link"],
                    placeholder: "Note here...",
                });

                // this.editor.clipboard.dangerouslyPasteHTML(this.modelValue);

                this.editor.on("selection-change", (range, oldRange, source) => {
                    if (range) {
                        if (range.length == 0) {
                        } else {
                            const text = quill.getText(range.index, range.length);
                        }
                    } else {
                        // console.log("Cursor not in the editor");
                    }
                });

                this.editor.on("text-change", () => {
                    if (!this.isUpdating) {
                        this.emitValue();
                    }
                });

                this.editor.enable(!this.disabled);
            } catch (error) {
                console.error("Error initializing Quill:", error);
            }
        },
    },
};
</script>
  