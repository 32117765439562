<template>
    <div class="sidebar__section__group">
        <p class="sidebar__section__title">
            Stats
        </p>
        <router-link to="/dashboard/reports" class="sidebar__section__item" :class="{ 'active': activeName === 'reports' }">
            <icon-svg class="sidebar__icon mr--5" name="report" icon-position="left" :width="'16px'" />   
            <span class="sidebar__section__group__title">Reports</span>
        </router-link>
        <router-link to="/dashboard/revenue" class="sidebar__section__item" :class="{ 'active': activeName === 'earnings' }">
            <icon-svg class="sidebar__icon mr--5" name="earnings" icon-position="left" :width="'16px'" />   
            <span class="sidebar__item__title">Revenue</span>
        </router-link>
    </div>
</template>

<script>
import IconSvg from "../../ui/icons/Icon-Svg.vue";

export default {
    name: "ReportsNavigation",
    components: {
        "icon-svg": IconSvg,
    },
    props: {
        activeName: {
            type: String,
            default: "",
        },
    },
};
</script>../icons/Icon-Svg.vue