<template>
    <div class="card" :class="[isShadow ? 'card--shadow' : '']" v-bind="$attrs">
        <div v-if="headerTitle" class="card-header text--color-gray-300" :class="[headerClass]">
            {{ headerTitle }}
        </div>
        <div class="card-body" :class="[cardBodyClass]">
            <slot name="card-body" />
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseCard",

    props: {
        headerTitle: {
            type: String,
            default: "",
        },
        
        headerClass: {
            type: String,
            default: "",
        },

        cardBodyClass: {
            type: String,
            default: "",
        },

        isShadow: {
            type: Boolean,
            default: false,
        },
    },
};
</script>