<template>
    <div class="h-100">
        <div class="dashboard--container" :class="{ 'disabled': logOutLoading }">
            <template v-if="pageLoading">
                <PageSpinner />
            </template>

            <template v-else-if="pageLoadingErr">
                <div class="mt--40">
                    <EmptyPage state="error" :handle-submit="loadAllApiRequests" />
                </div>
            </template>

            <template v-else>
                <Sidebar 
                    :is-collapse="isCollapse"
                    :show-settings-sidebar="showSettingsSidebar"
                    :sidebar-width="sidebarWidth"
                    @toggle="toggleSidebar"
                    @open-rank="toggleRankModal(true)"
                />

                <DashboardContent
                    :is-collapse="isCollapse"
                    :show-settings-sidebar="showSettingsSidebar"
                    @toggle="toggleSidebar"
                />
            </template>
        </div>

        <Rank :show-modal="showRankModal" @cancel="toggleRankModal(false)" />

        <PageLoadingModal :show-modal="pageWrapperLoading" />
        <OnboardingModal :show-modal="isNewUser" @continue="closeOnboardingModal" />
    </div>
</template>

<script>
import DashboardContent from "../layouts/DashboardContent.vue";
import { 
    Rank, 
    EmptyPage,
    Sidebar,
    BaseButton,
    PageSpinner,
    PageLoadingModal, OnboardingModal,
} from "@/components/ui/index";
import { assembleQueryList } from "@/utils/helpers";

export default {
    name: "AppDashboard",

    components: {
        Sidebar,
        DashboardContent,
        OnboardingModal,
        EmptyPage,
        Rank,
        PageSpinner,
        PageLoadingModal,
    },

    emits: ["openRank"],

    data() {
        return {
            isCollapse: false,
            sidebarWidth: "220px",
            showWelcomeModal: false,
            pageLoading: false,
            pageLoadingErr: false,
            showSettingsSidebar: false,
        };
    },

    computed: {
        logOutLoading() {
            return this.$store.getters["auth/logOutLoading"];
        },

        pageWrapperLoading() {
            return this.$store.getters["app/pageWrapperLoading"];
        },

        isAuthenticated() {
            return this.$store.getters["auth/isAuthenticated"];
        },

        showRankModal() {
            return this.$store.getters["app/showRankModal"];
        },

        showConfetti() {
            return this.$store.getters["app/showConfetti"];
        },

        isNewUser() {
            return this.$store.getters["app/isNewUser"];
        },
    },

    watch: {
        showConfetti(newVal) {            
            if (newVal) {
                this.$confetti.start();
                this.$store.dispatch("app/toggleRank", true);

                setTimeout(() => {
                    this.$store.dispatch("app/toggleRankConfetti", false);
                    this.$confetti.stop();
                }, 5000);
            }
        },

        "$route.path": {
            handler(newPath) {
                if (newPath.includes("/dashboard/settings")) {
                    this.showSettingsSidebar = true;
                } else {
                    localStorage.setItem("lastVisited", newPath);
                    this.showSettingsSidebar = false;
                }
            },
            deep: true,
            immediate: true,
        },
    },

    async mounted() {        
        await this.loadAllApiRequests();
    }, 

    methods: {
        assembleQueryList,

        toggleSidebar() {
            if (this.isCollapse === false) {
                this.isCollapse = true;
                this.sidebarWidth = "30px";
            } else {
                this.isCollapse = false;
                this.sidebarWidth = "220px";
            }
        },

        setShowWelcomeModal() {
            if(this.$route.query?.new_user) {
                this.showWelcomeModal = true; 
            } else {
                this.showWelcomeModal = false;
            }
        },

        async handleFetchUserProfile() {
            try {
                await this.$store.dispatch("auth/fetchProfile", { refresh: true });
                await this.$store.dispatch("app/fetchSettings", { refresh: true });
            } catch (error) {
                throw error;
            }
        },

        async handleFetchProjects() {
            try {
                const buildResult = assembleQueryList({ page: 1, type: "active" });
                await this.$store.dispatch("project/fetchProjects", { params: buildResult, refresh: true });
            } catch (error) {
                throw error;
            }
        },

        async handleFetchClients() {
            try {
                const buildResult = assembleQueryList({ page: 1, type: "all" });
                await this.$store.dispatch("clients/fetchClients", { params: buildResult, refresh: true });
            } catch (error) {
                throw error;
            }
        },

        async handleFetchInvoices(refresh = true) {
            try {
                const buildResult = assembleQueryList({ page: 1, type: "active" });
                await this.$store.dispatch("invoice/fetchInvoices", { params: buildResult, refresh });
            } catch (error) {
                throw error;
            }
        },

        async handleFetchRateCards() {
            try {                
                await this.$store.dispatch("ratecards/setRatesPagination", 1);
                await this.$store.dispatch("ratecards/fetchRateCards", { params: { page: 1 }, refresh: true });
            } catch (error) {
                throw error;
            }
        },

        async handleFetchTasks() {
            try {
                const groupSelection = localStorage.getItem("z_group_task_selection");

                const pageQueryObj = {
                    page: 1,
                    groupBy: groupSelection || "",
                };

                const buildResult = assembleQueryList(pageQueryObj);
                await this.$store.dispatch("task/fetchTasks", { params: buildResult, refresh: true, groupBy: groupSelection || "" });
            } catch (error) {
                throw error;
            }
        },

        async handleFetchActiveIntegrations() {
            try {
                const buildResult = assembleQueryList({ page: 1 });
                await this.$store.dispatch("integration/fetchUserIntegrations", { params: buildResult, refresh: true });
            } catch (error) {
                throw error;
            }
        },

        async loadAllApiRequests() {
            try {
                this.pageLoadingErr = false;
                this.pageLoading = true;
                
                await Promise.all([
                    this.handleFetchUserProfile(),
                    this.handleFetchProjects(),
                    this.handleFetchClients(),
                    this.handleFetchInvoices(),
                    this.handleFetchRateCards(),
                    this.handleFetchTasks(),
                    this.handleFetchActiveIntegrations(),
                ]);
            } catch (error) {
                this.pageLoadingErr = true;
            } finally {
                this.pageLoading = false;
            }
        },

        toggleRankModal(bool) {
            this.$store.dispatch("app/toggleRank", bool);
        },

        closeOnboardingModal() {
            this.$store.dispatch("app/toggleNewUser", false);
        },
    },
};
</script>

<style scoped lang="scss">
    .dashboard--container {
        width: 100%;
        height: 100%;
        overflow-x: hidden;
    }
</style>