<template>
    <div class="h-100">
        <div class="google-iframe-auth" :class="{ 'disabled': loading }" :style="{ width: actualWidth + 'px' }">
            <div
                v-if="oneTap"
                id="g_id_onload"
                :data-client_id="clientId"
                data-callback="googleAuthHandler"
                data-ux_mode="popup"
                :data-auto_select="autoTap"
                data-itp_support="true"
            />
            <div
                ref="signIn"
                class="g_id_signin"
                data-type="standard"
                data-shape="pill"
                data-size="large"
                data-theme="outline"
                data-text="continue_with"
                data-logo_alignment="center"
                :data-locale="locale"
                :data-width="actualWidth"
                data-button-style-id="google"
            />
            <!-- <button class="google-auth-btn" @click="handleGoogleLogin">
                <img src="https://developers.google.com/identity/images/g-logo.png" alt="Google Logo" class="google-logo">
                Sign in with Google
            </button> -->
        </div>
    </div>
</template>

<script>
import env from "@/config/env";

export default {
    name: "GoogleAuthBtn",

    props: {
        width: {
            type: String,
            default: "360",
        },

        autoTap: {
            type: Boolean,
            default: false,
        },
        loading: Boolean,
        redirectUri: {
            type: String,
            default: "",
        },
        scope: {
            type: String,
            default: "",
        },
    },

    emits: ["authenticated"],

    data() {
        return {
            oneTap: true,
            clientId: env.VUE_APP_GOOGLE_AUTH_CLIENT_ID,
        };
    },
    
    computed: {
        locale() {
            return this.$store.getters.locale;
        },
        actualWidth() {
            return window.innerWidth <= 500 ? "250" : this.width;
        },
    },

    mounted() {
        const input = document.getElementsByTagName("input")[0];
        
        if (input) {
            input.focus();
        }   
                
        this.loadGoogleScript();
    },

    beforeMount() {
        window.googleAuthHandler = this.googleAuthHandler;
    },

    methods: {
        loadGoogleScript() {
            const googleScript = document.getElementById("google-script");
            
            if (!googleScript) {
                const script = document.createElement("script");
                script.id = "google-script";
                script.src = "https://accounts.google.com/gsi/client";
                script.async = true;
                script.defer = true;
                script.onload = this.initializeGoogleSignIn;
                document.head.appendChild(script);
            } else {
                this.initializeGoogleSignIn();
            }
        },

        initializeGoogleSignIn() {
            if (window.google && window.google.accounts) {
                window.google.accounts.id.initialize({
                    client_id: this.clientId,
                    callback: this.googleAuthHandler,
                    ux_mode: "popup", // Ensure pop-up mode is set
                    auto_select: false,
                    itp_support: true,
                });

                // Render the button
                window.google.accounts.id.renderButton(this.$refs.signIn, {
                    theme: "outline",
                    size: "large",
                    width: this.actualWidth,
                });

                // Trigger One Tap manually
                window.google.accounts.id.prompt();
            } else {
                console.error("Google API not loaded.");
            }
        },

        googleAuthHandler(resp) {
            this.$emit("authenticated", resp);
        },
    },
};
</script>

<style lang="scss" scoper>
    .g_id_signin, #g_id_onload {
        width: 100%;
    }

    .google-auth-btn {
        border-radius: 20px;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 10px;
        justify-content: center;
        background: white;
        border: 1px solid #eee;

        img {
            height: 100%;
            max-height: 100%;
            margin-right: 10px;
        }
    }
</style>