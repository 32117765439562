<template>
    <div>
        <MainModal 
            :show-modal="showModal" 
            size="sm" :title="titleCopy" @close="onCancel"
        >
            <template #body>
                <form :class="{ 'project--form': currentFieldsLength > 6 }" @submit.prevent="">
                    <div class="client__modal__body scroll-height">
                        <FormInput 
                            v-if="hasField('title')"
                            id="title" v-model="form.title"
                            type="text" size="sm" info-type="required"
                            html-for="title" label="Title"
                        />

                        <FormInput 
                            v-if="hasField('description')"
                            id="title" v-model="form.description"
                            type="text" size="sm" html-for="description" label="Description"
                        />

                        <div v-if="hasField('priority')" class="mb--15">
                            <FormLabel title="Priority" html-for="priority" />
                            <select v-model="form.priority" class="form-select form-select-sm text--sm text--capitalize">
                                <option v-for="priority in priorities" :key="priority" :value="priority">
                                    {{ priority }}
                                </option>
                            </select>
                        </div>

                        <div v-if="hasField('startDate') || hasField('deadline')" class="form__row mb--15">
                            <div class="flex-1 mr--5">
                                <FormLabel title="Start date" />
                                <v-date-picker v-model="form.startDate" class="text--sm">
                                    <template #default="{ inputValue, togglePopover }">
                                        <input
                                            class="px-2 py-1 border rounded due_date form-control form-control-sm text--sm"
                                            :value="inputValue"
                                            @click="togglePopover"
                                        >
                                    </template>
                                </v-date-picker>
                            </div>

                            <div class="flex-1">
                                <FormLabel title="Due date" />
                                <v-date-picker v-model="form.deadline" class="text--sm">
                                    <template #default="{ inputValue, togglePopover }">
                                        <input
                                            class="px-2 py-1 border rounded due_date form-control form-control-sm text--sm"
                                            :value="inputValue"
                                            @click="togglePopover"
                                        >
                                    </template>
                                </v-date-picker>
                            </div>
                        </div>

                        <div v-if="hasField('status')" class="mb--15">
                            <FormLabel title="Status" html-for="status" />
                            <select v-model="form.status" class="form-select form-select-sm text--sm text--capitalize" aria-label="Default select example">
                                <option v-for="status in statuses" :key="status" :value="status">
                                    {{ status }}
                                </option>
                            </select>
                        </div>

                        <div v-if="hasField('isFavourite')" class="mb--15">
                            <FormLabel title="Favourite this project?" html-for="important" />
                            <select v-model="form.isFavourite" class="form-select form-select-sm text--capitalize" aria-label="Default select example">
                                <option v-for="item in favorites" :key="item" :value="item">
                                    {{ item }}
                                </option>
                            </select>
                        </div>

                        <div v-if="hasField('projectType')" class="mb--15">
                            <FormLabel title="Is this a paid project?" html-for="important" />
                            <select v-model="form.projectType" class="form-select form-select-sm text--capitalize">
                                <option v-for="item in ['paid', 'not paid']" :key="item" :value="item" class="text--capitalize">
                                    {{ item }}
                                </option>
                            </select>
                        </div>

                        <template v-if="hasField('projectType') && form.projectType === 'paid'">
                            <div class="flex justify-content-end">
                                <Base-Button 
                                    inner-style="primary" :label="!useRateCard ? 'Use rate cards' : 'Use a one-time estimate'"
                                    class="p--0" size="sm" variant="ghost" @submit="togglePaymentSelection" 
                                />
                            </div>

                            <div v-if="hasField('currency')" class="mb--15">
                                <FormLabel title="Currency" html-for="currency" />
                                <CustomDropdown 
                                    id="currencyDropdown" v-model="form.currency" :options="initialCurrencyList"
                                    list-type="currencies" index-label="currency" :add-on="selectedCurrency.symbol"
                                    @search="searchCurrencies" @select="selectCurrency" 
                                />
                            </div>

                            <template v-if="!useRateCard">
                                <div v-if="hasField('billableHours')" class="mb--15">
                                    <FormLabel title="Billable Hours" html-for="billableHours" />
                                    <money
                                        v-model="form.billableHours" v-bind="billableHoursConfig"
                                        class="form-control form-control-sm" spellcheck="false"
                                        @keydown.native="preventKeys"
                                    />
                                </div>

                                <div v-if="hasField('nonBillableHours')" class="mb--15">
                                    <FormLabel title="Non-billable Hours" html-for="nonBillableHours" />
                                    <money
                                        v-model="form.nonBillableHours" v-bind="billableHoursConfig"
                                        class="form-control form-control-sm" spellcheck="false"
                                        @keydown.native="preventKeys"
                                    />
                                </div>

                                <div v-if="hasField('estimatedRevenue')" class="mb--15">
                                    <FormLabel title="Estimated Revenue" html-for="estimatedRevenue" />
                                    <money
                                        v-model="form.estimatedRevenue" v-bind="estimatedRevenueConfig"
                                        class="form-control form-control-sm" spellcheck="false"
                                        @keydown.native="preventKeys"
                                    />
                                </div>
                            </template>

                            <template v-else>
                                <FormLabel title="Select one or more rate cards" html-for="rateCards" />
                                <CustomDropdown 
                                    id="rateCardDropdown" v-model="form.rateCards" :options="rateCards"
                                    list-type="rate cards" index-label="title" multiple :loading="dropdownLoading"
                                    @search="searchRateCards" @select="addRateCard" 
                                />
                            </template>
                        </template>

                        <div v-if="hasField('trackProject')" class="mb--15">
                            <FormLabel title="Track Project?" html-for="important" />
                            <select v-model="form.trackProject" class="form-select form-select-sm text--capitalize">
                                <option v-for="item in ['Yes', 'No']" :key="item" :value="item" class="text--capitalize">
                                    {{ item }}
                                </option>
                            </select>
                        </div>

                        <template v-if="form.trackProject === 'Yes'">
                            <div v-if="hasField('trackingType')" class="mb--15">
                                <FormLabel title="Tracking type" html-for="important" />
                                <select v-model="form.trackingType" class="form-select form-select-sm text--capitalize">
                                    <option v-for="item in ['automatic']" :key="item" :value="item" class="text--capitalize">
                                        {{ item }}
                                    </option>
                                </select>
                            </div>

                            <div v-if="form.trackingType === 'manual'" class="mb--15">
                                <div class="form__row">
                                    <div class="flex-1 mr--5">
                                        <FormLabel title="Start time" html-for="important" />
                                        <v-date-picker
                                            v-model="startTime" class="text--sm" mode="dateTime" color="blue"
                                            name="dueDate" nav-visibility="click"
                                        >
                                            <template #default="{ inputValue, togglePopover }">
                                                <input
                                                    class="px-2 py-1 border rounded due_date form-control form-control-sm text--sm"
                                                    :value="inputValue"
                                                    @click="togglePopover"
                                                >
                                            </template>
                                        </v-date-picker>
                                    </div>
                                    <div class="flex-1 mr--5">
                                        <FormLabel title="End time" html-for="important" />
                                        <v-date-picker
                                            v-model="endTime" class="text--sm" mode="dateTime" color="blue"
                                            name="dueDate" nav-visibility="click"
                                        >
                                            <template #default="{ inputValue, togglePopover }">
                                                <input
                                                    class="px-2 py-1 border rounded due_date form-control form-control-sm text--sm"
                                                    :value="inputValue"
                                                    @click="togglePopover"
                                                >
                                            </template>
                                        </v-date-picker>
                                    </div>
                                </div>
                            </div>

                            <!-- <div v-if="hasField('trackingDays')" class="mt--15">
                                <FormLabel title="Tracking days" html-for="important" />
                                <div>
                                    <div v-for="day in trackingDays" :key="day" class="form-check form-check-inline">
                                        <input
                                            id="flexCheckDefault" v-model="form.trackingDays" 
                                            :value="day" class="form-check-input" type="checkbox"
                                        >
                                        <label class="form-check-label text--xs text--capitalize" for="flexCheckDefault">
                                            {{ day }}
                                        </label>
                                    </div>
                                </div>
                            </div> -->
                        </template>

                        <div v-if="hasField('reminders') && form.deadline" class="mt--15">
                            <FormLabel title="Set reminders" html-for="important" />
                            <div>
                                <div v-for="day in invoiceReminders" :key="day" class="form-check form-check-inline">
                                    <input
                                        :id="`${day.id}Id`" v-model="form.reminders" 
                                        :value="day.id" class="form-check-input" type="checkbox"
                                    >
                                    <label class="form-check-label text--xs text--capitalize" :for="`${day.id}Id`">
                                        {{ day.name }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </template>

            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <BaseButton 
                        class="mr--5" label="Cancel" variant="outline" size="sm"
                        @submit="onCancel"
                    />
                    <BaseButton 
                        type="submit" size="sm" :label="btnCopy"
                        :loading="loading" :disabled="loading" 
                        @submit="handleSubmit"
                    />
                </div>
            </template>
        </MainModal>
    </div>
</template>

<script>
import MainModal from "./MainModal.vue";
import { projectFilterStatuses, priorities } from "@/utils/enums";
import { invoiceReminders, days } from "@/utils/dummy";
import FormLabel from "../forms/FormLabel.vue";
import BaseButton from "../buttons/BaseButton.vue";
import FormInput from "../input/FormInput.vue";
import CustomDropdown from "../dropdowns/CustomDropdown.vue";
import IconSvg from "../icons/Icon-Svg.vue";
import { assembleQueryList } from "@/utils/helpers";
import { updateProjectDetailsFields } from "@/components/auth/auth";

export default {
    name: "CreateProjectModal",

    components: {
        MainModal,
        FormLabel,
        BaseButton, IconSvg,
        FormInput, CustomDropdown,
    },

    props: {
        showModal: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        actionType: { type: String, default: "add" },
        currentProject: { type: Object, default: () => {} },
        currentSelection: { type: String, default: "create" },
    },

    data() {
        return {
            statuses: projectFilterStatuses,
            form: {
                _id: "",
                title: "",
                description: "",
                status: "to do",
                deadline: null,
                startDate: new Date(),
                priority: "low",
                projectType: "",
                isFavourite: "no",
                tags: [],
                clients: [],
                rateCards: [],
                currency: "",
                billableHours: 0,
                nonBillableHours: 0,
                estimatedRevenue: 0,
                reminders: [],
                trackProject: "",
                trackingDays: [],
                trackingType: "",
            },
            priorities,
            invoiceReminders,
            favorites: ["no", "yes"],
            initialCurrencyList: [],
            billableHoursConfig: {
                decimal: ".",
                thousands: ",",          // The currency name or symbol followed by a space.
                precision: 0,
                masked: false,
            },
            dropdownLoading: false,
            useRateCard: false,
            fields: updateProjectDetailsFields,
            selectedCurrency: {
                currency: "", 
                symbol: "",
            },
            currencyMap: null,
            trackingDays: days,
            startTime: null,
            endTime: null,
        };
    },

    computed: {
        titleCopy() {
            return this.fields[this.currentSelection]?.title || "";
        },

        clients() {
            return this.$store.getters["clients/clients"] || [];
        },

        accountPlan() {
            return this.$store.getters["auth/profile"]?.accountPlan;
        },

        appCurrency() {
            return this.$store.getters["app/userSettings"]?.general?.currency;
        },

        btnCopy() {
            return this.fields[this.currentSelection]?.cta || "Create project";
        },

        rateCards() {
            return this.$store.getters["revenue/rateCards"]?.list;
        },

        estimatedRevenue() {
            if (!this.form.rateCards.length) return 0;

            const total = this.form.rateCards.reduce((previous, current) => previous + current.amount, 0);

            return total;
        },

        currentFieldsLength() {
            if (!this.fields || !this.currentSelection) return 0;

            return Object.keys(this.fields[this.currentSelection].fields).length;
        },

        estimatedRevenueConfig() {
            const selectItem = this.selectedCurrency?.currency && this.selectedCurrency || this.currentProject;
            const selectCurrency = selectItem?.currency?.toLowerCase();

            if (!selectCurrency || !this.currencyMap) {
                return {
                    precision: 0,
                    decimal: ".",
                    thousands: ",",
                    masked: false,
                    prefix: "",
                };
            }

            return {
                precision: 0,
                decimal: ".",
                thousands: ",",
                masked: false,
                prefix: this.currencyMap[selectCurrency].symbol,
            };
        },
    },

    watch: {
        currentProject: {
            handler(newVal) {
                if (newVal?._id) {
                    this.prefillForm(newVal);
                }
            },
            immediate: true,
            deep: true,
        },
        "form.trackProject": {
            handler(newVal, oldVal) {
                if (oldVal === "Yes" && newVal === "No") {
                    this.form.trackingDays = [];
                }

                if (oldVal === "No" && newVal === "Yes") {
                    this.form.trackingDays = this.currentProject.trackingDays;
                }
            },
        },
    },

    mounted() {
        this.currencyMap = this.$supported_currencies_map;
        this.initialCurrencyList = Object.values(this.$currencies_obj);
    },

    methods: {
        hasField(field) {
            return !this.fields[this.currentSelection] ? false : this.fields[this.currentSelection].fields[field]; 
        },

        populateFormBasedOnSelection(val) {
            const selectedItem = this.fields[val]?.fields;

            for (const key in selectedItem) {
                if (this.currentProject.hasOwnProperty(key)) {
                    this.form[key] = this.currentProject[key];
                }
            }
        },

        selectCurrency(item) {
            const index = item && item.toLowerCase() || "";

            this.selectedCurrency = this.currencyMap[index];
            this.form.currency = item;
        },

        searchCurrencies(val) {
            const initialList = this.initialCurrencyList;

            if (val) {
                const filteredList = initialList.filter(item => item.toLowerCase().includes(val));
                this.initialCurrencyList = filteredList;
            } else {
                this.initialCurrencyList = Object.values(this.$currencies_obj);
            }
        },

        preventKeys(event) {
            if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 190 || event.keyCode == 37 || event.keyCode == 39) {
            } else {
                return event.preventDefault();
            }
        },

        prefillForm(val) {
            this.form = {
                ...this.form,
                ...val,
                isFavourite: val?.isFavourite ? "yes" : "no",
                currency:  val?.currency || this.appCurrency,
                billingType:  val.billingType || "flat",
                trackProject: val.trackProject ? "Yes" : "No",
                nonBillableHours: val.nonBillableHours || "table",
            };
        },

        addTag(newTag) {
            this.form.tags.push(newTag);
        },

        addRateCard(item) {
            this.form.rateCards = [ ...this.form.rateCards, item ];
        },

        removeRateCard(item) {
            this.form.rateCards = this.form.rateCards.filter(x => x._id !== item._id);
        },

        resetForm() {
            this.form = {
                _id: "",
                title: "",
                description: "",
                status: "",
                deadline: null,
                priority: "",
                projectType: "",
                isFavourite: "no",
                tags: [],
                clients: [],
                rateCards: [],
                currency: "",
                billableHours: 0,
                nonBillableHours: 0,
                estimatedRevenue: 0,
                reminders: [],
                trackProject: "",
                trackingDays: [],
                trackingType: "",
            };

            this.selectedCurrency = {
                currency: "", 
                symbol: "",
            };
        },

        onCancel() {
            if (this.actionType === "add") {
                this.resetForm();
            }
            this.$emit("close");
        },

        setSelected(value) {
            this.form.clients = [ value, ...this.form.clients ];
        },

        buildPayload() {
            const payload = {
                title: this.form.title,
                description: this.form.description,
                status: this.form.status?.toLowerCase(),
                startDate: this.form.startDate,
                deadline: this.form.deadline,
                priority: this.form.priority,
                projectType: this.form.projectType?.toLowerCase() || "not paid",
                billingType: this.form.billingType || "flat",
                estimatedRevenue: this.form.estimatedRevenue,
                isFavourite: this.form.isFavourite === "no" ? false : true,
                currency: this.form.currency,
                billableHours: Number(this.form.billableHours || ""),
                reminders: this.form.reminders,
                trackProject: this.form.trackProject === "Yes" ? true : false,
                trackingDays: this.form.trackingDays,
                trackingType: this.form.trackingType,
            };

            if (this.actionType === "edit" && this.form._id) {
                payload._id = this.form._id;
            }

            if (this.actionType === "add") {
                payload.clientIds = this.form.clients.length ? this.form.clients.map(x => x._id) : []; // we need only client ids
                payload.tagIds = this.form.tags.length ? this.form.tags.map(x => x._id) : [];
                payload.rateCardIds = this.form.rateCards.length ? this.form.rateCards.map(x => x._id) : [];
            }

            if (this.currentSelection === "trackingDetails" && this.form.trackProject) {
                payload.timeTrackingLogs = this.form.trackingType === "automatic" ? [] : [
                    {
                        startTime: this.startTime,
                        endTime: this.endTime,
                    },
                ];
            }
            return payload;
        },

        async handleSubmit() {
            const payload = this.buildPayload();
            const data = { payload, resetFn: this.resetForm };
            
            await this.$emit("complete", data);    
        },

        async searchRateCards(params) {
            try {
                this.dropdownLoading = true;

                const pageQueryObj = {
                    page: 1,
                };

                if (params.title) {
                    pageQueryObj.title = params.title;
                }

                const buildResult = assembleQueryList(pageQueryObj);

                await this.$store.dispatch("revenue/fetchRateCards",  { payload: buildResult, refresh: params?.refresh });
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
            } finally {
                this.dropdownLoading = false;
            }
        },

        setReminders() {
            this.form.reminders = [];

            for (let i = 0; i < this.remindersNames.length; i++) {
                this.form.reminders.push({
                    remindersName: this.remindersNames[i],
                    reminderDate: new Date(),
                });
            } 
        },

        togglePaymentSelection() {
            if (this.useRateCard) {
                this.useRateCard = false;
                this.form.rateCards = [];
            } else {
                this.useRateCard = true;
                this.form.billableHours = 0;
                this.form.estimatedRevenue = 0;
            }
        },

        upgradeAccount() {
            this.onCancel();
            this.$router.push("/dashboard/settings/billings-and-plans");
        },
    },
};
</script>

<style scoped lang="scss">
.project--form {
    height: 500px;
    overflow: scroll;
    padding: 0 5px;
}
</style>