<template>
    <div class="multiple__item email" v-bind="$attrs">
        <div class="email--wrap" :class="{ 'flex align-items-center': isFlex }">
            <div v-if="maxLimit > items.length" class="form__item mb--20" :class="{ 'mr--10': isFlex }">
                <div class="input-group">
                    <input
                        v-model="emailToBeAdded"
                        name="email" type="email"
                        class="form__input" :readonly="disabled" 
                        aria-describedby="basic-addon2" @keyup.enter="add"
                    >
                </div>
            </div>

            <div v-if="items.length" class="multiple__emails ml--0" :class="{ 'mt--10': !isFlex }">
                <div v-for="item in items" :key="item" class="email--item">
                    <span class="inlineBlock">{{ item }}</span>
                    <span class="pointer inline-block" :class="{ 'disabled': disabled }" type="button" @click="remove(item)">
                        <svg
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M4.25837 4.02991L1.08967 0.861205C0.892799 0.664505 0.892799 0.345351 1.08967 0.14865C1.28654 -0.0482175 1.60535 -0.0482175 1.80222 0.14865L4.97092 3.31735L8.13979 0.14865C8.33666 -0.0482175 8.65548 -0.0482175 8.85235 0.14865C9.04922 0.345351 9.04922 0.664505 8.85235 0.861205L5.68348 4.02991L8.85235 7.19861C9.04922 7.39531 9.04922 7.71446 8.85235 7.91116C8.75391 8.00943 8.62491 8.05865 8.49607 8.05865C8.36723 8.05865 8.23823 8.00943 8.13979 7.911L4.97092 4.74229L1.80222 7.911C1.70379 8.00943 1.57478 8.05865 1.44594 8.05865C1.31711 8.05865 1.1881 8.00943 1.08967 7.911C0.892799 7.7143 0.892799 7.39514 1.08967 7.19844L4.25837 4.02991Z"
                                fill="#696969"
                            />
                        </svg>
                    </span>
                </div>
            </div>
        </div>
        <p class="text--xs mt--10" style="color: #6a788a;">
            Click enter to add an email address. You can add up to <strong>{{ maxLimit }}</strong> email addresses.
        </p>
    </div>
</template>

<script>
import FormLabel from "../forms/FormLabel.vue";

export default {
    name: "MultipleBillingEmails",
    
    props: {
        label: {
            type: String,
            default: "Other billing emails",
        },
        maxLimit: {
            type: Number,
            default: 3,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isFlex: {
            type: Boolean,
            default: true,
        },
        items: {
            type: Array,
            default: () => [],
        },
    },

    emits: ["add", "delete"],
    
    data() {
        return {
            emailToBeAdded: "",
        };
    },

    beforeDestroy() {
        this.emailToBeAdded = "";
    },

    methods: {
        remove(email) {
            if (this.disableRemoveEmailBtn) {
                return;
            };

            this.$emit("delete", email);
        },
        
        add() {
            if (!this.emailToBeAdded) return;

            this.$emit("add", this.emailToBeAdded);
            this.emailToBeAdded = "";
        },
    },
};
</script>

<style lang="scss" scoped>
   .form__input {
        width: 100%;
        border-radius: 0px !important;
        border: none !important;
        background-color: var(--white, #FFF) !important;
        font-size: 0.875rem !important;
        border-bottom: 1px solid #eee !important;
    }

    .multiple__item {
        width: 100%;
        margin-bottom: 0.5rem;

        &.email {
            align-items: flex-end !important;
        }
    }

    .email {
        &--wrap {
            max-width: 100%;
            border-radius: 4px;
            border: 1px solid var(--gray-300, #E0E0E0);
            background-color: var(--white, #FFF);
            font-size: 0.875rem !important;
            padding: 8px 10px;
        }

        &--item {
            display: flex;
            align-items: center;
            background: #c7d2f5;
            border-radius: 50px;
            padding: 3px 8px;
            justify-content: center;
            font-size: 14px;
            margin-right: 5px;
            margin-bottom: 10px;
            width: fit-content;

            span {
                &:first-of-type {
                    margin-right: 5px;
                }
            }
        }
    }
    .multiple__emails {
        margin-left: 2rem;
        // max-width: 70%;
        // display: flex;
        // flex-wrap: wrap;
        // width: fit-content;
        // width: 70%;
        width: 100%;
    }

    .input-group-text {
        color: #596DDE;
        background: #f2ebff;
        padding-bottom: 5px;
        padding-top: 5px;
        font-size: 14px;
    }
</style>