<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <div class="h--100 relative min-h-inherit">
        <div class="page--wrap" :class="{ 'disabled': deleteLoading, 'min-h-inherit': !invoices.length }">
            <template v-if="pageLoading">
                <PageSpinner />
            </template>

            <template v-else-if="fetchInvoicesErr">
                <EmptyPage state="error" :handle-submit="handleFetchInvoices" />
            </template>

            <template v-else>
                <div class="flex align-items-center justify-content-end width-100">
                    <div class="filter__actions--list">
                        <template v-if="showActionBtns">
                            <div v-if="selectedInvoices.length > 0" class="mr--5">
                                <BaseButton variant="warning" size="sm" @submit="toggleDelete">
                                    Delete {{ selectedInvoices.length }} {{ selectedInvoices.length === 1 ? 'invoice' : 'invoices' }}
                                </BaseButton>
                            </div>
                            <main-filter page-type="invoices" class="mr--5" />
                            <BaseButton 
                                label="+ Add invoice" :disabled="checkUpgradeLoading && checkUpgradeFields.invoices" 
                                size="sm" :show-spinner="false" @submit="initiateCreate"
                            />
                        </template>
                    </div>
                </div>

                <div class="w--100" :class="{ 'min-h-inherit justify-content-center flex align-items-center': !invoices.length }">
                    <template v-if="!invoices.length">
                        <empty-page v-if="isSearched" state="no_result" />
                        <empty-page v-else state="empty" icon-name="invoice" type="invoice" width="60px" height="60px">
                            <template #button>
                                <BaseButton 
                                    label="+ New invoice" size="sm" :show-spinner="false"
                                    :disabled="checkUpgradeLoading && checkUpgradeFields.invoices" @submit="initiateCreate"
                                />
                            </template>
                        </empty-page>
                    </template>
                    
                    <template v-else>
                        <div class="flex mt--40">
                            <CustomTable
                                :columns="invoiceHeaders"
                                :data="invoices"
                                row-type="link"
                                :selected-for-deletion="selectedInvoices"
                                router-name="invoice-details-view"
                                is-action-btns table-type="striped"
                                @onRowClick="goToInvoiceDetailsPage"
                                @onRowSelected="selectInvoices"
                            >
                                <template #rows="data">
                                    <td class="first">
                                        {{ data?.client?.name || "-" }}
                                    </td>
                                    <td class="first">
                                        {{ data?.client?.email || "-" }}
                                    </td>
                                    <td>
                                        <Status type="invoice" :value="data.status" />
                                    </td>
                                    <td class="text--upper">
                                        {{ getSymbol(data.currency).symbol }}{{ $format_number(data.totalAmount) }}
                                    </td>
                                    <td>#{{ data.invoiceNo }}</td>
                                    <td>{{ $format_date(data.dueDate) }}</td>
                                    <td>{{ $format_date(data.createdAt) || "-" }}</td>
                                </template>
                            </CustomTable>
                        </div>

                        <div class="table__striped__footer mt--5">
                            <p class="table__striped__footer__results">
                                {{ invoices.length }} results
                            </p>
                            <pagination v-if="showPagination" :pagination-data="pageData" />
                        </div>
                    </template>
                </div>
            </template>
        </div>

        <ConfirmDeletionTwo
            :show-modal="showDeleteModal"
            type="invoice" custom-msg
            :items="selectedInvoices"
            :loading="deleteLoading" 
            @cancel="showDeleteModal = false"
            @delete="deleteMultipleInvoices"
        >
            <template #custom-msg>
                <p class="text--sm">
                    These invoice(s) will be removed from all related projects and clients. <strong>This action cannot be undone.</strong>
                </p>
            </template>
        </ConfirmDeletionTwo>

        <UpgradeAccountModal :show-modal="showUpgradeModal" type="invoices" @cancel="closeUpgradeModal" />
    </div>
</template>

<script>
import {
    MainFilter,
    SortFilter,
    Pagination,
    EmptyPage,
    ConfirmDeletionTwo,
    PageSpinner,
    BaseButton,
    CustomTable,
    Status,
    MainModal,
    UpgradeAccountModal,
} from "@/components/ui/index";
import { assembleQueryList } from "@/utils/helpers";
import { invoiceHeaders } from "@/utils/dummy";
import { invoiceStatusMap } from "@/utils/enums";
import { supportedCurrenciesMap } from "@/plugins/countries";

export default {
    name: "InvoicesPage",

    components: {
        CustomTable,
        ConfirmDeletionTwo,
        EmptyPage,
        Pagination,
        MainFilter,
        SortFilter,
        MainModal,
        PageSpinner,
        BaseButton,
        Status,
        UpgradeAccountModal,
    },

    data() {
        return {
            isSearched: false,
            displayType: "",
            selectedInvoices: [],
            deleteLoading: false,
            pageLoading: false,
            fetchInvoicesErr: false,
            fetchInvoicesErrMsg: "",
            invoiceHeaders,
            invoiceStatusMap,
            showDeleteModal: false,
        };
    },

    computed: {
        pageData() {
            return this.$store.getters["invoice/pageData"];
        },

        invoices() {
            return this.$store.getters["invoice/invoices"] || [];
        },

        showActionBtns() {
            if (this.invoices?.length) {
                return true;
            } else if (!this.invoices?.length && this.isSearched) {
                return true;
            } else {
                return false;
            }
        },

        showPagination() {
            if (this.pageData?.total > 50 && this.pageData?.totalPages > 1) {
                return true;
            } else {
                return false;
            }
        },

        refreshList() {
            return this.$store.getters["project/refreshList"];
        },

        invoiceCount() {
            return this.pageData?.total;
        },

        accountPlan() {
            return this.$store.getters["auth/profile"]?.accountPlan;
        },

        showUpgradeModal() {
            return this.$store.getters["app/showUpgradeModal"];
        },

        checkUpgradeLoading() {
            return this.$store.getters["app/checkUpgradeLoading"];
        },

        checkUpgradeFields() {
            return this.$store.getters["app/checkUpgradeFields"];
        },
    },

    watch: {
        "$route.query": {
            async handler(newVal) {
                await this.handleFetchInvoices({ refresh: true, ...newVal });
            },
            immediate: false,
            deep: true,
        },
    },

    methods: {
        goToInvoiceDetailsPage(row) {
            if (!row && !row._id) return;

            this.$router.push(`/dashboard/invoices/${row?._id}`);
        },
        
        getSymbol(currency) {
            const lowerCaseCurrency = currency && currency?.toLowerCase();
            return supportedCurrenciesMap[lowerCaseCurrency] || "$";
        },

        async initiateCreate() {
            try {                
                await this.$store.dispatch("app/checkUpgradeStatus", "invoices");
                return this.$router.push("/dashboard/invoices/create");
            } catch (error) {
                // console.log({ error });
            }
        },

        closeUpgradeModal() {
            this.$store.dispatch("app/closeUpgradeModal", "invoices");
        },

        toggleDelete() {
            this.showDeleteModal = !this.showDeleteModal;
        },

        async deleteMultipleInvoices() {
            try {
                this.deleteLoading = true;

                await this.$store.dispatch("invoice/deleteInvoices", this.selectedInvoices);

                this.$toast.success("Invoices deleted");
                
                this.selectedInvoices = [];
                this.showDeleteModal = false;
                
                await this.handleFetchInvoices();
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.deleteLoading = false;
            }
        },

        selectInvoices(val) {
            this.selectedInvoices = val;
        },

        async handleFetchInvoices(params) {
            try {
                this.pageLoading = true;
                this.fetchInvoicesErr = false;
                this.fetchInvoicesErrMsg = "";

                const queryKeys = Object.keys(this.$route.query);

                if (queryKeys.length) {
                    this.isSearched = true;
                } else {
                    this.isSearched = false;
                }

                let pageQueryObj = {
                    page: params?.page || 1,
                    type: params?.type || "all",
                };

                if (params.name) {
                    pageQueryObj.name = params.name;
                }

                if (params.industry) {
                    pageQueryObj.industry = params.industry;
                }

                if (params.status) {
                    pageQueryObj.status = params.status;
                }

                if (params.invoiceNo) {
                    pageQueryObj.invoiceNo = params.invoiceNo;
                }

                if (params.email) {
                    pageQueryObj.email = params.email;
                }

                if (params.fromDueDate) {
                    pageQueryObj.fromDueDate = params.fromDueDate;
                }

                if (params.toDueDate) {
                    pageQueryObj.toDueDate = params.toDueDate;
                }

                if (params.startDate) {
                    pageQueryObj.startDate = params.startDate;
                }

                if (params.endDate) {
                    pageQueryObj.endDate = params.endDate;
                }

                const buildResult = assembleQueryList(pageQueryObj);

                await this.$store.dispatch("invoice/fetchInvoices", { params: buildResult, isSearch: this.isSearched, refresh: params?.refresh });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;                
                this.fetchInvoicesErr = true;
                this.fetchInvoicesErrMsg = errMsg;
            } finally {
                this.pageLoading = false;
            }
        },
    },
};
</script>