import api from "@/api/url";
import { sortList } from "@/utils/helpers";

export default {
    namespaced: true,

    state: () => ({
        pageData: {
            currentPage: 1,
            totalPages: 1,
            pageSize: 50,
            total: 0,
        },
        rateCards: [],
        refreshList: true,
    }),

    getters: {
        pageData: (state) => state.pageData,
        rateCards: state => state.rateCards,
        currentYear: state => state.currentYear,
    },

    mutations: {
        set(state, [key, value]) {
            state[key] = value;
        },
    },
    
    actions: {
        sortRateCards({ commit, state }, sortVal) {
            const copiedArr = state.rateCards.slice();
            sortList(sortVal, copiedArr, "title");
                        
            commit("set", ["rateCards", copiedArr]);
        },

        setRatesPagination({ commit, state }, page) {
            commit("set", ["pageData", {
                ...state.pageData,
                currentPage: page,
            }]);
        },

        addRateCard({ state, commit }, payload) {
            // optimistic update
            const currentList = state.rateCards;

            commit("set", ["rateCards", [ payload, ...currentList ]]);
            commit("set", ["pageData", {
                ...state.pageData,
                total: state.pageData.total + 1,
                totalPages: Math.ceil((state.pageData.total + 1) / state.pageData.pageSize),
            }]);

            return api.rateCard.create(payload)
                .then((resp) => {
                    const data = resp.data.data;
                    return data;
                });
        },

        fetchRateCards({ commit, state }, { payload, refresh = false, config, external }) {
            if (!refresh && state.rateCards.length) {
                return;
            };

            return api.rateCard.getAll(payload, config)
                .then((resp) => {
                    const data = resp.data.data;

                    if (external) {
                        return data.data;
                    }

                    commit("set", ["rateCards", data.data]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: data.pageDetails.total,
                        totalPages:data.pageDetails.totalPages,
                    }]);
                });
        },

        fetchRateCard(_, id) {
            return api.rateCard.getOne(id)
                .then((resp) => {
                    return resp.data.data;
                });
        },

        updateRateCard({ state, commit }, payload) {
            return api.rateCard.updateOne(payload?._id, payload)
                .then((resp) => {
                    const data = resp?.data?.data;
                    const rateCards = state.rateCards;

                    const updatedList = rateCards.map(c => {
                        if (c?._id === payload?._id) {
                            return {
                                ...c,
                                ...data,
                            };
                        } else {
                            return c;
                        }
                    });
        
                    commit("set", ["rateCards", updatedList]);         
                    return data;
                });
        },

        deleteRateCards({ state, commit }, ids) {
            return api.rateCard.delete({ ids })
                .then(() => {
                    const rateCards = state.rateCards;
                    const updatedList = rateCards.filter(r => !ids.includes(r?._id));

                    commit("set", ["rateCards", updatedList]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total - ids.length,
                        totalPages: Math.ceil((state.pageData.total - ids.length) / state.pageData.pageSize),
                    }]);
                });
        },

        uploadRateCardSheet({ state, commit }, { formData, progressCB }) {            
            return api.rateCard.upload(formData, { headers: { "Content-Type": "multipart/form-data" }, onUploadProgress: progressCB })
                .then((resp) => {
                    const data = resp.data.data;

                    commit("set", ["rateCards", [ ...data, ...state.rateCards ]]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total + data.length,
                        totalPages: Math.ceil((state.pageData.total + data.length) / state.pageData.pageSize),
                    }]);
                });
        },

        sendClientRateCard(_, payload) {
            return api.rateCard.sendRateCard(payload)
                .then((resp) => {
                    return resp.data.data;
                });
        },

        downloadRateCard(_, { id, config = {} }) {
            return api.rateCard.download(id, config)
                .then((resp) => {
                    return resp;
                });
        },

        diagnose(_, payload) {
            return api.rateCard.diagnose(payload)
                .then((resp) => {
                    return resp;
                });
        },
    },
};