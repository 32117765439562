<template>
    <div class="h-100 content__page">
        <div>
            <template v-if="pageLoading">
                <PageSpinner />
            </template>

            <template v-else-if="pageErr">
                <EmptyPage state="error">
                    <template #button>
                        <BaseButton 
                            label="Reload page" icon-name="reload" icon-width="16px" 
                            size="sm" prefix-icon @submit="handleFetchCalendar"
                        />
                    </template> 
                </EmptyPage>
            </template>

            <template v-else>
                <div class="w-100" :class="{ 'empty': !calendarIntegrations.length }">
                    <div>
                        <div>
                            <div>
                                <div class="calendar__header">
                                    <div class="w-100">
                                        <p class="text--semi--bold">
                                            Connected calendar services
                                        </p>
                                        <p class="text--xs">
                                            Integrate your workflows with some of your favourite calendar services. 
                                        </p>
                                    </div>
                                    <div v-if="calendarIntegrations.length > 1" class="mt--10">
                                        <base-button size="sm" variant="outline" inner-style="primary" label="Disable all" />
                                    </div>
                                </div>

                                <div v-for="service in availableIntegrations" :key="service.name" class="calendar__item">
                                    <div class="left">
                                        <span class="mr--5">
                                            <SvgAssets width="36px" height="36px" :name="service.iconName" />
                                        </span>
                                        
                                        <div class="calendar__item__text">
                                            <p class="title">
                                                {{ service.name }}
                                            </p>
                                            <div v-if="isItemActive(service)" class="live flex align-items-center">
                                                <span>   
                                                    <IconSvg name="check-successful-outline" width="12px" fill="#289CA9" />
                                                </span>
                                                <span class="ml--5 text--color-primary">Live</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="right relative">
                                        <template v-if="itemLoading && currentItemIntegrate === service.label">
                                            <PageSpinner size="sm" :is-page="false" />
                                        </template>
                                        
                                        <template v-else>
                                            <div class="dropdown">
                                                <div id="invoiceDetailsDropDown" class="pointer" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <div>
                                                        <IconSvg name="dots-horizontal-rounded" fill="#667085" width="16px" />
                                                    </div>
                                                </div>
                
                                                <ul class="dropdown-menu" aria-labelledby="invoiceDetailsDropDown">
                                                    <template v-if="isItemActive(service)">
                                                        <li class="dropdown-item flex align-items-baseline pointer">
                                                            <IconSvg name="calendar" fill="#667085" width="12px" />
                                                            <span class="ml--5 text--xs text--color-gray-500">View calendars</span>
                                                        </li>
                                                        <li class="dropdown-item flex align-items-baseline pointer">
                                                            <IconSvg name="shape-square" fill="#667085" width="12px" />
                                                            <span class="ml--5 text--xs text--color-gray-500">Resync</span>
                                                        </li>
                                                        <li class="dropdown-item flex align-items-baseline pointer" @click="updateIntegration(service, 'disable')">
                                                            <IconSvg name="no-entry" fill="#667085" width="12px" />
                                                            <span class="ml--5 text--xs text--color-gray-500">Temporarily Disable</span>
                                                        </li>
                                                        <li class="dropdown-item flex align-items-baseline pointer" @click="updateIntegration(service, 'delete')">
                                                            <IconSvg name="delete" fill="#f1616f" width="12px" />
                                                            <span class="ml--5 text--xs text--color-warning">Delete</span>
                                                        </li>
                                                    </template>
                                                    <template v-else>
                                                        <li class="dropdown-item flex align-items-baseline pointer" @click="connectCalendar(service)">
                                                            <IconSvg name="login-circle" fill="#667085" width="12px" />
                                                            <span class="ml--5 text--xs text--color-gray-500">Connect</span>
                                                        </li>
                                                    </template>
                                                </ul>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import SvgAssets from "@/components/ui/icons/SvgAssets.vue";
import {
    PageSpinner, BaseButton,
    EmptyPage, IconSvg,
    MainModal, Alert,
} from "@/components/ui/index";
import { assembleQueryList } from "@/utils/helpers";

export default {
    name: "CalendarsView",

    components: {
        Alert,
        IconSvg,
        EmptyPage,
        PageSpinner,
        BaseButton, SvgAssets,
        MainModal,
    },

    data() {
        return {
            pageLoading: false,
            pageErr: false,
            pageErrMsg: "",
            showConnectModal: false,
            availableIntegrations: [
                { name: "Calendly", iconName: "calendly", label: "calendly" },
                { name: "Google Calendar", iconName: "google_calendar", label: "google_calendar" },
            ],
            itemLoading: false,
            currentItemIntegrate: "",
        };
    },

    computed: {
        userProfile() {
            return this.$store.getters["auth/profile"];
        },

        calendarIntegrations() {
            return this.userProfile?.calendarIntegrations || [];
        },

        calendarRefreshList() {
            return this.$store.getters["app/calendarRefreshList"];
        },

        liveIntegrations() {
            const obj = new Map();

            for (let i = 0; i < this.calendarIntegrations.length; i++) {
                const item = this.calendarIntegrations[i];
                const key = item.providerName;
                
                obj.set(key, item);
            }

            return obj;
        },
    },

    async mounted() {
        if (this.calendarIntegrations.length && this.calendarRefreshList) {
            await this.handleFetchCalendar({ refresh: true });
        }
    },

    methods: {
        async handleFetchCalendar() {
            try {
                this.pageLoading = false;
                this.pageErr = "";
                this.pageErrMsg = "";

                await this.$store.dispatch("app/fetchCalendar");
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;     
                this.pageErr = true;
                this.pageErrMsg = errMsg;
            } finally {
                this.pageLoading = false;
            }
        },

        isItemActive(item) {
            const activeItem = this.liveIntegrations.get(item.label);
            return activeItem && activeItem.settings.syncEnabled;
        },

        async connectCalendar(item) {
            try {    
                this.currentItemIntegrate = item.label;
                this.itemLoading = true;

                const payload = {
                    providerName: item.label,
                };

                const buildResult = assembleQueryList(payload);

                const res = await this.$store.dispatch("auth/connectCalendar", buildResult);

                if (!res.url) {
                    this.$toast.error("Unable to connect calendar");
                    return;
                }
               
                localStorage.setItem("z:calendar_provider", payload.providerName);
                localStorage.setItem("z:auth_type", "calendar");

                window.location.href = res?.url;
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.itemLoading = false;
                this.currentItemIntegrate = "";
            }
        },

        async updateIntegration(item, action) {
            try {
                const data = this.liveIntegrations.get(item?.label);

                if (!data || !data?._id) {
                    this.$toast.error("Missing item id");
                    return;
                }

                this.itemLoading = true;
                await this.$store.dispatch("auth/updateCalendarProvider", { action, payload: data });
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;

                console.log({ errMsg });
                this.$toast.error(errMsg);
            } finally {
                this.itemLoading = false;
            }
        },
    },
};
</script>