const vClickOutside = {
    beforeMount(el, binding) {
        el.__ClickOutsideHandler__ = (event) => {
            // Check if click is outside the dropdown element
            if (!el.contains(event.target) && el !== event.target) {
                if (typeof binding.value === "function") {
                    binding.value(event);
                }
            }
        };
        document.addEventListener("click", el.__ClickOutsideHandler__);
    },
    unmounted(el) {
        document.removeEventListener("click", el.__ClickOutsideHandler__);
        el.__ClickOutsideHandler__ = null;
    },
};

export default vClickOutside;