<template>
    <span v-bind="$attrs" @click="$emit('click')">
        <keep-alive>
            <component :is="{ template: iconName }" width="48" height="48" />
        </keep-alive>
    </span>
</template>

<script>   
export default {
    name: "IntegrationLogos",
    props: {
        name: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        iconMap: {
            "google calendar": "<svg xmlns=\"http://www.w3.org/2000/svg\" aria-label=\"Google Calendar\" role=\"img\" viewBox=\"0 0 512 512\"><rect width=\"512\" height=\"512\" rx=\"15%\" fill=\"#ffffff\"/><path d=\"M100 340h74V174H340v-74H137Q100 100 100 135\" fill=\"#4285f4\"/><path d=\"M338 100v76h74v-41q0-35-35-35\" fill=\"#1967d2\"/><path d=\"M338 174h74V338h-74\" fill=\"#fbbc04\"/><path d=\"M100 338v39q0 35 35 35h41v-74\" fill=\"#188038\"/><path d=\"M174 338H338v74H174\" fill=\"#34a853\"/><path d=\"M338 412v-74h74\" fill=\"#ea4335\"/><path d=\"M204 229a25 22 1 1 1 25 27h-9h9a25 22 1 1 1-25 27M270 231l27-19h4v-7V308\" stroke=\"#4285f4\" stroke-width=\"15\" stroke-linejoin=\"bevel\" fill=\"none\"/></svg>",
            
            "calendly": `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1580.91 381.52">
                        <defs>
                        <style>
                        .cls-1{fill:#006bff;}.cls-2{fill:#0ae8f0;}
                        </style>
                        </defs>
                        <g id="Layer_2" data-name="Layer 2">
                        <g id="Logo_assets" data-name="Logo assets">
                        <g id="Wordmark">
                            <path class="cls-1" d="M938.46,150.54c24.52,0,47.74,15,52.64,47.41H881.9c4.57-28.44,25.83-47.41,56.56-47.41m49.15,95.36c-8.34,13-24.46,23-46.53,23-30.41,0-53.63-16.67-59.18-46.1h137a83,83,0,0,0,1-12.75c0-45.77-32-86.32-81.42-86.32-51,0-85.66,37.6-85.66,86,0,49,35,86,87.62,86,32.7,0,57.55-14.72,71.93-37Z">
                            </path>
                            <rect class="cls-1" x="810.92" y="52.78" width="28.44" height="238.69">
                            </rect>
                            <path class="cls-1" d="M1178.15,191.09V291.46H1149.7V192.72c0-26.81-15.36-41.85-40.54-41.85-26.16,0-47.41,15.37-47.41,53.95v86.64H1033.3V128h28.45v23.54c12.1-19.29,30.08-27.79,53-27.79,38.25,0,63.43,25.5,63.43,67.36">
                            </path>
                            <path class="cls-1" d="M1335,209.72c0-33.35-25.18-58.53-58.2-58.53-32.7,0-57.87,25.18-57.87,58.53s25.17,58.53,57.87,58.53c33,0,58.2-25.18,58.2-58.53m28.44-156.94V291.47H1335V263.34c-13.08,20-34,32.38-61.8,32.38-45.45,0-82.72-37.61-82.72-86s37.27-86,82.72-86c27.79,0,48.72,12.42,61.8,32.37V52.78Z">
                            </path>
                            <rect class="cls-1" x="1386.38" y="52.78" width="28.44" height="238.69">
                            </rect>
                            <path class="cls-1" d="M760.22,209.72c0-33.35-25.17-58.53-58.2-58.53-32.69,0-57.87,25.18-57.87,58.53s25.18,58.53,57.87,58.53c33,0,58.2-25.18,58.2-58.53M788.67,128V291.46H760.22V263.34c-13.08,20-34,32.38-61.79,32.38-45.45,0-82.73-37.61-82.73-86s37.28-86,82.73-86c27.79,0,48.71,12.42,61.79,32.37V128Z">
                            </path>
                            <path class="cls-1" d="M573.51,260a88.53,88.53,0,1,1,25.6-151.42,87,87,0,0,1,13.48,13.92l24.61-18a119.17,119.17,0,1,0-21.72,166.65L599.1,245.25A89.32,89.32,0,0,1,573.51,260">
                            </path>
                            <path class="cls-1" d="M1552.12,128v92.78c0,30-17.45,49-44.09,49s-45.93-19-45.93-49V128h-28.78v90c0,47.16,29.39,77.78,74.71,77.78,39.2,0,45.32-24.8,45.32-25.42v33.38c0,33.07-14.39,51.75-44.4,51.75a44.19,44.19,0,0,1-44-38.2l-25.73,9A71.53,71.53,0,0,0,1509,381.52c46.54,0,72-30.63,72-77.78V128Z">
                            </path>
                            </g>
                            <g id="Brand_mark" data-name="Brand mark">
                                <path class="cls-1" d="M231.58,223.23C220.65,232.93,207,245,182.25,245h-14.8c-17.91,0-34.2-6.51-45.86-18.31-11.39-11.53-17.66-27.31-17.66-44.44V162c0-17.13,6.27-32.91,17.66-44.44,11.66-11.8,27.95-18.3,45.86-18.3h14.8c24.78,0,38.4,12.06,49.33,21.76,11.35,10,21.14,18.74,47.25,18.74a75.11,75.11,0,0,0,11.89-.95l-.09-.23a89.53,89.53,0,0,0-5.49-11.28L267.69,97.07a89.65,89.65,0,0,0-77.64-44.82H155.14A89.65,89.65,0,0,0,77.5,97.07L60.05,127.3a89.67,89.67,0,0,0,0,89.65L77.5,247.18A89.65,89.65,0,0,0,155.14,292h34.91a89.65,89.65,0,0,0,77.64-44.82L285.14,217a89.53,89.53,0,0,0,5.49-11.28l.09-.22a74,74,0,0,0-11.89-1c-26.11,0-35.9,8.69-47.25,18.74">
                                </path>
                                <path class="cls-1" d="M182.25,117.61h-14.8c-27.26,0-45.17,19.47-45.17,44.39v20.25c0,24.92,17.91,44.39,45.17,44.39h14.8c39.72,0,36.6-40.5,96.58-40.5a91.64,91.64,0,0,1,16.94,1.56,89.54,89.54,0,0,0,0-31.15,92.51,92.51,0,0,1-16.94,1.56c-60,0-56.86-40.5-96.58-40.5">
                                </path>
                                <path class="cls-1" d="M330.23,202.5a83.62,83.62,0,0,0-34.45-14.81c0,.11,0,.2,0,.3a89.7,89.7,0,0,1-5,17.45,65.58,65.58,0,0,1,28.48,11.73c0,.08-.05.18-.08.27a153.57,153.57,0,1,1,0-90.63c0,.09.05.19.08.27a65.45,65.45,0,0,1-28.48,11.72,90.3,90.3,0,0,1,5,17.47,2.33,2.33,0,0,0,0,.28,83.6,83.6,0,0,0,34.45-14.8c9.82-7.27,7.92-15.48,6.43-20.34a172.13,172.13,0,1,0,0,101.43c1.49-4.86,3.39-13.07-6.43-20.34">
                                </path>
                                <path class="cls-2" d="M290.72,138.8a74,74,0,0,1-11.89,1c-26.11,0-35.9-8.69-47.24-18.74-10.94-9.7-24.56-21.77-49.34-21.77h-14.8c-17.92,0-34.2,6.51-45.86,18.31-11.39,11.53-17.66,27.31-17.66,44.44v20.25c0,17.13,6.27,32.91,17.66,44.44,11.66,11.8,27.94,18.3,45.86,18.3h14.8c24.78,0,38.4-12.06,49.34-21.76,11.34-10,21.13-18.74,47.24-18.74a75.11,75.11,0,0,1,11.89.95,89,89,0,0,0,5-17.45,2.68,2.68,0,0,0,0-.3,92.51,92.51,0,0,0-16.94-1.55c-60,0-56.86,40.51-96.58,40.51h-14.8c-27.26,0-45.17-19.48-45.17-44.4V162c0-24.92,17.91-44.39,45.17-44.39h14.8c39.72,0,36.6,40.49,96.58,40.49a91.64,91.64,0,0,0,16.94-1.55c0-.09,0-.18,0-.28a90.3,90.3,0,0,0-5-17.47">
                                </path>
                                <path class="cls-2" d="M290.72,138.8a74,74,0,0,1-11.89,1c-26.11,0-35.9-8.69-47.24-18.74-10.94-9.7-24.56-21.77-49.34-21.77h-14.8c-17.92,0-34.2,6.51-45.86,18.31-11.39,11.53-17.66,27.31-17.66,44.44v20.25c0,17.13,6.27,32.91,17.66,44.44,11.66,11.8,27.94,18.3,45.86,18.3h14.8c24.78,0,38.4-12.06,49.34-21.76,11.34-10,21.13-18.74,47.24-18.74a75.11,75.11,0,0,1,11.89.95,89,89,0,0,0,5-17.45,2.68,2.68,0,0,0,0-.3,92.51,92.51,0,0,0-16.94-1.55c-60,0-56.86,40.51-96.58,40.51h-14.8c-27.26,0-45.17-19.48-45.17-44.4V162c0-24.92,17.91-44.39,45.17-44.39h14.8c39.72,0,36.6,40.49,96.58,40.49a91.64,91.64,0,0,0,16.94-1.55c0-.09,0-.18,0-.28a90.3,90.3,0,0,0-5-17.47">
                                </path>
                            </g>
                            </g>
                            </g>
                    </svg>`,
        },
    }),

    computed: {
        iconName() {
            return this.iconMap[this.name];
        },
    },
};
</script>

<style lang="scss" scoped>
    .icon-wrap {
        width: 32px;
        height: 32px;
    }
</style>