<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <div class="revenue__page">
        <div class="content__page__body mt--5" :class="{ 'empty': !activeIntegrations.length }">
            <div v-if="activeIntegrations.length" class="content__page__header mb--35">
                <div class="list--count">
                    <p class="text--medium">
                        {{ activeIntegrations.length }} integrations
                    </p>
                </div>
                <MainFilter page-type="integrations" />
            </div>

            <div class="w--100">
                <template v-if="pageState.loading">
                    <PageSpinner />
                </template>

                <EmptyPage v-else-if="pageState.err" state="error" :handle-submit="handleFetchIntegrations" />

                <template v-else>
                    <EmptyPage 
                        v-if="!activeIntegrations.length"
                        state="empty_custom" icon-name="integration"
                        width="60px" height="60px" title="You have no active integrations" subtitle="Visit the integrations directory to activate a productivity tool"
                    />
                    <CustomTable
                        v-else
                        :columns="integrationHeaders" custom-action
                        :data="activeIntegrations" is-action-btns :is-checkbox="false"
                    >
                        <template #rows="data">
                            <td class="text--capitalize">
                                <div class="flex align-items-center"> 
                                    <div class="mr--10">
                                        <IntegrationLogos :name="data.name" />
                                    </div>
                                    <div>
                                        <p class="mt--20 text--color-gray-400 text--semi--bold">
                                            {{ data.name }}
                                        </p>
                                        <p class="text--xs text--color-gray-100">
                                            {{ data.category }}
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <span 
                                    class="tag badge rounded-pill mr--5 text--sm text--capitalize"  
                                    :class="[integrationMap[data.status].tag || '']" 
                                >
                                    {{ data.status }}
                                </span>
                            </td>
                        </template>

                        <template #customActionBtns="data">
                            <li class="pointer">
                                <p class="dropdown-item block width-100 text--xs">
                                    {{ data.status === 'enabled'? 'Disable': 'Enable' }}
                                </p>
                            </li>
                            <li class="pointer">
                                <p class="dropdown-item text--xs text--color-warning">
                                    Remove
                                </p>
                            </li>
                        </template>
                    </CustomTable>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { 
    IconSvg, 
    Pagination, 
    EmptyPage, 
    PageSpinner, 
    BaseButton, IntegrationLogos,
    MainFilter, CustomTable,
} from "@/components/ui/index";
import { assembleQueryList } from "@/utils/helpers";
import { integrationHeaders } from "@/utils/dummy";
import { integrationMap, integrationProvidersLogos } from "@/utils/enums";

export default {
    name: "ActiveIntegrations",

    components: {
        IconSvg,
        EmptyPage,
        Pagination, CustomTable,
        PageSpinner, IntegrationLogos,
        BaseButton,
        MainFilter,
    },

    data() {
        return {
            integrationHeaders,
            pageState: {
                loading: false,
                err: false,
                errMsg: "",
            },
            showNoteModal: false,
            integrationMap,
            integrationProvidersLogos,
        };
    },

    computed: {
        activeIntegrations() {
            return this.$store.getters["integration/activeIntegrations"];
        },

        userProfile() {
            return this.$store.getters["auth/profile"];
        },
    },
    
    async mounted() {     
        await this.handleFetchIntegrations({ refresh: true });
    },

    methods: {
        async handleFetchIntegrations(params = {}) {
            try {
                this.pageState.loading = true;
                this.pageState.errMsg = "";
                this.pageState.err = false;

                await this.$store.dispatch("integration/fetchUserIntegrations", { refresh: params?.refresh });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.pageState.err = true;
                this.pageState.errMsg = errMsg;
            } finally {
                this.pageState.loading = false;
            }
        },
    },
};
</script>