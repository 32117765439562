<template>
    <div class="settings__page">
        <div>
            <template v-if="fetchUserLoading">
                <PageSpinner />
            </template>
    
            <template v-else-if="fetchUserErr">
                <div class="mt--40">
                    <EmptyPage state="error" :handle-submit="handleFetchProfile" />
                </div>
            </template>
    
            <template v-else>
                <div>
                    <div class="mb--35 mt--0">
                        <div class="flex align-items-center justify-content-between">
                            <CustomText size="xl" color="gray-800" transform="capitalize" weight="semi--bold">
                                Profile
                            </CustomText>
                            <BaseButton
                                size="sm" inner-style="secondary" variant="outline" label="Edit"
                                @submit="openAccountModal('profile')"
                            />
                        </div>
                        <div>
                            <CustomAvatar
                                border-style="solid" width="70px" font-size="20px"
                                :name="profile.fullName" class="mt--20"
                            />
                        </div>
                    </div>

                    <div class="border-b-light">
                        <div>
                            <FormLabel title="Full name" html-for="info" label-type="menu" class="mb--0" />
                            <p class="text--xs text--color-gray-200">
                                How your full name appears in your invoice to clients
                            </p>
                        </div>
                        <div class="mb--15 mt--15">                            
                            <p class="text--base text--color-gray-700">
                                {{ profile.fullName }}
                            </p>
                        </div>
                    </div>

                    <div class="border-b-light mt--15">
                        <FormLabel title="Contact details" html-for="info" label-type="title" />
                        
                        <div class="mt--5 mb--15">
                            <p class="text--sm text--color-gray-600 mt--5">
                                Phone: {{ profile.phoneNumber || '-' }}
                            </p>
                            <p class="text--sm text--color-gray-600 mt--5">
                                Address: {{ profile.address || '-' }}
                            </p>
                            <p class="text--sm text--color-gray-600 mt--5">
                                Country: {{ profile.country || '-' }}
                            </p>
                            <p class="text--sm text--color-gray-600 mt--5">
                                Zipcode: {{ profile.zipCode || '-' }}
                            </p>
                        </div>

                        <div class="settings__row__between mb--15">
                            <div>
                                <FormLabel title="Email address" html-for="info" label-type="menu" />
                                <p class="text--sm text--color-gray-600 mt--5">
                                    {{ profile.email }}
                                </p>
                            </div>
                            
                            <div>
                                <BaseButton
                                    size="sm" variant="outline" inner-style="primary" label="Change email"
                                    @submit="openAccountModal('emailSettings')"
                                />
                            </div>
                        </div>
                    </div>

                    <div v-if="!profile.googleId" class="settings__sub__header text--sm mt--15">
                        <FormLabel title="Password" html-for="info" label-type="menu" />
                        <BaseButton
                            size="sm" class="text--sm pl--0 pr--0" variant="ghost" inner-style="primary"
                            label="Change password"
                            @submit="togglePasswordUpdateModal"
                        />
                    </div>
                </div>

                <!-- <div>
                    <div class="settings__auth__header">
                        <p>Two-factor authentication</p>
                        <div class="mt--10">
                            <base-button size="sm" variant="outline" inner-style="primary" label="Disabled" />
                        </div>
                    </div>
                    
                    <div>
                        <Alert type="primary" classes="text--sm mt--10">
                            <template #text>
                                <p class="alert--title">
                                    Secure your account
                                </p>
                                <p class="alert--subtitle">
                                    Two-factor authentication adds an extra layer of security to your account. When you log in, you'll need to provide a 6-digit code.
                                </p>
                            </template>
                        </Alert>
                    </div>

                    <div>
                        <div class="flex justify-content-between align-items-center mb--25 mt--15">
                            <div class="flex align-items-baseline">
                                <span>
                                    <icon-svg name="cell-phone" width="24px" fill="#A0AEC0" />   
                                </span>
                                
                                <span class="ml--5">
                                    <p class="alert--title">Authenticator app</p>
                                    <p class="alert--subtitle">Use an authentication app to get a two-factor authentication code to complete sign-in.</p>
                                </span>
                            </div>
                            <span>
                                <icon-svg name="check-successful-outline" width="24px" fill="#289CA9" />   
                            </span>
                        </div>
                        
                        <div class="justify-content-between flex align-items-center">
                            <div class="flex align-items-baseline">
                                <span>
                                    <icon-svg name="envelope" width="24px" fill="#A0AEC0" />   
                                </span>
                                <span class="ml--5">
                                    <p class="alert--title">SMS/Text message</p>
                                    <p class="alert--subtitle">Get a one-time code sent to your phone via SMS to complete sign-in.</p>
                                </span>
                            </div>
                            <span>
                                <icon-svg name="check-successful-outline" width="24px" fill="#289CA9" />   
                            </span>
                        </div>
                    </div>
                </div> -->

                <div class="mt--30">
                    <FormLabel title=" Delete your account" html-for="info" label-type="title" class="mb--0" />
                    <p class="text--sm text--color-gray-200">
                        Clicking the button below will delete all your data including your files and history.
                    </p>
    
                    <div class="row__item relative pl--0 mt--20">
                        <BaseButton 
                            :loading="deleteLoading" 
                            label="Delete account" size="sm" variant="warning" inner-style="secondary"
                            @submit="toggleDelete"
                        />
                    </div>
                </div>
            </template>
        </div>

        <ChangePassword :show-modal="showPasswordChangeModal" @cancel="togglePasswordUpdateModal" />

        <UpdateProfileModal 
            :show-modal="showAccountModal"
            :current-selection="currentAccountModalSelection" 
            @cancel="closeAccountModal"
        />

        <ConfirmDeletionTwo
            type="account" custom-msg
            :show-modal="showDeleteModal"
            :loading="deleteLoading" 
            @cancel="toggleDelete" 
            @delete="handleDeleteProfile"
        >
            <template #custom-msg>
                <p class="text--sm">
                    Are you sure you want to delete your account? <strong>This action cannot be undone.</strong>
                </p>
            </template>
        </ConfirmDeletionTwo>
    </div>
</template>

<script>
import {
    BaseButton,
    PageSpinner,
    ConfirmDeletionTwo,
    EmptyPage,
    ChangePassword,
    Alert, FormLabel,
    IconSvg,
    CustomAvatar,
    UpdateProfileModal,
    CustomText,
} from "@/components/ui/index";

export default {
    name: "SettingsAccount",

    components: {
        ChangePassword,
        BaseButton,
        PageSpinner,
        ConfirmDeletionTwo,
        EmptyPage,
        CustomAvatar,
        CustomText,
        IconSvg,
        FormLabel,
        UpdateProfileModal,
    },

    data() {
        return {
            showPasswordChangeModal: false,
            deleteLoading: false,
            fetchUserLoading: false,
            fetchUserErr: false,
            fetchUserErrMsg: "",
            showDeleteModal: false,
            showAccountModal: false,    
            currentAccountModalSelection: "",
        };
    },

    computed: {
        profile() {
            return this.$store.getters["auth/profile"];
        },

        twoFactorAuth() {
            return this.$store.getters["app/userSettings"]?.twoFactorAuth;
        },
    },

    async mounted() {
        await this.handleFetchProfile();
    },

    methods: {
        toggleDelete() {
            this.showDeleteModal = !this.showDeleteModal;
        },

        openAccountModal(val) {
            if (!val || typeof val !== "string") return;

            this.currentAccountModalSelection = val;
            this.showAccountModal = true;
        },

        closeAccountModal() {
            this.showAccountModal = false;
            this.currentAccountModalSelection = "";
        },

        async handleFetchProfile() {
            try {
                this.fetchUserErr = false;
                this.fetchUserErrMsg = "";
                this.fetchUserLoading = true;

                await this.$store.dispatch("auth/fetchProfile", { refresh: false });
            } catch (error) {
                this.fetchUserErr = true;
                this.fetchUserErrMsg = error?.response?.message;
            } finally {
                this.fetchUserLoading = false;
            }
        },

        async handleDeleteProfile() {
            try {
                this.$store.dispatch("app/togglePageLoading", true);

                this.deleteLoading = true;

                await this.$store.dispatch("auth/deleteProfile");

                this.$toast.success("Profile deleted successfully");
                await this.$store.dispatch("auth/signOut");
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.deleteLoading = false;
            }
        },

        togglePasswordUpdateModal() {
            this.showPasswordChangeModal = !this.showPasswordChangeModal;
        },
    },
};
</script>

<style scoped lang="scss">
    .alert {
        padding: 15px 10px;
        border-radius: 6px;

        &--info {
            background: #EDF2F7;
        }

        &--title {
            color: #1A202C;
            font-size: 0.875rem;
            font-weight: 600;
        }

        &--subtitle {
            color: #718096;
            font-size: 0.875rem;
        }
    
    }
</style>