import api from "@/api/url";
import { sortList } from "@/utils/helpers";

export default {
    namespaced: true,

    state: () => ({
        pageData: {
            currentPage: 1,
            totalPages: 1,
            pageSize: 50,
            total: 0,
        },
        earnings: [],
        goals: {
            list: [],
            pageData: {
                currentPage: 1,
                totalPages: 0,
                pageSize: 0,
                total: 0,
            },
        },
        stats: [],
        refreshList: true,
        currentYear: new Date().getFullYear(),
        currency: "usd",
    }),

    getters: {
        earnings: (state) => state.earnings,
        pageData: (state) => state.pageData,
        goals: (state) => state.goals,
        stats: (state) => state.stats,
        currentYear: state => state.currentYear,
        currency: state => state.currency,
    },

    mutations: {
        set(state, [key, value]) {
            state[key] = value;
        },
    },
    
    actions: {
        setYear({ commit }, val) {
            commit("set", ["currentYear", val]);
        },

        setCurrency({ commit }, val) {
            commit("set", ["currency", val]);
        },

        fetchStats({ commit }, params) {
            return api.revenue.getStats(params)
                .then((resp) => {
                    commit("set", ["stats", resp.data.data]);
                });
        },

        fetchEarnings({ commit, state }, { payload, refresh = false, scroll }) {
            if (!refresh && state.earnings.length) return;

            return api.revenue.getHistory(payload)
                .then((resp) => {
                    const data = resp?.data?.data;

                    if (!scroll) {
                        commit("set", ["earnings", data.data]);
                    } else {
                        commit("set", ["earnings", [ ...state.earnings, ...data.data ]]);
                    }
                    commit("set", ["pageData", {
                        ...state.pageData,
                        ... data.pageDetails,
                    }]);
                });
        },

        setPaginationData({ commit, state }, payload) {
            commit("set", ["pageData", {
                ...state.pageData,
                ...payload,
            }]);
        },

        fetchGoals({ commit, state }, { payload, refresh, scroll }) {
            if (!refresh && state.goals.list.length) return;

            return api.revenue.getGoals(payload)
                .then((resp) => {
                    const data = resp?.data?.data;

                    if (!scroll) {
                        commit("set", ["goals", { 
                            ...state.goals, 
                            list: data.data,
                            pageData: data.pageDetails,
                        }]);
                    } else {
                        commit("set", ["goals", { 
                            ...state.goals, 
                            list: [ ...state.goals.list, ...data.data ],
                            pageData: data.pageDetails,
                        }]);
                    }
                });
        },

        addGoal({ commit, state }, payload) {
            // optimistic update
            const currentGoalList = state.goals.list;

            commit("set", ["goals", {
                list: [ payload, ...currentGoalList ],
                pageData: {
                    ...state.goals.pageData,
                    total: state.goals.pageData.total + 1,
                    totalPages: Math.ceil((state.goals.pageData.total + 1) / state.goals.pageData.pageSize),
                },
            }]);

            return api.revenue.createGoal(payload)
                .then((resp) => {
                    const data = resp?.data?.data;
                    return data;
                }).catch(error => {
                    // rollback update
                    commit("set", ["goals", {
                        list: currentGoalList,
                        pageData: {
                            ...state.goals.pageData,
                            total: state.goals.pageData.total - 1,
                            totalPages: Math.ceil((state.goals.pageData.total - 1) / state.goals.pageData.pageSize),
                        },
                    }]);
                    throw error; // Re-throw for component handling
                });
        },

        updateGoal({ commit, state }, payload) {
            return api.revenue.editGoal(payload._id, payload)
                .then((resp) => {
                    const data = resp?.data?.data;       
                    const goals = state.goals.list;

                    const updatedGoals = goals.map(item => {
                        if (item._id === payload._id) {
                            return {
                                ...item,
                                ...payload,
                                ...data,
                            };
                        } else {
                            return item;
                        }
                    });
        
                    commit("set", ["goals", { 
                        ...goals, 
                        list: updatedGoals,
                    }]);
                });
        },

        deleteGoal({ commit, state }, id) {
            return api.revenue.deleteGoal(id)
                .then((resp) => {                    
                    const goals = state.goals.list;
                    const updatedGoals = goals.filter(item => item._id !== id);

                    commit("set", ["goals", { 
                        ...goals, 
                        list: updatedGoals,
                        pageData: {
                            ...state.goals.pageData,
                            total: state.goals.pageData.total - 1,
                            totalPages: Math.ceil((state.goals.pageData.total - 1) / state.goals.pageData.pageSize),
                        },
                    }]);
                });
        },
    },
};