<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <div class="revenue__page">
        <div class="content__page__body mt--20" :class="{ 'empty': !groupByKeys.length }">
            <div v-if="meetings.length || isSearched" class="content__page__header">
                <div class="list--count">
                    <p class="text--medium">
                        Total upcoming meetings <span class="zm__count__badge">{{ meetings.length }}</span>
                    </p>
                </div>
                <main-filter page-type="meetings" />
            </div>

            <div class="zm__content">
                <div class="zm__content__left" :class="{ 'empty': !groupByKeys.length }">
                    <template v-if="!calendarIntegrations.length">
                        <EmptyPage 
                            state="empty_custom" icon-name="calendar"
                            width="60px" height="60px" 
                            title="You have no connected calendars" subtitle="To connect one or more calendars, click on the button below to get started"
                        >
                            <template #button>
                                <BaseButton 
                                    label="Go to calendar settings" prefix-icon icon-name="calendar"
                                    size="sm" variant="outline" inner-style="primary" @submit="goToCalendarPage"
                                />
                            </template> 
                        </EmptyPage>
                    </template>

                    <template v-else>
                        <template v-if="pageState.loading">
                            <PageSpinner />
                        </template>
    
                        <EmptyPage v-else-if="pageState.err" state="error">
                            <template #button>
                                <BaseButton
                                    label="Reload page" size="sm" icon-name="reload" icon-width="16px"
                                    @submit="handleFetchMeetings"
                                />
                            </template> 
                        </EmptyPage>
    
                        <template v-else>
                            <template v-if="!groupByKeys.length">
                                <EmptyPage 
                                    state="empty_custom" icon-name="calendar"
                                    width="60px" height="60px" title="You have no upcoming meetings" subtitle="To get started, schedule a new meeting with a client"
                                >
                                    <template #button>
                                        <BaseButton 
                                            label="+ New meeting" size="sm" variant="outline" 
                                            inner-style="primary" @submit="showAddModal = true"
                                        />
                                    </template> 
                                </EmptyPage>
                            </template>
                            <template v-else>
                                <ul v-for="item in groupByKeys" :key="item">
                                    <div class="revenue__date__header">
                                        {{ $format_date(item) }}
                                    </div>
                                    <li
                                        v-for="meeting in groupedListByDate[item]" :key="meeting._id" 
                                        class="cr__list__item zm__list__item" :class="{ 'active': meeting._id === currentMeetingItem._id }"
                                        @click="handleFetchSingleMeeting(meeting)" 
                                    >
                                        <div class="revenue__item__left">
                                            <div class="revenue__item__icon">
                                                <IconSvg name="calendar-event" width="16px" height="16px" fill="#70878D" />
                                            </div>
                                            <div class="revenue__item__left__text">
                                                <div class="revenue__item__title text--medium">
                                                    {{ meeting.title }}
                                                </div>
                                                <p class="mt--5 text--color-gray-600 text--xs">
                                                    Sat, 21 Sept 2024 at 10:40
                                                </p>
                                                <div>
                                                    <p class="text--color-gray-200 text--xs">
                                                        2 attendees | 6 notes
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </template>
                        </template>
                    </template>
                </div>

                <div v-if="groupByKeys.length" class="zm__content__right">
                    <template v-if="currentItemState.loading">
                        <PageSpinner />
                    </template>

                    <EmptyPage v-else-if="currentItemState.err" state="error">
                        <template #button>
                            <BaseButton
                                label="Reload page" size="sm" icon-name="reload" icon-width="16px"
                                @submit="handleFetchMeetings"
                            />
                        </template> 
                    </EmptyPage>

                    <div v-else class="zm__list__item__details">
                        <div class="title">
                            <p>{{ currentMeetingItem.title }}</p>
                        </div>

                        <div class="row__right mt--10 pr--0 pl--0">
                            <BaseButton
                                label="Manage meeting" size="sm"
                                variant="outline" inner-style="primary" class-names="mr--5"
                            />
                            <BaseButton
                                label="Delete meeting" size="sm" variant="outline" class-names="mr--5"
                            />
                        </div>

                        <div class="meta__date">
                            <p class="text--bold text--base text--color-gray-700">
                                Sat, 21 Sept 2024 at 10:40
                            </p>
                            <p class="text--color-gray-100 text--xs">
                                1 hour, 20 minutes duration
                            </p>
                        </div>

                        <div class="meta__item">
                            <p class="text--bold text--color-gray-500 mt--10 mb--10">
                                Overview
                            </p>

                            <div class="invoice__details__group">
                                <div>
                                    <p class="text--color-gray-100 text--sm text--medium">
                                        Description
                                    </p>
                                    <p class="text--upper invoice__detail text--sm">
                                        {{ currentMeetingItem.description || 'N/A' }}
                                    </p>
                                </div>
                            </div>

                            <div class="invoice__details__group">
                                <div>
                                    <p class="text--color-gray-100 text--sm text--medium">
                                        Attendees
                                    </p>
                                    <template v-if="currentMeetingItem.attendees && currentMeetingItem.attendees.length">
                                        <div>
                                            <div 
                                                v-for="item in currentMeetingItem.attendees" :key="item" 
                                                class="project__item__desc mb--5"
                                            >
                                                {{ item }}
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <p class="project__item__desc">
                                            No attendees present.
                                        </p>
                                    </template>
                                </div>
                            </div>

                            <div>
                                <div class="flex align-items-center justify-content-between">
                                    <p class="text--bold text--color-gray-500 mt--10 mb--10">
                                        Meeting notes
                                    </p>

                                    <BaseButton 
                                        variant="ghost" label="+ Add note" 
                                        class-names="text--xs text--color-primary p--0" @submit="openNoteModal('add')"
                                    />
                                </div>
                                <div>
                                    <template v-if="currentMeetingItem.notes && currentMeetingItem.notes.length">
                                        <div 
                                            v-for="item in currentMeetingItem.notes" :key="item" 
                                            class="meta__note mb--5 flex align-items-center justify-content-between"
                                        >
                                            <p class="text--sm text--color-gray-700">
                                                {{ item }}
                                            </p>
                                            <div class="dropdown">
                                                <BaseButton 
                                                    id="noteDropdown" data-bs-auto-close="true" 
                                                    aria-expanded="false" data-bs-toggle="dropdown" variant="ghost"
                                                    label="+ Add new item" size="xs" inner-style="secondary" 
                                                    prefix-icon icon-name="dots-horizontal-rounded" icon-fill="#637579" icon-width="12px"
                                                />

                                                <ul id="noteDropdownList" class="dropdown-menu dropdown-menu--tag" aria-labelledby="noteDropdown">
                                                    <li class="dropdown-item flex align-items-center pointer text--xs" @click="openNoteModal('edit', item)">
                                                        <icon-svg class="mr--5" name="edit" :style="{ fill: '#212529' }" width="16px" /> 
                                                        <span>Edit note</span>
                                                    </li>
                                                    <li class="dropdown-item flex align-items-center pointer text--xs" @click="handleDeleteNote(item)">
                                                        <icon-svg class="mr--5" name="delete" :style="{ fill: '#212529' }" width="16px" /> 
                                                        <span>Delete note</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <p class="project__item__desc">
                                            No notes yet.
                                        </p>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <MeetingNoteModal 
            :id="currentMeetingItem._id" :action="noteAction"
            :show-modal="showNoteModal" :current-note="currentNote"
            @complete="handleNoteComplete" @cancel="closeNoteModal" 
        />

        <CreateMeetingModal :show-modal="showAddModal" @close="showAddModal = false" />
    </div>
</template>

<script>
import { 
    IconSvg, 
    Pagination, 
    EmptyPage, 
    PageSpinner, 
    BaseButton, ConnectCalendarModal,
    MainFilter, MeetingNoteModal,
    CreateMeetingModal,
} from "@/components/ui/index";
import { groupList, assembleQueryList } from "@/utils/helpers";

export default {
    name: "UpcomingMeetings",

    components: {
        IconSvg,
        EmptyPage,
        Pagination, MeetingNoteModal,
        PageSpinner,
        BaseButton, CreateMeetingModal,
        MainFilter, ConnectCalendarModal,
    },

    data() {
        return {
            isSearched: false,
            pageState: {
                loading: false,
                err: false,
                errMsg: "",
            },
            showAddModal: false,
            currentMeetingItem: {
                _id: "",
                googleEventId: "",
                title: "",
                description: "",
                startTime: null,
                endTime: null,
                attendees: [],
            },
            currentItemState: {
                loading: false,
                err: false,
                errMsg: "",
            },
            showNoteModal: false,
            noteAction: "add",
            currentNote: "",
        };
    },

    computed: {
        meetings() {
            return this.$store.getters["meeting/upcomingMeetings"]?.list || [];
        },

        pageData() {
            return this.$store.getters["meeting/upcomingMeetings"]?.pageData;
        },

        showPagination() {
            if (this.pageData?.total > 50 && this.pageData?.totalPages > 1) {
                return true;
            } else {
                return false;
            }
        },

        userProfile() {
            return this.$store.getters["auth/profile"];
        },
        
        showUpgradeAccount() {
            if (this.userProfile?.accountPlan === "free") {
                return true;
            }

            return false;
        },

        calendarIntegrations() {
            return this.userProfile?.calendarIntegrations || [];
        },

        groupedListByDate() {
            if (!this.meetings.length) {
                return [];
            }

            const arr = this.groupList(this.meetings, (item) => {
                // createdAt comes in form of timestamp from API
                // Convert to YYYY-MM-DD
                const date = new Date(item.createdAt);

                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();

                const filter = `${year}-${month}-${day}`;

                return filter;
            });

            return Object.fromEntries(arr);
        },

        groupByKeys() {
            return Object.keys(this.groupedListByDate) || [];
        },
    },

    watch: {
        "$route.query": {
            async handler(newVal) {
                const queryKeys = Object.keys(newVal);

                if (queryKeys.length) {
                    this.isSearched = true;
                } else {
                    this.isSearched = false;
                }

                await this.handleFetchMeetings({ refresh: true, ...newVal });
            },
            deep: true,
            immediate: true,
        },
    },
    
    // async mounted() {     
    //     await this.handleFetchMeetings({ refresh: false });
    // },

    methods: {
        groupList,
        assembleQueryList,

        goToCalendarPage() {
            return this.$router.push("/dashboard/settings/calendars");
        },

        async handleFetchMeetings(params = {}) {
            try {
                this.pageState.loading = true;
                this.pageState.errMsg = "";
                this.pageState.err = false;
                
                const pageQueryObj = {
                    page: params.pageNumber || this.pageData.currentPage,
                };

                if (params.title) {
                    pageQueryObj.title = params.title;
                }

                if (params.status) {
                    pageQueryObj.status = "";
                }

                if (params.startDate) {
                    pageQueryObj.startDate = params.startDate || new Date();
                }

                if (params.endDate) {
                    let endDate;

                    switch (params.endDate) {
                    case "a_week_after": // 1 week after the current date
                        endDate = new Date(currentDate);
                        endDate.setDate(currentDate.getDate() + 7);
                        break;

                    case "two_weeks_after": // 2 weeks after the current date
                        endDate = new Date(currentDate);
                        endDate.setDate(currentDate.getDate() + 14);
                        break;

                    case "current_month": // The end of the current month
                        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                        break;

                    default:
                        endDate = new Date(currentDate);
                        endDate.setDate(currentDate.getDate() + 7);
                        break;
                    }
                        
                    pageQueryObj.endDate = endDate;
                }

                const buildResult = assembleQueryList(pageQueryObj);

                await this.$store.dispatch("meeting/fetchUpcomingMeetings", { 
                    params: buildResult, 
                    refresh: params.refresh, 
                });

                const firstItemInArrId = this.meetings[0]?._id;
                await this.handleFetchSingleMeeting(firstItemInArrId);
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;

                this.pageState.err = true;
                this.pageState.errMsg = errMsg;
            } finally {
                this.pageState.loading = false;
            }
        },

        async handleFetchSingleMeeting(id) {
            try {
                if (!id) {
                    throw new Error("Missing meeting id required");
                }

                this.currentItemState.loading = true;
                this.currentItemState.err = false;
                this.currentItemState.errMsg = "";

                const meetingItemRes = await this.$store.dispatch("schedule/fetchOneMeeting", id);

                this.currentMeetingItem = {
                    ...this.currentMeetingItem,
                    ...meetingItemRes,
                };
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                
                this.currentItemState.err = true;
                this.currentItemState.errMsg = errMsg;
            } finally {
                this.currentItemState.loading = false;
            }
        },

        openNoteModal(type, note) {
            this.noteAction = type;
           
            if (note) {
                this.currentNote = note;
            }

            this.showNoteModal = true;
        },

        closeNoteModal() {
            this.noteAction = "";
            this.showNoteModal = false;
        },

        handleNoteComplete(val) {
            if (this.noteAction === "add") {
                this.currentMeetingItem.notes = [ ...this.currentMeetingItem.notes, val ];
            } else {
                this.currentMeetingItem.notes = this.currentMeetingItem.notes.map(x => {
                    if (x === this.currentNote) {
                        return val;
                    } else {
                        return x;
                    }
                });
            };
            this.currentNote = "";
        },

        async handleDeleteNote(item) {
            try {
                await this.$store.dispatch("schedule/modifyMeetingNote", { id: this.currentMeetingItem._id, note: item });

                this.currentMeetingItem.notes = this.currentMeetingItem.notes.filter(x => x !== item);
            } catch (error) {
                const errMsg = error?.response?.data?.message || error.message;
                this.$toast.error(errMsg);
            }
        },

        async handlePageAction() {
            if (this.showUpgradeAccount) {
                this.$router.push("/dashboard/settings/billings-and-plans");
            } else {
                this.$router.push("/dashboard/settings/calendar");
            }
        },
    },
};
</script>