<template>
    <div class="settings__page">
        <div>
            <div class="mb--35">
                <CustomText size="xl" color="gray-800" transform="capitalize" weight="semi--bold">
                    General Settings
                </CustomText>
            </div>

            <template v-if="pageLoading">
                <PageSpinner />
            </template>

            <template v-else>
                <div class="settings__row__item mt--0">
                    <div class="flex align-items-center w--100 justify-content-between">
                        <CustomText size="sm" color="gray-600" transform="capitalize" weight="medium">
                            Language & Currency
                        </CustomText>
                        <BaseButton
                            size="sm" inner-style="secondary" variant="outline" label="Edit"
                            @submit="openModal('currency')"
                        />
                    </div>
                    
                    <div>
                        <p class="text--sm text--color-gray-300 mt--5">
                            <span>{{ general.language || 'English' }},</span>
                            <span class="text--upper ml--5">{{ general.currency || '-' }}</span>
                        </p>
                    </div>
                </div>
    
                <!-- <div class="settings__sub__header mt--0">
                    <div class="left">
                        <p class="text--semi--bold text--color-gray-700 text--sm">
                            Theme
                        </p>
                        <div>
                            <p class="text--sm text--capitalize text--color-gray-300 mt--5">
                                {{ appearance.theme }}
                            </p>
                        </div>
                    </div>
                    <div>
                        <base-button
                            size="sm" inner-style="secondary" variant="outline" label="Edit"
                            @submit="openModal('appearance')"
                        />
                    </div>
                </div> -->
    
                <div class="mt--15">
                    <div class="mb--10 flex align-items-center w--100 justify-content-between">
                        <CustomText size="sm" color="gray-600" transform="capitalize" weight="medium">
                            Time settings
                        </CustomText>
                        <BaseButton
                            size="sm" inner-style="secondary" variant="outline" label="Edit"
                            @submit="openModal('timeSettings')"
                        />
                    </div>

                    <div>
                        <p class="text--sm text--color-gray-300 mt--10">
                            Timezone: {{ general.timeZone || 'N/A' }}
                        </p>
                        <p class="text--sm text--color-gray-300 mt--10">
                            Preferred Time Format: {{ general.timeFormat || 'N/A' }}
                        </p>
                        <p class="text--sm text--color-gray-300 mt--10">
                            Preferred Date Format: {{ general.dateFormat || 'N/A' }}
                        </p>
                        <p class="text--sm text--color-gray-300 mt--10 text--capitalize">
                            Week starts from: {{ general.dayOfWeek }}
                        </p>
                    </div>
                </div>
            </template>
        </div>

        <MainModal 
            :show-modal="showGeneralModal" size="sm"  
            :title="title" @close="closeModal"
        >
            <template #body>
                <form @submit.prevent> 
                    <div v-if="hasField('currency')" class="form__item">
                        <FormLabel id="currency" title="Currency" />
                        <CustomDropdown 
                            id="currencyDropdown" v-model="form.currency" :options="settlementAccountCurrencies"
                            list-type="currencies"
                            @search="searchCurrencies" @select="v => form.currency = v" 
                        />
                    </div>

                    <div v-if="hasField('theme')" class="form__item">
                        <FormLabel html-for="theme" title="Theme color" />
                        <select v-model="form.theme" class="form-select form-select-sm text--sm filter-input">
                            <option v-for="item in theme" :key="item" :value="item">
                                {{ item }}
                            </option>
                        </select>
                    </div>

                    <div v-if="hasField('timeFormat')" class="form__item">
                        <div class="form__item">
                            <FormLabel id="timeFormat" title="Preferred Time Format" />
                            <select v-model="form.timeFormat" class="form-select form-select-sm text--sm filter-input">
                                <option v-for="(format, index) in ['12h', '24h']" :key="index" :value="format">
                                    {{ format }}
                                </option>
                            </select>
                        </div>
    
                        <div v-if="hasField('timezone')" class="form__item">
                            <FormLabel id="timezone" title="Time zone" />
                            <select v-model="form.timezone" class="form-select form-select-sm text--sm filter-input">
                                <option v-for="(lang, index) in ['English', 'French']" :key="index" :value="lang">
                                    {{ lang }}
                                </option>
                            </select>
                        </div>
    
                        <div v-if="hasField('dateFormat')" class="form__item">
                            <FormLabel id="dateFormat" title="Preferred Date Format" />
                            <select v-model="form.dateFormat" class="form-select form-select-sm text--sm">
                                <option v-for="(item, index) in dateFormats" :key="index" :value="item">
                                    {{ item }}
                                </option>
                            </select>
                        </div>
    
                        <div v-if="hasField('dayOfWeek')" class="form__item">
                            <FormLabel id="dayOfWeek" title="Week starts from" />
                            <select v-model="form.dayOfWeek" class="form-select form-select-sm text--sm text--capitalize">
                                <option v-for="(day, index) in daysOfWeek" :key="index" :value="day">
                                    {{ day }}
                                </option>
                            </select>
                        </div>
                    </div>
                </form>
            </template>

            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <BaseButton 
                        label="Cancel" variant="outline" size="sm"
                        class="mr--5" @submit="closeModal"
                    />
                    <BaseButton label="Save changes" size="sm" :loading="loading" @submit="handleUpdateSettings" />
                </div>
            </template>
        </MainModal>
    </div>
</template>

<script>
import {
    MainModal,
    FormLabel,
    BaseButton,
    PageSpinner, CustomDropdown,
    CustomText,
} from "@/components/ui/index";
import { generalModalFields } from "@/components/auth/auth";
import { dateFormats, daysOfWeek, theme } from "@/utils/dummy";
import { settlementAccountCurrencies } from "@/plugins/countries";

export default {
    name: "GeneralSettings",

    components: {
        FormLabel,
        BaseButton,
        MainModal,
        PageSpinner, 
        CustomDropdown,
        CustomText,
    },

    data() {
        return {
            settlementAccountCurrencies,
            pageLoading: false,
            loading: false,
            form: {
                timezone: "",
                timeFormat: "",
                dateFormat: "",
                dayOfWeek: "Sunday",
                theme: "",
                language: "",
                currency: "",          
            },
            dateFormats,
            daysOfWeek,
            theme,
            showGeneralModal: false,
            generalModalFields,
            currentSelection: "",
        };
    },

    computed: {
        general() {
            return this.$store.getters["app/userSettings"]?.general;
        },

        title() {
            return this.generalModalFields[this.currentSelection]?.title || "";
        },

        appearance() {
            return this.$store.getters["app/userSettings"]?.appearance;
        },
    }, 

    watch: {
        currentSelection(newVal) {
            if (newVal) {
                if (this.generalModalFields[newVal] && this.generalModalFields[newVal]?.fields) {
                    this.populateFormBasedOnSelection(newVal);
                }
            }
        },
    },

    methods: {
        openModal(val) {
            if (!val || typeof val !== "string") return;

            this.currentSelection = val;
            this.showGeneralModal = true;
        },

        closeModal() {
            this.showGeneralModal = false;
            this.currentSelection = "";
        },

        setForm(data) {
            this.form = {
                timezone: data?.timezone || "",
                timeFormat: data?.timeFormat || "",
                dateFormat: data?.dateFormat || "",
                theme: data?.theme || "",
                dayOfWeek: data?.dayOfWeek || "Sunday",
                currency: data?.currency || "",    
            };            
        },

        async handleUpdateSettings() {
            try {
                this.loading = true;
                const payload = {
                    type: this.currentSelection === "appearance" ? "appearance" : "general",
                    settings: {},
                };

                const selectedItem = this.generalModalFields[this.currentSelection]?.fields;

                for (const key in selectedItem) {
                    if (this.form.hasOwnProperty(key)) {
                        payload.settings[key] = this.form[key];
                    }
                }
                
                await this.$store.dispatch("app/updateGeneralSettings", payload);
                this.$toast.success("Profile updated");
                this.closeModal();
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }
        },

        resetForm() {
            this.form = {
                timezone: "",
                timeFormat: "",
                dateFormat: "",
                theme: "",
                dayOfWeek: "Sunday",
                language: "",
                currency: "",     
            };
        },

        hasField(field) {
            return !this.generalModalFields[this.currentSelection] ? false : this.generalModalFields[this.currentSelection].fields[field]; 
        },

        populateFormBasedOnSelection(val) {
            const selectedItem = this.generalModalFields[val]?.fields;

            for (const key in selectedItem) {
                if (this.general.hasOwnProperty(key)) {
                    this.form[key] = this.general[key];
                }

                if (this.appearance.hasOwnProperty(key)) {
                    this.form[key] = this.appearance[key];
                }
            }
        },

        searchCurrencies(val) {
            const initialList = this.settlementAccountCurrencies;

            if (val) {
                const filteredList = initialList.filter(item => item.toLowerCase().includes(val));
                this.settlementAccountCurrencies = filteredList;
            } else {
                this.settlementAccountCurrencies = initialList;
            }
        },
    },
};
</script>