<template>
    <div class="content" :style="{ 'padding-left': isCollapse ? '40px' : '220px', transition: 'all .3s ease-out' }">
        <div class="header__dashboard">
            <div class="header__dashboard__left">
                <div class="collapse--content" />
                <div class="title__wrap">
                    <nav class="breadcrumb-nav" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li 
                                v-for="(path, index) in paths" 
                                :key="path" 
                                class="breadcrumb-item" 
                                :class="[computeBreadcrumbs(index) === 'last' ? 'active' : '' ]" 
                                :aria-current="computeBreadcrumbs(index) === 'last' ? 'page' : ''"
                            >
                                <span 
                                    class="breadcrumb__item breadcrumb-item"
                                    :class="[computeBreadcrumbs(index)]"
                                    @click="goBackToMainRoute(index)"
                                >
                                    <!-- {{ path }} -->
                                    {{ shortenBreadCrumbText(path, index) }}
                                </span>
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div class="header__dashboard__right">
                <div class="pointer activity" data-bs-toggle="offcanvas" href="#activityDrawer" aria-controls="activityDrawer">
                    <icon-svg :name="'bell'" fill="#374151" width="16" height="16" />
                    
                    <span v-if="unReadActivityCount > 0" class="activity__unread__icon position-absolute translate-middle border border-light rounded-circle">
                        <span class="visually-hidden">New alerts</span>
                    </span>
                </div>

                <div class="dropdown mr--10">
                    <div id="resourcesDropDown" class="pointer" data-bs-toggle="dropdown" aria-expanded="false">
                        <CustomAvatar :name="userFullName" />
                    </div>
                    <ul class="dropdown-menu dropdown--menu header--menu" aria-labelledby="resourcesDropDown">
                        <li class="dropdown-item">
                            <p class="header__nav__email">
                                {{ userEmail }}
                            </p>
                        </li>

                        <li><hr class="dropdown-divider"></li>

                        <li class="dropdown-item header__dashboard__dropdown--item" @click="signOutOfApp">
                            <a class="flex align-items-center text--color-gray-500">
                                <IconSvg name="info" fill="#667085" width="16px" height="16px" />
                                <span class="header__nav__link">Help center</span>
                            </a>
                        </li>

                        <li class="dropdown-item header__dashboard__dropdown--item" @click="signOutOfApp">
                            <a class="flex align-items-center text--color-gray-500">
                                <IconSvg name="map-pin" fill="#667085" width="16px" height="16px" />
                                <span class="header__nav__link">Getting started</span>
                            </a>
                        </li>

                        <li class="dropdown-item header__dashboard__dropdown--item" @click="signOutOfApp">
                            <a class="flex align-items-center text--color-gray-500">
                                <IconSvg name="money" fill="#667085" width="16px" height="16px" />
                                <span class="header__nav__link">Pricing</span>
                            </a>
                        </li>

                        <template v-if="profile.accountPlan === 'free'">
                            <li><hr class="dropdown-divider"></li>
    
                            <li v-if="accountPlan === 'free'" class="dropdown-item no-hover header__dashboard__dropdown--item" @click="signOutOfApp">
                                <div class="flex align-items-center justify-content-between">
                                    <p class="text--sm text--medium">
                                        Free plan
                                    </p>
                                    <BaseButton 
                                        size="sm" label="Upgrade" variant="outline" inner-style="primary"
                                        @submit="goToUpgradePage"
                                    />
                                </div>
                            </li>
                        </template>

                        <li><hr class="dropdown-divider"></li>

                        <li class="dropdown-item header__dashboard__dropdown--item" @click="signOutOfApp">
                            <a class="flex align-items-center text--color-gray-500">
                                <IconSvg name="log-out" fill="#667085" width="16px" height="16px" />
                                <span class="header__nav__link">Log out</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="content__body" :style="collapse ? bodyStylesCollapse : bodyStyles">
            <div class="content__view">
                <router-view :key="$route.fullPath" />
            </div>
        </div>

        <ActivityDrawer />
    </div>
</template>

<script>
import { 
    BaseDrawer,EmptyPage, IconSvg, 
    CustomAvatar, BaseButton, ActivityDrawer,
} from "@/components/ui/index";
import { headerResources, accountPlanMap } from "@/utils/dummy";


export default {
    name: "DashboardContent",

    components: {
        IconSvg,
        ActivityDrawer,
        CustomAvatar,
        BaseButton,
    },

    props: {
        isCollapse: {
            type: Boolean,
            default: false,
        },
        showSettingsSidebar: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            accountPlanMap,
            collapse: this.isCollapse,
            bodyStyles: {
                maxWidth: "97%",
                marginLeft: "auto",
                marginRight: "auto",
                transition: "all .3s ease-out",
            },
            bodyStylesCollapse: {
                maxWidth: "none",
                marginLeft: "0px",
                marginRight: "0px",
                transition: "all .3s ease-out",
            },
            activities: [],
            unreadActivitiesOnly: false,
            currencyLoading: false,
            headerResources,
        };
    },

    computed: {
        paths() {
            return this.$store.getters["app/paths"] || [];
        },

        profile() {
            return this.$store.getters["auth/profile"];
        },

        showBackArrowBtn() {
            return this.$route.params.id || this.$route.name === "create-invoice-view" ? true : false;
        },

        unReadActivityCount() {
            return this.$store.getters["app/unReadActivityCount"];
        },

        email() {
            return this.profile?.email;
        },

        currency() {
            return this.$store.getters["app/userSettings"]?.general?.currency;
        },

        userFullName() {
            return this.profile?.fullName || "";
        },

        userEmail() {
            return this.profile?.email || "";
        },

        accountPlan() {
            return this.$store.getters["auth/profile"]?.accountPlan;
        },
    },

    methods: {
        async signOutOfApp() {
            try {
                this.$store.dispatch("auth/setLogoutLoading", true);
                await this.$store.dispatch("auth/signOut");
                this.$store.dispatch("auth/setLogoutLoading", false);
            } catch (error) {
                this.$store.dispatch("auth/setLogoutLoading", false);
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            }
        },

        goBackToMainRoute(index) {
            if (index && !this.paths[index]) return;
            if (index && index === this.paths.length - 1) return;

            if (index === 0) {
                this.$router.push(`/dashboard/${this.paths[0]}`);
                this.$store.dispatch("app/setCurrentRouteTwo", `/dashboard/${this.paths[0]}`);
            } else if (index === 1) {
                this.$router.push(`/dashboard/${this.paths[0]}/${this.paths[1]}`);
                this.$store.dispatch("app/setCurrentRouteTwo", `/dashboard/${this.paths[0]}/${this.paths[1]}`);
            } else {
                return;
            }
        },

        computeBreadcrumbs(idx) {
            if (idx === this.paths.length - 1) {
                if (this.paths.length === 1) {
                    return "only";
                } else {
                    return "last";
                }
            } else if (idx === 0) {
                return "head";
            } else {
                return "sub";
            }
        },

        shortenBreadCrumbText(path, idx) {
            if (typeof path !== "string") return "";

            if (idx === 0) return path;
            
            return `${path.slice(0, 8)}${path.length > 8 ? "...": ""}`;
        },

        goToUpgradePage() {
            this.$router.push("/dashboard/settings/billings-and-plans");
        },
    },
};
</script>

<style>
    .dropdown-menu.currency {
        height: 100px;
        overflow: scroll;
    }

    .header--menu {
        min-width: 14rem !important;
    }
</style>