import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index";

import LandingPageView from "@/pages/index.vue";
import DashboardView from "@/views/Dashboard.vue";

import ReportsView from "@/pages/dashboard/Reports.vue";
import BaseRevenuePage from "@/pages/dashboard/revenue/index.vue";
import Overview from "@/pages/dashboard/revenue/RevenueOverview.vue";
import History from "@/pages/dashboard/revenue/RevenueHistory.vue";
import RateCardView from "@/pages/dashboard/RateCard.vue";

import CalendarView from "@/pages/dashboard/settings/Calendar.vue";
import MeetingsView from "@/pages/dashboard/meetings/index.vue";
import UnconfirmedMeetingsView from "@/pages/dashboard/meetings/UnconfirmedMeetings.vue";
import UpcomingMeetingsView from "@/pages/dashboard/meetings/UpcomingMeetings.vue";
import RecurringMeetingsView from "@/pages/dashboard/meetings/RecurringMeetings.vue";
import CanceledMeetingsView from "@/pages/dashboard/meetings/CanceledMeetings.vue";

import ClientsView from "@/pages/dashboard/clients/index.vue";
import ClientsDetailsView from "@/pages/dashboard/clients/_id.vue";

import ProjectsView from "@/pages/dashboard/projects/index.vue";
import ProjectsDetailsView from "@/pages/dashboard/projects/_id/index.vue";
import ProjectTasksView from "@/pages/dashboard/projects/_id/Tasks.vue";

import AccountSettings from "@/pages/dashboard/settings/Account.vue";
import GeneralSettings from "@/pages/dashboard/settings/GeneralSettings.vue";
import BillingsAndPlans from "@/pages/dashboard/settings/BillingsAndPlans.vue";
import GoalsAndProductivity from "@/pages/dashboard/settings/GoalsAndProductivity.vue";
// import RemindersAndAlerts from "@/pages/dashboard/settings/Reminders.vue";
import Notifications from "@/pages/dashboard/settings/Notifications.vue";
import Integrations from "@/pages/dashboard/settings/integrations/index.vue";
import ActiveIntegrations from "@/pages/dashboard/settings/integrations/ActiveIntegrations.vue";
import AllIntegrations from "@/pages/dashboard/settings/integrations/AllIntegrations.vue";

import LoginView from "@/pages/auth/Login.vue";
import InitiateResetView from "@/pages/auth/InitiateReset.vue";
import SignupView from "@/pages/auth/Signup.vue";
import ResetView from "@/pages/auth/Reset.vue";
// import AuthPageContainer from "@/pages/auth/index.vue";
import AuthHandlerView from "@/pages/auth/ProviderCallback.vue";

import TasksView from "@/pages/dashboard/Tasks.vue";
import TaskDetailsView from "@/components/ui/modals/TaskDetails.vue";

import InvoicesView from "@/pages/dashboard/invoices/index.vue";
import CreateEditInvoiceView from "@/pages/dashboard/invoices/CreateEdit.vue";
import InvoiceDetailsView from "@/pages/dashboard/invoices/_id/index.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: LandingPageView,
        meta: { requiresAuth: false,  title: "Home | Ziner" },
    },
    {
        path: "/auth/",
        name: "auth",
        component: () => import("@/pages/auth/index.vue"),
        meta: { requiresAuth: false, publicAuth: true },
        children: [
            {
                path: "login",
                name: "login",
                component: LoginView,
                meta: { requiresAuth: false,  title: "Login | Ziner", publicAuth: true },
                // redirect: { name: "home" }, // redirect to the home page for now until I start working on this project again
            },
            {
                path: "signup",
                name: "signup",
                component: SignupView,
                meta: { requiresAuth: false,  title: "Signup | Ziner", publicAuth: true },
        
            },
            {
                path: "reset",
                name: "reset",
                component: ResetView,
                meta: { requiresAuth: false,  title: "Reset Password | Ziner", publicAuth: true },
            },
            {
                path: "initiate-reset",
                name: "intiate-reset",
                component: InitiateResetView,
                meta: { requiresAuth: false,  title: "Request Password Reset | Ziner", publicAuth: true },
            },
            {
                path: ":provider/callback",
                name: "auth",
                component: AuthHandlerView,
                meta: { requiresAuth: false,  title: "Auth Callback | Ziner" },
            },
        ],
    },
    {
        path: "/dashboard/",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: { requiresAuth: true },
        children: [
            { path: "reports", name: "reports", component: ReportsView, meta: { requiresAuth: true, title: "Reports | Ziner" } },

            { path: "revenue", component: BaseRevenuePage, meta: { requiresAuth: true },
                children: [                
                    { path: "overview", name: "revenue-overview", component: Overview, meta: { requiresAuth: true,  title: "Revenue Overview | Ziner"  } },
                    { path: "history", name: "revenue-history", component: History, meta: { requiresAuth: true,  title: "Revenue History | Ziner"  } },
                ], 
                redirect: { name: "revenue-overview" },
            },
                  
            { path: "projects", name: "projects-view", component: ProjectsView, meta: { requiresAuth: true, title: "Projects | Ziner" } },
      
            {  path: "projects/:id", name: "project-details-view", component: ProjectsDetailsView, meta: { requiresAuth: true, title: "Project Details | Ziner" }},

            {  path: "projects/:id/tasks", name: "project-details-tasks", component: ProjectTasksView, title: "Project Tasks | Ziner",
                children: [
                    {
                        path: ":taskId", // This makes it a child route
                        component: TaskDetailsView,
                        meta: { requiresAuth: true, title: "Task Details | Ziner", modal: true },
                    },
                ],
            },
                   
            { path: "rate-cards", name:"rate-cards-view", component: RateCardView, meta: { requiresAuth: true, title: "Rate Cards | Ziner" } },
            
            { path: "tasks", name:"tasks-view", component: TasksView, meta: { requiresAuth: true, title: "Tasks | Ziner" }},
                  
            // { path: "meetings", component: MeetingsView, meta: { requiresAuth: true },
            //     children: [                
            //         { path: "upcoming", name: "meetings-upcoming", component: UpcomingMeetingsView, meta: { requiresAuth: true,  title: "Upcoming Meetings | Ziner"  } },
            //         { path: "unconfirmed", name: "meetings-unconfirmed", component: UnconfirmedMeetingsView, meta: { requiresAuth: true,  title: "Unconfirmed Meetings | Ziner"  } },
            //         { path: "recurring", name: "meetings-recurring", component: RecurringMeetingsView, meta: { requiresAuth: true,  title: "Recurring Meetings | Ziner"  } },
            //         { path: "canceled", name: "meetings-canceled", component: CanceledMeetingsView, meta: { requiresAuth: true,  title: "Canceled Meetings | Ziner"  } },
            //     ], 
            //     redirect: { name: "meetings-upcoming" },
            // },
            
            { path: "invoices", name: "invoices-view", component: InvoicesView, meta: { requiresAuth: true, title: "Invoices | Ziner" } },
            
            { path: "invoices/create", name: "create-invoice-view", component: CreateEditInvoiceView, meta: { requiresAuth: true, title: "Create Invoice | Ziner" } },

            { path: "invoices/:id/edit", name: "edit-invoice-view", component: CreateEditInvoiceView, meta: { requiresAuth: true, title: "Edit Invoice | Ziner" } },
      
            { 
                path: "invoices/:id", 
                name: "invoice-details-view", 
                component: InvoiceDetailsView, 
                meta: { 
                    requiresAuth: true,
                    title: "Invoice Details | Ziner",
                }, 
            },
      
            { path: "clients", name: "clients-view", component: ClientsView, meta: { requiresAuth: true, title: "Clients | Ziner" } },

            { path: "clients/:id", name:"client-details-view", component: ClientsDetailsView, meta: { requiresAuth: true, title: "Client Details | Ziner" } },
      
            { path: "settings", name:"settings-view", meta: { requiresAuth: true },
                children: [                
                    { path: "account", name: "account-settings", component: AccountSettings, meta: { requiresAuth: true, title: "Account | Ziner" }},
                    
                    { path: "general-settings", name: "general-settings", component: GeneralSettings, meta: { requiresAuth: true, title: "General settings | Ziner" } },

                    { path: "goals-and-productivity", name: "goals-settings", component: GoalsAndProductivity, meta: { requiresAuth: true, title: "Goals & Productivity | Ziner" } },

                    { path: "billings-and-plans", name: "billings-settings", component: BillingsAndPlans, meta: { requiresAuth: true, title: "Billings & Plans | Ziner" }},

                    { path: "calendars", name: "calendar-settings", component: CalendarView, meta: { requiresAuth: true,  title: "Calendar | Ziner"  } },

                    // { path: "reminders", name: "reminders-settings", component: RemindersAndAlerts, meta: { requiresAuth: true, title: "Reminders | Ziner" }},

                    { path: "notifications", name: "notifications-settings", component: Notifications, meta: { requiresAuth: true, title: "Notifications | Ziner" }},

                    { path: "integrations", component: Integrations, meta: { requiresAuth: true, title: "Integrations | Ziner" },
                        children: [                
                            { path: "active", name: "integrations-active", component: ActiveIntegrations, meta: { requiresAuth: true,  title: "Active Integrations | Ziner"  } },
                            { path: "directory", name: "integrations-directory", component: AllIntegrations, meta: { requiresAuth: true,  title: "Integration Directory| Ziner"  } },
                        ], 
                        redirect: { name: "integrations-active" },
                    },
                ], 
                redirect: { name: "account-settings" },
            },
        ],
    },
    {
        path: "/:catchAll(.*)*",
        component: () => import("../views/ErrorPage.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: "active",
});

// navigation guard for protected routes and dynamic document titles
router.beforeEach(async(to, from, next) => {
    document.title = to.meta.title || "Ziner - Manage your freelance workflows";

    let isAuthenticated = store.getters["auth/isAuthenticated"];
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const publicAuth = to.matched.some(record => record.meta.publicAuth);

    const PUBLIC_ROUTES = ["login", "signup", "reset", "intiate-reset"];
    const DEFAULT_AUTH_REDIRECT = "login";

    try {
        if (!isAuthenticated) {
            // skip check for certain publicly accessible routes
            if (!requiresAuth) { 
                next(); 
                return;  
            } 
        }
        
        isAuthenticated = await (store.dispatch("auth/checkAuth"));

        if (!isAuthenticated) {
            next("/auth/login");
            return;
        }

        // user is authenticated and should not access the following public routes
        if (publicAuth) {
            next("/dashboard/reports");
            return;
        }

        next();   
        return;
    } catch (error) {
        return next({
            name: DEFAULT_AUTH_REDIRECT,
            query: { redirect: to.fullPath },
        });
    }
});

export default router;