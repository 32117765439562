<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <div>
        <div>
            <template v-if="pageLoading">
                <PageSpinner />
            </template>

            <template v-else-if="fetchInvoicesErr">
                <EmptyPage state="error" :handle-submit="handleFetchInvoices" />
            </template>

            <template v-else>
                <div class="w--100 justify-content-center">
                    <template v-if="!invoices.length">
                        <EmptyPage 
                            state="empty_custom" type="invoice" icon-name="invoice" 
                            width="60px" height="60px"
                            title="You have no invoices for this project" subtitle="All invoices associated with this project show up here."
                        >
                            <template #button>
                                <BaseButton 
                                    label="+ Add an invoice" size="sm" :loading="upgradeLoadingDisabled" :disabled="upgradeLoadingDisabled"
                                    class="flex text--sm align-items-center" @submit="goToCreateInvoicePage"
                                />
                            </template>
                        </EmptyPage>
                    </template>

                    <template v-else>
                        <div class="flex align-items-center mb--15 justify-content-between">
                            <p class="text--bold text--color-gray-400 mt--20 mb--15">
                                Invoices
                            </p>
                            <BaseButton
                                label="+ Add invoice" size="sm" :loading="upgradeLoadingDisabled" :disabled="upgradeLoadingDisabled"
                                :show-spinner="false" variant="ghost" inner-style="primary" 
                                class="text--semi--bold" @submit="goToCreateInvoicePage"
                            />
                        </div>
                        <div class="flex mt--20">
                            <CustomTable
                                :columns="projectInvoiceHeaders" :data="invoices"
                                is-row-link :is-checkbox="false"
                                @onRowClick="goToInvoiceDetailsPage"
                            >
                                <template #rows="data">
                                    <td>#{{ data.invoiceNo }}</td>
                                    <td class="text--upper">
                                        {{ data.currency }} {{ $format_number(data.totalAmount) }}
                                    </td>
                                    <td>
                                        <Status type="invoice" :value="data.status || 'N/A'" is-table />
                                    </td>
                                    <td>{{ $format_date(data.createdAt) || "-" }}</td>
                                </template>
                            </CustomTable>
                        </div>
                        <div class="flex align-items-center justify-content-between">
                            <p class="text--color-gray-200 text--medium text--xs">
                                COUNT: <span>{{ invoices.length }}</span>
                            </p>
                            <pagination v-if="showPagination" :pagination-data="pageData" />
                        </div>
                    </template>
                </div>
            </template>
        </div>

        <UpgradeAccountModal :show-modal="showUpgradeModal" type="invoices" @cancel="closeUpgradeModal" />
    </div>
</template>

<script>
import {
    Pagination,
    EmptyPage,
    PageSpinner,
    BaseButton,
    CustomTable,
    Status,
    UpgradeAccountModal,
} from "@/components/ui/index";
import { projectInvoiceHeaders } from "@/utils/dummy";

export default {
    name: "ProjectInvoices",

    components: {
        CustomTable,
        EmptyPage,
        Pagination,
        PageSpinner,
        BaseButton,
        Status,
        UpgradeAccountModal,
    },

    props: {
        currentProject: { type: Object, default: () => {} },
    },

    data() {
        return {
            pageLoading: false,
            fetchInvoicesErr: false,
            fetchInvoicesErrMsg: "",
            projectInvoiceHeaders,
            invoices: [],
            pageData: { 
                currentPage: 1,
                pageSize: 50,
                total: 0,
                totalPages: 1,
            },
        };
    },

    computed: {
        showPagination() {
            if (this.pageData?.total > 50 && this.pageData?.totalPages > 1) {
                return true;
            } else {
                return false;
            }
        },

        showUpgradeModal() {
            return this.$store.getters["app/showUpgradeModal"];
        },

        checkUpgradeLoading() {
            return this.$store.getters["app/checkUpgradeLoading"];
        },

        checkUpgradeFields() {
            return this.$store.getters["app/checkUpgradeFields"];
        },
        
        upgradeLoadingDisabled() {
            return this.checkUpgradeLoading && this.checkUpgradeFields.invoices;
        },
    },
    
    async mounted() {
        await this.handleFetchInvoices();
    },

    methods: {
        async goToCreateInvoicePage() {
            try {
                await this.$store.dispatch("app/checkUpgradeStatus", "invoices");
                this.$store.dispatch("invoice/setSelectedProject", this.currentProject);
                return this.$router.push({ path: "/dashboard/invoices/create", query: { from_route: "project" } });
            } catch (error) {
                // console.log({ error });
            }
        },

        goToInvoiceDetailsPage(row) {
            if (!row._id) return;
            this.$router.push(`/dashboard/invoices/${row._id}`);
        },

        closeUpgradeModal() {
            this.$store.dispatch("app/closeUpgradeModal", "invoices");
        },

        async handleFetchInvoices() {
            try {
                this.pageLoading = true;
                this.fetchInvoicesErr = false;
                this.fetchInvoicesErrMsg = "";

                const pageQueryObj = {
                    page: this.pageData.currentPage || 1,
                };

                const res = await this.$store.dispatch("project/fetchProjectInvoices", { id: this.$route.params.id, params: pageQueryObj });                
                this.invoices = res.data;
                this.pageData = res.pageDetails;
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                
                this.fetchInvoicesErr = true;
                this.fetchInvoicesErrMsg = errMsg;
            } finally {
                this.pageLoading = false;
            }
        },

        viewInvoice(row) {
            if (!row?._id) return;

            this.$router.push(`/dashboard/invoices/${row._id}`);
        },
    },
};
</script>