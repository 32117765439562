<template>
    <table class="table" :class="[`${tableType}`]">
        <thead>
            <tr>
                <th v-show="isCheckbox" id="selectAll" class="header pl--0" colspan="1">
                    <span>
                        <input
                            id="select"
                            v-model="selectAll"
                            :class="{ 'indeterminate': isInterdeminate }"
                            class="table-checkbox form-check-input pointer"
                            type="checkbox" @change="logEvent"
                        >
                    </span> 
                </th>
                <th v-for="column in columns" :key="column.accessorKey" class="header pl--0" colspan="1">
                    <span class="flex align-items-center">
                        <!-- <icon-svg :name="column.iconName" width="16px" /> -->
                        <span class="text--capitalize">{{ column.id }}</span>
                    </span>
                </th>
                <template v-if="isActionBtns">
                    <th class="header" colspan="1" />
                </template>
            </tr>
        </thead>
        
        <tbody>
            <tr v-for="row in data" :key="row._id" :class="{ 'pointer': rowType === 'link' }" @click="rowType === 'link' ? $emit('onRowClick', row) : undefined">
                <td v-show="isCheckbox">
                    <div class="checkbox pointer mr--5">
                        <input
                            id="select"
                            v-model="selectedRows"
                            :value="row._id"
                            class="form-check-input mt--0"
                            type="checkbox"
                            @click.stop @change="emitValue"
                        >
                        <label class="form-check-label d-none" :for="'label' + row._id" />
                    </div>
                </td>

                <slot name="rows" v-bind="row" />
               
                <td v-if="rowType === 'btns'" class="overflow-visible">
                    <div class="dropdown">
                        <div id="tagActions" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                            <div class="icon pointer" tabindex="-1" title="More options">
                                <IconSvg name="table-icon" width="16px" />
                            </div>
                        </div>
                        <ul class="dropdown-menu dropdown-menu--tag" aria-labelledby="tagActions">
                            <template v-if="!customAction">
                                <li class="pointer" :disabled="rowIdToDelete === row._id && rowDisabled">
                                    <router-link
                                        :to="{ name: routerName, params:{ id: row._id || '' }}"
                                        class="dropdown-item block width-100 text--xs"
                                    >
                                        View
                                    </router-link>
                                </li>
                                <li class="pointer" @click="$emit('delete', row)">
                                    <p class="dropdown-item text--xs text--color-warning">
                                        Delete
                                    </p>
                                </li>
                            </template>
                            <template v-else>
                                <slot name="customActionBtns" v-bind="row" />
                            </template>
                        </ul>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import IconSvg from "../icons/Icon-Svg.vue";

export default {
    name: "CustomTable",

    components: { IconSvg }, 

    model: { prop: "value", event: "change" },

    props: {
        routerName: { type: String, default: "" },
        data: { type: Array, default: () => [] },
        columns: { type: Array, default: () => [] },
        selectedForDeletion: { type: Array, default: () => [] },
        isActionBtns: { type: Boolean, default: true },
        isCheckbox: { type: Boolean, default: true },
        modelValue: { default: "", type: [Number, String] },
        rowDisabled: { type: Boolean, default: false },
        rowIdToDelete: { type: String, default: "" },
        customAction: { type: Boolean, default: false },
        tableType: {
            type: String,
            default: "default",
            validator: value => !value || ["default", "striped"].includes(value),
        },
        rowType: {
            type: String,
            default: "link",
            validator: value => !value || ["link", "btns", "default"].includes(value),
        },
    },

    emits: [
        "openModal", "onRowSelected", 
        "rowSelection", "update:modelValue", 
        "onRowClick", "rowIdToDelete", 
        "rowDisabled",
    ],

    data() {
        return {
            selectedRows: [],
            selectAll: false,
            isInterdeminate: false,
        };
    },

    watch: {
        "selectedRows"(newVal) {
            this.$emit("onRowSelected", newVal);

            if (newVal.length) {
                this.isInterdeminate = true;
            } else {
                this.isInterdeminate = false;
            }
        },

        "selectAll"(newVal) {
            if (newVal === true) {
                this.selectedRows = this.data.map(row => row._id);
                this.isInterdeminate = true;
            } else {
                this.selectedRows = [];
                this.isInterdeminate = false;
            }
            this.$emit("onRowSelected", this.selectedRows);
        },
    },

    methods: {
        emitValue(e) {
            let value = e.target.value;
            
            this.$emit("update:modelValue", value);
        },

        logEvent(event) {
            const checked = event?.target?.checked;

            if (checked) {                
                // this.isInterdeminate = false;
                // this.selectedRows = [];
            }
        },
    },
};
</script>