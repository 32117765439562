<template>
    <label class="form-label" :for="htmlFor" v-bind="$attrs" :class="[`${labelType}`]">
        {{ title }}
        <span v-if="infoType" :class="[infoTypeObj[infoType].color || '']" class="text--xs">
            <slot v-if="infoText" />
            <template v-else>
                {{ infoTypeObj[infoType].text }}
            </template>
        </span>
    </label>
</template>

<script>
export default {
    name: "FormLabel",
    
    props: {
        title: {
            type: String,
            default: "",
        },
        htmlFor: {
            type: String,
            default: "",
        },
        infoType: {
            type: String,
            default: "",
            validator: value => !value || ["required", "optional", "info"].includes(value),
        },
        labelType: {
            type: String,
            default: "form",
            validator: value => !value || [
                "marketing", "title", "menu", "md_mono", "tabular", "form", "tertiary", "sm_mono",
            ].includes(value),
        },
        infoText: {
            type: String,
            default: "",
        },
        noMarginBottom: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            infoTypeObj: {
                "required": {
                    color: "text--color-warning",
                    text: "*",
                },
                "info": {
                    color: "text-label-info",
                    text: "",
                },
                "optional": {
                    color: "text-label-info",
                    text: "(optional)",
                },
            },
        };
    },
};
</script>