<template>
    <div>
        <MainModal 
            :show-modal="showModal" size="sm"
            :title="`${action === 'add' ? 'Create' : 'Edit'} meeting`" @close="closeModal"
        >
            <template #body>
                <div class="">
                    <form @submit.prevent="">
                        <div class="">
                            <div class="form--control mb--15">
                                <FormLabel title="Clients" />
                                <CustomDropdown 
                                    id="countryDropdown" v-model="form.clients" :options="clients"
                                    list-type="clients" index-label="name"
                                    @search="searchClients" @select="selectClient" 
                                />
                            </div>
                            
                            <FormInput 
                                id="title" v-model="form.title"
                                size="sm" info-type="required" label="Meeting title"
                            />

                            <FormInput 
                                id="description" v-model="form.description"
                                size="sm" label="Description" 
                            />

                            <div class="form--control mb--15">
                                <FormLabel title="Clients" />
                                <CustomDropdown 
                                    id="countryDropdown" v-model="form.clients" :options="clients"
                                    list-type="clients" index-label="name"
                                    @search="searchClients" @select="selectClient" 
                                />
                            </div>

                            <div class="row">
                                <div class="col m-w-100">
                                    <FormLabel title="Start date" info-type="required" />
                                    <v-date-picker v-model="form.startDate" class="text--sm">
                                        <template #default="{ inputValue, togglePopover }">
                                            <input
                                                class="px-2 py-1 border rounded due_date form-control form-control-sm text--sm"
                                                :value="inputValue"
                                                @click="togglePopover"
                                            >
                                        </template>
                                    </v-date-picker>
                                </div>
                                <div class="col m-w-100">
                                    <FormLabel title="End date" />
                                    <v-date-picker v-model="form.endDate" class="text--sm">
                                        <template #default="{ inputValue, togglePopover }">
                                            <input
                                                class="px-2 py-1 border rounded due_date form-control form-control-sm text--sm"
                                                :value="inputValue"
                                                @click="togglePopover"
                                            >
                                        </template>
                                    </v-date-picker>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </template>

            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <BaseButton
                        class="mr--5" label="Cancel"
                        size="sm" variant="outline" :disabled="loading"
                        @submit="closeModal"
                    />
                    <BaseButton 
                        :label="`${action === 'add' ? 'Add' : 'Save'}`"
                        size="sm" :loading="loading" :disabled="isBtnDisabled"
                        @submit="handleSubmit"                                       
                    />
                </div>
            </template>
        </MainModal>
    </div>
</template>

<script>
import countryList from "@/assets/js/countrycodes.json";
import MainModal from "./MainModal.vue";
import FormLabel from "../forms/FormLabel.vue";
import BaseButton from "../buttons/BaseButton.vue";
import FormInput from "../input/FormInput.vue";
import CustomDropdown from "../dropdowns/CustomDropdown.vue";
import { assembleQueryList } from "@/utils/helpers";

export default {
    name: "CreateMeeting",

    components: {
        MainModal,
        FormLabel,
        BaseButton,
        FormInput,
        CustomDropdown,
    },

    props: {
        action: { type: String, default: "add" },
        currentSelection: { type: Object, default: () => {} },
        showModal: { type: Boolean, default: false },
    },

    data() {
        return {
            countryList,
            form: {
                _id: "",
                title: "",
                attendees: "",
                startDate: null,
                endDate: null,
                location: "",
                description: "",
                calendarId: "",
            },
            loading: false,
            clients: [],
            searchClientsLoading: false,
        };
    },

    computed: {
        isBtnDisabled() {
            if (!this.form.clientName || !this.form.email) {
                return true;
            } else if (this.loading) {
                return true;
            } else {
                return false;
            }
        },

        userProfile() {
            return this.$store.getters["auth/profile"];
        },

        calendarIntegrations() {
            return this.userProfile?.calendarIntegrations || [];
        },

        enabledIntegrations() {
            return this.calendarIntegrations.filter(x => !!x.settings.syncEnabled);
        },
    },

    watch: {
        currentSelection: {
            handler(newVal, oldVal) {
                if (newVal?._id || oldVal?._id) {
                    this.fillForm(newVal);
                }
            },
            immediate: true,
            deep: true,
        },
    },

    mounted() {
        const cachedClients = this.$store.getters["clients/clients"];

        if (cachedClients.length) {
            this.clients = cachedClients;
        } else {
            this.searchClients();
        }
    },

    methods: {
        closeModal() {
            this.$emit("close");
        },

        resetForm() {
            this.form = {
                title: "",
                attendees: "",
                startDate: null,
                endDate: null,
                location: "",
                description: "",
            };
        },

        fillForm(val) {
            this.form = {
                _id: val?._id || "",
                title: val?.title,
                startDate: val?.startDate || null,
                endDate: val?.endDate || null,
                location: val?.location || "",
                description: val?.description || "",
                attendees: val?.attendees || [],
            };
        },

        buildPayload() {
            const payload = {
                title: this.form.title,
                email: this.form.email || "",
                description: this.form.description || "",
                attendees: this.form.attendees,
                startDate: this.form.startDate,
                endDate: this.form.endDate || null,
                location: this.form.location,
            };

            if (this.form._id) {
                payload._id = this.form._id;
            }

            return payload;
        },

        async createMeeting() {
            try {    
                this.loading = true;
                const payload = this.buildPayload();
                const resp = await this.$store.dispatch("clients/addClient", payload);

                this.$toast.success("Client created");
                this.resetForm();
                this.$emit("complete", resp);
            } catch (error) {
                const errMsg = error?.response?.message || error.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }
        },

        async updateMeeting() {
            try {
                if (!this.form._id) {
                    throw new Error("Meeting id is required");
                }

                this.loading = true;
                const payload = this.buildPayload();
                const res = await this.$store.dispatch("clients/updateClient", payload);

                this.$toast.success("Client info updated");
                this.$emit("complete", res);
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }
        },

        async handleSubmit() {
            if (this.action === "edit") {
                return await this.updateMeeting();
            } 
            
            if (this.action === "add") {
                return await this.createMeeting();
            }
        },

        selectClient(item) {
            this.form.attendees = [ ...this.form.attendees, item ];
        },

        async searchClients(name) {
            try {
                this.searchClientsLoading = true;

                const pageQueryObj = {
                    page: 1,
                    type: "all",
                    name,
                };
                
                const buildResult = assembleQueryList(pageQueryObj);
                await this.$store.dispatch("clients/fetchClients", { params: buildResult, refresh: true, external: true });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                
            } finally {
                this.searchClientsLoading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.client__modal__body {    
    &.scroll-height {
        max-height: 490px;
        overflow: scroll;

        .form--control {
            &:not(:last-of-type) {
                margin-bottom: 15px !important;
            }
        }
    }
}
</style>