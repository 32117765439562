
<template>
    <div>
        <main-modal 
            :show-modal="showModal" 
            size="sm" title="Manage reminders" @close="closeModal"
        >
            <template #body>
                <form @submit.prevent="">
                    <div v-for="item in items" :key="item">
                        <div class="form-check form-check-inline text--sm">
                            <input
                                v-model="reminders" class="form-check-input" type="checkbox"
                                :value="item"
                            >
                            <label class="form-check-label" for="inlineCheckbox1">{{ mapReminders[item] }}</label>
                        </div>
                    </div>
                </form>
            </template>

            <template #footer>
                <div class="w--100 flex justify-content-end align-items-center">
                    <BaseButton 
                        class="mr--5" variant="outline"
                        label="Cancel" size="sm"
                        :disabled="loading" @submit="closeModal"
                    />

                    <BaseButton 
                        :loading="loading" :disabled="loading" 
                        type="submit" size="sm" label="Update" @submit="handleUpdate"
                    />
                </div>
            </template>
        </main-modal>
    </div>
</template>

<script>
import MainModal from "./MainModal.vue";
import BaseButton from "../buttons/BaseButton.vue";
import { invoiceReminders, mapReminders } from "@/utils/dummy";

export default {
    name: "ManageReminders",

    components: { MainModal, BaseButton },

    props: {
        initialReminders: { type: Array, default: () => [] },
        showModal: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
    },

    emits: ["cancel", "complete", "selected"],

    data() {
        return {
            reminders: [],
            items: invoiceReminders.map(x => x.id),
            mapReminders,
        };
    },
    
    watch: {
        initialReminders: {
            async handler(newVal) {
                if (newVal.length) {
                    this.reminders = newVal;
                }
            },
            deep: true,
            immediate: true,
        },
    },

    methods: {
        closeModal() {
            this.$emit("cancel", false);
        },

        handleUpdate() {
            this.$emit("complete", this.reminders);
        },
    },
};
</script>