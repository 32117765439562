export const industryData = [
    "food",
    "software",
    "fintech",
    "HR",
    "blockchain",
    "artificial intelligence",
    "arts",
    "radio",
    "software",
    "venture capital",
    "sales",
    "ux research",
    "research",
    "psychology",
    "marketing",
    "makeup",
    "design",
    "hair",
    "skincare",
    "operations",
];

export const revenueTabList = [
    {   
        id: "Overview",
        component: "RevenueOverview",
        route: "revenue/overview",
    },
    {   
        id: "History",
        component: "RevenueHistory",
        route: "revenue/history",
    },
];

export const integrationTabList = [
    {   
        id: "Active integrations",
        component: "ActiveIntegrations",
        route: "settings/integrations/active",
        name: "integrations-active",
    },
    {   
        id: "Integration directory",
        component: "AllIntegrations",
        route: "settings/integrations/directory",
        name: "integrations-directory",
    },
];

export const scheduleTabList = [
    {   
        id: "Calendar",
        component: "Calendar",
        route: "schedule/calendar",
    },
    {   
        id: "Meetings",
        component: "Meetings",
        route: "schedule/meetings",
    },
];

export const clientTabList = [
    // {   
    //     name: "Activity",
    //     component: "ClientActivity",
    //     iconName: "map-pin",
    //     iconFill: "sm",
    // },
    {   
        name: "Notes",
        component: "ClientNotes",
        iconName: "notes",
        iconFill: "sm",
    },
    {   
        name: "Projects",
        component: "ClientProjects",
        iconName: "project",
        iconFill: "sm",
    },
    {   
        name: "Invoices",
        component: "ClientInvoices",
        iconName: "receipt",
        iconFill: "sm",
    },
];

export const meetingTabList = [
    {   
        id: "Upcoming",
        component: "UpcomingMeetings",
        route: "meetings/upcoming",
    },
    {   
        id: "Unconfirmed",
        component: "UnconfirmedMeetinsg",
        route: "meetings/unconfirmed",
    },
    {   
        id: "Recurring",
        component: "RecurringMeetings",
        route: "meetings/recurring",
    },
    {   
        id: "Canceled",
        component: "CanceledMeetings",
        route: "meetings/canceled",
    },
];

export const projectTabList = [
    {   
        name: "Activity",
        component: "ProjectActivity",
    },
    {   
        name: "Notes",
        component: "ProjectNotes",
    },
    {   
        name: "Invoices",
        component: "ProjectInvoices",
    },
];

export const securityAccessQuestions = [
    "What is the name of your first pet?",
    "Who was your childhood hero?",
    "What is your mother's maiden name?",
    "What is the title of your favourite movie?",
    "What is the name of the street you lived in as a kid?",
    "What is the name of the street you lived in as a kid?",
];

export const settingsRoutesList = [
    {   
        name: "account",
        iconName: "user",
    },
    {   
        name: "general settings",
        iconName: "home",
    },
    {   
        name: "calendars",
        iconName: "calendar",
    },
    {   
        name: "notifications",
        iconName: "bell",
    },
    {   
        name: "billings & plans",
        iconName: "purchase-tag",
    },
    {   
        name: "integrations",
        iconName: "expand",
    },
    {   
        name: "goals & productivity",
        iconName: "map-pin",
    },
];

export const featuresList = [
    {
        id: 1, title: "Reports", 
        desc: "Get detailed reports on your completed projects, earnings and clients within a selected time period.",
        icon: "<svg xmlns=\"http://www.w3.org/2000/svg\"  fill=\"none\" width=\"800px\" height=\"800px\" viewBox=\"0 0 36 36\"><rect x=\"6.48\" y=\"18\" width=\"5.76\" height=\"11.52\" rx=\"1\" ry=\"1\"/><rect x=\"15.12\" y=\"6.48\" width=\"5.76\" height=\"23.04\" rx=\"1\" ry=\"1\"/><rect x=\"23.76\" y=\"14.16\" width=\"5.76\" height=\"15.36\" rx=\"1\" ry=\"1\"/></svg>",
    },
    {   id: 2, title: "Reminders", 
        desc: "Get custom reminders about specific tasks and projects when the deadline is approaching.",
        icon:  "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"800px\" height=\"800px\" viewBox=\"0 0 1024 1024\" class=\"icon\" version=\"1.1\"><path d=\"M788.164 566.042V398.239c0-141.81-105.555-258.955-242.38-277.188V99.927c0-20.595-16.696-37.291-37.291-37.291-20.593 0-37.287 16.696-37.287 37.291v21.123c-136.825 18.232-242.38 135.378-242.38 277.189v167.804c0 36.859-74.53 56.585-74.578 111.868-0.067 75.17 115.224 126.585 223.734 151.176v2.643c0 69.505 58.434 125.851 130.512 125.851 72.08 0 130.514-56.346 130.514-125.851v-2.47c108.168-24.303 223.312-75.292 223.735-151.348 0.311-56.068-74.579-73.576-74.579-111.87zM508.493 920.29c-49.242 0-89.574-36.912-93.089-83.712 34.793 6.049 67.293 9.131 93.089 9.131 25.707 0 58.226-3.031 93.086-9.029-3.565 46.752-43.878 83.61-93.086 83.61z m0-111.868c-84.005 0-317.061-55.544-316.957-130.512 0.063-46.375 74.578-38.125 74.578-130.514V398.239c0-133.861 108.517-242.38 242.378-242.38 133.865 0 242.382 108.519 242.382 242.38v149.157c-0.233 95.891 75.401 85.404 74.578 130.514-1.348 73.967-234.721 130.512-316.959 130.512z\" fill=\"#BF2C6F\"/></svg>",
    },
    {   id: 3, title: "Billing & Invoicing", 
        desc: "Bill your clients for work done. Create invoices, download PDF copies of invoices or mail them over to your clients.",
        icon: "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" style=\"fill:; transform: ;msFilter:;\"><path  fill=\"none\" d=\"M21 11h-3V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v14c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-6a1 1 0 0 0-1-1zM5 19a1 1 0 0 1-1-1V5h12v13c0 .351.061.688.171 1H5zm15-1a1 1 0 0 1-2 0v-5h2v5z\"></path><path fill=\"none\" d=\"M6 7h8v2H6zm0 4h8v2H6zm5 4h3v2h-3z\"></path></svg>",  
    },
    {   id: 4, title: "Rate card management", 
        desc: "Create and manage your rate cards. Directly send your rate cards to clients or just download them.",
        icon: "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"800px\" height=\"800px\" viewBox=\"0 0 64 64\" stroke-width=\"3\" stroke=\"#000000\" fill=\"none\"><path d=\"M45.77,51a.09.09,0,0,1,.11,0l3.4,3.31s.12,0,.12-.07V9.7H14.6V54.16a.08.08,0,0,0,.13.07l4.38-3.29a.08.08,0,0,1,.1,0l4.53,3.33a.08.08,0,0,0,.11,0l4-3.33s.07,0,.11,0l4.52,3.32a.08.08,0,0,0,.11,0L36.79,51a.09.09,0,0,1,.12,0l4.51,3.31a.09.09,0,0,0,.12,0l4-3.3\" stroke-linecap=\"round\"/><line x1=\"18.72\" y1=\"31.11\" x2=\"32.02\" y2=\"31.11\" stroke-linecap=\"round\"/><line x1=\"18.72\" y1=\"25.77\" x2=\"34.69\" y2=\"25.77\" stroke-linecap=\"round\"/><line x1=\"18.72\" y1=\"15\" x2=\"36.05\" y2=\"15\" stroke-linecap=\"round\"/><line x1=\"18.72\" y1=\"20.11\" x2=\"33.15\" y2=\"20.11\" stroke-linecap=\"round\"/><line x1=\"41.01\" y1=\"30.95\" x2=\"45.28\" y2=\"30.95\" stroke-linecap=\"round\"/><line x1=\"18.72\" y1=\"36.81\" x2=\"34.69\" y2=\"36.81\" stroke-linecap=\"round\"/><line x1=\"41.86\" y1=\"36.66\" x2=\"45.28\" y2=\"36.66\" stroke-linecap=\"round\"/><line x1=\"41.01\" y1=\"42.56\" x2=\"45.28\" y2=\"42.56\" stroke-linecap=\"round\"/><line x1=\"41.01\" y1=\"25.61\" x2=\"45.28\" y2=\"25.61\" stroke-linecap=\"round\"/><line x1=\"41.86\" y1=\"20.11\" x2=\"45.28\" y2=\"20.11\" stroke-linecap=\"round\"/><line x1=\"41.01\" y1=\"15\" x2=\"45.28\" y2=\"15\" stroke-linecap=\"round\"/></svg>",
    },
    {   id: 5, title: "Project management", 
        desc: "Estimate your projects. Get insights and track your freelance projects with ease.",
        icon: "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" style=\"fill:; transform: ;msFilter:;\"><path fill=\"none\" d=\"M4.626 8.878a7.937 7.937 0 0 1 1.71-2.541 7.92 7.92 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.49 2.49 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059-1.19.5-2.26 1.22-3.18 2.139A9.98 9.98 0 0 0 2 12h2c0-1.086.211-2.136.626-3.122zm14.747 6.244c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.973 9.973 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z\"></path><path fill=\"none\" d=\"M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538c2.502 0 4.538-2.036 4.538-4.538S14.502 7.462 12 7.462z\"></path></svg>",  
    },
    {   id: 6, title: "Client management", 
        desc: "Organize and manage your client list. Add notes or documents about clients that's accessible to only you.",
        icon:  `<svg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 16 16" fill="none">
                    <path d="M8 7C9.65685 7 11 5.65685 11 4C11 2.34315 9.65685 1 8 1C6.34315 1 5 2.34315 5 4C5 5.65685 6.34315 7 8 7Z" fill=""/>
                    <path d="M14 12C14 10.3431 12.6569 9 11 9H5C3.34315 9 2 10.3431 2 12V15H14V12Z" fill=""/>
                </svg>
                `,
    },
];

export const integrationList = [
    {
        id: "Calendar",
        desc: "Sync your workflows and organize your work schedules effectively. Explore the convenience our calendar integration offers.",
    },
    {
        id: "AI Assistance & Automation",
        desc: "Use the power of automation for a smoother, more productive experience.",
    },
    {
        id: "Time tracking",
        desc: "Track and manage your projects with ease. Stay on top of deadlines with our time tracking integration.",
    },
];

export const faqs = [
    {
        question: "What does Ziner do?",
        answer: "Ziner is a centralized platform of features and integrations designed to help creators boost their workflows. Manage everything in one place and save time for what matters most—your work!",
    },
    {
        question: "How does Ziner benefit freelancers?",
        answer: "We simplify your workflows by allowing you to connect essential tools, manage integrations, potential leads, projects, invoices...etc, to suit your needs as a creator.",
    },
    {
        question: "Is Ziner free?",
        answer: "We offer a free plan with access to basic features and integrations. For advanced features, a standard is available. Check out our pricing section for more details.",
    },
];

export const rankingList = [
    { 
        name: "Shoshinsha", 
        symbol: "初心者",
        desc: "Congratulations! You've completed up to 5 projects.", 
    },
    { 
        name: "Chūkyū", 
        symbol: "中級",
        desc: "Congratulations! You've completed up to 30 projects.", 
    },
    { 
        name: "Sensei", 
        symbol: "先生",
        desc: "Congratulations! You've completed up to 70 projects.", 
    },
    { 
        name: "Shishou", 
        symbol: "師匠",
        desc: "Congratulations! You've completed up to 160 projects.", 
    },
];

export const rankObj = {
    "Shoshinsha": "Congratulations! You've completed a minimum of 5 projects.", 
    "Chūkyū": "Congratulations! You've completed a minimum of 10 projects.", 
    "Sensei": "Congratulations! You've completed a minimum of 25 projects.", 
    "Shishou": "Congratulations! You've completed a minimum of 50 projects.", 
};

export const clientHeaders = [
    { id: "Name", accessorKey: "name", iconName: "user"},
    // { id: "Company name", accessorKey: "companyName", iconName: "user"},
    { id: "email", accessorKey: "email", iconName: "envelope-solid"},
    { id: "industry", accessorKey: "industry", iconName: "buildings"},
    { id: "status", accessorKey: "status", iconName: "reports-solid"},
    { id: "Date added", accessorKey: "createdAt", iconName: "timer-solid"},
];

export const projectHeaders = [
    { id: "Project title", accessorKey: "title", iconName: "user"},
    { id: "status", accessorKey: "status", iconName: "envelope-solid"},
    { id: "deadline", accessorKey: "deadline", iconName: "buildings"},
    { id: "Payment Status", accessorKey: "paymentStatus", iconName: "reports-solid"},
    { id: "Priority", accessorKey: "priority", iconName: "buildings"},
    { id: "Date added", accessorKey: "createdAt", iconName: "timer-solid"},
];

export const invoiceHeaders = [
    { id: "Client", accessorKey: "clientName", iconName: "envelope-solid"},
    { id: "Email", accessorKey: "clientEmail", iconName: "envelope-solid"},
    { id: "Status", accessorKey: "Status", iconName: "user"},
    { id: "Amount", accessorKey: "amount", iconName: "buildings"},
    { id: "Invoice No.", accessorKey: "invoiceNo", iconName: "reports-solid"},
    { id: "Due Date", accessorKey: "dueDate", iconName: "timer-solid"},
    { id: "Date added", accessorKey: "createdAt", iconName: "timer-solid"},
];

export const clientInvoiceHeaders = [
    { id: "Status", accessorKey: "Status", iconName: "user"},
    { id: "Amount", accessorKey: "amount", iconName: "buildings"},
    { id: "Invoice No.", accessorKey: "invoiceNo", iconName: "reports-solid"},
    { id: "Due Date", accessorKey: "dueDate", iconName: "timer-solid"},
    { id: "Date added", accessorKey: "createdAt", iconName: "timer-solid"},
];

export const paymentHistoryHeaders = [
    { id: "Payment Method", accessorKey: "paymentMethod", iconName: "envelope-solid"},
    { id: "Price", accessorKey: "price", iconName: "user"},
    { id: "Duration", accessorKey: "duration", iconName: "buildings"},
    { id: "Billing Date", accessorKey: "billingDate", iconName: "reports-solid"},
];

export const integrationHeaders = [
    { id: "Service name", accessorKey: "name", iconName: "user"},
    { id: "status", accessorKey: "status", iconName: "reports-solid"},
    // { id: "Date added", accessorKey: "dateAdded", iconName: "reports-solid"},
];

export const timeSheetHeaders = [
    { id: "Start date", accessorKey: "startDate", iconName: "user"},
    { id: "End date", accessorKey: "endDate", iconName: "timer-solid"},
    { id: "Total time", accessorKey: "totalTime", iconName: "buildings"},
];

export const workingDays = [
    { name: "Sunday", isActive: false },
    { name: "Monday", isActive: true },
    { name: "Tuesday", isActive: false },
    { name: "Wednesday", isActive: true },
    { name: "Thursday", isActive: true },
    { name: "Friday", isActive: true },
    { name: "Saturday", isActive: false },
];

export const allowedCurrencies = ["USD", "NGN", "GBP", "GHS", "EUR"];

export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const dateFormats = ["DD/MM/YY", "DD/MM/YYYY", "DD/MMMM/YYYY", "MM/DD/YY", "MMMM/DDDD/YYYY"];

export const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

export const theme = ["default", "dark", "orange", "brick red"];

export const invoiceReminders = [
    { id: "14_days_before", name: "14 days before the due date"},
    { id: "7_days_before", name: "7 days before the due date"},
    { id: "on_due_date", name: "On the due date"},
    { id: "a_day_after", name: "A day after the due date"},
    { id: "3_days_after", name: "3 days after the due date"},
    { id: "7_days_after", name: "7 days after the due date"},
    { id: "14_days_after", name: "14 days after the due date"},
];

export const rateCardUploadHeaders = [
    { title: "Title", required: true },
    { title: "Currency", required: true },
    { title: "Amount", required: true },
    { title: "Rate Type", required: true },
    { title: "Description", required: false },
    { title: "Billable Hours", required: false },
    { title: "Billable Rate", required: false },
];

export const clientUploadHeaders = [
    { title: "Name", required: true },
    { title: "Company Name", required: true },
    { title: "Email", required: true },
    { title: "Status", required: true },
    { title: "Industry", required: false },
];

export const projectUploadHeaders = [
    { title: "Title", required: true },
    { title: "Status", required: false },
    { title: "Payment status", required: false },
];

export const accountIntegrations = {
    calendar: [],
    time_tracking: [], // monday.com, google sheets
    email: [],
};

export const passwordChecks = {
    id: "sixCharacters",
};

export const mapReminders = {
    "14_days_before": "14 days before the due date",
    "7_days_before": "7 days before the due date",
    "on_due_date": "On the due date",
    "a_day_after": "A day after the due date",
    "3_days_after": "3 days after the due date",
    "7_days_after": "7 days after the due date",
    "14_days_after": "14 days after the due date",
};

export const headerResources = [
    {
        name: "Help center",
        iconName: "info",
    },
    {
        name: "Getting started",
        iconName: "map-pin",
    },
    {
        name: "Pricing",
        iconName: "money",
    },
];

export const projectInvoiceHeaders = [
    { id: "Invoice No.", accessorKey: "invoiceNo", iconName: "reports-solid"},
    { id: "Amount", accessorKey: "amount", iconName: "buildings"},
    { id: "Status", accessorKey: "Status", iconName: "user"},
    { id: "Date added", accessorKey: "createdAt", iconName: "timer-solid"},
];

export const billingMethods = [
    { id: "personal_account", name: "Personal account" },
    { id: "external", name: "A third-party service, e.g, Stripe, Paystack..." },
];

export const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Fridays", "Saturday", "Sunday"];

export const tasksGroup = ["none", "tags", "priority"];

export const paymentProviders = [
    { id: "flutterwave", name: "Flutterwave" },
    { id: "paystack", name: "Paystack" },
];

export const taxTypes = [
    { id: "income", name: "Income" },
    { id: "vat", name: "Value Added Tax (VAT)" },
    { id: "payroll", name: "Payroll" },
    { id: "corporate", name: "Corporate" },
    { id: "sales", name: "Sales" },
    { id: "property", name: "Property" },
    { id: "tariff", name: "Tariff" },
    { id: "estate", name: "Estate" },
];

export const mapTaxTypes = {
    "income": "Income",
    "vat": "Value Added Tax (VAT)",
    "payroll": "Payroll",
    "corporate": "Corporate",
    "sales": "Sales",
    "property": "Property",
    "tariff": "Tariff",
    "estate": "Estate",
};

export const occupationList = [
    "software development", "marketing", "digital content creation", "writing", "product design", 
    "photography", "videography", "product management", "project management", "fashion",
    "business analysis", "data analysis", "data science", "finance", 
    "research","personal assistance", "customer service", "social media management",
];

export const zinerUseCases = [
    "Project and task management",
    "Manage clients and schedule meetings",
    "Invoice management",
    "Rate card management",
    "All of the above",
];

export const accountPlanMap = {
    "free": "Free",
    // "standard": "Standard",
    "plus": "Plus",
};

export const pricingInfoData = [
    { 
        id: "Free",
        desc: "", 
        price: 0, 
        plans: [
            "Limited client database management",
            "Project and task management",
            "Calendar integrations",
            "Up to 5 invoices",
            "Limited rate card management",
        ],
    },
    { 
        id: "Standard",
        desc: "", 
        price: 5000, 
        plans: [
            "Unlimited client database management",
            "Project and task management",
            "Integrations",
            "Unlimited invoices",
            "Advanced reporting",
            "Unlimited rate cards with downloads",
            "Unlimited document uploads",
            "Reminders and email notifications",
        ],
    },
];

export const socials = ["Instagram", "X", "Pinterest", "Github", "Facebook", "TikTok", "LinkedIn"];

export const rateCardSocialHeaders = [
    { id: "Platform name", accessorKey: "platformName", iconName: "reports-solid"},
    { id: "Link", accessorKey: "amount", link: "buildings"},
];

export const rateCardServiceHeaders = [
    { id: "Service Name", accessorKey: "serviceName", iconName: "reports-solid"},
    { id: "Description", accessorKey: "description", iconName: "user"},
    { id: "Amount", accessorKey: "amount", iconName: "money"},
];