<template>
    <AuthForm 
        auth-type="signup" :loading="loading" :error="error"
        @form-submit="handleSignup"
    />
</template>

<script>
import AuthForm from "@/components/auth/AuthForm.vue";

export default {
    name: "SignupPage",

    components: {
        AuthForm,
    },

    data() {
        return {
            error: {
                show: false,
                value: "",
            },
            loading: false,
        };
    },

    computed: {
        isNewUser() {
            return this.$store.getters["app/isNewUser"];
        },
    },

    methods: {
        async handleSignup(payload) {
            try {
                this.error.show = false;
                this.error.value = "";
                this.loading = true;

                const plan = localStorage.getItem("z_pricing_plan");
                payload.accountPlan = plan;

                await this.$store.dispatch("auth/signup", payload);
                
                this.$toast.success("Signup successful");
                this.$store.dispatch("app/toggleNewUser", true);
                this.$router.push(`/dashboard/reports?type=clients&year=${new Date().getFullYear()}`);
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                                
                this.error.value = errMsg;
                this.error.show = true;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>