<template>
    <div class="clients__page clients__page--view">
        <template v-if="pageLoading">
            <PageSpinner />
        </template>

        <template v-else-if="loadingErr">
            <EmptyPage state="error" :handle-submit="handleFetchGoals" />
        </template>

        <template v-else>
            <div>
                <div>
                    <p class="text--sm">
                        Set goals to measure your progress, narrow focus, and boost productivity. Find motivation through goal setting today! 
                        <CustomLink to="/goals" is-primary>
                            Learn more
                        </CustomLink>.
                    </p>
                </div>
    
                <div>
                    <div class="settings__auth__header mt--30">
                        <div class="flex align-center justify-content-between w-100">
                            <p>Goals</p>
                            <div class="form-check form-switch">
                                <input
                                    id="flexSwitchCheckDefault" v-model="form.isGoalsEnabled" class="form-check-input"
                                    type="checkbox" role="switch"
                                >
                                <label class="form-check-label" for="flexSwitchCheckDefault" />
                            </div>
                        </div>
                    </div>

                    <div v-for="item in groupByKeys" :key="item" class="mt--20">
                        <p class="mb--15 text--semi--bold text--base text--capitalize">
                            {{ item }}
                        </p>
                        <div>
                            <p class="mb--10 text--medium text--sm">
                                Frequency
                            </p>
                            <div v-for="freq in groupedListByType[item]" :key="freq.name" class="form-check form-check-inline">
                                <input
                                    id="flexCheckDefault" v-model="freq.isActive" class="form-check-input" 
                                    type="checkbox" :value="freq.isActive"
                                >
                                <label class="form-check-label text--sm text--capitalize" for="flexCheckDefault">
                                    {{ freq.name }}
                                </label>
                            </div>
                        
                            <div v-for="freq in groupedListByType[item]" :key="freq.name" class="mt--20 w--40">
                                <p class="mb--10 text--sm text--medium">
                                    Target for {{ freq.name }} {{ item }}
                                </p>
                                <FormInput
                                    :id="`${freq.name}Tasks`" v-model="freq.value" size="sm" type="number"
                                    :disabled="!form.isGoalsEnabled"
                                />
                            </div>
                        </div>
                    </div>
                </div>
    
                <div>
                    <div class="settings__auth__header mt--30">
                        <div class="flex align-center justify-content-between w-100">
                            <p>Productivity</p>
                            <div class="form-check form-switch">
                                <input
                                    id="flexSwitchCheckDefault" v-model="form.isProductivityEnabled" class="form-check-input"
                                    type="checkbox" role="switch"
                                >
                                <label class="form-check-label" for="flexSwitchCheckDefault" />
                            </div>
                        </div>
                    </div>
                    <div class="mt--20">
                        <p class="mb--10 text--medium text--sm">
                            Working days
                        </p>
                        <div v-for="day in daysOfWeek" :key="day" class="form-check form-check-inline">
                            <input
                                id="flexCheckDefault" v-model="form.productivity.workingDays" 
                                :value="day" class="form-check-input" type="checkbox" :disabled="!form.isProductivityEnabled"
                            >
                            <label class="form-check-label text--sm text--capitalize" for="flexCheckDefault">
                                {{ day }}
                            </label>
                        </div>
                    </div>

                    <div class="mt--15">
                        <p class="mb--10 text--medium text--sm">
                            Status
                        </p>
                        <div v-for="status in ['work', 'vacation']" :key="status" class="form-check form-check-inline">
                            <input
                                id="inlineRadio1" v-model="form.productivity.status" class="form-check-input" type="radio"
                                name="inlineRadioOptions" :value="status" :disabled="!form.isProductivityEnabled"
                            >
                            <label class="form-check-label text--capitalize" for="inlineRadio1">{{ status }}</label>
                        </div>
                    </div>
                </div>
    
                <div class="settings__auth__header mt--30" />

                <div class="flex justify-content-end w-100 mt--20 mb--45">
                    <base-button size="sm" label="Save changes" :loading="loading" @submit="handleUpdate" />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {
    BaseButton,
    CustomLink,
    EmptyPage,
    PageSpinner,
    FormInput,
} from "@/components/ui/index";
import { groupList } from "@/utils/helpers";
import { daysOfWeek } from "@/utils/dummy";

export default {
    name: "GoalsAndProductivity",

    components: {
        BaseButton,
        CustomLink,
        PageSpinner,
        EmptyPage,
        FormInput,
    },

    data() {
        return {
            daysOfWeek,
            form: {
                _id: "",
                isGoalsEnabled: false,
                isProductivityEnabled: false,
                goals: [],
                productivity: {
                    status: "",
                    workingDays: [],
                },
            },
            loadingErr: false,
            pageLoading: false,
            loading: false,
        };
    },

    computed: {
        groupedListByType() {
            if (!this.form.goals.length) {
                return [];
            }

            const arr = this.groupList(this.form.goals, (item) => {
                const name = item.type;
                const filter = name;

                return filter;
            });

            return Object.fromEntries(arr);
        },

        groupByKeys() {
            return Object.keys(this.groupedListByType);
        },
    },

    async mounted() {
        await this.handleFetchGoals();
    },

    methods: {
        groupList,

        async handleFetchGoals() {
            try {
                this.loadingErr = false;
                this.pageLoading = true;

                const res = await this.$store.dispatch("app/fetchGoals");

                this.form = {
                    ...this.form,
                    ...res,
                };
            } catch (error) {
                this.loadingErr = true;
            } finally {
                this.pageLoading = false;
            }
        },

        async handleUpdate() {
            try {
                this.loading = true;

                const payload = this.form;
                await this.$store.dispatch("app/updateGoals", payload);
                this.$toast.success("Goals updated");
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>