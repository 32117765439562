<template>
    <MainModal :show-modal="showModal" size="sm" :footer="false" :close-btn="false">
        <template #body>
            <div class="onboarding__wrap">
                <div class="onboarding__content">
                    <div class="onboarding__body"> 
                        <template v-if="step === 1">
                            <div class="onboarding__img">
                                <img src="@/assets/img/system-testing-svgrepo-com.svg">
                            </div>

                            <div class="onboarding__header">
                                <p class="onboarding__body__title">
                                    Welcome to Ziner
                                </p>
                                <p class="mt--5 text--sm text--color-gray-200">
                                    We're glad to have you onboard. To get you started, we need some more information from you.
                                </p>
                            </div>
                            
                            <div class="flex justify-content-center align-items-center">
                                <BaseButton 
                                    variant="ghost" 
                                    inner-style="secondary" 
                                    class="mr--10"
                                    label="Skip" 
                                    @submit="skipSteps"
                                />
                                <BaseButton 
                                    label="Next" suffix-icon icon-name="arrow-right"
                                    @submit="step = 2"
                                />
                            </div>
                        </template>

                        <template v-else>
                            <div class="mt--30">
                                <div class="onboarding__header">
                                    <p class="onboarding__body__title">
                                        Tell us a bit more about yourself
                                    </p>
                                    <p class="mt--5 text--sm text--color-gray-200">
                                        This helps us customize your experience by providing relevant tools and features for your specific needs as a creator.
                                    </p>
                                </div>

                                <template v-if="step === 2">
                                    <FormLabel title="What industry do you primarily work in?" />
                                    <CustomDropdown 
                                        id="occupationDropdown" v-model="form.occupation" list-item-class="text--capitalize"
                                        size="md" btn-classes="text--capitalize" :options="occupationList"
                                        list-type="occupation" @search="searchOccupation" @select="val => form.occupation = val" 
                                    />
                                </template>
                                
                                <template v-if="step === 3">
                                    <FormLabel title="How do you plan to use Ziner?" />
                                    <div 
                                        v-for="(item, index) in zinerUseCases" :key="item" 
                                        class="onboarding__usecase__item" :class="{ 'active': form.use_cases.includes(item) }"
                                    >
                                        <div class="form-check form-check-inline w--100">
                                            <input 
                                                :id="`inlineCheckbox${index}`" v-model="form.use_cases" 
                                                class="form-check-input" type="checkbox" :value="item"
                                            >
                                            <label class="form-check-label mb--0" :for="`inlineCheckbox${index}`">{{ item }}</label>
                                        </div>
                                    </div>
                                </template>
            
                                <template v-if="step === 4">
                                    <div>
                                        <FormLabel title="What country are you signing up from?" />
                                        <CustomDropdown 
                                            id="countryDropdown" v-model="form.country"
                                            size="md" :options="countries" index-label="name"
                                            list-type="country" @search="searchCountry" @select="val => form.country = val" 
                                        />
                                    </div>

                                    <div v-if="form.country" class="mt--15">
                                        <FormLabel title="Select a primary currency" html-for="currency" />
                                        <CustomDropdown 
                                            id="currencyDropdown" v-model="form.currency" :options="initialCurrencyList"
                                            list-type="currencies" index-label="currency" :add-on="selectedCurrency.symbol"
                                            @search="searchCurrencies" @select="selectCurrency" 
                                        />
                                    </div>
                                </template>
                            </div>
                        </template>
                    </div>

                    <div v-if="step > 1" class="w--100 flex align-items-center justify-content-between mt--30">
                        <div class="flex align-items-center">
                            <p class="text--xs mr--10">
                                {{ step }}/4
                            </p>
                            <SegmentedProgressBar 
                                :total="4" 
                                :filled="step"  
                                clickable
                                item-class="onboarding__progress__item" 
                                @segment-click="setStep"
                            />
                        </div>

                        <div class="flex align-items-center">
                            <BaseButton 
                                variant="outline" 
                                inner-style="secondary" 
                                class="mr--10"
                                size="sm"
                                label="Skip" @submit="skipSteps"
                            />
                            <BaseButton 
                                :label="step < 4 ? 'Next' : 'Submit'" 
                                :disabled="btnDisabled || onboardingLoading" 
                                :loading="onboardingLoading"
                                size="sm"
                                @submit="nextStep"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </MainModal>
</template>

<script>
import BaseButton from "../buttons/BaseButton.vue";
import IconSvg from "../icons/Icon-Svg.vue";
import WelcomeIcon from "../icons/WelcomeIcon.vue";
import MainModal from "./MainModal.vue";
import SegmentedProgressBar from "../progress/Segmented.vue";
import CustomDropdown from "../dropdowns/CustomDropdown.vue";
import { occupationList, zinerUseCases } from "@/utils/dummy";
import countryList from "@/assets/js/countrycodes.json";
import FormLabel from "../forms/FormLabel.vue";

export default {
    name: "OnboardingModal",

    components: {
        MainModal, CustomDropdown,
        SegmentedProgressBar,
        BaseButton, IconSvg, FormLabel,
    },

    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
    },

    emits: ["continue"],

    data() {
        return {
            occupationList,
            countries: countryList,
            zinerUseCases,
            step: 1,
            form: {
                country: "",
                occupation: "",
                use_cases: [],
                currency: "",
            },
            onboardingLoading: false,
            initialCurrencyList: [],
            currencyMap: null,
            selectedCurrency: {
                currency: "", 
                symbol: "",
            },
        };
    },

    computed: {
        btnDisabled() {
            if (this.step === 2) {
                return !this.form.occupation;
            } 

            if (this.step === 3) {
                return !this.form.use_cases.length;
            }

            if (this.step === 4) {
                return !this.form.country;
            }

            return false;
        },

        profile() {
            return this.$store.getters["auth/profile"];
        },
    },

    mounted() {
        this.currencyMap = this.$supported_currencies_map;
        this.initialCurrencyList = Object.values(this.$currencies_obj);
    },

    methods: {
        outsideee() {
            // console.log("clicked on account setup");
        },

        selectCurrency(item) {
            const index = item && item.toLowerCase() || "";

            this.selectedCurrency = this.currencyMap[index];
            this.form.currency = item;
        },

        searchCurrencies(val) {
            const initialList = this.initialCurrencyList;

            if (val) {
                const filteredList = initialList.filter(item => item.toLowerCase().includes(val));
                this.initialCurrencyList = filteredList;
            } else {
                this.initialCurrencyList = Object.values(this.$currencies_obj);
            }
        },

        nextStep() {
            if (this.step < 4) {
                this.step = this.step + 1;
            } else {
                this.updateUserInformation();
            }
        },

        setStep(index) {            
            if (this.step !== index + 1) {                
                this.step = index + 1;
            }
        },

        prevStep() {
            this.step = this.step - 1;
        },

        skipSteps() {
            this.$emit("continue");
        },

        searchOccupation(val) {
            this.occupationList = occupationList.filter((item) => {
                return item.toLowerCase().includes(val?.toLowerCase());
            });
        },

        searchCountry(val) {
            this.countries = countryList.filter((item) => {
                return item.name.toLowerCase().includes(val?.toLowerCase());
            });
        },

        async updateUserInformation() {
            try {
                this.onboardingLoading = true;

                const payload = {
                    ...this.profile,
                    occupation: this.form.occupation,
                    country: this.form.country.name,
                };

                delete payload?.updatedAt;
                delete payload?.createdAt;
                delete payload?.__v;
                delete payload?._id;

                await this.$store.dispatch("auth/updateProfile", payload);
                this.onboardingLoading = false;
                this.skipSteps();
                this.$toast.success("Information recorded");
            } catch (error) {
                this.onboardingLoading = false;
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            }
        },
    },
};
</script>