<template>
    <div
        :id="computeModalId"
        class="modal fade"
        tabindex="-1"
        :aria-labelledby="computeModalAriaLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5
                        id="deleteClientLabel"
                        class="modal-title"
                    >
                        Delete {{ type }}
                    </h5>
                    <button
                        v-if="!loading"
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    />
                </div>
                <div class="modal-body">
                    <div class="form--workspace__create mb--20">
                        <div class="form__item">
                            <div class="mb-3">
                                <p v-if="!isCustomMsg">
                                    {{ computedMsg }}
                                </p>
                                <slot v-else name="deletedMsg" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <base-button 
                        class-names="mr--5" label="Cancel" 
                        variant="outline" inner-style="secondary" size="sm" aria-label="Close"
                        data-bs-dismiss="modal" @submit="$emit('cancel')"
                    />
                    <base-button 
                        :loading="loading"
                        :disabled="loading" 
                        :full-width="false"
                        variant="warning"
                        :label="copy.btnLabel" 
                        size="sm"
                        @submit="$emit('delete')"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseButton from "../buttons/BaseButton";

export default {
    name: "ConfirmDeletion",

    components: {
        BaseButton,
    },

    props: {
        type: {
            type: String,
            default: "",
        },
        loading: {
            type: Boolean,
            default: false,
        },
        items: {
            type: Array,
            default: () => [],
        },

        isCustomMsg: {
            type: Boolean,
            default: false,
        },
    },
    
    computed: {
        computeModalId() {
            return "delete" + this.type.charAt(0).toUpperCase() + this.type.slice(1);
        },

        computeModalAriaLabel() {
            return "delete" + this.type.charAt(0).toUpperCase() + this.type.slice(1) + "Label";
        },

        computedMsg() {
            if (this.isCustomMsg) {
                return "";
            }

            if (this.items && this.items.length) {
                return `Are you sure you want to delete these ${this.items.length} ${this.type}s?`;
            } else {
                return `Are you sure you want to delete this ${this.type}?`;
            }
        },
    },
};
</script>