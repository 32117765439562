<template>
    <div 
        class="circular-badge"
        v-bind="$attrs"
        :class="[`${checked ? 'checked': ''} circular-badge--${size}`]" 
    >
        <span v-if="label" class="circular-badge-label">
            {{ label }}
        </span>
        <icon-svg name="check" :fill="checked ? '#92bfc4' : 'rgb(9 9 11 / 51%)'" width="16px" height="16px" />
    </div>
</template>

<script>
import IconSvg from "../icons/Icon-Svg.vue";

export default {
    name: "BadgeCheck",

    components: { IconSvg },

    props: {
        checked: { type: Boolean, default: false },
        label: { type: String, default: "" },
        size: { 
            type: String, 
            default: "md",
            validator: (value) => {
                return ["sm", "md", "lg"].includes(value);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.circular-badge {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin: 3px 8px;
    margin-left: 0px;
    border-radius: 20px;
    border: 1px solid #cecece;
    background: rgb(255, 255, 255); 
    font-size: 0.75rem;
    padding: 5px 8px;
    color: rgb(9 9 11 / 71%);

    &.checked {
        background: #3b3b3c;
        color: #f2f2f2;
        background: transparent;
        border: 1px solid #92bfc4;
        color: #92bfc4;
    }

    &--sm {
        padding: 4px 10px;
    }

    &-label {
        font-weight: 500;
    }
}
</style>