<template>
    <div class="h--100 relative min-h-inherit">
        <div class="page--wrap" :class="{ 'disabled': deleteLoading, 'min-h-inherit': !projects.length }">
            <template v-if="pageLoading">
                <PageSpinner />
            </template>

            <template v-else-if="fetchErr">
                <EmptyPage state="error" :handle-submit="handleFetchProjects" />
            </template>
            
            <template v-else>
                <div v-if="showActionBtns" class="flex align-items-center justify-content-end w--100" :class="{ 'disabled': checkUpgradeLoading && checkUpgradeFields.projects }">                        
                    <BaseButton 
                        v-if="selectedProjects.length > 0" class="mr--5"
                        variant="warning" size="sm"
                        @submit="toggleDelete"
                    >
                        Delete {{ selectedProjects.length }} {{ selectedProjects.length === 1 ? 'project' : 'projects' }}
                    </BaseButton>
                    <MainFilter class="mr--5" page-type="projects" />
                    <SortFilter class="mr--5" size="sm" :value="displayType" @setType="setDisplayType" />
                    <BaseButton 
                        label="+ Add project" size="sm" class="'flex align-items-center"
                        :class="{ 'disabled': checkUpgradeLoading && checkUpgradeFields.projects }" @submit="initiateCreate"
                    />
                </div>

                <div class="w--100" :class="{ 'min-h-inherit justify-content-center flex align-items-center': !projects.length }">
                    <template v-if="!projects.length">
                        <EmptyPage v-if="isSearched" state="no_result" />

                        <EmptyPage
                            v-else state="empty" icon-name="project" width="60px"
                            height="60px" type="project"
                        >
                            <template #button>
                                <BaseButton 
                                    label="+ New project" size="sm"
                                    :disabled="checkUpgradeLoading && checkUpgradeFields.projects"
                                    @submit="initiateCreate"
                                />
                            </template>
                        </EmptyPage>
                    </template>

                    <template v-else>
                        <div class="flex mt--40">
                            <CustomTable
                                :columns="projectHeaders"
                                :data="projects"
                                :selected-for-deletion="selectedProjects"
                                router-name="project-details-view"
                                is-action-btns table-type="striped"
                                is-row-link
                                @onRowClick="openDetailsPage"
                                @onRowSelected="selectProjects"
                            >
                                <template #rows="data">
                                    <td>{{ data.title || '-' }}</td>
                                    <td>
                                        <Status type="project" :value="data.status" />
                                    </td>
                                    <td>{{ $format_date(data.deadline) || '-' }}</td>
                                    <td class="text--capitalize">
                                        {{ data.projectType || '-' }}
                                    </td>
                                    <td class="text--capitalize">
                                        {{ data.priority || '-' }}
                                    </td>
                                    <td>{{ $format_date(data.createdAt) || "-" }}</td>
                                </template>
                            </CustomTable>
                        </div>

                        <div class="table__striped__footer mt--5">
                            <p class="table__striped__footer__results">
                                {{ projects.length }} results
                            </p>
                            <pagination v-if="showPagination" :pagination-data="pageData" />
                        </div>
                    </template>
                </div>
            </template>
        </div>

        <CreateProjectModal
            :show-modal="showCreateProjectModal" :loading="createLoading"
            @close="showCreateProjectModal = false" @complete="handleCreateProject"
        />

        <ConfirmDeletionTwo
            type="project" custom-msg
            :show-modal="showDeleteModal" :items="selectedProjects"
            :loading="deleteLoading"
            @cancel="showDeleteModal = false" @delete="deleteMultipleProjects"
        >
            <template #custom-msg>
                <p class="text--sm">
                    The selected item(s) will be removed from all related clients and invoices. <strong>This action cannot be undone.</strong>
                </p>
            </template>
        </ConfirmDeletionTwo>

        <UpgradeAccountModal :show-modal="showUpgradeModal" type="projects" @cancel="closeUpgradeModal" />
    </div>
</template>

<script>
import {
    MainFilter,
    SortFilter,
    Pagination,
    EmptyPage,
    CreateProjectModal,
    ConfirmDeletionTwo,
    PageSpinner,
    BaseButton,
    CustomTable,
    ImportButton, UpgradeAccountModal,
    Status,
} from "@/components/ui/index";
import { assembleQueryList } from "@/utils/helpers";
import { projectHeaders } from "@/utils/dummy";

export default {
    name: "ProjectsPage",

    components: {
        CreateProjectModal,
        MainFilter,
        ConfirmDeletionTwo,
        SortFilter,
        Pagination,
        EmptyPage,
        BaseButton,
        PageSpinner,
        CustomTable,
        UpgradeAccountModal,
        Status,
    },

    data() {
        return {
            displayType: "",
            selectedProjects: [],
            showCreateProjectModal: false,
            selectedProject: null,
            createLoading: false,
            deleteLoading: false,
            fetchErr: false,
            fetchErrMsg: "",
            showDeleteModal: false,
            projectHeaders,
            pageLoading: false,
            downloadType: "",
            showUploadModal: false,
            uploadProgress: 0,
            uploadContent: [],
            uploadErr: false,
            downloadLoading: false,
        };
    },

    computed: {
        pageData() {
            return this.$store.getters["project/pageData"];
        },

        showActionBtns() {
            if (this.pageData.total) {
                return true;
            } else if (!this.pageData.total && this.isSearched) {
                return true;
            } else {
                return false;
            }
        },

        projects() {
            return this.$store.getters["project/projects"];
        },

        clients() {
            return this.$store.getters["clients/clients"];
        },

        showPagination() {
            if (this.pageData?.total > 50 && this.pageData?.totalPages > 1) {
                return true;
            } else {
                return false;
            }
        },

        refreshList() {
            return this.$store.getters["project/refreshList"];
        },

        checkUpgradeLoading() {
            return this.$store.getters["app/checkUpgradeLoading"];
        },

        checkUpgradeFields() {
            return this.$store.getters["app/checkUpgradeFields"];
        },

        showUpgradeModal() {
            return this.$store.getters["app/showUpgradeModal"];
        },

        isSearched() {
            return Object.keys(this.$route.query).length;
        },
    },

    watch: {
        "$route.query": {
            async handler(newVal) {
                await this.handleFetchProjects({ refresh: true, ...newVal });
            },
            immediate: false,
        },

        displayType(newType, oldType) {
            if (newType !== oldType) {
                this.sortProjects();
            }
        },
    },

    methods: {
        async initiateCreate() {
            try {                
                await this.$store.dispatch("app/checkUpgradeStatus", "projects");
                this.showCreateProjectModal = true;
            } catch (error) {
                // console.log({initiateCreate:  error });
            }
        },

        closeUpgradeModal() {
            this.$store.dispatch("app/closeUpgradeModal", "projects");
        },

        openDetailsPage(row) {
            if (!row?._id) return;

            this.$router.push(`/dashboard/projects/${row._id}`);
        },

        setDownloadType(val) {
            this.downloadClients(val);
        },

        setDisplayType(val) {
            this.displayType = val;
        },

        toggleDelete() {
            this.showDeleteModal = !this.showDeleteModal;
        },

        sortProjects() {
            this.$store.dispatch("project/sortProjects", this.displayType);
        },

        async handleCreateProject({ payload, resetFn }) {
            try {
                this.loading = true;

                const resp = await this.$store.dispatch("project/addProject", { payload });

                if (resetFn) {
                    resetFn();
                }
                
                this.showCreateProjectModal = false;

                // don't await
                this.$store.dispatch("app/fetchActivities", { refresh: true });
                
                return this.$router.push({ name:"project-details-view", params: { id: resp?._id }});
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }
        },

        async handleFetchProjects(params) {
            try {
                this.pageLoading = true;
                this.fetchErr = false;
                this.fetchErrMsg = "";
                
                const pageQueryObj = {
                    page: params?.page || 1,
                    type: params.type || "active",
                    ...params,
                };

                const buildResult = assembleQueryList(pageQueryObj);
                await this.$store.dispatch("project/fetchProjects", { params: buildResult, refresh: params?.refresh });
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                
                this.fetchErr = true;
                this.fetchErrMsg = errMsg;
            } finally {
                this.pageLoading = false;
            }
        }, 

        async deleteMultipleProjects() {
            try {
                this.deleteLoading = true;

                await this.$store.dispatch("project/deleteProjects", this.selectedProjects);
                this.$toast.success("Project(s) deleted");
                
                this.selectProjects([]);
                this.showDeleteModal = false;
            } catch (error) {
                const errMsg = error?.response?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.deleteLoading = false;
            }
        },

        selectProjects(val) {
            this.selectedProjects = val;
        },
    },
};
</script>