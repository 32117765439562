export const projectStatusMap = {
    "in progress": {
        tag: "tag--blue",
        iconName: "hour-glass",
    },
    completed: {
        tag: "tag--green",
        iconName: "earnings",
    },
    blocked: {
        tag: "tag--red",
        iconName: "close",
    },
    pending: {
        tag: "tag--pink",
        iconName: "timer-solid",
    },
    "awaiting feedback": {
        tag: "tag--grey",
        iconName: "folder-open",
    },
    "to do": {
        tag: "tag--purple-2",
        iconName: "layer",
    },
    "requires fixes": {
        tag: "tag--red",
        iconName: "settings",
    },
    "archived": {
        tag: "tag--cornsilk",
        iconName: "folder-open",
    },
};

export const projectTypeMap = {
    "paid": {
        tag: "tag--green",
        iconName: "money",
    },
    "not paid": {
        tag: "tag--pink",
        iconName: "balance",
    },
};

export const invoiceStatusMap = {
    draft: {
        tag: "tag--blue",
        iconName: "layer",
    },
    blocked: {
        tag: "tag--purple",
        iconName: "close",
    },
    "fully paid": {
        tag: "tag--green",
        iconName: "money",
    },
    overdue: {
        tag: "tag--red",
        iconName: "alarm",
    },
    issued: {
        tag: "tag--pink",
        iconName: "receipt",
    },
    rejected: {
        tag: "tag--grey",
        iconName: "close",
    },
    "partially paid": {
        tag: "tag--purple-2",
        iconName: "signal",
    },
};

export const clientStatusMap = {
    active: {
        tag: "tag--green",
        iconName: "signal",
    },
    inactive: {
        tag: "tag--grey",
        iconName: "no-signal",
    },
    "onboarding": {
        tag: "tag--blue",
        iconName: "map-pin",
    },
    "potential lead": {
        tag: "tag--cornsilk",
        iconName: "user",
    },
    "reached out": {
        tag: "tag--purple",
        iconName: "phone",
    },
    "archived": {
        tag: "tag--pink",
        iconName: "close",
    },
};

export const integrationMap = {
    "enabled": {
        tag: "tag--green",
        iconName: "money",
    },
    "disabled": {
        tag: "tag--pink",
        iconName: "balance",
    },
    "none": {
        tag: "tag--ble",
        iconName: "balance",
    },
};

export const INVOICE_STATUSES = ["blocked", "draft", "overdue", "fully paid", "issued", "rejected", "partially paid"];

export const INVOICE_UPDATE_STATUSES = ["blocked", "draft", "due", "fully paid", "issued", "rejected", "partially paid"];

export const projectFilterStatuses = ["blocked", "completed", "in progress", "pending", "awaiting feedback", "requires fixes", "to do", "archived"];

export const clientFilterStatuses = ["active", "inactive", "onboarding", "potential lead", "reached out", "archived"];

export const invoiceFilterStatuses = ["rejected", "fully paid", "issued", "blocked", "overdue", "draft", "partially paid"];

export const priorities = ["low", "medium", "high", "highest"];

export const taskStatus = [
    {
        name: "Completed",
        value: false,
    },
    {
        name: "Completed",
        value: true,
    },
];

export const CLIENT_STATUSES = ["active", "inactive", "onboarding", "potential lead", "reached out"];

export const integrationProvidersLogos = {
    "calendly": "https://cdn.brandfetch.io/idbJpTKFPT/theme/dark/logo.svg?c=1dxbfHSJFAPEGdCLU4o5B",
    "google calendar": "",
};

export const rateTypeMap = {
    "yearly": "tag--blue",
    "one-off": "tag--cornsilk",
    "monthly": "tag--purple",
    "hourly": "tag--green",
    "weekly": "tag--pink",
};