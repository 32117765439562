<template>
    <div>
        <div class="pl--15 pr--15 pb--25">
            <template v-if="pageLoading">
                <PageSpinner />
            </template>

            <template v-else-if="pageLoadingErr">
                <EmptyPage state="error" type="task" :handle-submit="handleFetchTasks" />
            </template>

            <template v-else>
                <div class="w-100 pb--5 pt--25 invoice__details__row">
                    <div class="invoice__details__row__left project__details__row__left">
                        <div v-if="showActionBtns" class="flex align-items-center w-100 mb--15 justify-content-between">
                            <div class="list--count">
                                <p class="text--sm text--semi--bold text--color-gray-700">
                                    {{ tasks.length }} {{ tasks.length === 1 ? 'Task' : 'Tasks' }}
                                </p>
                            </div>
            
                            <div class="filter__actions--list">
                                <MainFilter page-type="tasks" />
                                <Group class="ml--5" :items="tasksGroup" :value="groupSelection" @select="setGroupSelection" />
                            </div>
                        </div>

                        <template v-if="!tasks.length">
                            <EmptyPage v-if="isSearched" state="no_result" />

                            <EmptyPage
                                v-else
                                state="empty" type="task" icon-name="task"
                                width="60px" height="60px"
                            >
                                <template #button>
                                    <BaseButton 
                                        label="+ New task" size="sm"
                                        class="flex align-items-center" @submit="showNewTask = true"
                                    />
                                </template>
                            </EmptyPage>
                        </template>

                        <template v-else>
                            <div class="mt--40 mb--15">
                                <template v-if="groupSelection === 'none'">
                                    <div v-for="item in tasks" :key="item._id" class="client__notes__item task__item">
                                        <div class="w--100 flex align-items-center justify-content-between" :class="{ 'disabled': taskIdToModify === item._id && markCompletedLoading }">
                                            <div class="pointer flex-1">
                                                <div class="form-check mb--0">
                                                    <input id="flexCheckDefault" class="form-check-input" type="checkbox" @change.stop="e => markTaskAsCompleted(e, item)">
                                                    <label class="form-check-label task__item__label text--sm pointer" for="flexCheckDefault" @click.prevent="setCurrentTask(item)">
                                                        {{ item.name }}
                                                        <div class="flex align-items-center mt--5">
                                                            <!-- <div v-if="item.project" class="text--color-gray-200 text--xs task__project__title ml--5">
                                                                # {{ item.project.title }}
                                                            </div> -->
                                                            <!-- <span v-if="item.project && item.deadline" class="ml--5 mr--5 text--color-gray-200">|</span> -->
                                                            <div v-if="item.deadline" class="flex align-items-center">
                                                                <span class="mr--5">
                                                                    <icon-svg name="calendar" fill="#BF2C6F" width="12px" height="12px" />
                                                                </span>
                                                                <span class="task__item__deadline">{{ $format_date(item.deadline) }}</span>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <template v-else>
                                    <ul v-for="task in tasks" :key="task._id">
                                        <div class="revenue__date__header">
                                            {{ task._id }}
                                        </div>

                                        <template v-if="task.tasks">
                                            <div v-for="item in task.tasks" :key="item._id" class="client__notes__item task__item">
                                                <div class="w--100 flex align-items-center justify-content-between" :class="{ 'disabled': taskIdToModify === item._id && markCompletedLoading }">
                                                    <div class="pointer flex-1">
                                                        <div class="form-check mb--0">
                                                            <input id="flexCheckDefault" class="form-check-input" type="checkbox" @change.stop="e => markTaskAsCompleted(e, item)">
                                                            <label class="form-check-label task__item__label text--sm pointer" for="flexCheckDefault" @click.prevent="setCurrentTask(item)">
                                                                {{ item.name }}
                                                                <div class="flex align-items-center mt--5">
                                                                    <div v-if="item.project" class="text--color-gray-200 text--xs task__project__title ml--5">
                                                                        # {{ item.project.title }}
                                                                    </div>
                                                                    <span v-if="item.project && item.deadline" class="ml--5 mr--5 text--color-gray-200">|</span>
                                                                    <div v-if="item.deadline" class="flex align-items-center">
                                                                        <span class="mr--5">
                                                                            <icon-svg name="calendar" fill="#BF2C6F" width="12px" height="12px" />
                                                                        </span>
                                                                        <span class="task__item__deadline">{{ $format_date(item.deadline) }}</span>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </ul>
                                </template>
                            </div>
                        </template>
                        
                        <div class="w-100">
                            <form class="w--100 client__notes__form mt--5" @submit.prevent="">
                                <div>
                                    <input 
                                        id="taskName"
                                        ref="newTask" 
                                        v-model="form.name" 
                                        class="form-control form-control-sm task__item__inline__input main" 
                                        type="text" placeholder="What do you want to do?"
                                    >
                                    <input 
                                        id="taskDescription" 
                                        v-model="form.description" 
                                        class="form-control form-control-sm sub task__item__inline__input" 
                                        type="text" placeholder="Add task description here..."
                                    >
                                </div>
    
                                <div class="client__notes__action__btns task__action__btns pr--0">
                                    <base-button
                                        class="mr--5" label="Cancel" variant="outline" size="sm" 
                                        @submit="showNewTask = false"
                                    />
                                    <base-button 
                                        type="submit" label="Add" :loading="addTaskLoading"
                                        size="sm" :disabled="!form.name || addTaskLoading" @submit="handleAddTask"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="invoice__details__row__right">
                        <TaskDetails :current-item-id="currentTaskId" task-type="task" :group-selection="groupSelection" />
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {
    IconSvg,
    EmptyPage,
    PageSpinner,
    BaseButton,
    MainFilter, Group,
    CustomDropdown,
} from "@/components/ui/index";
import { assembleQueryList } from "@/utils/helpers";
import { tasksGroup } from "@/utils/dummy";
import TaskDetails from "@/components/tasks/TaskDetails.vue";

export default {
    name: "TasksView",

    components: {
        EmptyPage,
        IconSvg,
        BaseButton,
        PageSpinner,
        MainFilter,
        TaskDetails, Group,
    },

    data() {
        return {
            pageLoading: false,
            pageLoadingErr: false,
            pageLoadingErrMsg: "",
            showNewTask: false,
            isSearched: false,
            form: {
                name: "",
                description: "",
                projectId: null,
                priority: null,
                deadline: null,
                isCompleted: false,
            },
            addTaskLoading: false,
            currentTaskId: "",
            markCompletedLoading: false,
            taskIdToModify: "",
            tasksGroup,
            groupSelection: "none",
        };
    },

    computed: {
        showActionBtns() {
            if (this.tasks.length) {
                return true;
            } else if (!this.tasks.length && this.isSearched) {
                return true;
            } else {
                return false;
            }
        },

        tasks() {
            return this.$store.getters["task/tasks"] || [];
        },

        pageData() {
            return this.$store.getters["task/pageData"];
        },

        refreshList() {
            return this.$store.getters["task/refreshList"];
        },
    },

    watch: {
        "$route.query": {
            async handler(newVal) {
                await this.handleFetchTasks({ refresh: true, ...newVal });
            },
            immediate: false,
        },

        "groupSelection": {
            async handler(newVal) {
                await this.handleFetchTasks({ refresh: true, groupBy: newVal });
            },
        },

        showNewTask(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    this.$refs?.newTask?.focus();
                });
            }
        },
    },

    async mounted() {
        const groupSelection = localStorage.getItem("z_group_task_selection");

        if (groupSelection) {
            this.groupSelection = groupSelection;
            this.setCurrentTask(this.tasks[0]?.tasks[0]);
        } else {
            this.setCurrentTask(this.tasks[0]);
        }
    },

    methods: {
        setGroupSelection(val) {
            this.groupSelection = val;
            localStorage.setItem("z_group_task_selection", val);
        },

        setCurrentTask(task) {            
            if (!task?._id) return;
            this.currentTaskId = task._id;
        },

        async handleFetchTasks(query) {
            try {
                this.pageLoading = true;
                this.pageLoadingErr = false;
                this.pageLoadingErrMsg = "";
                const queryKeys = Object.keys(query);
                const groupSelection = localStorage.getItem("z_group_task_selection") || this.groupSelection;

                if (queryKeys.length) {
                    this.isSearched = true;
                } else {
                    this.isSearched = false;
                }

                const pageQueryObj = {
                    page: query?.page || 1,
                    groupBy: query?.groupBy || groupSelection,
                };

                if (query.name) {
                    pageQueryObj.name = query.name;
                }

                if (query.priority) {
                    pageQueryObj.priority = query.priority;
                }

                if (query.startDate) {
                    pageQueryObj.startDate = query.startDate;
                }

                if (query.endDate) {
                    pageQueryObj.endDate = query.endDate;
                }

                const buildResult = assembleQueryList(pageQueryObj);
                await this.$store.dispatch("task/fetchTasks", { params: buildResult, refresh: query.refresh, groupBy: query.groupBy });

                if (query?.groupBy !== "none") {
                    this.setCurrentTask(this.tasks[0]?.tasks[0]);
                } else {
                    this.setCurrentTask(this.tasks[0]);
                }
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.pageLoadingErr = true;
                this.pageLoadingErrMsg = errMsg;
            } finally {
                this.pageLoading = false;
            }
        }, 

        async handleAddTask() {
            try {
                this.addTaskLoading = true;

                const payload = { 
                    name: this.form.name, 
                    description: this.form.description || null,
                    projectId: this.form.projectId,
                    priority: null,
                    deadline: null,
                    isCompleted: false,
                };

                await this.$store.dispatch("task/addTask", payload);
                this.$toast.success("Task added");

                this.form = {
                    name: "",
                    description: "",
                    projectId: "",
                    priority: null,
                    deadline: null,
                    isCompleted: false,
                };
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.addTaskLoading = false;
            }
        },

        async markTaskAsCompleted(e, task) {
            try {        
                this.markCompletedLoading = true;        
                this.taskIdToModify = task._id;
                
                const val = e?.target?.checked;
                const payload = {
                    _id: task._id, 
                    isCompleted: val, 
                    projectId: task?.project?._id, 
                    name: task.name,
                    description: task.description,
                };

                await this.$store.dispatch("task/editTask", { 
                    payload,
                    fullData: {
                        ...payload, 
                        ...task,
                    },
                });
                
                this.$toast.success("Task completed");
            } catch (err) {
                const errMsg = err?.response?.data?.message || err?.message;
                this.$toast.error(errMsg);
            } finally {
                this.markCompletedLoading = false;
                this.taskIdToModify = "";
            }
        },
    },
};
</script>