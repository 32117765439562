<template>
    <main-modal 
        :show-modal="showModal" 
        size="sm" title="Update password" @close="onCancel"
    >
        <template #body>
            <div class="modal__wrapper--onboarding">
                <div class="modal--section">
                    <form @submit.prevent="">
                        <FormInput 
                            id="oldPassword"
                            v-model="form.oldPassword.value"
                            :type="showPassword ? 'text' : 'password'"
                            required size="sm"
                            label="Old Password" 
                            :is-error="form.oldPassword.isError"
                            is-group is-suffix
                            :err-msg="form.oldPassword.errMsg"
                        >
                            <template #suffix>
                                <span @click="toggleViewPasswordIcon">
                                    <icon-svg fill="rgba(194, 200, 212, 1)" :name="showPassword ? 'show' : 'eye-hide'" width="16px" /> 
                                </span>
                            </template>
                        </FormInput>

                        <FormInput 
                            id="newPassword"
                            v-model="form.newPassword.value"
                            required
                            :html-for="'newPassword'" 
                            :label="'New Password'" size="sm"
                            :type="showPassword ? 'text' : 'password'"
                            :is-error="form.newPassword.isErr"
                            is-group is-suffix is-help
                            :err-msg="form.newPassword.errMsg"
                            :help-msg="'Password must be 8-20 characters long, contain letters and numbers only.'"
                            @keyup="checkIfPasswordIsValid"
                        >
                            <template #suffix>
                                <span @click="toggleViewPasswordIcon">
                                    <icon-svg fill="rgba(194, 200, 212, 1)" :name="showPassword ? 'show' : 'eye-hide'" width="16px" /> 
                                </span>
                            </template>
                        </FormInput>

                        <FormInput 
                            id="confirmNewPassword"
                            v-model="form.confirmNewPassword.value"
                            :html-for="'confirmNewPassword'" 
                            :label="'Confirm New Password'" size="sm"
                            :type="showPassword ? 'text' : 'password'"
                            :is-error="form.confirmNewPassword.isErr"
                            is-group 
                            is-suffix
                            no-margin-bottom
                            :err-msg="form.confirmNewPassword.errMsg"
                            @keyup="checkIfPasswordIsValid"
                        >
                            <template #suffix>
                                <span @click="toggleViewPasswordIcon">
                                    <icon-svg fill="rgba(194, 200, 212, 1)" :name="showPassword ? 'show' : 'eye-hide'" width="16px" /> 
                                </span>
                            </template>
                        </FormInput>
                    </form>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="w--100 flex justify-content-end align-items-center">
                <base-button 
                    class="mr--5" label="Cancel" size="sm"
                    variant="outline" @submit="onCancel"
                />
                <base-button 
                    :loading="loading" type="submit" size="sm" :disabled="isBtnDisabled || loading"
                    label="Update password" @submit="handleUpdate"
                />
            </div>
        </template>
    </main-modal>
</template>

<script>
import BaseButton from "../buttons/BaseButton.vue";
import FormInput from "../input/FormInput.vue";
import IconSvg from "../icons/Icon-Svg.vue";
import MainModal from "./MainModal.vue";

export default {
    name: "ChangePasswordModal",

    components: {
        FormInput,
        BaseButton,
        IconSvg,
        MainModal,
    },

    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
    },

    emits: ["cancel"],

    data() {
        return {
            form: {
                newPassword: {
                    value: "",
                    errMsg: "",
                    isErr: false,
                },
                confirmNewPassword:{
                    value: "",
                    errMsg: "",
                    isErr: false,
                },
                oldPassword: {
                    value: "",
                    errMsg: "",
                    isErr: false,
                },
            },
            loading: false,
            showPassword: false,
        };
    },

    computed: {
        isBtnDisabled() {
            if (!this.form.oldPassword.value || !this.form.newPassword.value || !this.form.confirmNewPassword.value) {
                return true;
            }

            return false;
        },

        isPasswordMatch() {
            if (!this.form.newPassword.value) {
                return false;
            } 
            
            if (this.form.newPassword.value && (this.form.newPassword.value !== this.form.confirmNewPassword.value)) {
                return false;
            }

            return true;
        },
    },

    methods: {
        checkIfPasswordIsValid(e) {
            const value = e?.target.value;

            if (value.length < 1) return;

            if (value.length < 5) {
                this.passwordErr = true;
            } else {
                this.passwordErr = false;
            }
        },

        async handleUpdate() {
            try {
                this.form.newPassword.isErr = false;
                this.form.newPassword.errMsg = "";
                this.form.confirmNewPassword.isErr = false;
                this.form.confirmNewPassword.errMsg = "";

                if (this.form.oldPassword.value === this.form.newPassword.value) {
                    this.form.newPassword.isErr = true;
                    this.form.newPassword.errMsg = "Please input a different new password";
                    return;
                } 

                if (this.form.newPassword.value !== this.form.confirmNewPassword.value) {
                    this.form.newPassword.isErr = true;
                    this.form.newPassword.errMsg = "Passwords do not match";
                    
                    this.form.confirmNewPassword.isErr = true;
                    this.form.confirmNewPassword.errMsg = "Passwords do not match";
                    return;
                } 
                
                this.loading = true;

                const payload = {
                    oldPassword: this.form.oldPassword.value,
                    newPassword: this.form.newPassword.value,
                    confirmNewPassword: this.form.confirmNewPassword.value,
                };

                await this.$store.dispatch("app/updatePassword", payload);
                this.$toast.success("Password updated");
                this.onCancel();
            } catch (error) {
                const errMsg = error?.response?.data?.message || error?.message;
                this.$toast.error(errMsg);
            } finally {
                this.loading = false;
            }
        },

        reset() {
            this.form = {
                newPassword: {
                    value: "",
                    errMsg: "",
                    isErr: false,
                },
                confirmNewPassword:{
                    value: "",
                    errMsg: "",
                    isErr: false,
                },
                oldPassword: {
                    value: "",
                    errMsg: "",
                    isErr: false,
                },
            };
        },

        toggleViewPasswordIcon() {
            this.showPassword = !this.showPassword;
        },

        onCancel() {
            this.reset();
            this.$emit("cancel");
        },
    },
};
</script>