import api from "@/api/url";
import { sortList } from "@/utils/helpers";

export default {
    namespaced: true,

    state: () => {
        return {
            projects: [],
            recentProjects: [],
            pageData: {
                currentPage: 1,
                totalPages: 0,
                pageSize: 50,
                total: 0,
            },
            refreshList: true,
        };
    },

    getters: {
        projects: (state) => state.projects,
        pageData: (state) => state.pageData,
        currentProjectTask: state => state.currentProjectTask,
        refreshList: state => state.refreshList,
    },

    mutations: {
        set(state, [key, value]) {
            state[key] = value;
        },
    },
    
    actions: {
        setRefreshList({ commit }, bool) {
            commit("set", ["refreshList", bool]);
        },
        
        addProject({ state, commit }, { payload, external = false }) {   
            // optimistic updates
            const list = state.projects;
            const pageData = state.pageData;
        
            return api.project.create(payload)
                .then((resp) => {
                    const data = resp?.data?.data;
                    
                    if (!data) {
                        throw new Error("Invalid response format");
                    }

                    commit("set", ["projects", [ { ...payload, ...data }, ...list ]]);
                    commit("set", ["pageData", {
                        ...pageData,
                        total: pageData?.total + 1,
                        totalPages: Math.ceil((pageData?.total + 1) / pageData.pageSize),
                    }]);     

                    if (external) return;                        
                    return data;
                })
                .catch((error) => {
                    commit("set", ["projects", list]);
                    commit("set", ["pageData", {
                        ...pageData,
                        total: pageData?.total - 1,
                        totalPages: Math.ceil((pageData?.total - 1) / pageData.pageSize),
                    }]);   

                    throw error; // Re-throw for component handling
                });
        },
        
        fetchProjects({ commit, state }, { params, refresh, config = {}, external = false }) {
            if (!refresh && state.projects.length) return;
            
            return api.project.getAll(params, config)
                .then((resp) => {
                    if (external) {
                        return resp?.data?.data;
                    } else if (config?.responseType) {
                        const data = resp?.data;
                        return data;
                    } else {
                        const data = resp?.data?.data;
    
                        commit("set", ["projects", data.data]);
                        commit("set", ["pageData", {
                            ...state.pageData,
                            ... data.pageDetails,
                        }]);
                    }
                });
        },

        getProject(_, id) {
            return api.project.getOne(id)
                .then((resp) => {
                    const data = resp?.data?.data;                    
                    return data;
                });
        }, 
 
        toggleTimeTracking(_, { id, payload }) {
            return api.project.toggleTimeTracking(id, payload)
                .then((resp) => {
                    const data = resp?.data?.data;
                    return data;      
                });
        },


        updateProject({ commit, state }, { id, payload }) {
            return api.project.updateOne(id, payload)
                .then((resp) => {
                    const data = resp?.data?.data;
                    const projects = state.projects;

                    const updatedProjects = projects.map(c => {
                        if (c?._id === payload?._id) {
                            return {
                                ...c,
                                ...data,
                            };
                        } else {
                            return c;
                        }
                    });
        
                    commit("set", ["projects", updatedProjects]);   
                    return data;      
                });
        },

        deleteProjects({ state, commit }, ids) {
            return api.project.delete({ ids: ids })
                .then((resp) => {
                    // optimistic updates
                    const projects = state.projects;
                    const updatedProjects = projects.filter(c => !ids.includes(c?._id));

                    commit("set", ["projects", updatedProjects]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total - ids.length,
                        totalPages: Math.ceil((state.pageData.total - ids.length) / state.pageData.pageSize),
                    }]);
            
                    return resp;
                }).catch(error => {
                    commit("set", ["projects", projects]);
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total + ids.length,
                        totalPages: Math.ceil((state.pageData.total + ids.length) / state.pageData.pageSize),
                    }]);   
                    throw error; // Re-throw for component handling
                });
        },

        uploadList(_, { formData, progressCB }) {            
            return api.project.upload(formData, { headers: { "Content-Type": "multipart/form-data" }, onUploadProgress: progressCB })
                .then((resp) => {});
        },

        sortProjects({ commit, state }, sortVal) {
            const copiedArr = state.projects.slice();
            
            sortList(sortVal, copiedArr, "title");
                        
            commit("set", ["projects", copiedArr]);
        }, 

        fetchRecentProjects({ commit, state }) {
            if (state.recentProjects.length) {
                return state.recentProjects;
            }

            return api().project.getAll(params)
                .then((resp) => {
                    const data = resp?.data?.data;

                    commit("set", ["recentProjects", data.data]);
                    return data?.data;
                });
        },

        uploadMutlipleProjects(_, { formData, progressCB }) {            
            return api.project.upload(formData, { headers: { "Content-Type": "multipart/form-data" }, onUploadProgress: progressCB })
                .then((resp) => {});
        },

        archiveProject({ state, commit }, id) {            
            return api.project.archive(id)
                .then((resp) => {
                    const data = resp.data.data;
                    const projects = state.projects;

                    const updatedProjects = projects.map(c => {
                        if (c?._id === data?._id) {
                            return {
                                ...c,
                                ...data,
                            };
                        } else {
                            return c;
                        }
                    });

                    commit("set", ["projects", updatedProjects]); 
                    commit("set", ["pageData", {
                        ...state.pageData,
                        total: state.pageData.total - 1,
                        totalPages: Math.ceil(state.updatedProjects.length / 50),
                    }]);
                });
        },

        updateProjectSubs(_, { id, payload }) {
            return api.project.updateSub(id, payload)
                .then((resp) => {});
        },

        removeItemFromStore({ commit }, { storageKey, stateKey, value }) {
            localStorage.removeItem(storageKey);
            commit("set", [`${stateKey}`, value]);
        },

        duplicateProject({ commit, state }, payload) {      
            return api.project.duplicate(payload)
                .then((resp) => {
                    const data = resp?.data?.data;
                    return data;
                });
        },
        
        // ------------- INVOICES ------------------
        fetchProjectInvoices(_, { id, params }) {
            return api.project.getInvoices(id, params)
                .then((resp) => resp.data.data);
        }, 

        // ------------ NOTES ---------------
        getNotes(_, { id, params }) {
            return api.project.getNotes(id, params)
                .then((resp) => {
                    return resp.data.data;
                });
        },

        addNewNote(_, payload) {
            return api.project.createNote(payload)
                .then((resp) => {
                    return resp.data.data;
                });
        },

        updateNote(_, payload) {
            return api.project.editNote(payload.projectId, payload)
                .then((resp) => {
                    return resp.data.data;
                });
        },
        
        deleteNote(_, payload) {
            return api.project.deleteNote(payload.projectId, payload)
                .then((resp) => {
                    return resp.data.data;
                });
        },

        // ------------- TASKS ------------------
        fetchTasks(_, { params, refresh }) {
            return api.task.getTasks(params)
                .then((resp) => {
                    return resp?.data?.data.data;
                });
        },

        addTask(_, payload) {
            return api.task.addTask(payload)
                .then((resp) => {
                    return resp.data.data;
                });
        },

        editTask(_, payload) {
            return api.task.editTask(payload?._id, payload)
                .then((resp) => {
                    return resp.data?.data;
                });
        },

        deleteTask(_, id) {
            return api.task.deleteTask(id)
                .then((resp) => {});
        },

        duplicateTask(_, payload) {
            return api.task.duplicateTask(payload)
                .then((resp) => {
                    const data = resp.data.data;
                    return data;
                });
        },
    },
};