<!-- CustomToast.vue -->
<template>
    <div class="custom-toast">
        <p v-html="message" />
        <div v-if="actions || links" class="toast-ctas">
            <button
                v-for="(action, index) in actions"
                :key="`action-${index}`"
                class="toast-button"
                @click="action.handler"
            >
                {{ action.label }}
            </button>
            <a
                v-for="(link, index) in links"
                :key="`link-${index}`"
                :href="link.url"
                target="_blank"
                rel="noopener noreferrer"
                class="toast-link"
            >
                {{ link.label }}
            </a>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "CustomToast",
    props: {
        message: {
            type: String,
            required: true,
        },
        actions: {
            type: Array,
            default: () => [],
        },
        links: {
            type: Array,
            default: () => [],
        },
    },
    mounted() {
        // console.log("using custom toast");
        // console.log({ "links": this.links, "this.message": this.message });
    },
};
</script>
  
  <style scoped>
  .custom-toast {
    font-family: Arial, sans-serif;
    display: flex;
    align-items: center;
  }

  .toast-ctas {
    margin-left: 10px;
  }
  
  .toast-button {
    margin-right: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
  }
  .toast-link {
    color: #007bff;
    text-decoration: underline;
    margin-right: 10px;
  }
  </style>
  